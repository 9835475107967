import { RequestResponseVM } from './../../../../../view-model/RequestResponseVM';
import { Component, Output, Input, OnInit, EventEmitter, ViewChild } from '@angular/core';
import { ApplicationService } from '../../../../../shared/services/application.service';
import { ValidationService } from '../../../../../shared/services/validation.service';
import { MessagesService } from '../../../../../shared/services/messages.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalService } from '../../../../../shared/services/modal.service';
import { PPBGrupoVM } from "../../../../../view-model/PPBGrupoVM";
import { EnumRestResponse } from '../../../../../shared/enums/EnumRestResponse';
import { GruposPPBComponent } from '../grupos-ppb.component'
import { ChangeEtapaPPBService } from '../../../../../shared/services/changeEtapaPPB.service';
import { ItensPPBVM } from '../../../../../view-model/ItensPPBVM';
import { OrderGrid } from '../../../../../shared/services/orderGridByNumeroSequencia.service';
import { EnviaParametrosSelecaoItemVM } from '../../../../../view-model/EnviaParametrosSelecaoItemVM';
import { ServiceTipoListagemVM } from '../../../../../view-model/ServiceTipoListagemVM';

enum EnumControllPPB{
	OK = 1,
	ERR_DATA_VIGENCIA_VENCIDA = 2,
	ERR_EXISTE_COPIA_EM_ANDAMENTO = 3,
	ERR_ITEM_MARCADO_COMO_EXCLUIDO = 4
}

@Component({
	selector: 'app-grid-grupos-ppb',
	templateUrl: './grid.component.html'
})

export class GridGruposPPBComponent {

	ocultarTituloGrid = true;
	path : string;

	constructor(
		private changeEtapaPPBService : ChangeEtapaPPBService,
		private gruposPPBComponent : GruposPPBComponent,
		private modal : ModalService,
		private validation : ValidationService,
		private route: ActivatedRoute,
		private applicationService: ApplicationService,
		private msg: MessagesService,
		private router: Router,
		private ordenarGrid: OrderGrid
	) {
		this.path = this.route.snapshot.url[this.route.snapshot.url.length - 1].path;
		this._idPPBRota = Number(this.route.snapshot.params['idPpb']);
	 }

	@Input()
		lista: any[];

	@Input()
		total: number;

	@Input()
		size: number;

	@Input()
		page: number;

	@Input()
		sorted: string;

	@Input()
		habilitarInputs: boolean;

	@Input()
		exportarListagem: boolean;

	@Input()
		parametros: any = {};

	@Input()
		formPai: any;

	@Input()
		idPPB: any;

	@Input()
		dataVigenciaInicialPPB : string;

	@Input()
		isAlteracaoParaRevogacao : boolean;

		@Input() isRetificacao : boolean;

	EXCLUIDO : number = 4;
	_idPPBRota : number;

	@Output() onChangeSort: EventEmitter<any> = new EventEmitter();
	@Output() onChangeSize: EventEmitter<any> = new EventEmitter();
	@Output() onChangePage: EventEmitter<any> = new EventEmitter();
	@Output() onChange: EventEmitter<any> = new EventEmitter();
	@Output() atualizaListagemGrid: EventEmitter<any> = new EventEmitter();

	servico = "GrupoPPB";
	tituloGrid = 'Grupos do PPB';

	@ViewChild('appModalIncluirGruposPPB') appModalIncluirGruposPPB : any;
	@ViewChild ('appModalMoverPorPosicao') appModalMoverPorPosicao : any;
  @ViewChild ('appModalHistoricoListagemGrupos') appModalHistoricoListagemGrupos : any;

	changeSize($event : any) {
		this.onChangeSize.emit($event);
	}

	changeSort($event : any) {
		this.sorted = $event.field;
		this.onChangeSort.emit($event);
		this.changePage(this.page);
	}

	changePage($event : any) {
		this.page = $event;
		this.onChangePage.emit($event);
	}

	atualizaListagem($event){
		this.atualizaListagemGrid.emit($event);
	}

	formatDate(date : Date){
		return this.validation.getDateWhithChangeFormat('DD/MM/YYYY', date);
	}

	excluir(item : PPBGrupoVM){
		this.modal.confirmacao("Deseja Excluir o Registro?").subscribe( (isConfirmado : any) => {
			if(isConfirmado) {
				this.apagarBackEnd(item);
			}
		});
	}

	validarSePermiteAbrirModal(item : PPBGrupoVM){

		let serviceObject : ItensPPBVM = new ItensPPBVM();
		serviceObject.idItemCorrente = Number(item.id);
		serviceObject.idPPBCorrente = Number(this._idPPBRota);
		serviceObject.idPPBVinculo = Number(item.idPPB);

		this.applicationService.post(this.servico+"/PostValidacaoModalEdicao", serviceObject).subscribe((result : ItensPPBVM) => {
			if(result.statusControllModal == EnumControllPPB.OK){
				let obj : PPBGrupoVM = new PPBGrupoVM()
				obj.idPPB = Number(this._idPPBRota);
				obj.id = result.idItemCorrente;
				this.atualizaListagens(obj.idPPB);
				this.abrirModal(obj);
			} else if(result.statusControllModal == EnumControllPPB.ERR_DATA_VIGENCIA_VENCIDA) {
				this.modal.informacao("Item está com a Data de Vigência Inferior a Data Corrente!", "Informação").subscribe( () => false);
			} else if(result.statusControllModal == EnumControllPPB.ERR_EXISTE_COPIA_EM_ANDAMENTO){
				this.modal.informacao("Existe uma cópia em Andamento! Apague a cópia para Executar essa Operação.", "Informação").subscribe( () => false);
			}
		});
	}

	abrirModal(item : PPBGrupoVM){
		this.appModalIncluirGruposPPB.abrir(item.idPPB, "editar", item.id);
	}


	apagarBackEnd(item: PPBGrupoVM){

		let serviceObject : ItensPPBVM = new ItensPPBVM();
		serviceObject.idItemCorrente = Number(item.id);
		serviceObject.idPPBCorrente = Number(this._idPPBRota);
		serviceObject.idPPBVinculo = Number(item.idPPB);

		this.applicationService.post(this.servico+"/PostApagarGrupo", serviceObject).subscribe( (result : EnumControllPPB) => {
			if(result == EnumControllPPB.OK){
				this.modal.sucesso(this.msg.OPERACAO_REALIZADA_COM_SUCESSO, "Success").subscribe(() => {
					this.atualizaListagens(serviceObject.idPPBCorrente);
				});
			} else if(result == EnumControllPPB.ERR_EXISTE_COPIA_EM_ANDAMENTO){
				this.modal.informacao("Existe uma cópia em Andamento! Apague a cópia para Executar essa Operação.", "Informação").subscribe( () => false);
			}
			else if(result == EnumControllPPB.ERR_ITEM_MARCADO_COMO_EXCLUIDO){
				this.modal.informacao("Item Ja Está Marcado Como excluído!", "Informação").subscribe( () => false);
			} else {
				this.modal.infoError("Falha ao Apagar Registro!", "Error").subscribe( () => false);
			}
		});
	}

	atualizaListagens(idPPB){
		this.gruposPPBComponent.listar(idPPB);

    const obj = new ServiceTipoListagemVM();
    obj.idPpb = Number(idPPB);
    obj.getFromSessionStorage = true;
    this.changeEtapaPPBService.atualizaListagemPPB(obj);
	}

  ordenarItemGrid(item, tipoOperacao)
  {
    let objetoOrdenacao = new ItensPPBVM();
    objetoOrdenacao.idItemCorrente = item.id;
    objetoOrdenacao.idPPBCorrente = this._idPPBRota;
    objetoOrdenacao.tipoOperacao = tipoOperacao;
    this.applicationService.post("GrupoPPB/OrdenarGrupo",objetoOrdenacao).subscribe((result:RequestResponseVM)=>{
        if(result.statusResponse==200)
        {
          this.atualizaListagem(this._idPPBRota);
        }
        else if(result.statusResponse == 300)
        {
          this.modal.informacao("Esse registro é o primeiro da lista");
        }
        else if(result.statusResponse == 403)
        {
          this.modal.informacao("Esse registro é o ultimo da lista");
        }
        else
        {
          this.modal.error(result.textResponse, "Error");
        }
    });
  }

  validacaoIsRetificacao(item : any) : boolean{
	if(this.isRetificacao){
		if(item.idPPB == this.idPPB){
			return true
		}
		else{
			return false
		};
	}
	return true;
  }

  abrirModalMoverPorSelecao(item){
    let _obj : EnviaParametrosSelecaoItemVM = new EnviaParametrosSelecaoItemVM();
    _obj.tituloItemSelecionado = item.descricaGrupo;
    _obj.descricaoItemOrigem = item.descricaProdutos;
    _obj.numeroSequencia = item.numeroSequencia;
    _obj.idItemOrigem = item.id;
    _obj.idPpb = Number(this.route.snapshot.params['idPpb']);
    const viewRef = "Grupo"
    this.appModalMoverPorPosicao.abrir(_obj,viewRef);
  }

  abrirHistorico(item: any){
    this.appModalHistoricoListagemGrupos.abrir(item.descricaGrupo, 'Histórico do Grupo', item.idPPB, item.id);
  }

}
