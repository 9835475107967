<div class="modal-dialog center">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header bg-primary" style="padding: 10px 15px; color: white;">
          <h4 class="modal-title">{{ title || 'Justificativa'}}</h4>
        </div>
        <div class="modal-body text-justified">            
            <label for="descricao" class="">Descrição Justificativa:</label>
            <textarea
                class="form-control ng-pristine ng-valid ng-touched"
                id="descricao"
                name="descricao"
                rows="3"
                style="resize: none;"
                [(ngModel)]="justificativa">
            </textarea>
            <div *ngIf=" _isJustificativaVazia " class="alert-danger">
                <b style="margin-left: 15px;">
                    <button type="button" class="close" (click)=" _isJustificativaVazia = false; "><b style="color:black;">&times;</b></button>
                    <b style="margin-left: 15px;">Informe a Justificativa </b>
                </b>
            </div>       
        </div>
        <div class="modal-footer" style="padding: 8px 15px;">
            <button 
                style="margin-right: 5px; cursor: pointer;"
                [mat-dialog-close]="false"
                type="button" 
                class="btn btn-default" 
                data-dismiss="modal">
                    <i class="fa fa-times" style="color: black;"></i> Não
            </button>
            <button         
                style="cursor: pointer;"   
                (click)="confirm()"
                color="primary"
                type="button" 
                class="btn btn-primary">
                    <i class="fa fa-save" style="color: white;"></i> Salvar
            </button>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-backdrop fade in"></div>