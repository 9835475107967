<app-grid
  [(titulo)]="tituloGrid"
  [(page)]="parametros.page"
  [(size)]="parametros.size"
  [(total)]="grid.total"
  [(parametros)]="parametros"
  (onChangeSize)="changeSize($event)"
  (onChangePage)="changePage($event)"
>
<div class="table-responsive no-margin-bottom no-border">
  <table class="table">
    <thead class="table-header-color">
      <tr>
        <th class="th-100 text-left"></th>
        <th class="th-sortable unset-nowrap text-left">Nº Sequencial</th>
        <th class="th-sortable unset-nowrap text-left">{{ titleCol2 }}</th>
        <th class="th-sortable unset-nowrap text-left">{{ descricaoTitle }}</th>
        <th class="th-sortable unset-nowrap text-left">Documento</th>
        <th  class="th-sortable unset-nowrap text-left" *ngIf="_exibeColunaPpbMultiplo && viewRef == 'Etapa'">
          PPB Multiplo
        </th>
      </tr>
    </thead>
    <tbody
      *ngFor="let item of grid.lista; let i = index"
      [attr.data-index]="i"
    >
      <tr>
        <td class="text-center">
          <input
            name="radios"
            type="radio"
            [value]="item.id"
            (change)="onChangeRadioSelector($event)"
          />
        </td>
        <td class="text-center">
          {{ item.numeroSequencia }}
        </td>
        <td class="">
          {{ obterValorCol3(item) }}
        </td>
        <td class="text-left">
          <span *ngIf="!item.existeDescricao"> -- </span>
          <a *ngIf="item.existeDescricao" style="color: blue">
            Clique em Expandir para Visualizar
          </a>
          <app-collapse-row-button
            style="cursor: pointer"
            *ngIf="item.existeDescricao"
            target="{{ 'desc-' + i }}"
          >
          </app-collapse-row-button>
        </td>
        <td>
          {{ obterValorCol5(item) }}
        </td>
        <td  *ngIf="_exibeColunaPpbMultiplo && viewRef == 'Etapa'">
          {{ item.ppbMultiplo.descricao }}
        </td>
        <!-- <td *ngIf="viewRef == 'PPB'">
          {{ item.descricaoPPBMultiplo || "--" }}
        </td> -->
      </tr>
      <td colspan="6" id="{{ 'desc-' + i }}" style="display: none">
        <div
          class="row"
          style="margin-left: 30px; height: 500px; overflow: scroll"
          *ngIf="item.existeDescricao"
        >
          <legend style="margin-top: 15px">Descrição</legend>
          <angular-editor
            [(ngModel)]="item.descricao"
            [config]="config"
          ></angular-editor>
        </div>
      </td>
    </tbody>
  </table>
</div>
</app-grid>

<div class="row form-group">
  <div style="margin-left: 15px">
    <div
      class="col-lg-6"
      style="margin-top: 26px"
      *ngIf="idItemDestino"
    >
      <button
        (click)="alterarPosicao('anterior')"
        title="Mover para o Local Selecionado"
        data-original-title="Mover para o Local Selecionado"
        data-toggle="tooltip"
        class="btn btn-sm btn-info pull-left"
      >
        <i class="fa fa-share" aria-hidden="true"
          >Mover para o Local Selecionado</i
        >
      </button>
    </div>
  </div>
</div>
