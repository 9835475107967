import { FiltrosModalListagemVM } from './FiltrosModalListagemVM';
import { PagedItems } from './PagedItems';
export class PPBGrupoVM extends PagedItems{
    id: Number;
    idPPB: Number;
    descricaGrupo: string;
    descricaProdutos: string;
    descricaoMetas: string;
    dataVigenciaInicial: Date;
    dataVigenciaFinal: Date;
    statusGrupo: number;
    buscarVinculos: boolean;
    numeroSequencia: number;
    fromGridPorGrupo: boolean;
    isSomenteVigente: boolean;
    objetoConsultaFiltrada: FiltrosModalListagemVM;
    isConsultaFiltradaModalListagem: boolean = false;
    exibirRevogados:boolean = false;
}

