<s *ngIf="indicadorParaTaxarItem && !flagIsTextArea">
  {{ (texto == null || texto == undefined) ? '--' : texto }}
</s>

<span *ngIf="!indicadorParaTaxarItem && !flagIsTextArea">
  {{ (texto == null || texto == undefined) ? '--' : texto }}
</span>

<textarea
  *ngIf="flagIsTextArea && !indicadorParaTaxarItem"
  [(ngModel)]="texto"
  type="text"
  name="textarea-taxa-item"
  id="textarea-taxa-item"
  [attr.style]="getStyle()"
  [ngStyle]="{'background-color': backgroundAmarelo ? '#ffff99' : 'white'}"
  class="form-control"
  [attr.rows]="calculaQtdLinhas()"
  disabled>
</textarea>

<textarea
  *ngIf="flagIsTextArea && indicadorParaTaxarItem"
  [(ngModel)]="texto"
  type="text"
  name="textarea-taxa-item"
  id="textarea-taxa-item"
  [attr.style]="getStyle()"
  [ngStyle]="{'background-color': backgroundAmarelo ? '#ffff99' : 'white'}"
  class="form-control"
  [attr.rows]="calculaQtdLinhas()"
  disabled>
</textarea>
