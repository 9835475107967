import { Component, Injectable, OnInit, ViewChild, Output, EventEmitter } from "@angular/core";
import { MessagesService } from "../../../../shared/services/messages.service";
import { ValidationService } from "../../../../shared/services/validation.service";
import { ApplicationService } from "../../../../shared/services/application.service";
import { ActivatedRoute} from "@angular/router";
import { ModalService } from '../../../../shared/services/modal.service';
import { PPBAnexoVM } from "../../../../view-model/PPBAnexoVM";
import { PPBVM } from "../../../../view-model/PPBVM";

@Component({
	selector: 'app-modal-baixar-anexos',
	templateUrl: './modal-anexos.component.html'
})
export class ModalBaixarAnexos implements OnInit {

	listaAnexos: Array<PPBAnexoVM>;

    @ViewChild('appModalBaixarAnexo') appModalBaixarAnexo : any;
	@ViewChild('appModalBaixarAnexobackground') appModalBaixarAnexobackground : any;

	servicoAnexo = 'AnexoPPB';
    titulo : string = "";

	constructor(
        private modal: ModalService,
		private applicationService: ApplicationService,
		private msg: MessagesService,
		private validation: ValidationService,
		private route: ActivatedRoute
	) {
    }

    ngOnInit() {
	}

	public abrir(objeto: PPBVM){
        this.appModalBaixarAnexobackground.nativeElement.style.display = 'block';
		this.appModalBaixarAnexo.nativeElement.style.display = 'block';
        this.titulo = objeto.descricaoTitulo;
		this.buscarAnexos(objeto.id);
    }

    public fechar(){
        this.appModalBaixarAnexobackground.nativeElement.style.display = 'none';
		this.appModalBaixarAnexo.nativeElement.style.display = 'none';
		this.listaAnexos = new Array<PPBAnexoVM>();
        this.titulo = "";
    }

	buscarAnexos(idPPB: number){
		this.applicationService.get(this.servicoAnexo, idPPB).subscribe((response: Array<PPBAnexoVM>) => {
			if(response != null){
				this.listaAnexos = response;
			}else{
				this.modal.informacao("O PPB não possui Anexo","Não encontrado !");
			}
		});
	}

  baixarAnexo(anexo: PPBAnexoVM) {
		if (anexo.objetoArquivo != null) {
			const hashPDF = anexo.objetoArquivo;
			const linkSource = 'data:' + 'application/pdf' + ';base64,' + hashPDF;
			const downloadLink = document.createElement('a');
			const fileName = anexo.descricaoArquivo;
			document.body.appendChild(downloadLink);
			downloadLink.href = linkSource;
			downloadLink.download = fileName;
			downloadLink.target = '_self';
			downloadLink.click();
		} else {
			this.modal.infoError('Erro ao baixar arquivo.', 'Informação');
			return false;
		}
 	}

	PdfNovaAba(str, title) {
        var objbuilder = '';
            objbuilder += ('<object width="100%" height="100%" data="data:application/pdf;base64,');
            objbuilder += (str);
            objbuilder += ('" type="application/pdf" class="internal">');
            objbuilder += ('<embed src="data:application/pdf;base64,');
            objbuilder += (str);
            objbuilder += ('" type="application/pdf"  />');
            objbuilder += ('</object>');
            var win = window.open("#","_blank");
            if(!win || win.closed || typeof win.closed=='undefined')
            {
                this.modal.infoError("O Anexo não pode ser automaticamente exibido, pois o bloqueador de pop-ups do navegador está ativo.", "Informação");
            } else{
                win.document.write('<html><title>'+ title +'</title><body style="margin-top: 0px; margin-left: 0px; margin-right: 0px; margin-bottom: 0px;">');
                win.document.write(objbuilder);
                win.document.write('</body></html>');
                var layer = jQuery(win.document);
            }
    }

}
