import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../shared/shared.module';
import { CnpjPipe, CnpjPipe2 } from '../shared/pipes/cnpj.pipe';
import { CpfPipe } from '../shared/pipes/cpf.pipe';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        RouterModule,
        SharedModule
    ],
    declarations: [
    ],
    exports: [
    ],
    providers: [
        CnpjPipe,
        CnpjPipe2,
        CpfPipe
    ],
    // Don't forget to add the component to entryComponents section
    entryComponents: [

    ]
})
export class ViewsComponentsModule { }
