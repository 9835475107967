<app-grid
    [(titulo)]="tituloGrid"
    [(page)]="page"
    [(size)]="size"
    [(total)]="total"
    [(parametros)]="parametros"
    (onChangeSize)="changeSize($event)"
    (onChangePage)="changePage($event)">

	<div class="table-responsive no-margin-bottom no-border">
		<table class="table table">

            <!-- ##################################################### -->
            <!-- ################ TABELA POR GRUPO ################### -->
            <!-- ##################################################### -->


            <thead class="table-header-color" *ngIf="_isPorGrupo ">
              <!-- TO NO GRUPO -->
                <tr>
                    <th class="th-100 text-left" *ngIf="habilitarInputs">Ações</th>
                    <th class="th-sortable unset-nowrap text-left" *ngIf="habilitarInputs">
                      Nº Sequencial
                    </th>
                    <th class="th-sortable unset-nowrap text-left">
                         Etapa
                    </th>
                    <th class="th-sortable unset-nowrap text-left">
                        Vigência
                    </th>
                    <th class="th-sortable unset-nowrap text-left">
                        Descrição das Etapas
                    </th>
                    <th class="th-sortable unset-nowrap text-left">
                        Documento
                    </th>
                    <th class="th-sortable unset-nowrap text-left" *ngIf=" _isPorPontuacao ">
                        Pontuação
                    </th>
                    <th class="th-sortable unset-nowrap text-left" *ngIf="_isPPBMultiplo ">
                      PPB Multiplo
                    </th>
                    <th
                        *ngFor="let item of infoGrupos.letrasGruposPPB; let i = index ;">
                            <app-ordenacao
                                field="{{i}}"
                                [sorted]="sorted"
                                (onChangeSort)="changeSort($event)">
                                <span > Grupo {{ item }} </span>
                            </app-ordenacao>
                    </th>
                </tr>
            </thead>
            <ng-container *ngIf=" _isPorGrupo ">
                <tbody *ngFor="let item of lista; let j = index">
                    <tr  [ngStyle]="{'background-color': item.idPPB != idPPB ? '#ffff99' : ''  }">
                        <td class="text-left" *ngIf="habilitarInputs">
                            <a
                                *ngIf="item.habilitaBotoes && habilitarInputs"
                                (click)="validarSePermiteAbrirModal(item)"
                                class="btn btn-default btn-sm"
                                data-toggle="tooltip"
                                title="Alterar"
                                data-original-title="Alterar">
                                    <i class="fa fa-pencil"></i>
                            </a>
                            <!--
                            ######################################################
                                No Tipo de Publicação "Alteração para Revogação",
                                as linhas amarelas não deverão mostrar o botão de excluir.
                            ######################################################
                            -->
                            <a
                                *ngIf="item.habilitaBotoes && item.exibeBotaoExclusao && habilitarInputs"
                                (click)="excluir(item)"
                                class="btn btn-danger btn-sm"
                                data-toggle="tooltip"
                                title="Excluir"
                                data-original-title="Excluir">
                                    <i class="fa fa-trash-o"></i>
                            </a>
                        </td>
                        <td class="text-center" *ngIf="habilitarInputs">
                            <a
                              *ngIf="habilitarInputs"
                              (click)="abrirModalMoverPorSelecao(item)"
                              data-toggle="tooltip"
                              title="Mover por seleção"
                              data-original-title="Mover por seleção"
                              style="color: blue; cursor: pointer;">
                                {{item.numeroSequencia}}
                            </a>
                            <span *ngIf="!habilitarInputs"> {{item.numeroSequencia}} </span>
                        </td>
                        <td class="text-left">
                          <app-taxa-texto
                              [(texto)]="item.descricaTopico"
                              [backgroundAmarelo]="item.idPPB != idPPB"
                              [(indicadorParaTaxarItem)]="item.indicadorParaTaxarItem">
                          </app-taxa-texto>
                        </td>
                        <td class="text-left">
                          <app-taxa-texto
                              [(texto)]="item.dataInicioVigenciaString"
                              [(indicadorParaTaxarItem)]="item.indicadorParaTaxarItem">
                          </app-taxa-texto>
                        </td>
                        <td class="text-left">
                            <span *ngIf="!item.existeDescricaoEtapa"> -- </span>
                            <a
                                *ngIf="item.existeDescricaoEtapa"
                                style="color: blue;"> Clique em Expandir para Visualizar
                            </a>
                            <app-collapse-row-button
                                (click)="buscarDescricaoEtapa(item, j)"
                                style="cursor: pointer;"
                                *ngIf="item.existeDescricaoEtapa"
                                target="{{'etapa-' + j}}">
                            </app-collapse-row-button>
                        </td>
                        <td class="text-left">
                            <app-taxa-texto
                                [(texto)]="item.descricaoDocumento"
                                [(indicadorParaTaxarItem)]="item.indicadorParaTaxarItem">
                            </app-taxa-texto>
                        </td>
                        <td class="text-left" *ngIf=" _isPorPontuacao ">
                          <!--  OS_10027 [texto]="(item.valorPontuacao | number:'':'es')" -->
                            <app-taxa-texto
                                [texto]="item.valorPontuacao"
                                [(indicadorParaTaxarItem)]="item.indicadorParaTaxarItem">
                            </app-taxa-texto>
                        </td>
                        <td class="text-left" *ngIf=" _isPPBMultiplo ">
                          <app-taxa-texto
                              [(texto)]="item.ppbMultiplo.descricao"
                              [(indicadorParaTaxarItem)]="item.indicadorParaTaxarItem">
                          </app-taxa-texto>
                        </td>
                        <td
                            class="text-left"
                            *ngFor="let indice of item.gruposEtapaPPB">
                            {{ (indice.totalPontuacao == null || indice.totalPontuacao == undefined) ? '--' : formatter(indice.totalPontuacao) }}
                        </td>

                    </tr>
                    <td colspan="6" id="{{ 'etapa-' + j }}" style="display:none" >
                        <div class="row" style="margin-left: 30px; height: 500px; overflow: scroll;" *ngIf="item.existeDescricaoEtapa">
                            <legend style="margin-top: 15px;">Descrição da Etapa</legend>
                            <angular-editor [(ngModel)]="item.descricaoEtapa" [config]="config"></angular-editor>
                        </div>
                    </td>
                </tbody>
            </ng-container>

            <!-- ##################################################### -->
            <!-- ################ TABELA PADRÃO ################### -->
            <!-- ##################################################### -->

			<thead class="table-header-color" *ngIf=" !_isPorGrupo ">
        <!-- TO FORA GRUPO -->
				<tr>
              <th class="th-100 text-left" *ngIf="habilitarInputs">Ações</th>
              <th class="th-sortable unset-nowrap text-left" *ngIf="habilitarInputs">
                Nº Sequencial
              </th>
              <th class="th-sortable unset-nowrap text-left">
                  Etapa
              </th>
              <th class="th-sortable unset-nowrap text-left">
                  Vigência
              </th>
              <th class="th-sortable unset-nowrap text-left">
                  Descrição das Etapas
              </th>
              <th class="th-sortable unset-nowrap text-left">
                  Documento
              </th>
              <th class="th-sortable unset-nowrap text-left" *ngIf=" _isPorPontuacao ">
                  <app-ordenacao field="ValorPontuacao" [sorted]="sorted" (onChangeSort)="changeSort($event)">
                      Pontuação
                  </app-ordenacao>
              </th>
              <th class="th-sortable unset-nowrap text-left" *ngIf="_isPPBMultiplo ">
                  PPB Multiplo
              </th>
				</tr>
			</thead>
      <ng-container *ngIf=" !_isPorGrupo ">
          <tbody *ngFor="let item of lista; let j = index">
            <tr [ngStyle]="{'background-color': item.idPPB != idPPB ? '#ffff99' : ''  }">
              <td class="text-left" *ngIf="habilitarInputs">
                  <a
                      *ngIf="validacaoIsRetificacao(item) && item.habilitaBotoes && habilitarInputs"
                      (click)="validarSePermiteAbrirModal(item)"
                      class="btn btn-default btn-sm"
                      data-toggle="tooltip"
                      title="Alterar"
                      data-original-title="Alterar">
                          <i class="fa fa-pencil"></i>
                  </a>
                  <!--
                  ######################################################
                      No Tipo de Publicação "Alteração para Revogação",
                      as linhas amarelas não deverão mostrar o botão de excluir.
                  ######################################################
                  -->
                  <a
                      *ngIf="validacaoIsRetificacao(item) && item.habilitaBotoes && item.exibeBotaoExclusao && habilitarInputs"
                      (click)="excluir(item)"
                      class="btn btn-danger btn-sm"
                      data-toggle="tooltip"
                      title="Excluir"
                      data-original-title="Excluir">
                          <i class="fa fa-trash-o"></i>
                  </a>
              </td>
              <td class="text-center" *ngIf="habilitarInputs">
                  <a
                    *ngIf="habilitarInputs"
                    (click)="abrirModalMoverPorSelecao(item)"
                    data-toggle="tooltip"
                    title="Mover por seleção"
                    data-original-title="Mover por seleção"
                    style="color: blue; cursor: pointer;">
                      {{item.numeroSequencia}}
                  </a>
                  <span *ngIf="!habilitarInputs">{{item.numeroSequencia}}</span>
                </td>
              <td class="text-left">
                  <p style="white-space: pre-line;">
                    <app-taxa-texto
                        [(texto)]="item.descricaTopico"
                        [backgroundAmarelo]="item.idPPB != idPPB"
                        [(indicadorParaTaxarItem)]="item.indicadorParaTaxarItem">
                    </app-taxa-texto>
                  </p>
              </td>
              <td class="text-left">
                  <app-taxa-texto
                      [(texto)]="item.dataInicioVigenciaString"
                      [(indicadorParaTaxarItem)]="item.indicadorParaTaxarItem">
                  </app-taxa-texto>
              </td>
              <td class="text-left">
                  <span *ngIf="!item.existeDescricaoEtapa"> -- </span>
                  <a
                      *ngIf="item.existeDescricaoEtapa"
                      style="color: blue;"> Clique em Expandir para Visualizar
                  </a>
                  <!-- (click)="buscarDescricaoEtapa(item, j)" -->
                  <app-collapse-row-button
                      style="cursor: pointer;"
                      *ngIf="item.existeDescricaoEtapa"
                      target="{{'etapa-' + j}}">
                  </app-collapse-row-button>
              </td>
              <td class="text-left">
                  <app-taxa-texto
                      [(texto)]="item.descricaoDocumento"
                      [(indicadorParaTaxarItem)]="item.indicadorParaTaxarItem">
                  </app-taxa-texto>
              </td>
              <td class="text-left" *ngIf="_isPorPontuacao || _isPorGrupo">
                  <app-taxa-texto
                      [texto]="item.valorPontuacao ? formatter(item.valorPontuacao) : '--'"
                      [(indicadorParaTaxarItem)]="item.indicadorParaTaxarItem">
                  </app-taxa-texto>
              </td>
              <td class="text-left" *ngIf="_isPPBMultiplo">
                  <app-taxa-texto
                      [(texto)]="item.ppbMultiplo.descricao"
                      [(indicadorParaTaxarItem)]="item.indicadorParaTaxarItem">
                  </app-taxa-texto>
              </td>
            </tr>
              <td colspan="6" id="{{ 'etapa-' + j }}" style="display:none" >
                  <div class="row" style="margin-left: 30px; height: 500px; overflow: scroll;" *ngIf="item.existeDescricaoEtapa">
                      <legend style="margin-top: 15px;">Descrição da Etapa</legend>
                      <angular-editor [(ngModel)]="item.descricaoEtapa" [config]="config"></angular-editor>
                  </div>
              </td>
          </tbody>
      </ng-container>

		</table>
	</div>

</app-grid>

<!-- ##################################################### -->
<!-- ############## PONTUAÇÃO GERAL ###################### -->
<!-- ##################################################### -->
<div style="margin-top: 10px;" class="table-responsive no-margin-bottom no-border" *ngIf=" _isPorPontuacao ">
    <table class="table" _ngcontent-c2="">
        <thead style="background-color: #00552b; color: white;">
        <tr>
        </tr>
        </thead>
        <tbody>
            <tr>
                <td class="text-center">Pontuação Total</td>
                <td  class="text-center">{{ (infoGrupos.valorPontuacaoGeral == null || infoGrupos.valorPontuacaoGeral == undefined) ? '--' : formatter(infoGrupos.valorPontuacaoGeral) }}</td>
            </tr>
        </tbody>
    </table>
</div>

<!-- ##################################################### -->
<!-- ########## TABELA PONTUACAO DOS GRUPOS ############## -->
<!-- ##################################################### -->
<div style="margin-top: 10px;" class="table-responsive no-margin-bottom no-border" *ngIf=" _isPorGrupo ">
    <table class="table table-striped" _ngcontent-c2="">
        <thead style="background-color: #00552b; color: white;">
            <tr>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let item of infoGrupos.valoresTotaisGrupoMPPB">
                <td  class="text-center">Total do Grupo {{ item.letraGrupo }}</td>
                <td  class="text-center">Total {{ (item.totalPontuacao == null || item.totalPontuacao == undefined) ? '--' : formatter(item.totalPontuacao) }}</td>
            </tr>
        </tbody>
    </table>
</div>

<app-modal-incluir-etapas-ppb
    #appModalIncluirEtapasPPB
    [(dataVigenciaInicialPPB)]="dataVigenciaInicialPPB"
    (atualizaListagem)="atualizaListagem($event)">
</app-modal-incluir-etapas-ppb>

<app-modal-mover-por-selecao
  [_exibeColunaPpbMultiplo]="_isPPBMultiplo"
  [_servicoDropDown]="'OrdenacaoEtapas'"
  [_servicoParaSalvar]="'EtapasPPB'"
  (atualizaListagemGrid)="atualizaListagem($event)"
  #appModalMoverPorPosicao>
</app-modal-mover-por-selecao>
<!--
[(_isPPBMultiplo)]= "_isPPBMultiplo"
[(_isPorGrupo)] = "_isPorGrupo"
[(_isPorPontuacao)] = "_isPorPontuacao"
-->
