import { Component, Injectable, OnInit, ViewChild, Input } from "@angular/core";
import { PagedItems } from "../../../../view-model/PagedItems";
import { ValidationService } from "../../../../shared/services/validation.service";
import { ApplicationService } from "../../../../shared/services/application.service";
import { ModalService } from "../../../../shared/services/modal.service";
import { MetaPPBVM } from "../../../../view-model/MetaPPBVM";
import { ChangeMetasPPBService } from "../../../../shared/services/changeMetasPPB.service";
import { ServiceTipoListagemVM } from "../../../../view-model/ServiceTipoListagemVM";
import { FiltrosModalListagemVM } from "../../../../view-model/FiltrosModalListagemVM";
import { TiposReferenciaVM } from "../../../../view-model/TiposReferenciaVM";

@Component({
	selector: "app-metas-ppb",
	templateUrl: "./metas-ppb.component.html"
})
@Injectable()

export class MetasPPBComponent implements OnInit {


	@Input()
		idPPB: number;

	@Input()
		habilitarInputs: boolean;

	@Input()
		dataVigenciaInicialPPB : string;

	@Input()
		validacao : any = {} = null;

	@Input()
		buscarVinculos: boolean;

	@Input()
		_isPorGrupo: boolean;

	@Input()
		isAlteracaoParaRevogacao : boolean;

	@Input()
		_fromTelaElaboracao : boolean;

	@Input()
		_isPorPontuacao : boolean;

	@Input()
		isRetificacao : boolean;

  @Input() flagIsSomenteVigente: boolean = false;
  @Input() objConsultaFiltradaModalListagem: FiltrosModalListagemVM;
  @Input() isConsultaFiltradaModalListagem: boolean = false;

  @Input() exibeRevogados:boolean;

  @ViewChild('appModalIncluirMetasPPB') appModalIncluirMetasPPB : any;
  @ViewChild('appModalMoverPorPosicao') appModalMoverPorPosicao : any;
  @ViewChild('appModalHistoricoListagemMetas') appModalHistoricoListagemMetas : any;

	grid: any = { sort: {} };
	parametros = new MetaPPBVM();

	servico = "MetasPPB";

	constructor(
		private applicationService: ApplicationService,
		private validationService: ValidationService,
		private modal: ModalService,
    private ChangeMetasPPBService: ChangeMetasPPBService
	) {

	}

	ngOnInit() {
		this.buscar();
    this.ChangeMetasPPBService.AtualizaListagemMetasPPB.subscribe(
			(obj: ServiceTipoListagemVM) => {
        if(obj != null){
          //console.log('to aqui - metas');
          this.flagIsSomenteVigente = obj.isSomenteVigente;
          this.grid.page = 1,
			    this.grid.size = 10,
          this.buscar();
        }
      }
		);

	}

	onChangeSort($event : any) {
		this.grid.sort = $event;
		this.buscar();
	}

	onChangeSize($event : any) {
		this.grid.size = $event;
    this.grid.page = 1;
		this.buscar();
	}

	onChangePage($event : any) {
		this.grid.page = $event;
		this.buscar();
	}

	incluir(){
		this.appModalIncluirMetasPPB.abrir(this.idPPB);
	}

	buscar() {

		var consulta = {
			page: this.grid.page || 1,
			size: this.grid.size || 10,
			sort: this.grid.sort.field || "NumeroSequencia",
			reverse: this.grid.sort.reverse,
			idPPB: this.idPPB,
			buscarVinculos: this.buscarVinculos,
			fromTelaElaboracao : this._fromTelaElaboracao,
      isSomenteVigente: this.flagIsSomenteVigente,
      objetoConsultaFiltrada: this.objConsultaFiltradaModalListagem,
      isConsultaFiltradaModalListagem: this.isConsultaFiltradaModalListagem,
      exibirRevogados:this.exibeRevogados
		}

		this.applicationService.get(this.servico, consulta).subscribe((result: PagedItems) => {
			if(result.total != null && result.total > 0){
				this.grid.lista = result.items;
				this.grid.total = result.total;
			} else {
				this.grid = { sort: {} };
			}
		});
	}

  abrirModalOrdenacao($event){
    const nList = this.grid.lista.filter(x=>x.id!= $event.id);
    const viewRef = "Metas"
    this.appModalMoverPorPosicao.abrir($event,viewRef,nList);
  }

  abrirModalMetas($event){
    this.appModalHistoricoListagemMetas.abrir(String($event.valorMeta), 'Histórico da Meta', $event.idPPB, $event.id);
  }

}
