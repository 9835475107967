<div class="row form-group" style="margin-left: 2px;">	
   
    <app-grid-produtos-vinculados-ppb     
        [(lista)]="grid.lista"
        [(total)]="grid.total"
        [(size)]="grid.size"
        [(page)]="grid.page"
        [(parametros)]="parametros"
        (onChangeSort)="onChangeSort($event)"
        (onChangeSize)="onChangeSize($event)"
        (onChangePage)="onChangePage($event)"
    >
    </app-grid-produtos-vinculados-ppb>

</div>