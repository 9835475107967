
export class CronogramaItemVM{
  idCriacao : number;
  idPPB: number;
  idPPBGrupo: number;
  descricaoTopico: string;
  descricaoEtapa : string;
  ano2023: number;
  ano2024: number;
  ano2025: number;
  ano2026: number;
  ano2027: number;
  isSomatorio: boolean;

}
