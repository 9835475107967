import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// ORDEM ALFABÉTICA
import { ApplicationService } from './application.service';
import { ArrayService } from './array.service';
import { AuthenticationService } from './authentication.service';
import { ConverterService } from './converter.service';
import { DateService } from './date.service';
import { ExtractNumberService } from './extract-number.service';
import { GeneratorService } from './generator.service';
import { InformationService } from './information.service';
import { LoadingService } from './loading.service';
import { MessagesService } from './messages.service';
import { ObjectService } from './object.service';
import { SharedDataService } from './shared-data.service';
import { StringService } from './string.service';
import { ValidationService } from './validation.service';
import { ExcelService } from './excel.service';
import { Excel2Service } from './excel2.service';
import { DataSharedService } from './data-shared.service';
import { PssService } from './pss.service';
import { DiffDateDaysService } from './diff-date-days.service';
import { PDFService } from './pdf.service';
import { ModalService } from './modal.service';
import { ChangeEtapaPPBService } from './changeEtapaPPB.service';
import { ChangeGrupoPPBService } from './changeGrupoPPB.service';
import { ChangeCondicionantePPBService } from './changeCondicionantePPB.service';
import { LoaderService } from './loader.service';
import { InterceptorService } from './interceptors.service';
import { OrderGrid } from './orderGridByNumeroSequencia.service';
import { ChangeIniciaPaginacao } from './changeIniciaPaginacao.service';
import { ChangeMetasPPBService } from './changeMetasPPB.service';
import { ChangePpbMultiploService } from './changePpbMultiplo.service';
import { ChangeListagemProdutosService } from './changeListagemProdutos.service';
import { ControladorTiposEtapaPPB } from './controladorTiposEtapaPpb.service';

@NgModule({
	imports: [
		CommonModule,
	],
	declarations: [],
	providers: [
    ChangeListagemProdutosService,
    ChangePpbMultiploService,
    ChangeMetasPPBService,
		ChangeIniciaPaginacao,
		ApplicationService,
		ArrayService,
		AuthenticationService,
		ConverterService,
		DateService,
		ExtractNumberService,
		GeneratorService,
		InformationService,
		LoadingService,
		MessagesService,
		ObjectService,
		PssService,
		ModalService,
		SharedDataService,
		StringService,
		ValidationService,
		ExcelService,
		Excel2Service,
		PDFService,
		DataSharedService,
		DiffDateDaysService,
		ChangeEtapaPPBService,
		ChangeGrupoPPBService,
		ChangeCondicionantePPBService,
		LoaderService,
		InterceptorService,
		OrderGrid,
    ControladorTiposEtapaPPB
	]
})
export class ServicesModule { }
