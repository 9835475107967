<app-grid-historico-listagem
  [(tituloGrid)]="_nomeGrid"
  [(lista)]="grid.lista"
  [(total)]="grid.total"
  [(size)]="grid.size"
  [(page)]="grid.page"
  [(parametros)]="parametros"
  (onChangeSort)="onChangeSort($event)"
  (onChangeSize)="onChangeSize($event)"
  (onChangePage)="onChangePage($event)">
</app-grid-historico-listagem>
