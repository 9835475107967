import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ChangeIniciaPaginacao } from '../../services/changeIniciaPaginacao.service';

@Component({
	selector: 'app-grid-cabecalho',
	templateUrl: './grid-cabecalho.component.html',
})
export class GridCabecalhoComponent {
	@Input() size : number = 1;
	@Input() setPage : number = 1;

	@Output() onChangeSize: EventEmitter<number> = new EventEmitter();

	constructor(private changeIniciaPaginacao : ChangeIniciaPaginacao) { }

	changeSize($event : any) {
		this.onChangeSize.emit($event);

		//reinicia a paginacao dentro de app-grid
		this.changeIniciaPaginacao.Reinicia(true);
	}
}
