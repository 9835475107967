import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { ReCaptchaModule } from 'angular2-recaptcha';

// Importação de componentes customizados devem ser organizados por ordem alfabética
import { CalendarComponent } from './calendar/calendar.component';
import { CaptchaComponent } from './captcha/captcha.component';
import { DropListComponent } from './drop-list/drop-list.component';
import { GridCabecalhoComponent } from './grid-cabecalho/grid-cabecalho.component';
import { GridComponent } from './grid/grid.component';
import { ItensPorPaginaComponent } from './itens-por-pagina/itens-por-pagina.component';
import { ModalSucessComponent } from './modal/modal-success/modal-sucess.component'; 
import { ModalErrorComponent } from './modal/modal-error/modal-error.component';
import { ModalConfirmacaoComponent } from './modal/modal-confirmacao/modal-confirmacao.component';
import { ModalRespostaSucessComponent } from "./modal/modal-resposta-sucess/modal-resposta-sucess.component";
import { ModalRespostaErrorComponent } from './modal/modal-resposta-error/modal-resposta-error.component';
import { OrdenacaoComponent } from './ordenacao/ordenacao.component';
import { PaginacaoComponent } from './paginacao/paginacao.component';
import { ResultadoPorPaginasComponent } from './resultado-por-paginas/resultado-por-paginas.component';
import { RodapeMenuComponent } from './rodape-menu/rodape-menu.component';
import { SubTituloComponent } from './sub-titulo/sub-titulo.component';
import { TituloComponent } from './titulo/titulo.component';
import { AcoesComponent } from './acoes/acoes.component';
import { CollapseButtonComponent } from './collapse-button/collapse-button.component';
import { CollapseRowButtonComponent } from './collapse-row-button/collapse-row-button.component';
import { Select2CustomComponent } from './select2-custom/select2-custom.component';
import { DropListSelectComponent } from './drop-list/drop-list-select.component';
//import { DropListSelect2Component } from './drop-list-2/drop-list-2.component';
import { ModalInformacaoComponent } from './modal/modal-informacao/modal-informacao.component';
import { TaxaTextoComponent } from './taxa-item/taxa-item.component';

//importacao Modais de Interação
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { ModalJustificativaComponent } from './modal/modal-justificativa/modal-justificativa.component';

@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		ReCaptchaModule,
		MatDialogModule,
		MatIconModule,
		MatButtonModule,
	],
	declarations: [
		CalendarComponent,
		CaptchaComponent,
		DropListComponent,
		GridCabecalhoComponent,
		GridComponent,
		ItensPorPaginaComponent,
		ModalSucessComponent,
		ModalErrorComponent,
		ModalConfirmacaoComponent,
		ModalRespostaSucessComponent,
		ModalRespostaErrorComponent,
		OrdenacaoComponent,
		PaginacaoComponent,
		ResultadoPorPaginasComponent,
		RodapeMenuComponent,
		SubTituloComponent,
		TituloComponent,
		AcoesComponent,
		Select2CustomComponent,
		DropListSelectComponent,
		//DropListSelect2Component,
		ModalInformacaoComponent,
		ModalJustificativaComponent,
		CollapseButtonComponent,
		CollapseRowButtonComponent,
		TaxaTextoComponent
	],
	exports: [
		CalendarComponent,
		CaptchaComponent,
		DropListComponent,
		GridCabecalhoComponent,
		GridComponent,
		ItensPorPaginaComponent,
		ModalSucessComponent,
		ModalErrorComponent,
		ModalRespostaSucessComponent,
		ModalConfirmacaoComponent,
		ModalRespostaErrorComponent,
		OrdenacaoComponent,
		PaginacaoComponent,
		ResultadoPorPaginasComponent,
		RodapeMenuComponent,
		SubTituloComponent,
		TituloComponent,
		AcoesComponent,
		DropListSelectComponent,
		//DropListSelect2Component,
		ModalInformacaoComponent,
		ModalJustificativaComponent,
		CollapseButtonComponent,
		CollapseRowButtonComponent,
		TaxaTextoComponent
	],
	// Don't forget to add the component to entryComponents section
	entryComponents: [
		ModalSucessComponent,
		ModalErrorComponent,
		ModalRespostaSucessComponent,
		ModalConfirmacaoComponent,
		ModalInformacaoComponent,
		ModalRespostaErrorComponent,
		ModalJustificativaComponent
	]
})
export class ComponentsModule { }
