
<div class="row form-group" style="margin-left: 2px;">

    <div [ngClass]="(validacao.pendenciaEtapaPPB) ? 'pendencia-validacao' : '' ">
        <a
            *ngIf="habilitarInputs"
            style="margin-right: 10px; margin-top: 5px;"
            class="btn btn-primary btn-sm pull-right"
            data-toggle="tooltip"
            title=""
            (click)="incluirEtapaPPB()"
            data-original-title="">
                <i class="fa fa-plus"></i> Incluir na Lista
        </a>
        <app-grid-etapas-ppb
            [(dataVigenciaInicialPPB)]="dataVigenciaInicialPPB"
            [(gruposPPB)]="gruposPPB"
            [(infoGrupos)]="infoGrupos"
            [(habilitarInputs)]="habilitarInputs"
            (atualizaListagemGrid)="atualizaListagem($event)"
            [(_isSempontuacao)]="_isSempontuacao"
            [(_isPorPontuacao)]="_isPorPontuacao"
            [(_isPorGrupo)]="_isPorGrupo"
            [(_isPPBMultiplo)]="_isPPBMultiplo"
            [(isAlteracaoParaRevogacao)]="isAlteracaoParaRevogacao"
            [(lista)]="grid.lista"
            [(total)]="grid.total"
            [(size)]="grid.size"
            [(page)]="grid.page"
            [(parametros)]="parametros"
            (onChangeSort)="onChangeSort($event)"
            (onChangeSize)="onChangeSize($event)"
            (onChangePage)="onChangePage($event)"
            [(isRetificacao)]="isRetificacao"
            [(idPPB)]="idPPB">
        </app-grid-etapas-ppb>
    </div>

</div>

<app-modal-incluir-etapas-ppb
    [(dataVigenciaInicialPPB)] = "dataVigenciaInicialPPB"
    (atualizaListagem) = "atualizaListagem($event)"
    #appModalIncluirEtapasPPB
    [(habilitarInputs)] = "habilitarInputs">
</app-modal-incluir-etapas-ppb>
<!--
[(_isPPBMultiplo)] = "_isPPBMultiplo"
[(_isPorGrupo)] = "_isPorGrupo"
[(_isPorPontuacao)] = "_isPorPontuacao"
-->
