<div #appModalHistorico class="modal modal-wide-2 fade in centro" tabindex="-1" role="dialog" aria-hidden="false"
  style="display: auto; overflow: scroll; overflow-y: auto;">

  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header bg-primary" style="padding: 10px 15px; color: white;">
        <h4 class="modal-title" id="incluir_etapas">Histórico do Processo Produtivo Básico</h4>
      </div>

      <div class="modal-body">
        <div>
          <label for="descricao-titulo-ppb">Descrição Documento:</label>
          {{tituloPPB}}
        </div>

        <!--########################-->
        <!--### HISTÓRICO DE PPB ###-->
        <!--########################-->
        <h3>
            Histórico do PPB
            <a
              (click)="exibirGridPpbCorrente ? exibirGridPpbCorrente = false : exibirGridPpbCorrente = true;"
              class="btn btn-sm btn-default"
              data-dismiss="modal">
                {{ exibirGridPpbCorrente ? 'Ocultar' : 'Exibir'  }}
            </a>
        </h3>

        <div style="margin-left: 10px;" *ngIf="exibirGridPpbCorrente">
          <form *ngIf="gridPpb.total > 0">
            <app-grid-historico-ppb
              [(parametros)]="parametrosGridPrincipal"
              [(lista)]="gridPpb.lista"
              [(total)]="gridPpb.total"
              [(size)]="gridPpb.size"
              [(page)]="gridPpb.page"
              (onChangeSort)="onChangeSort($event)"
              (onChangeSize)="onChangeSize($event)"
              (onChangePage)="onChangePage($event)">
            </app-grid-historico-ppb>
          </form>
          <h2 *ngIf="gridPpb.total == 0" class="text-center">O PPB não possui histórico </h2>
        </div>

        <!--###############################-->
        <!--### HISTÓRICO DAS LISTAGENS ###-->
        <!--###############################-->
        <h3>
          Histórico das Listagens
          <a
            (click)="exibirGridListagens ? exibirGridListagens = false : exibirGridListagens = true;"
            class="btn btn-sm btn-default"
            data-dismiss="modal">
               {{ exibirGridListagens ? 'Ocultar' : 'Exibir'  }}
          </a>
        </h3>

        <div style="margin-left: 10px;" *ngIf="exibirGridListagens">
          <form *ngIf="gridHistoricoListagem.total > 0">
            <app-grid-historico-listagens-ppb
              [(parametros)]="parametrosHistoricoListagens"
              [(lista)]="gridHistoricoListagem.lista"
              [(total)]="gridHistoricoListagem.total"
              [(size)]="gridHistoricoListagem.size"
              [(page)]="gridHistoricoListagem.page"
              (onChangeSort)="onChangeSortHistoricoListagem($event)"
              (onChangeSize)="onChangeSizeHistoricoListagem($event)"
              (onChangePage)="onChangePageHistoricoListagem($event)">
            </app-grid-historico-listagens-ppb>
          </form>
          <h2 *ngIf="gridHistoricoListagem.total == 0" class="text-center">O PPB não possui Histórico de Listagens </h2>
        </div>

      </div>

      <div class="modal-footer" style="padding: 10px 15px;">
        <a (click)="fechar()" class="btn btn-sm btn-default" data-dismiss="modal"><i class="fa fa-times"></i> Fechar </a>
      </div>
    </div>
  </div>
</div>

<div #appModalHistoricoBackground class="modal-backdrop fade in" style="display: none;"></div>
