<select
	[(ngModel)]="model"
  (ngModelChange)="onChange2(select.value)"
	#select
	class="form-control"
	id="drop-list"
	(change)="onChange(select.value)"
	[attr.required]="required || isRequired ? 'required' : null"
	[attr.disabled]="isDisabled || !list || list.length == 0 ? 'disabled' : null"
>
	<option *ngIf="list && list.length > 1 || exibirPlaceholder" selected="{{ list && list.length > 1 && !idSelected ? 'selected' : '' }}" value="">
		{{placeholder}}
	</option>
	<option *ngFor="let item of list" [value]="item.id" [selected]="item.id === idSelected" >
		{{item.text}}
	</option>
</select>
