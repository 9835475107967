<div class="modal-dialog center">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header bg-primary" style="padding: 10px 15px; color: white;">
          <h4 class="modal-title">{{data.title || 'Informação'}}</h4>
        </div>
        <div class="modal-body">
          <div class="alert alert-block alert-success" style="margin-bottom: 0;">
            <p [innerHTML]="data.message"></p>
          </div>        
        </div>
      </div>
    </div>
</div>
<div class="modal-backdrop fade in"></div>
  