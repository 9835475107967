import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { ApplicationService } from '../../services/application.service';
import { PagedItems } from '../../../view-model/PagedItems';
import { Excel2Service } from '../../services/excel2.service';
import { ChangeIniciaPaginacao } from '../../services/changeIniciaPaginacao.service';
import { PDFService } from '../../services/pdf.service';

@Component({
	selector: 'app-grid',
	templateUrl: './grid.component.html',
})

export class GridComponent implements OnInit {
	@Input() isHideCabecalho = false;
	@Input() isHidePaginacao = false;
	@Input() setPage: number = 1;
	@Input() isShowPanel: boolean = true;
	@Input() lista: Array<any> = new Array;
	@Input() page: number = 1;
	@Input() size : number = 10;
	@Input() sorted : string = "";
	@Input() total: number = 0;
	@Input() titulo : string = "";
	@Input() titulo2 : string = "";
	@Input() parametros: any = {};
	@Output() onChangePage: EventEmitter<any> = new EventEmitter();
	@Output() onChangeSize: EventEmitter<any> = new EventEmitter();
	@Output() onChangeSort: EventEmitter<any> = new EventEmitter();

	constructor(
		private changeIniciaPaginacao : ChangeIniciaPaginacao,
		private applicationService: ApplicationService,
		private excelService: Excel2Service,
		private pdfService: PDFService
	) { }

	ngOnInit() {
    
		this.changeIniciaPaginacao.AlteraPaginacao.subscribe(
			result => { this.page = 1; }
		);
		this.page = this.page || 1;
				this.size = this.size || 10;
	}

	changeSize($event : any) {
		this.onChangeSize.emit($event);
		//this.changePage(this.page);
	}

	changeSort($event : any) {
		this.parametros.sort = $event;
		this.onChangeSort.emit($event);
		this.changePage(this.page);
	}

	changePage($event : any) {
		this.page = $event;
		this.onChangePage.emit($event);
	}

	exportar(tipo : any) {
		this.parametros.page = 1;
		this.parametros.size = 1000000;

    var file = this.parametros.titulo || window.location.href.split("#")[1].replace("/", "");

		if (this.parametros.lista) {


			this.lista = this.parametros.lista;
			let rows = Array<any>();

			for (var i = 0; i < this.lista.length; i++) {

				let r = Array<any>();

				let valor: any;
				for (var j = 0; j < this.parametros.fields.length; j++) {
					var item = this.parametros.fields[j].split("|");
					valor = item.length > 0
							? Object.values(this.lista)[i][item[0].trim()]
							: Object.values(this.lista)[i][this.parametros.fields[j].trim()];
					if (this.parametros.fields[j].trim() == "status")
						r[j] = (valor == 1 ? "ATIVO" : "INATIVO");
					else {
						r[j] = valor;
					}
				}

				rows.push(r);
			}

			if (tipo == 1) {
				this.pdfService.exportAsPDFFile(this.parametros.columns, rows, this.parametros.width, file, this.parametros.titulo);
			}
			else if (tipo == 2) {
				var excel: any = [];

				excel.push(this.parametros.columns);

				for (var i = 0; i < rows.length; i++) {
					excel.push(rows[i]);
				}

				this.excelService.exportAsExcelFile(excel, file);
			}

		}
		else {
		this.applicationService.get(this.parametros.servico, this.parametros).subscribe((result: PagedItems) => {

			this.lista = result.items; let rows = Array<any>();

			for (var i = 0; i < this.lista.length; i++) {

				let r = Array<any>();

				let valor: any; for (var j = 0; j < this.parametros.fields.length; j++) {

          var item = this.parametros.fields[j].split("|");

          valor = (item.length > 0) ?
                    Object.values(this.lista)[i][item[0].trim()] :
                      Object.values(this.lista)[i][this.parametros.fields[j].trim()];

          if (this.parametros.fields[j].trim() == "status")
          {
						r[j] = (valor == 1 ? "ATIVO" : "INATIVO");
					}
          else
          {
						r[j] = valor;
					}
				}

				rows.push(r);
			}


			if (tipo == 1) {
				this.pdfService.exportAsPDFFile(this.parametros.columns, rows, this.parametros.width, file, this.parametros.titulo);
			}
			else if (tipo == 2) {
				var excel: any = [];

				excel.push(this.parametros.columns);

				for (var i = 0; i < rows.length; i++) {
					excel.push(rows[i]);
				}

				this.excelService.exportAsExcelFile(excel, file);
			}
			});
		}
	}
}
