import { Component, Output, Input, EventEmitter } from '@angular/core';

@Component({
	selector: 'app-collapse-button',
	templateUrl: './collapse-button.component.html',

})
export class CollapseButtonComponent {

	@Input() isCollapsedLegenda!: boolean;

	@Input() target!: string;
}
