import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface AlertaModel {
	message: string;
}

@Component({
  selector: 'app-modal-confirmacao',
  templateUrl: './modal-confirmacao.component.html'
})
export class ModalConfirmacaoComponent implements OnInit {
 
  constructor(@Inject(MAT_DIALOG_DATA) public data : AlertaModel) { }

  ngOnInit(): void {
  }

  onConfirm(){
  }

}
