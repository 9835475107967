<div class="row form-group" style="margin-left: 2px;">
    <div [ngClass]="(validacao && validacao.pendenciaMetasPPB) ? 'pendencia-validacao' : '' ">
        <a
            *ngIf="habilitarInputs"
            style="margin-right: 10px; margin-top: 5px;"
            class="btn btn-primary btn-sm pull-right"
            data-toggle="tooltip"
            title=""
            (click)="incluir()"
            data-original-title="">
                <i class="fa fa-plus"></i> Incluir na Lista
        </a>
        <app-grid-metas-ppb
            [(lista)]="grid.lista"
            [(total)]="grid.total"
            [(size)]="grid.size"
            [(page)]="grid.page"
            [(parametros)]="parametros"
            [(idPPB)]="idPPB"
            [(desabilitarInputs)]="habilitarInputs"
            (onChangeSort)="onChangeSort($event)"
            (onChangeSize)="onChangeSize($event)"
            (onChangePage)="onChangePage($event)"
            [(dataVigenciaInicialPPB)]="dataVigenciaInicialPPB"
            (atualizarListagem)="buscar()"
            [(isAlteracaoParaRevogacao)]="isAlteracaoParaRevogacao"
            [(isRetificacao)]="isRetificacao"
            [(_isPorPontuacao)]="_isPorPontuacao"
            [(_isPorGrupo)] = "_isPorGrupo"
            (abrirModalOrdenacao)="abrirModalOrdenacao($event)"
            (abrirModalHistorico)="abrirModalMetas($event)"
        >
        </app-grid-metas-ppb>
    </div>

</div>

<app-modal-incluir-metas-ppb
   [(dataVigenciaInicialPPB)]="dataVigenciaInicialPPB"
   #appModalIncluirMetasPPB
   (atualizarListagem)="buscar()">
</app-modal-incluir-metas-ppb>

<app-modal-mover-por-selecao
  #appModalMoverPorPosicao
  [_servicoDropDown]="'OrdenacaoMetas'"
  [_servicoParaSalvar]="'MetasPPB'"
  (atualizaListagemGrid)="buscar()">
</app-modal-mover-por-selecao>
