<div class="row form-group" style="margin-left: 2px;">

    <div [ngClass]="(validacao != null && validacao.pendenciaPPBMultiplo) ? 'pendencia-validacao' : '' ">
        <a
            *ngIf="habilitarInputs"
            style="margin-right: 10px; margin-top: 5px;"
            class="btn btn-primary btn-sm pull-right"
            data-toggle="tooltip"
            title=""
            (click)="incluirGrupoPPB()"
            data-original-title="">
                <i class="fa fa-plus"></i> Incluir na Lista
        </a>
        <app-grid-ppb-multiplo
            [(dataVigenciaInicialPPB)]="dataVigenciaInicialPPB"
            [(idPPB)]="idPPB"
            [(habilitarInputs)]="habilitarInputs"
            (atualizaListagemGrid)="atualizaListagem($event)"
            [(lista)]="grid.lista"
            [(total)]="grid.total"
            [(size)]="grid.size"
            [(page)]="grid.page"
            [(parametros)]="parametros"
            (onChangeSort)="onChangeSort($event)"
            (onChangeSize)="onChangeSize($event)"
            (onChangePage)="onChangePage($event)"
            [(isRetificacao)]="isRetificacao"
            [(isAlteracaoParaRevogacao)]="isAlteracaoParaRevogacao"
            [(tipoPublicacaoDasReferencias)]="tipoPublicacaoDasReferencias">
        </app-grid-ppb-multiplo>
    </div>
</div>

<app-modal-incluir-ppb-multiplo
    [(idPPB)]="idPPB"
    [(dataVigenciaInicialPPB)]="dataVigenciaInicialPPB"
    (atualizaListagem)="atualizaListagem($event)"
    #appModalIncluirGruposPPB
    [(habilitarInputs)]="habilitarInputs">
</app-modal-incluir-ppb-multiplo>
