import { FiltrosModalListagemVM } from "./FiltrosModalListagemVM";

export class ObjetoRelatorioModalListagemVM {
  isSempontuacao: boolean;
  isPorPontuacao: boolean;
  isPorGrupo: boolean;
  isPPBMultiplo: boolean;
  ppbId: number;
  filtros: FiltrosModalListagemVM;
}
