import { Component, Input } from '@angular/core';

@Component({
	selector: 'app-taxa-texto',
	templateUrl: './taxa-item.component.html'
})

export class TaxaTextoComponent {

  constructor() {}

	@Input() texto : any;
  @Input() indicadorParaTaxarItem : any;
  @Input() backgroundAmarelo : boolean = false;
  @Input() flagIsTextArea : boolean;
  @Input() width : string = "200";

  calculaQtdLinhas(){
    if(this.texto)
    {
      if(this.texto.length > 60){
        var linhas = this.texto.length/60;
        return (Math.ceil(linhas));
      }
    }
    return 1;
  }

  getStyle() : string {
    return "width: " + this.width + "px;resize:none; overflow: hidden; border-color:transparent; text-decoration: line-through; cursor: pointer;"
  }

}
