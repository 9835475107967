<app-grid [(titulo)]="tituloGrid" [(page)]="page" [(size)]="size" [(total)]="total" [(parametros)]="parametros"
          (onChangeSize)="changeSize($event)" (onChangePage)="changePage($event)"
          [(isHidePaginacao)]="ocultarPaginacaoCabecalho" [(isHideCabecalho)]="ocultarPaginacaoCabecalho">

  <div class="table-responsive no-margin-bottom no-border">
    <div *ngFor="let group of groupedData">
      <table class="table table">
        <thead class="table-header-color">
        <tr class="align-in-center">
          <th *ngIf="habilitarInputs">Ações</th>
          <th>
            {{ group.grupoDescricao }} | {{group.grupoDescricaoProduto }}
          </th>
          <td>2023</td>
          <td>2024</td>
          <td>2025</td>
          <td>2026</td>
          <td>2027 em diante</td>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let item of group.items">
          <td  *ngIf="habilitarInputs">
            <a
              (click)="editarPontuacao(item)"
              class="btn btn-default btn-sm"
              data-toggle="tooltip"
              title="Alterar"
              data-original-title="Alterar">
              <i class="fa fa-pencil"></i>
            </a>
            <a

              (click)="removerItemCronogramaAnual(item)"
              class="btn btn-danger btn-sm"
              data-toggle="tooltip"
              title="Excluir"
              data-original-title="Excluir">
              <i class="fa fa-trash-o"></i>
            </a>
          </td>
          <td style="width: 700px" class="tg-0lax">{{item.descricao}}</td>

          <td class="align-in-center">{{ajustarValor(item.ano2023)}} </td>
          <td class="align-in-center">{{ajustarValor(item.ano2024)}}</td>
          <td class="align-in-center">{{ajustarValor(item.ano2025)}}</td>
          <td class="align-in-center">{{ajustarValor(item.ano2026)}}</td>
          <td class="align-in-center">{{ajustarValor(item.ano2027)}}</td>

        </tr>

        </tbody>
      </table>
    </div>
  </div>
</app-grid>

<app-modal-incluir-cronograma
  [(idPPB)]="idPPB"
  (atualizaListagem)="atualizaListagem($event)"
  [_isPorPontuacao]="_isPorPontuacao"
  #appModalIncluirCronograma>
</app-modal-incluir-cronograma>

<app-modal-editar-cronograma
  [(idPPB)]="idPPB"
  [item]="itemSelecionado"
  #appModalEditarCronograma>
</app-modal-editar-cronograma>
