<div class="modal-dialog center">
    <div class="modal-content">

        <div class="modal-header bg-primary">
            <button [mat-dialog-close]="false" type="button" class="close"><b style="color: white;">&times;</b></button>
            <h4 class="modal-title" style="color: white;">Confirmação</h4>
        </div>
        
        <div class="modal-body">  
            <div class="alert alert-block alert-warning" style="margin-bottom: 0;">
                <p> {{ data.message || 'Deseja confirmar a Alteração?'}} </p>
            </div>
        </div>        
        
        <div class="modal-footer" style="padding: 8px 15px;">
            <button 
                style="margin-right: 5px; cursor: pointer;"
                [mat-dialog-close]="false"
                type="button" 
                class="btn btn-default" 
                data-dismiss="modal">
                    <i class="fa fa-times" style="color: black;"></i> Cancelar
            </button>
            <button         
                style="cursor: pointer;"                
                [mat-dialog-close]="true"
                color="primary"
                type="button" 
                class="btn btn-primary">
                    <i class="fa fa-check" style="color: white;"></i> Sim
            </button>
        </div>
    </div>
</div>
<div class="modal-backdrop fade in"></div>
