import { RequestResponseVM } from './../../../../../view-model/RequestResponseVM';
import { Component, Output, Input, OnInit, EventEmitter, ViewChild } from '@angular/core';
import { ApplicationService } from '../../../../../shared/services/application.service';
import { MessagesService } from '../../../../../shared/services/messages.service';
import { ValidationService } from '../../../../../shared/services/validation.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalService } from '../../../../../shared/services/modal.service';
import { EnumRestResponse } from '../../../../../shared/enums/EnumRestResponse'
import { EtapaVM } from '../../../../../view-model/EtapaVM';
import { ItensPPBVM } from '../../../../../view-model/ItensPPBVM';
import { MetaVM } from '../../../../../view-model/MetaVM';
import { MetaPPBVM } from '../../../../../view-model/MetaPPBVM';
import { OrderGrid } from '../../../../../shared/services/orderGridByNumeroSequencia.service';
import { EnviaParametrosSelecaoItemVM } from '../../../../../view-model/EnviaParametrosSelecaoItemVM';
import { TiposReferenciaVM } from '../../../../../view-model/TiposReferenciaVM';
import { registerLocaleData } from '@angular/common';
import es from '@angular/common/locales/es';

enum EnumControllPPB{
	OK = 1,
	ERR_DATA_VIGENCIA_VENCIDA = 2,
	ERR_EXISTE_COPIA_EM_ANDAMENTO = 3,
	ERR_ITEM_MARCADO_COMO_EXCLUIDO = 4
}

@Component({
	selector: 'app-grid-metas-ppb',
	templateUrl: './grid.component.html'
})

export class GridMetasPPBComponent {

    tituloGrid = "Metas do PPB";
	servico = "MetasPPB";

    @Input()
		lista!: any[];
	@Input()
		total: number;
	@Input()
		size!: number;
	@Input()
		page!: number;
	@Input()
		sorted!: string;
    @Input()
		parametros: any = {};
	@Input()
		idPPB: number;
  @Input()
		_isPorGrupo: boolean;
	@Input()
		desabilitarInputs: boolean = false;
	@Input()
		dataVigenciaInicialPPB : string;
	@Input()
		isAlteracaoParaRevogacao : boolean;
	@Input()
		_isPorPontuacao : boolean;
  @Input()
    tipoPublicacaoDasReferencias : TiposReferenciaVM = new TiposReferenciaVM();

	@Input() isRetificacao : boolean;

	EXCLUIDO : number = 4;
	_idPPBRota : number;
  _ativaTextArea : boolean = true;

	@Output() onChangeSort: EventEmitter<any> = new EventEmitter();
	@Output() onChangeSize: EventEmitter<any> = new EventEmitter();
	@Output() onChangePage: EventEmitter<any> = new EventEmitter();

	@Output() atualizarListagem : EventEmitter<any> = new EventEmitter();

  @Output() abrirModalOrdenacao : EventEmitter<any> = new EventEmitter();
  @Output() abrirModalHistorico : EventEmitter<any> = new EventEmitter();

	@ViewChild('appModalIncluirMetasPPB') appModalIncluirMetasPPB : any;

	constructor(
		private applicationService: ApplicationService,
		private modal: ModalService,
		private route: ActivatedRoute,
		private msg: MessagesService,
		private ordenarGrid: OrderGrid
	) {
		this._idPPBRota = Number(this.route.snapshot.params['idPpb']);
    registerLocaleData(es);
	}

  formatter(value){
    var test = (Math.round(value * 100) / 100).toFixed(2).toString().replace(".", ",");
    const thousant = test.replace(/\B(?=(\d{3})+(?!\d))/g, ".")

    if(thousant.substr(-2) == '00'){
      return thousant.slice(0, -3);
    }

    return thousant;
  }

	changeSize($event) {
		this.onChangeSize.emit($event);
	}

  changeSort($event) {
    this.sorted = $event.field;
    this.onChangeSort.emit($event);
  }

	changePage($event) {
		this.page = $event;
		this.onChangePage.emit($event);
	}

	changeListagem(){
		this.atualizarListagem.emit();
	}

	atualizaListagem($event){
		this.atualizarListagem.emit($event);
	}

	limitarDescricao(desc: string){
		var limit = desc.substring(0, 30);
		return limit + '...';
	}

	alterar(idMeta: number){
		this.appModalIncluirMetasPPB.abrir(this.idPPB, idMeta);
	}

	excluir(objeto : MetaVM) {

    let serviceObject: ItensPPBVM = new ItensPPBVM();
    serviceObject.idItemCorrente = Number(objeto.id);
    serviceObject.idPPBCorrente = Number(this._idPPBRota);
    serviceObject.idPPBVinculo = Number(objeto.idPPB);

    this.modal.confirmacao("Deseja Excluir o Registro?").subscribe((isConfirmado: any) => {
      if (isConfirmado) {
        this.applicationService.post(this.servico + "/PostApagarMeta", serviceObject).subscribe((result: EnumControllPPB) => {
          if (result == EnumControllPPB.OK) {
            this.modal.sucesso(this.msg.OPERACAO_REALIZADA_COM_SUCESSO, "Success").subscribe(() => {
              this.changeListagem();
            });
          } else if (result == EnumControllPPB.ERR_DATA_VIGENCIA_VENCIDA) {
            this.modal.informacao("Item está com a Data de Vigência Inferior a Data Corrente!", "Informação").subscribe(() => false);
          } else if (result == EnumControllPPB.ERR_EXISTE_COPIA_EM_ANDAMENTO) {
            this.modal.informacao("Existe uma cópia em Andamento! Apague a cópia para Executar essa Operação.", "Informação").subscribe(() => false);
          } else if (result == EnumControllPPB.ERR_ITEM_MARCADO_COMO_EXCLUIDO) {
            this.modal.informacao("Item Ja Está Marcado Como excluído!", "Informação").subscribe(() => false);
          }
        });
      }
    });
	}

	validarSePermiteAbrirModal(item : MetaVM){

		let serviceObject : ItensPPBVM = new ItensPPBVM();
		serviceObject.idItemCorrente = Number(item.id);
		serviceObject.idPPBCorrente = Number(this._idPPBRota);
		serviceObject.idPPBVinculo = Number(item.idPPB);

		this.applicationService.post(this.servico+"/PostValidacaoModalEdicao", serviceObject).subscribe((result : ItensPPBVM) => {

			if(result.statusControllModal == EnumControllPPB.OK){
				this.changeListagem();
				this.appModalIncluirMetasPPB.abrir(this.idPPB, result.idItemCorrente);
			} else if(result.statusControllModal == EnumControllPPB.ERR_DATA_VIGENCIA_VENCIDA) {
				this.modal.informacao("Item está com a Data de Vigência Inferior a Data Corrente!", "Informação").subscribe( () => false);
			} else if(result.statusControllModal == EnumControllPPB.ERR_EXISTE_COPIA_EM_ANDAMENTO){
				this.modal.informacao("Existe uma cópia em Andamento! Apague a cópia para Executar essa Operação.", "Informação").subscribe( () => false);
			}
		});
	}

  ordenarItemGrid(item, tipoOperacao)
  {
    let objetoOrdenacao = new ItensPPBVM();
    objetoOrdenacao.idItemCorrente = item.id;
    objetoOrdenacao.idPPBCorrente = this._idPPBRota;
    objetoOrdenacao.tipoOperacao = tipoOperacao;
    this.applicationService.post("MetasPPB/OrdenarMeta",objetoOrdenacao).subscribe((result:RequestResponseVM)=>{
        if(result.statusResponse==200)
        {
          this.changeListagem();
        }
        else if(result.statusResponse == 300)
        {
          this.modal.informacao("Esse registro é o primeiro da lista");
        }
        else if(result.statusResponse == 403)
        {
          this.modal.informacao("Esse registro é o ultimo da lista");
        }
        else
        {
          this.modal.error(result.textResponse, "Error");
        }
    });
  }

  validacaoIsRetificacao(item : any) : boolean{
	if(this.isRetificacao){
		if(item.idPPB == this.idPPB){
			return true
		}
		else{
			return false
		};
	}
	return true;
  }

  abrirModalMoverPorSelecao(item){
    let _obj : EnviaParametrosSelecaoItemVM = new EnviaParametrosSelecaoItemVM();
    _obj.tituloItemSelecionado = item.valorMeta;
    _obj.descricaoItemOrigem = item.descricaoMeta;
    _obj.numeroSequencia = item.numeroSequencia;
    _obj.idItemOrigem = item.id;
    _obj.idPpb = Number(this.route.snapshot.params['idPpb']);
    this.abrirModalOrdenacao.emit(_obj)
  }

  abrirHistorico(item: any){
    let obj = {
      valorMeta: String(item.valorMeta),
      tipoOperacao : 'Meta',
      idPPB :  item.idPPB,
      id : item.id
    }
    this.abrirModalHistorico.emit(obj);
  }

}
