
<div
[hidden]="AtivaRelatorio"
	id="conteudoPdf"
>

<!-- <div>
  <div class="row form-group">
    <div class="col-lg-12">
      <div class="col-sm-2">
        is grupo: {{_isPorGrupo}}
      </div>
      <div class="col-sm-2">
        is semPontuacao: {{_isSempontuacao}}
      </div>
      <div class="col-sm-2">
        is porPontuacao: {{_isPorPontuacao}}
      </div>
      <div class="col-sm-2">
        is multiplo: {{_isPPBMultiplo}}
      </div>
    </div>
  </div>
</div> -->

    <div style="width: 1100px;" id="after0">

        <div>

            <div class="row">
                <div class="col-md-12">
                    <div class="form-group m-n text-center">
                        <img src="../../../../../assets/images/brasao.jpeg" alt="Logo Brasao"
                            style="width: 9%;" />
                    </div>
                </div>
            </div>

            <div class="form-group m-n text-center">
                <h4 style="font-weight: bold">MINISTÉRIO DA ECONOMIA</h4>
                <h5>Superintendência da Zona Franca de Manaus</h5>
                <h5>SUFRAMA</h5>
            </div>

            <div class="form-group m-n text-center">
              <a
                onClick="window.print()"
                class=" btn btn-default btn-sm pull-left"
                data-toggle="tooltip"
                title="Imprimir"
                data-original-title="Imprimir">
                    <i class="fa fa-print" aria-hidden="true">Imprimir</i>
              </a>
              <label class="control-label">  {{ _tituloPPB }} </label>
              <a
                onClick="window.print()"
                class=" btn btn-default btn-sm pull-right"
                data-toggle="tooltip"
                title="Imprimir"
                data-original-title="Imprimir">
                    <i class="fa fa-print" aria-hidden="true">Imprimir</i>
              </a>

            </div>
        </div>

        <!-- ##################################################### -->
        <!-- ################ INFORMAÇÕES DO PPB ###############  -->
        <!-- ##################################################### -->

        <!-- <div style="border-width: 5px; border-style: solid; border-color: black; margin-top: 20px;">
          <app-cabecalho-relatorio-ppb
            style="margin-top:10px;"
            [objetoExportacao]="objetoExportacao"
            [_dataVigencia]="_dataVigencia"
            [_dataPublicacao]="_dataPublicacao"
            [_dataDocumento]="_dataDocumento"
            [_isSempontuacao]="_isSempontuacao"
            [_isPorPontuacao]="_isPorPontuacao"
            [_isPorGrupo]="_isPorGrupo"
            [_isPPBMultiplo]="_isPPBMultiplo"
            [listaAnexos]="listaAnexos"
            [arrayDocumentosVinculados]="arrayDocumentosVinculados"
            [isDocPosterior]="isDocPosterior"
            [arrayDocumentoPosteriores]="arrayDocumentoPosteriores"
            [statusPPB]="objetoExportacao.informacoesPPB.ppbVM.statusPPB">
          </app-cabecalho-relatorio-ppb>
        </div> -->

        <div style="border-width: 5px; border-style: solid; border-color: black;">

            <div style="margin-left: 10px;">
                <div class="row">
                    <div class="col-md-6">
                        <label>Tipo de Publicação: </label>
                        {{
                            objetoExportacao.informacoesPPB.ppbVM.tipoPublicacao === 1 ? 'FIXAÇÃO (NOVO)' :
                            objetoExportacao.informacoesPPB.ppbVM.tipoPublicacao === 2 ? 'ALTERAÇÃO POR MEIO DE REVOGAÇÃO' :
                            objetoExportacao.informacoesPPB.ppbVM.tipoPublicacao === 3 ? 'ALTERAÇÃO PARCIAL' :
                            objetoExportacao.informacoesPPB.ppbVM.tipoPublicacao === 4 ? 'NORMA COMPLEMENTAR' :
                                'FIXAÇÃO DE PPB (Genérico para Especifico)'
                        }}
                    </div>
                    <div class="col-md-6">
                      <label>Situação:</label>
                      {{ definirStatus(objetoExportacao.informacoesPPB.ppbVM.statusPPB) }}
                    </div>
                </div>

                <div class="row" *ngIf="objetoExportacao.informacoesPPB.ppbVM.tipoPublicacao != 1">
                    <div class="col-sm-6">
                        <label>Documento de referência antigo</label>
                        {{ objetoExportacao.informacoesPPB.ppbVM.descricaoDocReferencia ? objetoExportacao.informacoesPPB.ppbVM.descricaoDocReferencia : 'n/a' }}
                    </div>
                    <div class="col-sm-6">
                        <label>Início de Vigência:</label>
                        {{_dataVigencia | date: 'dd/MM/yyyy'}}
                    </div>
                </div>

                <div class="row">
                    <div class="col-sm-6" >
                            <label>Data Publicação:</label>
                            {{ _dataPublicacao | date: 'dd/MM/yyyy' }}
                    </div>
                    <div class="col-sm-6" >
                        <label>Tipo de Documento:</label>
                                {{ objetoExportacao.informacoesPPB.descricaoTipoDocumento }}
                    </div>

                </div>

                <div class="row">
                    <div class="col-sm-6">
                        <label>Número:</label>
                            {{ formatter(objetoExportacao.informacoesPPB.ppbVM.numeroDocumento) }}
                    </div>
                    <div class="col-sm-6">
                        <label>Data:</label>
                            {{ _dataDocumento | date: 'dd/MM/yyyy' }}
                    </div>
                </div>

                <div class="row">
                        <div class="col-sm-6">
                            <label>Órgãos do PPB:</label>
                                {{ objetoExportacao.informacoesPPB.ppbVM.descricaOrgao ? objetoExportacao.informacoesPPB.ppbVM.descricaOrgao : 'n/a' }}
                        </div>
                        <div class="col-lg-6">
                            <label>Tipo da Etapa:</label>
                                {{
                                  getTipoEtapaDs(objetoExportacao.informacoesPPB.ppbVM.tipoEtapa)

                                }}
                        </div>
                </div>

                <div class="row">
                    <div class="col-sm-12">
                        <label for="resumo-tecnico">Resumo Técnico:</label>
                            {{ objetoExportacao.informacoesPPB.ppbVM.descricaResumoTecnico }}
                    </div>
                </div>

                <div class="row">
                    <div class="col-sm-6">
                        <label>Anexos do PPB:</label>
                        <div *ngIf="listaAnexos != null && listaAnexos != undefined">
                            <div  *ngFor="let item of listaAnexos">
                                <b style="color:red; margin-left: 15px;">Arquivo: </b> {{ item.descricaoArquivo }}
                            </div>
                        </div>
                        {{ (listaAnexos == null && listaAnexos == undefined) ? 'n/a' : '' }}
                    </div>
                    <div style="margin-top: -1px;" class="col-sm-6" *ngIf="objetoExportacao.informacoesPPB.ppbVM.tipoPublicacao != 1">
                        <label>Documentos de Referência:</label>
                            <div *ngIf="arrayDocumentosVinculados">
                                <div *ngFor="let item of arrayDocumentosVinculados; let i = index ; " [attr.data-index]="i">
                                    <b style="color:red; margin-left: 15px;">Documento: </b> {{ item.descricaoDocumentoVinculado }}
                                </div>
                            </div>
                            {{ !arrayDocumentosVinculados ? 'n/a' : '' }}
                    </div>
                </div>

                <div class="row" *ngIf="isDocPosterior==1">
                    <div class="col-sm-6">
                        <label>Documento Posterior:</label>
                        <div *ngIf="arrayDocumentoPosteriores != null && arrayDocumentoPosteriores != undefined">
                            <div  *ngFor="let item of arrayDocumentoPosteriores">
                                <b style="color:red; margin-left: 15px;">Arquivo: </b> {{ item.descricaoDocumentoVinculado }}
                            </div>
                        </div>
                        {{ !arrayDocumentoPosteriores ? 'n/a' : '' }}
                    </div>
                </div>

            </div>


        </div>

    </div>

    <br>

    <!-- ##################################################### -->
	<!-- ################### PPB MULTIPLO #################### -->
    <!-- ##################################################### -->

    <div
         id="after1"
         *ngIf="objetoExportacao.ppbMultiplo && _isPPBMultiplo">
         <legend style="margin-left: 15px;">PPB MULTIPLO SEM PONTUAÇÃO</legend>
            <div>
                <div class=" no-margin-bottom no-border">
                    <table class="table table-striped" id="tabelaCond3">
                        <thead class="table-header-color">
                            <tr style="background-color: #00552b; color: white;">
                                <th class="th-sortable unset-nowrap text-left">
                                    PPB
                                </th>
                                <th class="th-sortable unset-nowrap text-left">
                                    Vigência
                                </th>
                                <th class="th-sortable unset-nowrap text-left">
                                    Documento
                                </th>
                                <th class="th-sortable unset-nowrap text-left">
                                    Produto
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of objetoExportacao.ppbMultiplo">
                                <td
                                    class="text-left"
                                    [ngStyle]="{'background-color': item.idPPB != _idPPBRota ? '#ffff99' : ''  }">
                                        <app-taxa-texto
                                            [(texto)]="item.descricao"
                                            [(indicadorParaTaxarItem)]="item.indicadorParaTaxarItem"
                                            [backgroundAmarelo]="item.idPPB != _idPPBRota">
                                        </app-taxa-texto>
                                </td>
                                <td
                                    class="text-left"
                                    [ngStyle]="{'background-color': item.idPPB != _idPPBRota ? '#ffff99' : ''  }">
                                        <app-taxa-texto
                                            [(texto)]="item.dataVigenciaConcatenada"
                                            [(indicadorParaTaxarItem)]="item.indicadorParaTaxarItem">
                                        </app-taxa-texto>
                                </td>
                                <td
                                    class="text-left"
                                    [ngStyle]="{'background-color': item.idPPB != _idPPBRota ? '#ffff99' : ''  }">
                                        <app-taxa-texto
                                            [(texto)]="item.descricaoDocumento"
                                            [(indicadorParaTaxarItem)]="item.indicadorParaTaxarItem">
                                        </app-taxa-texto>
                                </td>
                                <td
                                    class="text-left"
                                    [ngStyle]="{'background-color': item.idPPB != _idPPBRota ? '#ffff99' : ''  }">
                                        <app-taxa-texto
                                            [(texto)]="item.descricaProdutos"
                                            [(indicadorParaTaxarItem)]="item.indicadorParaTaxarItem">
                                        </app-taxa-texto>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
    </div>

    <br>

    <!-- ##################################################### -->
	<!-- ##################### GRUPOS PPB #################### -->
    <!-- ##################################################### -->

    <div
         id="after2"
         *ngIf="objetoExportacao.grupoPPB && _isPorGrupo">
         <legend style="margin-left: 15px;">GRUPOS</legend>
            <div>
                <div class=" no-margin-bottom no-border">
                    <table class="table table">
                        <thead class="table-header-color">
                            <tr style="background-color: #00552b; color: white;">
                                <th class="th-sortable unset-nowrap text-left">Grupo</th>
                                <th class="th-sortable unset-nowrap text-left">Vigência</th>
                                <th class="th-sortable unset-nowrap text-left">Documento</th>
                                <th class="th-sortable unset-nowrap text-left">Produto</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of objetoExportacao.grupoPPB">
                                <td class="text-left" [ngStyle]="{'background-color': item.idPPB != _idPPBRota ? '#ffff99' : ''  }">
                                    <app-taxa-texto
                                        [(texto)]="item.descricaGrupo"
                                        [backgroundAmarelo]="item.idPPB != _idPPBRota"
                                        [(indicadorParaTaxarItem)]="item.indicadorParaTaxarItem">
                                    </app-taxa-texto>
                                </td>
                                <td class="text-left" [ngStyle]="{'background-color': item.idPPB != _idPPBRota ? '#ffff99' : ''  }">
                                    <app-taxa-texto
                                        [(texto)]="item.dataInicioVigenciaString"
                                        [(indicadorParaTaxarItem)]="item.indicadorParaTaxarItem">
                                    </app-taxa-texto>
                                </td>
                                <td class="text-left" [ngStyle]="{'background-color': item.idPPB != _idPPBRota ? '#ffff99' : ''  }">
                                    <app-taxa-texto
                                        [(texto)]="item.descricaoDocumento"
                                        [(indicadorParaTaxarItem)]="item.indicadorParaTaxarItem">
                                    </app-taxa-texto>
                                </td>
                                <td class="text-left" [ngStyle]="{'background-color': item.idPPB != _idPPBRota ? '#ffff99' : ''  }">
                                    <app-taxa-texto
                                        [(texto)]="item.descricaProdutos"
                                        [(indicadorParaTaxarItem)]="item.indicadorParaTaxarItem">
                                    </app-taxa-texto>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
    </div>

    <br>

    <!-- ##################################################### -->
	<!-- ##################### ETAPAS PPB  ################### -->
    <!-- ##################################################### -->

  <div id="after3" *ngIf="objetoExportacao.etapaPPB.listagemPaginado.total">
    <div>
      <div>
        <legend style="margin-left: 15px;">ETAPAS</legend>

        <div class="no-margin-bottom no-border">

            <table *ngIf="_isPorGrupo" class="table table" id="tabelaCond1">

              <!-- ##################################################### -->
              <!-- ################ TABELA POR GRUPO ################### -->
              <!-- ##################################################### -->

              <thead class="table-header-color">
                <tr style="background-color: #00552b; color: white;">
                  <th class="th-sortable unset-nowrap text-left">Etapa</th>
                  <th class="th-sortable unset-nowrap text-left">Vigência</th>
                  <th class="th-sortable unset-nowrap text-left">Documento</th>
                  <th class="th-sortable unset-nowrap text-left" *ngIf="_isPorPontuacao">Pontuação</th>
                  <th class="th-sortable unset-nowrap text-left" *ngIf="_isPPBMultiplo">PPB Multiplo</th>
                  <th class="th-sortable unset-nowrap text-left">Grupos</th>
                </tr>
              </thead>
              <tbody *ngFor="let item of objetoExportacao.etapaPPB.listagemPaginado.items; let i = index ; " [attr.data-index]="0">
                <tr style="border-top: solid 2px;">
                  <td class="text-left"
                  [ngStyle]="{'background-color': item.idPPB != _idPPBRota ? '#ffff99' : ''  }">
                    <app-taxa-texto [(texto)]="item.descricaTopico" [(indicadorParaTaxarItem)]="item.indicadorParaTaxarItem">
                    </app-taxa-texto>
                  </td>
                  <td class="text-left" [ngStyle]="{'background-color': item.idPPB != _idPPBRota ? '#ffff99' : ''  }">
                    <app-taxa-texto [(texto)]="item.dataInicioVigenciaString"
                      [(indicadorParaTaxarItem)]="item.indicadorParaTaxarItem">
                    </app-taxa-texto>
                  </td>
                  <td class="text-left" [ngStyle]="{'background-color': item.idPPB != _idPPBRota ? '#ffff99' : ''  }">
                    <app-taxa-texto [(texto)]="item.descricaoDocumento" [(indicadorParaTaxarItem)]="item.indicadorParaTaxarItem">
                    </app-taxa-texto>
                  </td>
                  <td class="text-left" *ngIf="_isPorPontuacao"  [ngStyle]="{'background-color': item.idPPB != _idPPBRota ? '#ffff99' : ''  }">
                    <!--  OS_10027 (_grupo.totalPontuacao | number:'':'es') -->
                      <app-taxa-texto
                          [texto]="item.valorPontuacao"
                          [(indicadorParaTaxarItem)]="item.indicadorParaTaxarItem">
                      </app-taxa-texto>
                  </td>
                  <td class="text-left" *ngIf="_isPPBMultiplo"  [ngStyle]="{'background-color': item.idPPB != _idPPBRota ? '#ffff99' : ''  }">
                    <app-taxa-texto
                        [(texto)]="item.ppbMultiplo.descricao"
                        [(indicadorParaTaxarItem)]="item.indicadorParaTaxarItem">
                    </app-taxa-texto>
                  </td>
                  <td
                    colspan="3"
                    id="{{ 'etapa-' + i }}"
                    *ngIf="item.gruposEtapaPPB"
                    [ngStyle]="{'background-color': item.idPPB != _idPPBRota ? '#ffff99' : ''  }">
                    <ul *ngFor="let _grupo of item.gruposEtapaPPB; let i = index;">
                      <li>
                        {{ _grupo.letraGrupo }}: {{ _grupo.totalPontuacao ? formatter(_grupo.totalPontuacao) : '--' }}
                      </li>
                    </ul>
                  </td>
                </tr>
                <tr colspan="2" id="{{ 'etapa-' + i }}" style="margin-left: 25px;">
                  <div class="row" *ngIf="item.existeDescricaoEtapa">
                    <th style="max-width: 380px; border: none; !important">
                      Descrição
                      <p class="relatorio-etapa-{{i}}" style="margin-top: 10px; margin-left: 10px;">{{appendChildEtapa(i, item.descricaoEtapa)}}</p>
                    </th>
                  </div>
                </tr>
              </tbody>
            </table>

            <table *ngIf="!_isPorGrupo" class="table table" id="tabelaCond2">

              <!-- ##################################################### -->
              <!-- ################ TABELA PADRÃO ################### -->
              <!-- ##################################################### -->

              <thead class="table-header-color">
                <tr style="background-color: #00552b; color: white;">
                  <th class="th-sortable unset-nowrap text-left">Etapa</th>
                  <th class="th-sortable unset-nowrap text-left">Vigência</th>
                  <th class="th-sortable unset-nowrap text-left">Documento</th>
                  <th class="th-sortable unset-nowrap text-left" *ngIf="_isPorPontuacao || _isPorGrupo">Pontuação</th>
                  <th class="th-sortable unset-nowrap text-left" *ngIf="_isPPBMultiplo">PPB Multiplo</th>
                </tr>
              </thead>
              <tbody *ngFor="let item of objetoExportacao.etapaPPB.listagemPaginado.items; let i = index">
                <tr>
                  <td class="text-left" [ngStyle]="{'background-color': item.idPPB != _idPPBRota ? '#ffff99' : ''  }">
                    <app-taxa-texto [(texto)]="item.descricaTopico" [(indicadorParaTaxarItem)]="item.indicadorParaTaxarItem">
                    </app-taxa-texto>
                  </td>
                  <td class="text-left" [ngStyle]="{'background-color': item.idPPB != _idPPBRota ? '#ffff99' : ''  }">
                    <app-taxa-texto [(texto)]="item.dataInicioVigenciaString"
                      [(indicadorParaTaxarItem)]="item.indicadorParaTaxarItem">
                    </app-taxa-texto>
                  </td>
                  <td class="text-left" [ngStyle]="{'background-color': item.idPPB != _idPPBRota ? '#ffff99' : ''  }">
                    <app-taxa-texto [(texto)]="item.descricaoDocumento" [(indicadorParaTaxarItem)]="item.indicadorParaTaxarItem">
                    </app-taxa-texto>
                  </td>
                  <td class="text-left" *ngIf="_isPorPontuacao || _isPorGrupo"
                    [ngStyle]="{'background-color': item.idPPB != _idPPBRota ? '#ffff99' : ''  }">
                    <app-taxa-texto [texto]="formatter(item.valorPontuacao)" [(indicadorParaTaxarItem)]="item.indicadorParaTaxarItem">
                    </app-taxa-texto>
                  </td>
                  <td class="text-left" *ngIf="_isPPBMultiplo"
                    [ngStyle]="{'background-color': item.idPPB != _idPPBRota ? '#ffff99' : ''  }">
                    <app-taxa-texto [(texto)]="item.ppbMultiplo.descricao" [(indicadorParaTaxarItem)]="item.indicadorParaTaxarItem">
                    </app-taxa-texto>
                  </td>
                </tr>
                <tr colspan="3" id="{{ 'etapa-' + i }}" style="margin-left: 25px;">
                  <div class="row" *ngIf="item.existeDescricaoEtapa">
                    <th style="max-width: 380px; border:none; !important">
                      Descrição:
                      <p class="relatorio-etapa-{{i}}" style="margin-top: 10px; margin-left: 10px;">
                          {{appendChildEtapa(i, item.descricaoEtapa)}}
                      </p>
                    </th>
                  </div>
                </tr>
              </tbody>
            </table>
        </div>
      </div>

      <!-- ##################################################### -->
      <!-- ############## PONTUAÇÃO GERAL ###################### -->
      <!-- ##################################################### -->

      <div class=" no-margin-bottom no-border" *ngIf="_isPorPontuacao">
          <table class="table" _ngcontent-c2="">
              <thead style="background-color: #00552b; color: white;">
              <tr>
              </tr>
              </thead>
              <tbody>
                  <tr>
                      <td class="text-center">Pontuação Total</td>
                      <td  class="text-center">
                            {{
                              objetoExportacao.etapaPPB.grupos.valorPontuacaoGeral ?
                              formatter(objetoExportacao.etapaPPB.grupos.valorPontuacaoGeral) : 0
                            }}
                            <!--  OS_10027 (objetoExportacao.etapaPPB.grupos.valorPontuacaoGeral | number:'':'es') -->
                      </td>
                  </tr>
              </tbody>
          </table>
      </div>

      <!-- ##################################################### -->
      <!-- ########## TABELA PONTUACAO DOS GRUPOS ############## -->
      <!-- ##################################################### -->

      <div style="margin-top: 10px;" class=" no-margin-bottom no-border" *ngIf="_isPorGrupo">
          <table class="table table-striped" style="border-width: 3px;
                                                      border-style: solid;
                                                      border-color: black;">
              <thead style="background-color: #00552b; color: white;">
                  <tr>
                  </tr>
              </thead>
              <tbody>
                  <tr *ngFor="let item of objetoExportacao.etapaPPB.grupos.valoresTotaisGrupoMPPB">
                      <td  class="text-center">Total do Grupo {{ item.letraGrupo }}</td>
                      <td  class="text-center">Total {{ item.totalPontuacao ? formatter(item.totalPontuacao) : 0}}</td>
                  </tr>
              </tbody>
          </table>
      </div>

		</div>
	</div>

  <br>

    <!-- ##################################################### -->
	<!-- #################### METAS PPB ###################### -->
    <!-- ##################################################### -->
    <div

         id="after4"
         *ngIf="objetoExportacao.metasPPB && ( _isPorGrupo || _isPorPontuacao ) " >
         <legend style="margin-left: 15px;">METAS</legend>
		<div>
			<div class="no-margin-bottom no-border">
          <table class="table table">
              <thead class="table-header-color">
                  <tr style="background-color: #00552b; color: white;">
                      <th class="th-sortable unset-nowrap text-left">
                          Pontuação da Meta
                      </th>
                      <th class="th-sortable unset-nowrap text-left">
                          Vigência
                      </th>
                      <th class="th-sortable unset-nowrap text-left">
                          Documento
                      </th>
                      <th class="th-sortable unset-nowrap text-left">
                          Descrição
                      </th>
                  </tr>
              </thead>
              <tbody *ngFor=" let item of objetoExportacao.metasPPB ; let i = index ; " [attr.data-index]="i">
                  <tr>
                      <td class="text-left" [ngStyle]="{'background-color': item.idPPB != _idPPBRota ? '#ffff99' : ''  }">
                        <!--  OS_10027 (item.valorMeta | number:'':'es') -->
                          <app-taxa-texto
                              [texto]="formatter(item.valorMeta)"
                              [(indicadorParaTaxarItem)]="item.indicadorParaTaxarItem">
                          </app-taxa-texto>
                      </td>
                      <td class="text-left" [ngStyle]="{'background-color': item.idPPB != _idPPBRota ? '#ffff99' : ''  }">
                          <app-taxa-texto
                              [(texto)]="item.descricaoDataVigencia"
                              [(indicadorParaTaxarItem)]="item.indicadorParaTaxarItem">
                          </app-taxa-texto>
                      </td>
                      <td class="text-left" [ngStyle]="{'background-color': item.idPPB != _idPPBRota ? '#ffff99' : ''  }">
                          <app-taxa-texto
                              [(texto)]="item.ppb.descricaoTitulo"
                              [(indicadorParaTaxarItem)]="item.indicadorParaTaxarItem">
                          </app-taxa-texto>
                      </td>
                      <td class="text-left" [ngStyle]="{'background-color': item.idPPB != _idPPBRota ? '#ffff99' : ''  }">
                        <app-taxa-texto
                            [(texto)]="item.descricaoMeta"
                            [backgroundAmarelo]="item.idPPB != _idPPBRota"
                            [(indicadorParaTaxarItem)]="item.indicadorParaTaxarItem">
                        </app-taxa-texto>
                      </td>
                  </tr>
                  <tr [ngStyle]="{'background-color': item.idPPB != _idPPBRota ? '#ffff99' : ''  }" *ngIf="_isPorGrupo">
                    <div class="row">
                      <th style="max-width: 380px;">
                        <label>Grupo da Meta:</label>
                        <app-taxa-texto
                            [(texto)]="item.grupo.descricaGrupo "
                            [(indicadorParaTaxarItem)]="item.indicadorParaTaxarItem">
                        </app-taxa-texto>
                      </th>
                    </div>
                  </tr>
              </tbody>
          </table>
      </div>
		</div>
	</div>

  <br>

    <!-- ##################################################### -->
	<!-- ################ CONDICIONANTE PPB ################## -->
    <!-- ##################################################### -->
	<div  id="after5"
         *ngIf="objetoExportacao.condicionantePPB">
         <legend style="margin-left: 15px;">CONDICIONANTES</legend>
		<div>
			<div class="no-margin-bottom no-border">
          <table class="table table-striped" id="tabelaCond">
              <thead class="table-header-color">
                  <tr style="background-color: #00552b; color: white;">
                      <th class="th-sortable unset-nowrap text-left" >Condicionante</th>
                      <th class="th-sortable unset-nowrap text-left">Data Vigência</th>
                      <th class="th-sortable unset-nowrap text-left">Documento</th>
                      <th class="th-sortable unset-nowrap text-left" *ngIf="isMultiplo() && !isGrupo()">Múltiplo</th>
                      <th class="th-sortable unset-nowrap text-left" *ngIf="!isMultiplo() && isGrupo()">Grupo</th>
                  </tr>
              </thead>
              <tbody *ngFor=" let item of objetoExportacao.condicionantePPB ; let i = index ; " [attr.data-index]="0">
                  <tr style="border-top: solid 2px;">
                      <td class="text-left" [ngStyle]="{'background-color': item.idPPB != _idPPBRota ? '#ffff99' : ''  }">
                          <app-taxa-texto
                              [(texto)]="item.descricaTopico"
                              [(indicadorParaTaxarItem)]="item.indicadorParaTaxarItem">
                          </app-taxa-texto>
                      </td>
                      <td class="text-left" [ngStyle]="{'background-color': item.idPPB != _idPPBRota ? '#ffff99' : ''  }">
                          <app-taxa-texto
                              [(texto)]="item.dataInicioVigenciaString"
                              [(indicadorParaTaxarItem)]="item.indicadorParaTaxarItem">
                          </app-taxa-texto>
                      </td>
                      <td class="text-left m-l-lg" [ngStyle]="{'background-color': item.idPPB != _idPPBRota ? '#ffff99' : ''  }">
                          <app-taxa-texto
                              [(texto)]="item.descricaoDocumento"
                              [(indicadorParaTaxarItem)]="item.indicadorParaTaxarItem">
                          </app-taxa-texto>
                      </td>
                      <td *ngIf="isMultiplo() && !isGrupo()" class="text-left m-l-lg" [ngStyle]="{'background-color': item.idPPB != _idPPBRota ? '#ffff99' : ''  }">
                          <app-taxa-texto
                              [(texto)]="item.descricaoMultiplo"
                              [(indicadorParaTaxarItem)]="item.indicadorParaTaxarItem">
                          </app-taxa-texto>
                      </td>
                      <td *ngIf="!isMultiplo() && isGrupo()" class="text-left m-l-lg" [ngStyle]="{'background-color': item.idPPB != _idPPBRota ? '#ffff99' : ''  }">
                          <app-taxa-texto
                              [(texto)]="item.descricaoGrupo"
                              [(indicadorParaTaxarItem)]="item.indicadorParaTaxarItem">
                          </app-taxa-texto>
                      </td>
                  </tr>
                  <tr colspan="2" id="{{ 'condicionante-' + i }}" style="margin-left: 25px;">
                      <div class="row">
                        <th style="max-width: 380px; border:none; !important">
                          Descrição
                            <p class="relatorio-condicionante-{{i}}" style="margin-top: 10px;">
                              {{appendChildCondicionante(i, item.descricaCondicaoString)}}
                            </p>
                        </th>
                      </div>
                    </tr>
              </tbody>
          </table>
      </div>
		</div>
	</div>

  <br>

    <!-- ##################################################### -->
	<!-- ################ PRODUTOS DO PPB #################### -->
    <!-- ##################################################### -->
    <div
         *ngIf="objetoExportacao.produtosVinculadoPPB"
         id="after6">
        <legend style="margin-left: 15px;">PRODUTOS VINCULADOS</legend>
        <div class="no-margin-bottom no-border">
            <table class="table table-striped">
                <thead class="table-header-color">
                    <tr style="background-color: #00552b; color: white;">
                        <th class="th-100 text-left">
                            Código
                        </th>
                        <th class="th-sortable unset-nowrap text-left">
                            Descrição Produto
                        </th>
                        <th class="th-sortable unset-nowrap text-left">
                            Documento
                        </th>
                        <th class="th-sortable unset-nowrap text-left" *ngIf="_isPorGrupo">
                            Grupo
                        </th>
                    </tr>
                </thead>
                <tbody *ngFor=" let item of objetoExportacao.produtosVinculadoPPB ; let i = index ; " [attr.data-index]="i">
                    <tr>
                        <td class="text-left"> {{ item.numeroCodigo }} </td>
                        <td class="text-left"> {{ item.descricaoProduto }} </td>
                        <td class="text-left"> {{ item.documento }} </td>
                        <td class="text-left" *ngIf="_isPorGrupo"> {{ item.descricaoGrupo }} </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>



    <!------CRONOGRAMA PONTUAÇÃO MINIMA -->
<!--
    <app-cronograma-pontuacao-minima-ppb
    [habilitarInputs]="true"
    [_isPorPontuacao]="_isPorPontuacao"
    [dataVigenciaInicialPPB]="_dataVigenciaParams"
    [idPPB]="_idPPBRota"

    [buscarVinculos]="true"
    [validacao]="objetoValidacao"
    [isAlteracaoParaRevogacao]="isAlteracaoParaRevogacao"
    [isRetificacao]="isRetificacao"
    [_fromTelaElaboracao]="true"
    *ngIf="true"
    [_fromModalListagemPpb]="true"
  >
  </app-cronograma-pontuacao-minima-ppb> -->
  <div *ngIf="exibirGridPontMinima()">
    <legend style="margin-left: 15px;">Cronograma Anual - Pontuação Mínima</legend>
    <div class="table-responsive no-margin-bottom no-border" style="padding-bottom: 8px;">
      <table class="table table">
        <thead class="table-header-color">
          <tr style="background-color: #00552b; color: white;">
            <th class="th-sortable unset-nowrap text-center" *ngIf="objetoExportacao.informacoesPPB.ppbVM.tipoEtapa == 3">
              Grupo
            </th>
            <th class="th-sortable unset-nowrap text-center">
              Etapa
            </th>
            <th class="th-sortable unset-nowrap text-center">
              Vigência
            </th>
            <th class="th-sortable unset-nowrap text-center">
              Pontuação Minima
            </th>
          </tr>
        </thead>
        <tbody style="border: 1px solid black;" *ngFor="let item of objetoExportacao.pontuacaoMinima; let i = index">
          <tr>
            <!-- GRUPO -->
            <td *ngIf="objetoExportacao.informacoesPPB.ppbVM.tipoEtapa == 3" [attr.rowspan]="obterRowspan(item)" class="text-center"
              style="text-align: center; vertical-align: middle; border: 1px solid black;">
              <p>{{item.listaGrupo[0].descricaGrupo}}</p>
            </td>
          </tr>


          <ng-container *ngFor="let itemEtapas of item.listaGrupo[0].listaEtapas; let j = index">
            <tr *ngIf="j == 0">
              <!-- VIGENCIAS/DATAS SOMATORIO -->
              <td style="border: 1px solid black; vertical-align: middle;">
                {{'Somatória mínima das pontuações das etapas '+obterDescricaoEtapasConcat(item)}}
              </td>
              <!-- VIGENCIA / SOMATORIO-->
              <td style="border: 1px solid black; vertical-align: middle;">


                 <ng-container *ngFor="let objVigencia of obterRepeticaoInputs(item); let num = index">
                  <div class="input-group" style="display: flex;">

                    <span>{{ parametros2['dataInicio-'+i+j+num]}}</span>
                    <span style="padding-right: 4px;padding-left: 4px;">a</span>
                    <span>{{ parametros2['dataFim-'+i+j+num]}}</span>


                  </div>
                </ng-container>
              </td>

              <!-- VALOR MINIMA SOMATORIO -->
              <td class="text-center" style="border: 1px solid black;">
                  <ng-container *ngFor="let objVigencia of obterRepeticaoInputs(item); let num2 = index">
                  <div class="input-group" style="display: block; margin-top: 7px;">

                      <span >{{formatNumber(parametros2['valorSomaMinima-'+i+j+num2])}}</span>
                  </div>
                </ng-container>
              </td>
            </tr>


            <tr>
              <!-- ETAPAS -->
              <td style="border: 1px solid black; vertical-align: middle;">
                {{'Pontuação mínima para a etapa '+obterDescricaoEtapas(itemEtapas)}}
              </td>
              <!-- VIGENCIA / DATAS ETAPAS-->
              <td style="border: 1px solid black; vertical-align: middle;">
                <div class="input-group" style="display: flex;">
                  <span>{{ itemEtapas.dataVigenciaInicialEtapaFormatado || "---" }}</span>
                  <span style="padding-right: 4px;padding-left: 4px;">a</span>
                  <span>{{ itemEtapas.dataVigenciaFinalEtapaFormatado || "--"}}</span>

                </div>
              </td>

              <!-- VALOR MINIMA ETAPA -->
              <td class="text-center" style="border: 1px solid black;">

                <span>{{formatNumber(itemEtapas.valorPontuacaoMinima)}}</span>
              </td>
            </tr>
          </ng-container>

        </tbody>
      </table>
    </div>
  </div>




</div>
<!-- FIM CONTEUDO RELATORIO PDF -->
