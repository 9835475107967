import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BooleanoTextoPipe } from './booleano-texto.pipe';
import { CodigoNaturezaJuridicaPipe } from './codigo-natureza-juridica.pipe';
import { CepPipe } from './cep.pipe';
import { CnpjPipe } from './cnpj.pipe';
import { CnpjPipe2 } from './cnpj.pipe';
import { CpfPipe } from './cpf.pipe';
import { SafeHtmlPipe } from './safeHtml.pipe';
import { EnumPipe } from './enum.pipe';
import { ProtocoloPipe } from './protocolo.pipe';
import { GroupByPipe } from './group-by.pipe';
import { PadLeftPipe } from './pad-left.pipe';
import { MonthPipe } from './month.pipe';
import { PorcentagemPipe } from './porcentagem.pipe';
import { MesMesesPipe } from './mes-meses.pipe';

@NgModule({
	imports: [
		CommonModule,
	],
	exports: [
		BooleanoTextoPipe,
		CepPipe,
		CnpjPipe,
		CnpjPipe2,
		CodigoNaturezaJuridicaPipe,
		CpfPipe,
		EnumPipe,
		GroupByPipe,
		MonthPipe,
		PadLeftPipe,
		PorcentagemPipe,
		ProtocoloPipe,
		SafeHtmlPipe,
		MesMesesPipe
	],
	declarations: [
		BooleanoTextoPipe,
		CepPipe,
		CnpjPipe,
		CnpjPipe2,
		CodigoNaturezaJuridicaPipe,
		CpfPipe,
		EnumPipe,
		GroupByPipe,
		MonthPipe,
		PadLeftPipe,
		PorcentagemPipe,
		ProtocoloPipe,
		SafeHtmlPipe,
		MesMesesPipe
	],
})
export class PipesModule { }
