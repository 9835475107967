import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { ViewsComponentsModule } from "../views-components/views-components.module";
import { IndexComponent } from "./index/index.component";
import { ManterPBModule } from './manter-ppb/manter-ppb.module';
import { ManterDocumentoPPBModule } from './manter-documento-do-ppb/manter-documento-do-ppb.module';

@NgModule({
	declarations: [
		IndexComponent,
	],
	exports: [
	],
	imports: [
		CommonModule,
		FormsModule,
		ViewsComponentsModule,
		ManterPBModule,
		ManterDocumentoPPBModule
	]
})
export class ViewsModule { }
