export class ValidacaoMPPBVM{
    idPPB : number;
    pendenciaTipoPublicacao : boolean;
    pendenciaDocumentoDeReferencia : boolean;
    pendenciaInicioVigencia : boolean;
    pendenciaAnexarDocumentoComprobatorio : boolean;
    pendenciaResumoTecnico : boolean;
    pendenciaTipoEtapa : boolean;
    pendenciaGrupoPPB : boolean;
    pendenciaEtapaPPB : boolean;
    pendenciaCondicionantePPB : boolean;
    pendenciaTipoDocumento : boolean;
    pendenciaMetasPPB : boolean;
    pendenciaPPBMultiplo : boolean;
    _flagExistePendencia : boolean = false;
    descricaoPendencia : string = null;
}