import { Component, Inject, OnInit, Output, Input, ViewChild, ElementRef, EventEmitter } from '@angular/core';
import { ApplicationService } from "../../../../../shared/services/application.service";
import { PagedItems } from '../../../../../view-model/PagedItems';
import { PPBHistorico } from '../../../../../view-model/PPBHistoricoVM';
import { PPBListagensHistoricoEntityVM } from '../../../../../view-model/PPBListagensHistoricoEntityVM';

enum EnumStatusGridsPPB{
  NOVO = 1,
  SUBSTITUTO = 2,
  SUBSTITUIDO = 3,
  EXCLUIDO = 4
}

@Component({
	selector: 'app-listagem-historico-das-listagens',
	templateUrl: './app-listagens-historico-listagens.componenet.html'
})

export class AppListarHistoricoListagensComponent implements OnInit {

	//----GRID ITENS-----
	@Input() lista: any = {};
	@Input() total: number;
	@Input() size: number;
	@Input() page: number;
	@Input() sorted: string;
	grid: any = { sort: {} };
  parametros : any = {};
	//---------- Dados do Grid -------
	listaHistoricoPPB: Array<PPBHistorico>;
	idPPB: number;
	//------------

	//--------- Serviços ---------
	servicoHistorico = "HistoricoPPB";

  @Input() _idPpb : number;
	@Input() _nomeTabela: string;
	@Input() _nomeGrid: string;

	constructor(
		private applicationService : ApplicationService
	) {}

	ngOnInit() {this.listar();}

	onChangeSort($event: any) {
		this.grid.sort = $event;
		this.listar();
	}

	onChangeSize($event: any) {
		this.grid.size = $event;
		this.grid.page = 1;
		this.listar();
	}

	onChangePage($event: any) {
    this.grid.page = $event;
		this.listar();
	}

	prencheParametrosDeExportacao(){
    	this.parametros.exportarListagem = true;
		this.parametros.exportarPdf = true;
		this.parametros.servico = "HistoricoListagens";
		this.parametros.titulo = "Histórico des exclusões de " + this._nomeGrid;
		this.parametros.columns = ["Descrição Ação", "Usuário","Data e Hora"];
		this.parametros.fields = ["descricaoAcao", "nomeUsuario", "dataHoraAcaoString"];
		this.parametros.width = {
									0: { columnWidth: 420 }, 1: { columnWidth: 200 },
									2: { columnWidth: 150 }
								};
	}

	listar()
	{
		var objeto : PPBListagensHistoricoEntityVM = new PPBListagensHistoricoEntityVM();
		objeto.page =  this.grid.page || 1;
		objeto.size = this.grid.size || 10;
		objeto.sort = this.grid.sort.field;
		objeto.reverse = this.grid.sort.reverse;
		objeto.idPPB = this._idPpb;
		objeto.descricaoTabela = this._nomeTabela;
    //objeto.statusAtual = EnumStatusGridsPPB.EXCLUIDO;
    this.parametros = objeto;
		this.applicationService.get("HistoricoListagens", objeto).subscribe((retorno : PagedItems) => {
      if(retorno.items != null && retorno){
        this.grid.lista = retorno.items;
        this.grid.total = retorno.total;
        this.prencheParametrosDeExportacao();
      }else{
        this.parametros.exportarListagem = false;
        this.grid.total = 0;
      }
		});

	}
}
