import { Component, Output, Input, OnInit, EventEmitter, ViewChild } from '@angular/core';
import { ApplicationService } from '../../../shared/services/application.service';
import { MessagesService } from '../../../shared/services/messages.service';
import { ModalService } from '../../../shared/services/modal.service';
import { Router } from '@angular/router';
import { PPBVM } from '../../../view-model/PPBVM';
import { ValidationService } from '../../../shared/services/validation.service';
import { EnumStatusPPB } from '../../../shared/enums/EnumStatusPPB';
import { AnexoPPBVM } from '../../../view-model/AnexoPPBVM';
import { RelatorioListagensPPBVM } from '../../../view-model/RelatorioListagensPPBVM';
import { DocumentosVinculadosPPBVM } from '../../../view-model/DocumentosVinculadorPPBVM';
import { PPBAnexoVM } from '../../../view-model/PPBAnexoVM';
import { RequestResponseVM } from '../../../view-model/RequestResponseVM';
import { FinalizacaoPpbResponseVM } from '../../../view-model/FinalizacaoPpbResponseVM';
import { PDFService } from '../../../shared/services/pdf.service';
import { CondicionanteVM } from '../../../view-model/CondicionanteVM';
import { TipoConsultaRelatorioVM } from '../../../view-model/TipoConsultaRelatorioVM';
import { ControladorTiposEtapaPPB } from '../../../shared/services/controladorTiposEtapaPpb.service';

enum EnumTipoPublicacao{
	FIXAÇÃO = 1,
	ALTERAÇÃO_PARA_REVOGAÇÃO = 2,
	ALTERAÇÃO_PARCIAL = 3,
	NORMA_COMPLEMENTAR = 4,
	FIXAÇÃO_DE_PPB_ESPECÍFICO = 5
}

enum EnumTipoEtapa {
  SEM_PONTUACAO = 1,
  POR_PONTUACAO = 2,
  POR_GRUPO = 3,
  PPB_MULTIPLO = 4
}

@Component({
  selector: 'app-grid-manter-ppb',
  templateUrl: './grid.component.html'
})

export class GridManterPPBComponent {

  ocultarTituloGrid = true;

	constructor(
		private applicationService: ApplicationService,
		private modal: ModalService,
		private msg: MessagesService,
		private router: Router,
		private validation: ValidationService,
		private pdfService : PDFService,
    private contrTipoEtapa: ControladorTiposEtapaPPB
	) { }

  /****************************************************************
  ******************VARIÁVEIS EMISSÃO RELATÓRIO*******************/
  @ViewChild('appRelatorioConsolidado') appRelatorioConsolidado: any;
  mostraComponenteRelatorio: boolean = false;
  objetoExportacao: RelatorioListagensPPBVM = new RelatorioListagensPPBVM();
  _tituloPPB: string = "";
  _isSempontuacao: boolean = false;
  _isPorPontuacao: boolean = false;
  _isPorGrupo: boolean = false;
  _isPPBMultiplo: boolean = false;
  arrayDocumentosVinculados: Array<DocumentosVinculadosPPBVM> = new Array<DocumentosVinculadosPPBVM>();
  _arrayDocumentoPosteriores: Array<DocumentosVinculadosPPBVM> = new Array<DocumentosVinculadosPPBVM>();
  listaAnexos = new Array<PPBAnexoVM>();
  temArquivo: boolean = false;
  _dataDocumento: string;
  _dataPublicacao: string;
  _dataVigencia: string;
  _dataVigenciaParams: string;
  flagAtivarTxtDocReferencia: boolean = false;
  _idPPBRota: number;
  _isDocPosterior: number;
  /****************************************************************
  ****************************************************************/

  @Input() lista: any = {};
  @Input() total: number;
  @Input() size: number;
  @Input() page: number;
  @Input() sorted: string;
  @Input() isUsuarioInterno: boolean = false;
  @Input() isUsuarioExterno: boolean = false;
  @Input() exportarListagem: boolean;
  @Input() parametros: any = {};
  @Input() formPai: any;
  @Input() somenteLeitura: boolean;

  @Output() onChangeSort: EventEmitter<any> = new EventEmitter();
  @Output() onChangeSize: EventEmitter<any> = new EventEmitter();
  @Output() onChangePage: EventEmitter<any> = new EventEmitter();
  @Output() onChange: EventEmitter<any> = new EventEmitter();

  @Output() atualizarListagem: EventEmitter<any> = new EventEmitter();

  @ViewChild('appModalEtapasCondicoesPPB') appModalEtapasCondicoesPPB;
  @ViewChild('appModalVinculoProdutos') appModalVinculoProdutos;
  @ViewChild('appModalVinculos') appModalVinculos;
  @ViewChild('appModalHistorico') appModalHistorico;
  @ViewChild('appModalBaixarAnexo') appModalBaixarAnexo;

	relatorio : Array<any>;

  tipoEtapasDs = {
    1:"Sem Pontuação",
    2:"Por Pontuação",
    3:"Por Grupo com Pontuação",
    4:"Multiplo sem Pontuação"
  }


	//STATUS PPB
	EmElaboracao = 1;
    Vigente = 2;
    VigenciaFutura = 3;
    Revogado = 4;
    EmRetificacao = 5;

  tituloGrid = "PPB"
  servico = "Validar";
  servicoFinalizar = "FinalizarPPB";
  servicoExcluir = 'ExcluirPPB';
  servicoAnexo = 'AnexoPPB';

  changeSize($event) {
    this.onChangeSize.emit($event);
  }

  changeSort($event) {
    this.sorted = $event.field;
    this.onChangeSort.emit($event);
    this.changePage(this.page);
  }

  changePage($event) {
    this.page = $event;
    this.onChangePage.emit($event);
  }

  abrirListagens(objeto) {
    this.appModalEtapasCondicoesPPB.abrir(objeto);
  }

  abrirFinalizar(item: any) {
    this.applicationService.get(this.servicoFinalizar, { id: item.id }).subscribe((response: FinalizacaoPpbResponseVM) => {
      if (response.statusResponse == 200) {
        this.modal.sucesso("Operação realizada com sucesso", "Sucesso").subscribe(() => {
          if (response.existePPBRevogado) {
              this.desvinculasProdutosDeOutrosPpbEAdicionaNoCorrente(response, item);
          }
          else {
            this.abrirModalProdutoAposFinalizacao(item);
          }
        });
      }
      else if (response.statusResponse == 403) {
        this.modal.infoError("PPB com dados Inconsistentes", "Erro");
        this.router.navigate(["solicitacoes-ppb/" + item.id + "/validacao"]);
      }
      else if (response.statusResponse == 500) {
        this.modal.error(response.textResponse, "Erro 500");
        return false;
      }
      else {
        this.modal.infoError("Falha ao finalizar ppb. Favor entrar em contato com a equipe técnica.", "Erro desconhecido");
      }
    });
  }

  desvinculasProdutosDeOutrosPpbEAdicionaNoCorrente(objeto: FinalizacaoPpbResponseVM, item : any){
    this.applicationService.post("FinalizacaoPPBComDesvinculamentoDeProdutos", objeto).subscribe((response:FinalizacaoPpbResponseVM)=>{
      if (response.statusResponse == 200) {
        this.modal.sucesso("Os Produtos dos ppbs revogados foram migrados para o ppb corrente", "Atenção").subscribe(()=>{
          this.abrirModalProdutoAposFinalizacao(item);
        });
      }
      else {
        this.modal.error(response.textResponse, "Erro 500");
        return false;
      }
    });
  }

  abrirModalProdutoAposFinalizacao(item){
    this.appModalVinculoProdutos.abrir(item, true, null, true);
    this.changePage(this.page);
  }

  abrirVinculos(objeto) {
    this.appModalVinculos.abrir(objeto);
  }

  abrirEditar(idPPB: number) {
    this.router.navigate(['/solicitacoes-ppb/' + idPPB + '/editar']);
  }

  abrirVisualizar(idPPB: number) {
    this.router.navigate(['/solicitacoes-ppb/' + idPPB + '/visualizar']);
  }

  abrirHistorico(id: number, tituloPPB: string) {
    this.appModalHistorico.abrir(id, tituloPPB);
  }

  excluir(id: number) {
    this.modal.confirmacao("Deseja Excluir o Registro?").subscribe((isConfirmado: any) => {
      if (isConfirmado) {
        this.applicationService.delete(this.servicoExcluir, id).subscribe((response: RequestResponseVM) => {
          if (response.statusResponse == 200) {
            this.modal.infoSucess("Operação realizada com sucesso", "Sucesso");
            this.changePage(this.page)
          }
          else {
            this.modal.infoError(response.textResponse, "Error" + response.statusResponse);
            return false;
          }
        });
      }
    });

  }

  formatDate(date: Date) {
    return this.validation.getDateWhithChangeFormat('DD/MM/YYYY', date)
  }

  definirStatus(tipo: number): string {
    if (tipo == EnumStatusPPB.EM_ELABORACAO) {
      return "Em Elaboração";
    }

    if (tipo == EnumStatusPPB.REVOGADO) {
      return "Revogado";
    }

    if (tipo == EnumStatusPPB.VIGENCIA_FUTURA) {
      return "Vigencia Futura";
    }

    if (tipo == EnumStatusPPB.VIGENTE) {
      return "Vigente";
    }

    if (tipo == EnumStatusPPB.EM_RETIFICACAO) {
      return "Em Retificação";
    }
  }

  definirVinculo(vinculo: number) {
    switch (vinculo) {
      case 1:
        return "FIXAÇÃO (NOVO)";
      case 2:
        return "ALTERAÇÃO POR MEIO DE REVOGAÇÃO";
      case 3:
        return "ALTERAÇÃO PARCIAL";
      case 4:
        return "NORMA COMPLEMENTAR";
      case 5:
        return "FIXAÇÃO DE PPB (Genérico para Especifico)";
    }
  }

  validar(idPPB: number) {
    this.router.navigate(["solicitacoes-ppb/" + idPPB + "/validacao"]);
  }

  buscarAnexo(objeto) {
    this.appModalBaixarAnexo.abrir(objeto);
  }


  changeListagem($event) {
    this.atualizarListagem.emit($event);
  }

  retificar(id: number) {
    this.modal.confirmacao("Deseja Retificar o Registro?").subscribe((isConfirmado: any) => {
      if (isConfirmado) {
        this.applicationService.put("SolicitarCadastroPPB/" + id).subscribe((response: any) => {
          if (response != 0) {
            this.modal.infoSucess("Operação realizada com Sucesso!", "Sucesso");
            this.abrirEditar(response);
          } else {
            this.modal.infoError("Não foi possível realizar a retificação do registro", "Erro");
          }
        });
      }
    });
  }

  abrirModalProdutosVinculados(item) {
    this.appModalVinculoProdutos.abrir(item);
  }




	buscarDadosExportacao(idPPB : number)	{
    const obj = new TipoConsultaRelatorioVM();
    obj.buscarGrids = true;
    obj.idPpb = idPPB;
		this.applicationService.get("RelatorioListagensPPB", obj).subscribe((result : RelatorioListagensPPBVM) => {
			this.exibeRelatorio(result);
		});
	}


	GerarRelatorioEtapa(objetoRelatorio : any)
	{
    let parametros: any = {}
		parametros.titulo ="ETAPAS";
		parametros.columns = [
									"Etapa",
									"Vigência",
									"Descrição das Etapas",
									"Documento",
		];
		parametros.fields = [
									"descricaTopico",
									"dataInicioVigenciaString",
									"descricaoEtapa",
									"descricaoDocumento"
		];
		parametros.width = {
									0: { columnWidth: 250 },
									1: { columnWidth: 150 },
									2: { columnWidth: 210 },
									3: { columnWidth: 180 }
		};
    var lista = objetoRelatorio;
    let rows = Array<any>();
    var file = window.location.href.split("#")[1].replace("/", "");
    for (var i = 0; i < objetoRelatorio.length; i++)
    {
      let r = Array<any>();
      let valor: any; for (var j = 0; j < parametros.fields.length; j++) {
        var item = parametros.fields[j].split("|"); valor = item.length > 0 ? Object.values(lista)[i][item[0].trim()] : Object.values(lista)[i][parametros.fields[j].trim()];
        if (parametros.fields[j].trim() == "status")
        r[j] = (valor == 1 ? "ATIVO" : "INATIVO");
        else {
        r[j] = valor;
        }
      }
      rows.push(r);
    }
    this.pdfService.exportAsPDFFile(parametros.columns, rows, parametros.width, file, parametros.titulo);
	}

  GerarRelatorioMetas(objetoRelatorio : any)
	{
    let parametros: any = {}
		parametros.titulo ="METAS";
		parametros.columns = [
        "Pontuação da Meta",
        "Vigência",
        "Descrição",
        "Documento",
		];
		parametros.fields = [
        "valorMeta",
        "descricaoDataVigencia",
        "descricaoMeta",
        "ppb.descricaoTitulo"
		];
		parametros.width = {
									0: { columnWidth: 250 },
									1: { columnWidth: 150 },
									2: { columnWidth: 210 },
									3: { columnWidth: 180 }
		};

    var lista = objetoRelatorio;
    let rows = Array<any>();
    var file = window.location.href.split("#")[1].replace("/", "");
    for (var i = 0; i < objetoRelatorio.length; i++)
    {
      let r = Array<any>();
      let valor: any; for (var j = 0; j < parametros.fields.length; j++) {
        var item = parametros.fields[j].split("|"); valor = item.length > 0 ? Object.values(lista)[i][item[0].trim()] : Object.values(lista)[i][parametros.fields[j].trim()];
        if (parametros.fields[j].trim() == "status")
        r[j] = (valor == 1 ? "ATIVO" : "INATIVO");
        else {
        r[j] = valor;
        }
      }
      rows.push(r);
    }
    this.pdfService.exportAsPDFFile(parametros.columns, rows, parametros.width, file, parametros.titulo);
	}

	exibeRelatorio2(){
			var lista = this.relatorio;
			let rows = Array<any>();
			var file = window.location.href.split("#")[1].replace("/", "");
			for (var i = 0; i < this.relatorio.length; i++)
			{
				let r = Array<any>();
				let valor: any; for (var j = 0; j < this.parametros.fields.length; j++) {
					var item = this.parametros.fields[j].split("|"); valor = item.length > 0 ? Object.values(lista)[i][item[0].trim()] : Object.values(lista)[i][this.parametros.fields[j].trim()];
					if (this.parametros.fields[j].trim() == "status")
					r[j] = (valor == 1 ? "ATIVO" : "INATIVO");
					else {
					r[j] = valor;
					}
				}
				rows.push(r);
			}
			this.pdfService.exportAsPDFFile(this.parametros.columns, rows, this.parametros.width, file, this.parametros.titulo);
	}

	BaixouPdf($event){
		this.limparVariaveisDeExportacao();
	}

  exibeRelatorio(result: RelatorioListagensPPBVM) {

    this.objetoExportacao = result;

    this._tituloPPB = result.tituloPPB;

    this._idPPBRota = this.objetoExportacao.informacoesPPB.ppbVM.id;

    this.listaAnexos = result.informacoesPPB.ppbAnexoVM;

    result.informacoesPPB.ppbAnexoVM.length > 0 ?
      this.temArquivo = true :
      this.temArquivo = false;

    if (this.objetoExportacao.informacoesPPB.dataDocumentoString)
      this._dataDocumento = this.objetoExportacao.informacoesPPB.dataDocumentoString;

    if (this.objetoExportacao.informacoesPPB.dataPublicacaoString)
      this._dataPublicacao = this.objetoExportacao.informacoesPPB.dataPublicacaoString;

    if (this.objetoExportacao.informacoesPPB.dataInicioVigenciaString) {
      this._dataVigencia = this.objetoExportacao.informacoesPPB.dataInicioVigenciaString;
      this._dataVigenciaParams = this.objetoExportacao.informacoesPPB.dataInicioVigenciaString;
    }

    // PUBLICAÇÕES DO TIPO ALTERACAO PARCIAL E ALTERACAO POR REVOGACAO, TERAO SEUS GRIDS MONTADOS CONFORME AS REFERENCIAS.


    const tipoPublicacao = this.objetoExportacao.informacoesPPB.ppbVM.tipoPublicacao;
    const tipoEtapa = this.objetoExportacao.informacoesPPB.ppbVM.tipoEtapa;
    const tiposPublicacaoReferencia = this.objetoExportacao.informacoesPPB.tipoPublicacoesDasReferencias;

    const tiposEtapaFormulado = this.contrTipoEtapa.definirTiposEtapa(tipoPublicacao,tipoEtapa,tiposPublicacaoReferencia);

    this._isPPBMultiplo = tiposEtapaFormulado.isMultiplo;
    this._isPorGrupo = tiposEtapaFormulado.isPorGrupo;
    this._isPorPontuacao = tiposEtapaFormulado.isPorPontuacao;
    this._isSempontuacao = tiposEtapaFormulado.isSemPontuacao;


    if (this.objetoExportacao.informacoesPPB.documentosPosteriores) {
      this._arrayDocumentoPosteriores = this.objetoExportacao.informacoesPPB.documentosPosteriores;
    }
    else {
      this._arrayDocumentoPosteriores = null;
    }

    this._isDocPosterior = this.objetoExportacao.informacoesPPB.ppbVM.statusPosterior;

    if (this.objetoExportacao.informacoesPPB.documentosVinculados)
      this.arrayDocumentosVinculados = this.objetoExportacao.informacoesPPB.documentosVinculados;

    if (this.objetoExportacao.informacoesPPB.ppbVM.descricaoDocReferencia)
      this.flagAtivarTxtDocReferencia = true;

    this.mostraComponenteRelatorio = true;
  }

  limparVariaveisDeExportacao() {
    this.mostraComponenteRelatorio = false;
    this.objetoExportacao = null;
    this.listaAnexos = null;
    this.temArquivo = false;
    this._dataDocumento = null;
    this._dataPublicacao = null;
    this._dataVigencia = null;
    this._dataVigenciaParams = null;
    this.arrayDocumentosVinculados = null;
    this.flagAtivarTxtDocReferencia = false;
    this._idPPBRota = null;
    this._isSempontuacao = false;
    this._isPorPontuacao = false;
    this._isPorGrupo = false;
    this._isPPBMultiplo = false;
    this._isDocPosterior = null;
  }

	validaTipoEtapa(tipoEtapa : number){
		switch(tipoEtapa){
			case EnumTipoEtapa.SEM_PONTUACAO :
				this._isSempontuacao = true;
				this._isPorPontuacao = false;
				this._isPorGrupo = false;
				this._isPPBMultiplo = false;
				break;
			case EnumTipoEtapa.POR_PONTUACAO :
				this._isSempontuacao = false;
				this._isPorPontuacao = true;
				this._isPorGrupo = false;
				this._isPPBMultiplo = false;
				break;
			case EnumTipoEtapa.POR_GRUPO :
				this._isSempontuacao = false;
				this._isPorPontuacao = false;
				this._isPorGrupo = true;
				this._isPPBMultiplo = false;
				break;
			case EnumTipoEtapa.PPB_MULTIPLO :
				this._isSempontuacao = false;
				this._isPorPontuacao = false;
				this._isPorGrupo = false;
				this._isPPBMultiplo = true;
				break;
		}
	}


	GerarRelatorioGrupo()
	{
		this.parametros.titulo ="GRUPOS";
		this.parametros.columns = [
									"Grupo",
									"Vigência",
									"Documento",
									"Produto",
		];
		this.parametros.fields = [
									"descricaGrupo",
									"dataInicioVigenciaString",
									"descricaoDocumento",
									"descricaProdutos"
		];
		this.parametros.width = {
									0: { columnWidth: 250 },
									1: { columnWidth: 150 },
									2: { columnWidth: 180 },
									3: { columnWidth: 210 }
		};
	}

	GerarRelatorioCondicionante()
	{
		this.parametros.titulo ="CONDICIONANTES";
		this.parametros.columns = [
									"Condicionante",
									"Vigência",
									"Descrição da Condicionante",
									"Documento",
		];
		this.parametros.fields = [
									"descricaTopico",
									"dataInicioVigenciaString",
									"descricaCondicaoString",
									"descricaoDocumento"
		];
		this.parametros.width = {
									0: { columnWidth: 250 },
									1: { columnWidth: 150 },
									2: { columnWidth: 210 },
									3: { columnWidth: 180 }
		};
	}

	GerarRelatorioMultiplo()
	{
		this.parametros.titulo ="PPB MULTIPLO";
		this.parametros.columns = [
									"PPB",
									"Vigência",
									"Documento",
									"Produto",
		];
		this.parametros.fields = [
									"descricao",
									"dataVigenciaConcatenada",
									"descricaoDocumento",
									"descricaProdutos"
		];
		this.parametros.width = {
									0: { columnWidth: 250 },
									1: { columnWidth: 150 },
									2: { columnWidth: 180 },
									3: { columnWidth: 210 }
		};
	}
}
