import { Component, Output, Input, OnInit, EventEmitter, ViewChild } from '@angular/core';
import { ApplicationService } from '../../../shared/services/application.service';
import { MessagesService } from '../../../shared/services/messages.service';
import { ModalService } from '../../../shared/services/modal.service';
import { Router } from '@angular/router';
import { ValidationService } from '../../../shared/services/validation.service';
import { EnumGridStatusDocPPB } from '../../../shared/enums/EnumGridStatusDocPPB';


@Component({
	selector: 'app-grid-manter-documento-ppb',
	templateUrl: './grid.component.html'
})

export class GridManterDocumentoPPBComponent {

	ocultarTituloGrid = true;

	constructor(
		private applicationService: ApplicationService,
		private modal: ModalService,
		private msg: MessagesService,
		private router: Router,
		private validation: ValidationService,
	) { }

	@Input() lista: any = {};
	@Input() total: number;
	@Input() size: number;
	@Input() page: number;
	@Input() sorted: string;
	@Input() isUsuarioInterno: boolean = false;
	@Input() exportarListagem: boolean;
	@Input() parametros: any = {};
	@Input() formPai: any;
	@Input() somenteLeitura: boolean;

	@Output() onChangeSort: EventEmitter<any> = new EventEmitter();
	@Output() onChangeSize: EventEmitter<any> = new EventEmitter();
	@Output() onChangePage: EventEmitter<any> = new EventEmitter();
	@Output() onChange: EventEmitter<any> = new EventEmitter();

	@ViewChild('modalHistorico') appModalHistorico;	

	tituloGrid = "Registros"
	servico = "Validar";
	servicoFinalizar = "FinalizarPPB";
	servicoExcluir = 'ExcluirDocumentoPPB';
	servicoAnexo = 'AnexoPPB';
	servicoDocumentoPPB = 'TipoDocumento';

	changeSize($event) {
		this.onChangeSize.emit($event);
		this.changePage(this.page);
	}

	changeSort($event) {
		this.sorted = $event.field;
		this.onChangeSort.emit($event);
		this.changePage(this.page);
	}

	changePage($event) {
		this.page = $event;
		this.onChangePage.emit($event);
	}

	abrirHistorico(idTipoDocumento) {
		this.appModalHistorico.abrir(idTipoDocumento);
	}


	abrirEditar(id: number) {
		this.router.navigate(['/solicitacoes-documento-ppb/' + id + '/editar']);
	}

	excluir(id: number) {
		this.modal.confirmacao("Deseja Excluir o Registro?").subscribe((isConfirmado: any) => {
			if (isConfirmado) {
				this.applicationService.delete(this.servicoDocumentoPPB, id).subscribe((response: any) => {
					if (response != 500) {
						this.modal.infoSucess("Operação realizada com sucesso", "Sucesso");
						this.changePage(this.page)
					} else {
						this.modal.infoError("Não foi possível realizar a exclusão do registro", "Erro");
					}
				});
			}
		});

	}

	definirStatus(tipo: number): string {
		if (tipo == EnumGridStatusDocPPB.TODOS) {
			return "Todos";
		}

		if (tipo == EnumGridStatusDocPPB.ATIVO) {
			return "Ativo";
		}

		if (tipo == EnumGridStatusDocPPB.INATIVO) {
			return "Inativo";
		}
	}

	MudarStatusDocumentoPPB(id: number, descricaoStatus: string) {
		var stringTexto;
		if(descricaoStatus == 'VIGENTE'){
           stringTexto = "Inativar Tipo Documento";
		}else if(descricaoStatus == 'INATIVO'){
		   stringTexto = "Reativar Tipo Documento";
		}
		this.modal.justificativa(stringTexto).subscribe((justificativa: any) => {
			if(justificativa){

				this.applicationService.put(this.servicoDocumentoPPB+'/AtualizarStatus', 
				{
					id: id,
					justificativa: justificativa
				}).subscribe((response: any) => {
					if (response) {
						this.modal.infoSucess("Operação realizada com sucesso", "Sucesso");
						this.changePage(this.page)
					} else {
						this.modal.infoError("Não foi possível realizar ativação do documento", "Erro");
					}
				});
			}
		});
	}
}
