import { Component, Injectable, OnInit, ViewChild, Input } from "@angular/core";
import { PagedItems } from "../../../../view-model/PagedItems";
import { MessagesService } from "../../../../shared/services/messages.service";
import { LoadingService } from "../../../../shared/services/loading.service";
import { ActivatedRoute, Router } from '@angular/router';
import { ValidationService } from "../../../../shared/services/validation.service";
import { ApplicationService } from "../../../../shared/services/application.service";
import { Location } from '@angular/common';
import { ListagemEtapaPPBVM } from '../../../../view-model/ListagemEtapaPPBVM';
import { ChangeEtapaPPBService } from '../../../../shared/services/changeEtapaPPB.service';
import { ServiceTipoListagemVM } from "../../../../view-model/ServiceTipoListagemVM";
import { FiltrosModalListagemVM } from "../../../../view-model/FiltrosModalListagemVM";
import { TiposReferenciaVM } from "../../../../view-model/TiposReferenciaVM";

@Component({
	selector: "app-etapas-ppb",
	templateUrl: "./etapas-ppb.component.html"
})

@Injectable()
export class ControleEtapasPPBComponent implements OnInit {

	gruposPPB : any ={};
	infoGrupos : any ={};
	form = this;
	path : string;
	grid: any = { sort: {} };
	parametros: any = {};
	ocultarFiltro: boolean = false;
	ocultarGrid: boolean = true;
	isBuscaSalva : boolean = false;
	objetoSession : any = {} = null;

	@Input() dataVigenciaInicialPPB : string;
	@Input() habilitarInputs: boolean;
	@Input() _isSempontuacao : boolean;
	@Input() _isPorPontuacao : boolean;
	@Input() _isPorGrupo: boolean;
	@Input() _isPPBMultiplo: boolean;

  @Input() _exibeInfoGrupo: boolean;
  @Input() _exibeInfoMultiplo: boolean;
  @Input() _exibeInfoPontuacao: boolean;

	@Input() isAlteracaoParaRevogacao : boolean;
	@Input() _fromTelaElaboracao : boolean;
	@Input() validacao : any = {} = null;
	@Input() isRetificacao : boolean;
	@Input() buscarVinculos: boolean;
  @Input() objConsultaFiltradaModalListagem: FiltrosModalListagemVM;
  @Input() isConsultaFiltradaModalListagem: boolean = false;
  @Input() tipoEtapaPpbCorrente: number;
  @Input() exibeRevogados:boolean;

  flagIsSomenteVigente: boolean;
	servico = 'EtapasPPB';
  fuichamadoEtapa = 0;

	@Input() idPPB: number;
	@ViewChild('appModalIncluirEtapasPPB') appModalIncluirEtapasPPB : any;

	constructor(
    private ChangeEtapaPPBService: ChangeEtapaPPBService,
    private route: ActivatedRoute,
    private applicationService: ApplicationService,
    private loadingService: LoadingService,
    private router: Router,
	) {
		this.path = this.route.snapshot.url[this.route.snapshot.url.length - 1].path;
	}

	ngOnInit() {
		this.listar(this.idPPB);

		this.ChangeEtapaPPBService.AtualizaListagemEtapaPPB.subscribe(
			(obj: ServiceTipoListagemVM) => {
        if(obj != null){
          //console.log('to aqui - etapas');
          this.flagIsSomenteVigente = obj.isSomenteVigente;
          this.listar(obj.idPpb, obj.getFromSessionStorage);
        }
      }
		);

	 }

	ocultar() { }

	onChangeSort($event : any)
	{
		this.grid.sort = $event;
		this.listar(this.idPPB);
	}

	onChangeSize($event : any) {
		this.grid.size = $event;
    this.grid.page = 1;
		this.listar(this.idPPB);
	}

	onChangePage($event : any) {
		this.grid.page = $event;
		this.listar(this.idPPB);
  }

	public listar(idPPB : number, getConsultaFromSessionStorage? : boolean){

		var objeto : any = {};
		objeto.idPPB = idPPB;

		if(getConsultaFromSessionStorage)
		{
			this.retornaValorSessao();
			if(this.isBuscaSalva){
				objeto.page = this.objetoSession.page || 1;
				objeto.size = this.objetoSession.size || 10;
				objeto.sort = this.objetoSession.sort || "NumeroSequencia";
				objeto.reverse = this.objetoSession.reverse || false;
			} else {
				objeto.page = 1;
				objeto.size = 10;
			}
		}
		else
		{
			objeto.page = this.grid.page || 1;
			objeto.size = this.grid.size || 10;
			objeto.sort = this.grid.sort.field || "NumeroSequencia";;
			objeto.reverse = this.grid.sort.reverse || false;
		}

		objeto.buscarVinculos = this.buscarVinculos;
		objeto.fromTelaElaboracao = this._fromTelaElaboracao;
    objeto.permiteBuscarArquivo = true;
    objeto.isSomenteVigente = this.flagIsSomenteVigente;
    objeto.objetoConsultaFiltrada = this.objConsultaFiltradaModalListagem;
    if(!objeto.objConsultaFiltradaModalListagem){
      objeto.objConsultaFiltradaModalListagem = new FiltrosModalListagemVM()
    }


    objeto.isConsultaFiltradaModalListagem = this.isConsultaFiltradaModalListagem;
    objeto.exibirRevogados = this.exibeRevogados;

		this.applicationService.get(this.servico+"/GetPaginado" ,  objeto).subscribe( (retorno : any) => {
			if(retorno.listagemPaginado.total > 0) {
				this.gruposPPB = retorno.gruposEtapaPPB;
				this.infoGrupos = retorno.grupos;
				this.grid.lista = retorno.listagemPaginado.items;
				this.grid.total = retorno.listagemPaginado.total;
				this.gravarBusca(objeto);
			} else {
				this.grid = { sort: {} };
			}
		});
	}

	incluirEtapaPPB(){
    console.log("teste is por grupo " + this._isPorGrupo);
		this.appModalIncluirEtapasPPB.abrir(this.idPPB , "new");
	}

	atualizaListagem(idPPB){
		this.listar(idPPB, true);
	}

	gravarBusca(objeto : any) {
		sessionStorage.removeItem(this.router.url);
		sessionStorage.setItem("etapas-ppb-"+this.idPPB , JSON.stringify(objeto));
	}

	retornaValorSessao() {
		var router = sessionStorage.getItem("etapas-ppb-"+this.idPPB);
		if (router) {
			this.isBuscaSalva = true;
			this.objetoSession = JSON.parse(sessionStorage.getItem("etapas-ppb-"+this.idPPB));
		} else {
			this.isBuscaSalva = false;
			return null;
		}
	}


}
