<app-grid
    [(titulo)]="tituloGrid"
    [(page)]="page"
    [(size)]="size"
    [(total)]="total"
    [(parametros)]="parametros"
    (onChangeSize)="changeSize($event)"
    (onChangePage)="changePage($event)">

	<div class="table-responsive no-margin-bottom no-border">
		<table class="table">
      <thead class="table-header-color">
        <tr>
          <th class="th-100 text-left" *ngIf="habilitarInputs">Ações</th>
          <th class="th-sortable unset-nowrap text-left" *ngIf="habilitarInputs">Nº Sequencial</th>
          <th class="th-sortable unset-nowrap text-left">
            Condicionante
          </th>
          <th class="th-sortable unset-nowrap text-left">
            Data Vigência
          </th>
          <th class="th-sortable unset-nowrap text-left">
            Descrição da Condicionante
          </th>
          <th class="th-sortable unset-nowrap text-left">
            Documento
          </th>
          <th class="th-sortable unset-nowrap text-left" *ngIf="lista && lista[0].exibeColunaMultiplo">
            Múltiplo
          </th>
          <th class="th-sortable unset-nowrap text-left" *ngIf="lista && lista[0].exibeColunaGrupo">
            Grupo
          </th>
        </tr>
      </thead>
			<tbody *ngFor=" let item of lista ; let i = index ; " [attr.data-index]="i">
          <tr>
              <td class="text-left" [ngStyle]="{'background-color': item.idPPB != idPPB ? '#ffff99' : ''  }"  *ngIf="habilitarInputs">
                  <a
                      style="margin-left: 3px;"
                      *ngIf="validacaoIsRetificacao(item) && item.habilitaBotoes && habilitarInputs"
                      (click)="validarSePermiteAbrirModal(item)"
                      class="btn btn-default btn-sm"
                      data-toggle="tooltip"
                      title="Alterar"
                      data-original-title="Alterar">
                          <i class="fa fa-pencil"></i>
                  </a>
                  <!--
                  ######################################################
                      No Tipo de Publicação "Alteração para Revogação",
                      as linhas amarelas não deverão mostrar o botão de excluir.
                  ######################################################
                  -->
                  <a
                      style="margin-left: 3px;"
                      *ngIf="validacaoIsRetificacao(item) && item.habilitaBotoes && item.exibeBotaoExclusao && habilitarInputs"
                      (click)="excluir(item)"
                      class="btn btn-danger btn-sm"
                      data-toggle="tooltip"
                      title="Excluir"
                      data-original-title="Excluir">
                          <i class="fa fa-trash-o"></i>
                  </a>
              </td>
              <td class="text-center" [ngStyle]="{'background-color': item.idPPB != idPPB ? '#ffff99' : ''  }" *ngIf="habilitarInputs">
                <a
                  *ngIf="habilitarInputs"
                  (click)="abrirModalMoverPorSelecao(item)"
                  data-toggle="tooltip"
                  title="Mover por seleção"
                  data-original-title="Mover por seleção"
                  style="color: blue; cursor: pointer;">
                    {{item.numeroSequencia}}
                </a>
                <span *ngIf="!habilitarInputs">{{item.numeroSequencia}}</span>
              </td>
              <td class="text-left" [ngStyle]="{'background-color': item.idPPB != idPPB ? '#ffff99' : ''  }">
                <p style="white-space: pre-line;">
                  <app-taxa-texto
                      [(texto)]="item.descricaTopico"
                      [backgroundAmarelo]="item.idPPB != idPPB"
                      [(indicadorParaTaxarItem)]="item.indicadorParaTaxarItem">
                  </app-taxa-texto>
                </p>
              </td>
              <td class="text-left" [ngStyle]="{'background-color': item.idPPB != idPPB ? '#ffff99' : ''  }">
                  <app-taxa-texto
                      [(texto)]="item.dataInicioVigenciaString"
                      [(indicadorParaTaxarItem)]="item.indicadorParaTaxarItem">
                  </app-taxa-texto>
              </td>
              <td class="text-left" [ngStyle]="{'background-color': item.idPPB != idPPB ? '#ffff99' : ''  }">
                  <span *ngIf="!item.descricaCondicaoString"> -- </span>
                  <a
                      *ngIf="item.descricaCondicaoString"
                      style="color: blue;"> Clique em Expandir para Visualizar
                  </a>
                  <app-collapse-row-button
                      style="cursor: pointer;"
                      *ngIf="item.descricaCondicaoString"
                      target="{{'object-' + i}}">
                  </app-collapse-row-button>
              </td>
              <td class="text-left" [ngStyle]="{'background-color': item.idPPB != idPPB ? '#ffff99' : ''  }">
                  <app-taxa-texto
                      [(texto)]="item.descricaoDocumento"
                      [(indicadorParaTaxarItem)]="item.indicadorParaTaxarItem">
                  </app-taxa-texto>
              </td>
              <td class="text-left" [ngStyle]="{'background-color': item.idPPB != idPPB ? '#ffff99' : ''  }" *ngIf="lista[0].exibeColunaMultiplo">
                  <app-taxa-texto
                      [(texto)]="item.descricaoMultiplo"
                      [(indicadorParaTaxarItem)]="item.indicadorParaTaxarItem">
                  </app-taxa-texto>
              </td>
              <td class="text-left" [ngStyle]="{'background-color': item.idPPB != idPPB ? '#ffff99' : ''  }" *ngIf="lista[0].exibeColunaGrupo">
                  <app-taxa-texto
                      [(texto)]="item.descricaoGrupo"
                      [(indicadorParaTaxarItem)]="item.indicadorParaTaxarItem">
                  </app-taxa-texto>
              </td>
          </tr>
          <td colspan="6" id="{{ 'object-' + i }}" style="display:none" >
              <div class="row" style="margin-left: 30px; height: 500px; overflow: scroll;" *ngIf="item.descricaCondicaoString">

                  <legend style="margin-top: 15px;">Descrição da Condicionante</legend>
                  <angular-editor [(ngModel)]="item.descricaCondicaoString" [config]="config"></angular-editor>

              </div>
          </td>
			</tbody>
		</table>
	</div>
</app-grid>

<app-modal-incluir-condicionantes-ppb
  [(dataVigenciaInicialPPB)]="dataVigenciaInicialPPB"
  (atualizaListagem)="atualizaListagem($event)"
  #appModalIncluirCondicionantesPPB>
</app-modal-incluir-condicionantes-ppb>

<app-modal-mover-por-selecao
  [_servicoDropDown]="'OrdenacaoCondicionantes'"
  [_servicoParaSalvar]="'CondicionantesPPB'"
  (atualizaListagemGrid)="atualizaListagem($event)"
  #appModalMoverPorPosicao>
</app-modal-mover-por-selecao>
