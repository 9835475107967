
<div class="row form-group" style="margin-left: 2px;">	
    <div>
        <app-grid-produtos   
            [(lista)]="grid.lista"
            [(total)]="grid.total"
            [(size)]="grid.size"
            [(page)]="grid.page"
            [(parametros)]="parametros"
            [(mostrarDocumento)]="_mostrarCampoDocumento"
            (onChangeSort)="onChangeSort($event)"
            (onChangeSize)="onChangeSize($event)"
            (onChangePage)="onChangePage($event)">
        </app-grid-produtos>    
    </div>
</div>

