<div class="slim-scroll padder-h" data-height="auto" data-disable-fade-out="true" data-distance="0" data-size="10px"
    data-railOpacity="0.2">

    <div class="row m-b-md">
        <div class="col-lg-12">
            <h1 class="m-b-xs text-black">{{tituloTelaPath}}</h1>
            <small>{{subtituloPath}}</small>
        </div>
    </div>

    <div class="row">
        <div class="col-lg-12">
            <section class="panel panel-default">
                <header class="panel-heading FixPanelHeading">
                    <div class="pull-right">
                        <a id="btn-voltar-solicitacoes-ppb" name="btn-voltar-solicitacoes-ppb" (click)="voltar()"
                            class="btn btn-default btn-sm">
                            <i class="fa fa-arrow-left"></i> Voltar

                        </a>
                        <a id="btn-salvar-solicitacoes-documento-ppb" name="btn-salvar-solicitacoes-documento-ppb"
                            *ngIf="habilitarInputs" (click)="validar()" class="btn btn-primary btn-sm">
                            <i class="fa fa-save"></i> Salvar

                        </a>
                    </div>
                    <h2 class="panel-title h5">Formulário</h2>
                </header>
                <form class="form" role="form">
                    <article class="panel-body">
                        <div class="row form-group">
                        </div>
                        <div class="row form-group">
                            <div class="col-lg-12">
                                <label for="descricao">Descrição:</label>
                                <input type="text" [(ngModel)]="parametros.descricao" name="descricao" id="descricao" class="form-control"
                                    maxlength="50" />
                            </div>
                        </div>

                        
                        <div class="row form-group" *ngIf="path != 'novo'">
                            <div class="col-lg-12">
                                <label for="justificativao" class="required">Justificativa:</label>
                                <textarea
                                    [(ngModel)]="parametros.justificativa"
                                    class="form-control ng-pristine ng-valid ng-touched"
                                    maxlength="2000"                            
                                    name="justificativa"
                                    id="justificativa"                                
                                    rows="5"                                
                                    style="resize: none;">
                                </textarea>    
                            </div>
                        </div> 
                    </article>
                    <footer class="panel-footer clearfix" style="padding-top: 5px; padding-bottom: 5px;">
                        <div class="pull-right">
                            <a  id="btn-voltar-solicitacoes-ppb"
                                name="btn-voltar-solicitacoes-ppb" 
                                (click)="voltar()"
                                class="btn btn-default btn-sm">
                                <i class="fa fa-arrow-left"></i> Voltar

                            </a>
                            <a 
                                id="btn-salvar-solicitacoes-documento-ppb" 
                                name="btn-salvar-solicitacoes-documento-ppb"
                                *ngIf="habilitarInputs" (click)="validar()" 
                                 class="btn btn-primary btn-sm">
                                <i class="fa fa-save"></i> Salvar

                            </a>
                        </div>
                    </footer>
                </form>
            </section>
        </div>
    </div>
</div>