<div class="slim-scroll padder-h" data-height="auto" data-disable-fade-out="true" data-distance="0" data-size="10px" data-railopacity="0.2">
	<div class="row m-b-md">
		<div class="col-lg-12">
			<app-titulo>Visualizar Cadastro de PPB</app-titulo>
			<app-sub-titulo>Visualizar Dados</app-sub-titulo>
		</div>
	</div>

    <section class="panel panel-default">
        <header class="panel-heading">
            <div class="pull-right">                
                <a 
                    routerLink="/manter-ppb" 
                    class="btn btn-default btn-sm">
                        <i class="fa fa-arrow-left"></i>  Voltar
                </a>    
                <a 
                    class="btn btn-primary btn-sm">
                        <i class="fa fa-save"></i>  Salvar
                </a>                           
            </div>				
            <h2 class="panel-title h5">Formulário</h2>
        </header>
        
        <div class="panel-body">
    
            <div class="row form-group">
                <div class="col-lg-3">
                    <label for="descricao" class="">Tipo de Vínculo:</label>
                    <input 
                        type="datetime" 
                        name="codigo" 
                        id="codigo" 
                        class="form-control" 
                        maxlength="100" 
                        value="COMPLEMENTO" 
                        disabled
                    />
                </div>
                <div style="margin-top: 5px;" class="col-sm-9">
                    <label for="descricao" class="">Documento de Referência:</label>
                    <input 
                        type="datetime" 
                        name="codigo" 
                        id="codigo" 
                        class="form-control" 
                        maxlength="100" 
                        value="PORTARIA INTERMINISTERIAL Nº1, DE 29 DE JANEIRO DE 2020" disabled 
                    />
                </div>							           
            </div>

            <div class="row form-group">
                <div class="col-lg-3 text-nowrap">
                    <label for="codigo" >Início de Vigência:</label>
                    <input 
                        type="datetime" 
                        name="codigo" 
                        id="codigo" 
                        class="form-control" 
                        maxlength="10" 
                        value="30/06/2020" 
                        disabled
                    />
                </div>
                <div class="col-lg-8">
                    <b>Documento Aprobatório </b><b style="color:red;">Nome do arquivo</b>									
                    <div class="input-group">
                        <span class="input-group-btn">
                            <span 
                                data-toggle="modal" 
                                data-backdrop="static" 
                                data-keyborad="false">
                                    <a 
                                        class="btn btn-default btn-sm" 
                                        data-toggle="tooltip" 
                                        title="" 
                                        data-original-title="Abrir PPB">
                                            <i class="i i-publish"></i>
                                    </a>
                            </span>
                        </span>
                        <div class="text-danger"> PI 1255 de 2021.dpf</div> 
                    </div>							
                </div>
            </div>

            <div class="row form-group">
                <div class="col-lg-3 text-nowrap">
                    <label for="codigo" >Data Publicação:</label>
                    <input 
                        type="datetime" 
                        name="codigo" 
                        id="codigo" 
                        class="form-control" 
                        maxlength="3" 
                        value="02/07/2020" 
                        disabled/>
                </div>
                <div class="col-lg-9 text-nowrap" >
                    <label for="codigo">Título:</label>
                    <input 
                        type="text" 
                        name="codigo" 
                        id="codigo" 
                        class="form-control" 
                        maxlength="100" 
                        value="PORTARIA INTERMINISTERIAL Nº43, DE 29 DE JULHO DE 2020" 
                        disabled 
                    />
                </div>								
            </div>

            <div class="row form-group">
                <div class="col-lg-3">
                    <label for="descricao" class="">Tipo de Documento:</label>
                    <input 
                        type="datetime" 
                        name="codigo" 
                        id="codigo" 
                        class="form-control" 
                        maxlength="3" 
                        value="PORTARIA INTERMINISTERIAL" disabled 
                    />
                </div>
                <div class="col-lg-2">
                    <label for="codigo">Número:</label>
                    <input 
                        type="text" 
                        name="codigo" 
                        id="codigo" 
                        class="form-control" 
                        maxlength="3" 
                        value="43" 
                        disabled 
                    />
                </div>
                <div class="col-lg-2 text-nowrap">
                    <label for="codigo" >Data Documento:</label>
                    <input 
                        type="datetime" 
                        name="codigo" 
                        id="codigo" 
                        class="form-control" 
                        maxlength="3" 
                        value="29/07/2020" 
                        disabled 
                    />
                </div>         
                <div class="col-sm-5">
                    <label for="descricao" class="">Órgãos do PPB:</label>
                    <input 
                        type="datetime" 
                        name="codigo" 
                        id="codigo" 
                        class="form-control" 
                        maxlength="3" 
                        value=" " 
                        disabled 
                    />
                </div>		                                                                                                     
            </div>

            <div class="row form-group" style="margin-left: 2px;">

                <div class="row form-group">
                    <div style="margin-top: 10px;" class="col-lg-12">
                        <label>Resumo Técnico:</label>
                        <textarea 
                            class="form-control ng-pristine ng-valid ng-touched" 
                            id="Objetivo" 
                            maxlength="250" 
                            name="Objetivo" 
                            rows="5" 
                            style="resize: none;"
                            disabled 
                        >
                            O SECRETÁRIO ESPECIAL DE PRODUTIVIDADE, EMPREGO E COMPETITIVIDADE DO MINISTÉRIO
                            DA ECONOMIA, conforme delegação de competência atribuída pela Portaria ME nº 263, de 3 de junho de
                            2019 (publicada no DOU de 5.6.2019, Seção 1, pág. 18), e o SECRETÁRIO EXECUTIVO DO MINISTÉRIO DA
                            CIÊNCIA, TECNOLOGIA, INOVAÇÕES E COMUNICAÇÕES, conforme delegação de competência atribuída
                            pela Portaria MCTIC nº 5.071, de 24 de setembro de 2019
                        </textarea>
                    </div>
                </div>

            </div>

            <div class="form-group row">
                <div class="col-lg-8">
                    <b>Anexos </b><b style="color:red;">Nome do arquivo</b>									
                    <div class="input-group">
                        <span class="input-group-btn">
                            <span 
                                data-toggle="modal" 
                                data-target="#" 
                                data-backdrop="static" 
                                data-keyborad="false">
                                    <a 
                                        class="btn btn-default btn-sm" 
                                        data-toggle="tooltip" 
                                        title="" 
                                        data-original-title="Abrir Anexo">
                                            <i class="fa fa-download"></i>
                                    </a>
                            </span>
                        </span>
                        <div class="text-danger"> Minuta 1.dpf</div> 
                    </div>	
                </div>
            </div>
            
            <app-grupos-ppb></app-grupos-ppb>
            <app-etapas-ppb></app-etapas-ppb>
            <app-condicionantes-ppb></app-condicionantes-ppb>
            <produtos-vinculados-ppb></produtos-vinculados-ppb>

        </div>    

    </section>
   
</div>