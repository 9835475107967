import { PagedItems } from "./PagedItems";
import { PPBVM } from './PPBVM';

export class PPBHistorico extends PagedItems {
    id: number;
    ppbVM : PPBVM = new PPBVM();
    IdPPB: number;
    nomeUsuario: string;
    numeroUsuario: string;
    dataHoraAcao: Date;
    descricaoAcao: string;
}