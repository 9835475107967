<div #appModalIncluirMetasPPB class="modal modal-wide-2 fade in" tabindex="-1" role="dialog" aria-hidden="false"
    style="display: auto; overflow: scroll;">
    <div class="modal-dialog">

        <div class="modal-content">

            <div class="modal-header bg-primary" style="padding: 10px 15px;">
                <h4 class="modal-title" style="color: White;" id="modalParecer">Incluir Metas</h4>
            </div>

            <form *ngIf="exibeConteudoModal"
                class="form form-horinzontal needs-validation">
                <div class="modal-body">
                    <div class="row form-group" style="margin-left: 10px;margin-top: 15px;">
                        <div class="col-lg-4">
                            <label class="required">Valor Meta:</label>
                            <input
                                type="text"
                                name="topico-condicionante"
                                id="topico-condicionante"
                                class="form-control"
                                [(ngModel)]="parametros.valorMeta"
                                style="border-color: black;"
                                maxlength="12"
                                currencyMask
                                min="0" max="999.999.999,999"
                                [options]="{ prefix: '', suffix: '', thousands: '.', decimal: ',', precision: '2'}" />
                        </div>
                        <div class="col-lg-5">
                            <label for="cnpj" class="required">Período da Vigência:</label>
                            <div class="input-group" style="display: flex;">
                                <input
                                    type="date"
                                    id="dataInicio-grupo"
                                    name="dataInicio-grupo"
                                    min="0001-01-01" max="9999-12-31"
                                    class="form-control"
                                    style="width: 50%;"
                                    [(ngModel)]="parametros.dataVigenciaInicialString">
                                    <span class="text-center" style="padding-right: 15px; padding-top: 8px; margin-left: 15px;">a</span>
                                <input
                                    type="date"
                                    id="dataFim-grupo"
                                    name="dataFim-grupo"
                                    min="0001-01-01" max="9999-12-31"
                                    class="form-control"
                                    style="width: 50%;"
                                    [(ngModel)]="parametros.dataVigenciaFinalString">
                            </div>
                        </div>
                    </div>

                    <div class="row form-group" style="margin-left: 10px;margin-top: 15px;">
                      <div class="col-lg-9">
                          <label for="descricao">Descrição:</label>
                            <textarea
                              type="text"
                              id="meta"
                              name="meta"
                              maxlength="1000"
                              class="form-control"
                              wrap="hard"
                              style="resize: none;"
                              [(ngModel)]="parametros.descricaoMeta">
                            </textarea>
                       </div>
                    </div>

                    <div class="row form-group" style="margin-left: 10px;margin-top: 15px;">
                        <div class="col-lg-5" *ngIf="_isPorGrupo">
                            <label for="descricao" class="">Grupo:</label>
                            <select
                                *ngIf="_flagTrouxeItensGrupo"
                                name="grupo-ppb-meta"
                                id="grupo-ppb-meta"
                                class="form-control"
                                [(ngModel)]="parametros.idGrupo"
                                >
                                    <option value="" selected>Selecione uma opção ...</option>
                                    <option
                                        *ngFor="let item of listaGrupos"
                                        [ngValue]="item.id">
                                        {{ item.text }}
                                    </option>
                            </select>
                            <div *ngIf="!_flagTrouxeItensGrupo"><b style="color:red;">Nenhum grupo cadastrado</b></div>
                        </div>
                    </div>
                </div>

                <div class="pull-left" style="margin-left: 35px;" *ngIf="operation == 'new'">
                  <input
                      id="check-cadastro-continuo-grupo-ppb"
                      name="check-cadastro-continuo-grupo-ppb"
                      type="checkbox"
                      [(ngModel)]="flagCadastroContinuo">
                  <label for="Pesquisa" >Cadastro Contínuo</label>
              </div>
            </form>

            <div class="modal-footer" style="padding: 10px 15px;">
                <div class="pull-right">
                    <button
                        id="fechar-modal-metas-ppb"
                        name="fechar-modal-metas-ppb"
                        (click)="fechar()"
                        class="btn btn-sm btn-default"
                        data-dismiss="modal">
                            <i class="fa fa-times"></i> Fechar
                    </button>
                    <button
                        id="fechar-modal-grupo-ppb"
                        name="fechar-modal-grupo-ppb"
                        (click)="validar()"
                        class="btn btn-sm btn-primary"
                        data-dismiss="modal">
                            <i class="fa fa-save m-r-xs"></i> Salvar
                    </button>
                </div>
            </div>
        </div>

    </div>
</div>
<div #appModalIncluirMetasPPBBackground class="modal-backdrop fade in" style="display: none;"></div>
