import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'app-rodape-menu',
	templateUrl: 'rodape-menu.component.html',
})

export class RodapeMenuComponent {
	constructor() { }
}
