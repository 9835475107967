<div #appModalHistorico class="modal modal-wide-2 fade in centro" tabindex="-1" role="dialog" aria-hidden="false"
  style="display: auto; overflow: scroll; overflow-y: auto;">

  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header bg-primary" style="padding: 10px 15px; color: white;">
        <h4 class="modal-title" id="incluir_etapas">Histórico das Listagens</h4>
      </div>

      <div class="modal-body">
        <form *ngIf="exibirListagens">

          <a (click)="gerarRelatorioGeral()" class="btn btn-sm btn-default" data-dismiss="modal">
            <i style="color:red" class="fa fa-file-pdf-o"></i>
            <b style="color:black; margin-left: 8px;">Gerar Relatório das Listagens</b>
          </a>
          <br><br>

          <!--ETAPAS-->
          <app-listagem-historico-das-listagens
            [_nomeTabela]="'MPPB_PPB_ETAPA'"
            [_nomeGrid]="'Etapas'"
            [_idPpb]="_idPpb">
          </app-listagem-historico-das-listagens>
          <br>

          <!--CONDICIONANTES-->
          <app-listagem-historico-das-listagens
            [_nomeTabela]="'MPPB_PPB_CONDICIONANTE'"
            [_nomeGrid]="'Condicionantes'"
            [_idPpb]="_idPpb">
          </app-listagem-historico-das-listagens>
          <br>

          <!--GRUPOS-->
          <app-listagem-historico-das-listagens
            [_nomeTabela]="'MPPB_PPB_GRUPO'"
            [_nomeGrid]="'Grupos'"
            [_idPpb]="_idPpb">
          </app-listagem-historico-das-listagens>
          <br>

          <!--META-->
          <app-listagem-historico-das-listagens
            [_nomeTabela]="'MPPB_PPB_META'"
            [_nomeGrid]="'Metas'"
            [_idPpb]="_idPpb">
          </app-listagem-historico-das-listagens>
          <br>

          <!--PPB MULTIPLOS-->
          <app-listagem-historico-das-listagens
            [_nomeTabela]="'MPPB_PPB_MULTIPLO'"
            [_nomeGrid]="'Ppb Multiplo'"
            [_idPpb]="_idPpb">
          </app-listagem-historico-das-listagens>
          <br>
          <!--PPB PONTUACAO MINIMA-->
          <app-listagem-historico-das-listagens
            [_nomeTabela]="'MPPB_PPB_PONTUACAO_MINIMA'"
            [_nomeGrid]="'Ppb Pontuação Mínima'"
            [_idPpb]="_idPpb">
          </app-listagem-historico-das-listagens>



        </form>

      </div>

      <div class="modal-footer" style="padding: 10px 15px;">
        <a (click)="fechar()" class="btn btn-sm btn-default" data-dismiss="modal"><i class="fa fa-times"></i> Cancelar</a>
      </div>
    </div>
  </div>
</div>

<div #appModalHistoricoBackground class="modal-backdrop fade in" style="display: none;"></div>
