// http://barra.governoeletronico.gov.br
import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'app-cabecalho-governo-federal',
	templateUrl: './cabecalho-governo-federal.component.html'
})
export class CabecalhoGovernoFederalComponent {
	constructor() { }
}
