import { Component, OnInit, Input, Output, EventEmitter, Injectable } from '@angular/core';
import { EnumStatusPPB } from '../../../../../shared/enums/EnumStatusPPB';
import { DocumentosVinculadosPPBVM } from '../../../../../view-model/DocumentosVinculadorPPBVM';
import { PPBAnexoVM } from '../../../../../view-model/PPBAnexoVM';
import { RelatorioListagensPPBVM } from "../../../../../view-model/RelatorioListagensPPBVM";
import { registerLocaleData } from '@angular/common';
import es from '@angular/common/locales/es';

@Component({
	selector: 'app-cabecalho-relatorio-ppb',
	templateUrl: './cabecalho-relatorio.component.html'
})

@Injectable()
export class CabecalhoRelatorioPPBComponent implements OnInit {

	@Input()
		objetoExportacao: RelatorioListagensPPBVM;

	@Input()
		_tituloPPB: string;

	@Input()
		listaTipoDocumento : any = {};

    @Input()
		_isSempontuacao : boolean;

	@Input()
		_isPorPontuacao : boolean;

	@Input()
		_isPorGrupo: boolean;

	@Input()
		_isPPBMultiplo: boolean;

  @Input()
		isDocPosterior : number;

	@Input()
		arrayDocumentoPosteriores : Array<DocumentosVinculadosPPBVM> = new Array<DocumentosVinculadosPPBVM>();

	@Input()
		arrayDocumentosVinculados: Array<DocumentosVinculadosPPBVM> = new Array<DocumentosVinculadosPPBVM>();

	@Input()
		listaAnexos = new Array<PPBAnexoVM>();

	@Input()
		_dataDocumento : string;

	@Input()
		_dataPublicacao : string;

	@Input()
		_dataVigencia : string;

  @Input()
    statusPPB : number;

	constructor () { }

	ngOnInit () {  registerLocaleData(es); }

  String(value: any){
    console.log('aqui '+ JSON.stringify(value));
    //return String(value);
  }


  getTipoPublicacao(tipo:number){
    switch(tipo){
      case 1:
        return 'FIXAÇÃO (NOVO)';
      case 2:
        return 'ALTERAÇÃO POR MEIO DE REVOGAÇÃO';
      case 3:
        return  'ALTERAÇÃO PARCIAL' ;
      case 4:
        return 'NORMA COMPLEMENTAR';
      case 5:
        return 'FIXAÇÃO DE PPB (Genérico para Especifico)';
      default:
        return "";
    }
  }
  definirStatus(tipo: number): string {
    if (tipo == EnumStatusPPB.EM_ELABORACAO) {
      return "Em Elaboração";
    }

    if (tipo == EnumStatusPPB.REVOGADO) {
      return "Revogado";
    }

    if (tipo == EnumStatusPPB.VIGENCIA_FUTURA) {
      return "Vigencia Futura";
    }

    if (tipo == EnumStatusPPB.VIGENTE) {
      return "Vigente";
    }

    if (tipo == EnumStatusPPB.EM_RETIFICACAO) {
      return "Em Retificação";
    }
  }

}
