import { PagedItems } from './PagedItems';

export class PPBEtapaEntityVM extends PagedItems{
    id : number;
    idPPB : number;
    descricaTopico : string;
    descricaoEtapa : string;
    statusPontuacao : number = null;
    valorPontuacao : number;
    dataVigenciaInicio : Date;
    dataVigenciaFinal: Date;
    statusEtapa : number;
    idCriacao : number;
    numeroSequencia: number;
    idPPBMultiplo : any;
    fromTelaElaboracao : boolean;
}