import { PagedItems } from "./PagedItems";

export class MetaPPBVM extends PagedItems{
    id: number;
    idPPB: number;
    descricaoTopico: string;
    descricaoMeta: string;
    valorMeta: any;
    vigenciaInicial: string;
    vigenciaFinal: string;
    status: any;
    idEtapa: number = 0;
    idGrupo: number = 0;
    idCriacao : number = 0;
    numeroSequencia: number;
}
