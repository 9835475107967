import { Directive, ElementRef, HostListener, OnChanges, Input, SimpleChanges } from '@angular/core';

@Directive({
	selector: '[ngModel][placaCarro]',
})
export class PlacaCarroDirective implements OnChanges {
	@Input() ngModel!: string;

	constructor(private elementRef: ElementRef) { }

	ngOnChanges(changes: SimpleChanges) {
		if (changes && changes.ngModel && changes.ngModel.currentValue &&
			!changes.ngModel.previousValue && changes.ngModel.currentValue.length >= 7) {
			this.format(changes.ngModel.currentValue);
		}
	}

	@HostListener('blur') format(value : any) {
		if (!value) { value = this.elementRef.nativeElement.value; }

		value = value.toString().replace(/\W/g, '');

		if (value.toString().length === 7) {
			const parte1 = value.slice(0, 3);
			const parte2 = value.slice(3, 7);

			value = parte1.toUpperCase() + '-' + parte2.toUpperCase();
			//value = value.toString().replace(/([a-z]{3})(\d{4})/g, '\$1-\$2').toUpperCase();
		} else {
			value = '';
		}

		setTimeout(() => this.elementRef.nativeElement.value = value, 0);
	}
}
