import { Component, Injectable, OnInit, ViewChild } from "@angular/core";
import { PagedItems } from "../../view-model/PagedItems";
import { ModalService } from '../../shared/services/modal.service';
import { MessagesService } from "../../shared/services/messages.service";
import { ValidationService } from "../../shared/services/validation.service";
import { ApplicationService } from "../../shared/services/application.service";
import { Router, ActivatedRoute } from '@angular/router';
import { SolicitarCadastroDocumentoPPBVM } from '../../view-model/SolicitarCadastroDocumentoPPBVM';
import { ConsultarPPBVM } from "../../view-model/ConsultarPPBVM";
import { EnumStatusPPB } from "../../shared/enums/EnumStatusPPB";
import { PPBVM } from "../../view-model/PPBVM";

@Component({
	selector: "app-manter-documento-ppb",
	templateUrl: "./manter-documento-do-ppb.component.html"
})

@Injectable()
export class ManterDocumentoPPBComponent implements OnInit {

	form = this;
	grid: any = { sort: {} };
	parametros: SolicitarCadastroDocumentoPPBVM = new SolicitarCadastroDocumentoPPBVM();
	ocultarFiltros: boolean;
	isBuscaSalva : boolean = false;
	servicoCadastroDocumentoPPB = 'TipoDocumento';
	objetoConsulta : any = {} = null;
	listaTipoDocumento = new Array<any>();


	constructor(
		private applicationService: ApplicationService,
		private validationService: ValidationService,
		private modal: ModalService,
		private msg: MessagesService,
		private router: Router,
		private route: ActivatedRoute,
		private validation: ValidationService
	) {
	}

	ngOnInit() {
		//this.preencherCombo();
		this.buscar();
		this.parametros.exportarListagem = false;
	}

	onChangeSort($event : any) {
		this.grid.sort = $event;
    this.grid.page = 1;
		this.buscar();
	}

	onChangeSize($event : any) {
		this.grid.size = $event;
    this.grid.page = 1;
		this.buscar();
	}

	onChangePage($event : any) {
		this.grid.page = $event;
		this.buscar();
	}

	preencherCombo(){
		this.applicationService.get("TipoDocumentoDropDown").subscribe((result: any) => {
			if(result != null){
				this.listaTipoDocumento = result;
			}
		});
	}

	validar(){

	}

  onClickBtnBuscar(){
    this.grid.page = 1;
    this.buscar();
  }

	buscar(){

		var objeto : any = {};
		objeto = {
			page : this.grid.page ? this.grid.page : 1,
			size : this.grid.size ? this.grid.size : 10,
			sort : this.grid.sort.field,
			reverse : this.grid.sort.reverse,
			descricao : this.parametros.descricao,
			status: this.parametros.status,
			posicaoPalavra: this.parametros.posicaoPalavra,
			id: this.parametros.id
		}
        this.applicationService.get(this.servicoCadastroDocumentoPPB, objeto).subscribe((response: PagedItems)=>{
			if (response.total != null && response.total > 0){
                this.grid.total = response.total;
				this.grid.lista = response.items;

				this.preencherParamentrosExportacao();
            }else{
				this.grid = { sort: {} };
				this.parametros.exportarListagem = false;
				this.modal.infoError(this.msg.NENHUM_REGISTRO_ENCONTRADO, "Alerta");
			}
		});
    }

	limpar(){
		this.parametros = new SolicitarCadastroDocumentoPPBVM();
	}

	gerarNovoDocumentoPPB() {
		this.modal.confirmacao("Deseja Cadastrar um Novo Tipo de Documento de Processo Produtivo Básico?").subscribe( (isConfirmado : any) => {
			if(isConfirmado) {
				this.router.navigate(['/solicitacoes-documento-ppb/novo']);
			}
		});
	}

	preencherParamentrosExportacao(){
		this.parametros.exportarListagem = true;
		this.parametros.exportarPdf = true;
		this.parametros.titulo = "Tipo de Documento PPB"
		this.parametros.servico = this.servicoCadastroDocumentoPPB;
		this.parametros.columns = [
									"Tipo de Documento", "Situação"
								  ];

		this.parametros.fields = [
									"descricao", "descricaoStatus"
								 ];

		this.parametros.width = {
									0: { columnWidth: 600 },
									1: { columnWidth: 150 }
								};

	}
}
