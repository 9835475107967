<div #appModalHistorico class="modal modal-wide-2 fade in centro" tabindex="-1" role="dialog" aria-hidden="false"
  style="display: auto; overflow: scroll; overflow-y: auto;">

  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header bg-primary" style="padding: 10px 15px; color: white;">
        <h4 class="modal-title" id="incluir_etapas">Histórico do Tipo do Documento</h4>
      </div>
      <div class="modal-body">
        <div class="table-responsive no-margin-bottom no-border">
            <app-grid 
                [(page)]="page" 
                [(size)]="size" 
                [(total)]="total" 
                (onChangeSize)="changeSize($event)" 
                (onChangePage)="changePage($event)">
            
                <div class="table-responsive no-margin-bottom no-border">
                    <table class="table">
                        <thead class="table-header-color">
                            <tr>
                                <th class="th-sortable unset-nowrap text-left">
                                    <app-ordenacao field="DataHoraAcao" [sorted]="sorted" (onChangeSort)="changeSort($event)">
                                        Data/Hora
                                    </app-ordenacao>
                                </th>                                            
                                <th class="th-sortable unset-nowrap text-left">
                                    <app-ordenacao field="NumeroUsuario" [sorted]="sorted" (onChangeSort)="changeSort($event)">
                                        Login
                                    </app-ordenacao>
                                </th>  
                                <th class="th-sortable unset-nowrap text-left">
                                    <app-ordenacao field="NomeUsuario" [sorted]="sorted" (onChangeSort)="changeSort($event)">
                                        Responsável
                                    </app-ordenacao>
                                </th>  
                                <th class="th-sortable unset-nowrap text-left">
                                    <app-ordenacao field="DescricaoAcao" [sorted]="sorted" (onChangeSort)="changeSort($event)">
                                        Ação
                                    </app-ordenacao>
                                </th>  
                                <th class="th-sortable unset-nowrap text-left">
                                    <app-ordenacao field="DescricaoJustificativa" [sorted]="sorted" (onChangeSort)="changeSort($event)">
                                       Justificativa
                                    </app-ordenacao>
                                </th>  
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of listaHistorico">    
                                <td class="text-left">{{ item.dataHoraFormatada }}</td>
                                <td class="text-left">{{ item.numeroUsuario }}</td>
                                <td class="text-left">{{ item.nomeUsuario }}</td>
                                <td class="text-left">{{ item.descricaoAcao }}</td>  
                                <td class="text-left">{{ item.descricaoJustificativa || '--' }}</td>                                                        
                            </tr>
                        </tbody>
                    </table>
                </div>
            </app-grid>
        </div>
      </div>
      <div class="modal-footer" style="padding: 10px 15px;">
        <a (click)="fechar()" class="btn btn-sm btn-default" data-dismiss="modal"><i class="fa fa-times"></i> Cancelar</a>
      </div>
    </div>
  </div>
</div>

<div #appModalHistoricoBackground class="modal-backdrop fade in" style="display: none;"></div>