import { PPBEtapaEntityVM } from './PPBEtapaEntityVM';
import { ValoresPorGrupoVM } from './ValoresPorGrupoVM'

export class EtapaVM extends PPBEtapaEntityVM {
    gruposEtapaPPB : Array<ValoresPorGrupoVM> = new Array();
    dataInicioVigenciaString : string = null;
    dataFimVigenciaString : string = null;
    descricaoDocumento : string = null;
    indicadorParaTaxarItem : boolean;
    existeDescricaoEtapa : boolean;
    existePontuacaoMinima : number;
}
