import { Injectable, Output, EventEmitter } from '@angular/core';
 
@Injectable()
export class ChangeIniciaPaginacao {

    AlteraPaginacao : EventEmitter<any> = new EventEmitter()

	constructor() { }

	public Reinicia(obj) {
		this.AlteraPaginacao.emit(obj);
	}

}