export class MessagesService {
	ALTERACAO_REALIZADA_COM_SUCESSO = 'Alteração realizada com sucesso.';
	ARQUIVO_INCLUIDO_ANTERIORMENTE = 'O arquivo já foi incluído anteriormente.';
	CADASTRO_REALIZADO_COM_SUCESSO = 'Cadastro realizado com sucesso.';
	REMOCAO_NAO_EXECUTADA = 'Operação não executada.<br/><br/> Remova os item(s) do somatório primeiro';
	CADASTRO_REALIZADO_COM_SUCESSO_COMPLETO = 'Cadastro efetuado com sucesso! <br/><br/> Se já possui acesso aos demais sistemas da SUFRAMA, seu usuário e senha permanecem os mesmos.<br/><br/> Caso seja seu primeiro cadastro, dentro de alguns instantes receberás um e-mail contendo instruções para acesso.';
	CALENDARIO_NAO_SELECIONADO = 'Você deve selecionar um calendário para publicar!';
	CAMPO_NAO_INFORMADO = 'Campo não informado.';
	CAMPO_OBRIGATORIO_NAO_INFORMADO = 'Campo obrigatório não informado.';
	CAMPO_OBRIGATORIO_NAO_INFORMADO_PREENCHIDO_INCORRETAMENTE = 'Campos obrigatórios não informados ou preenchidos incorretamente.';
	CANCELAMENTO_REALIZADO_COM_SUCESSO = 'Cancelamento realizado com sucesso.';
	CAPTCHA_INVALIDO = 'O captcha é inválido.';
	CEP_INVALIDO = 'CEP inválido';
	CEP_JA_CADASTRADO = 'Este CEP já foi cadastrado.';
	CHAVE_PRECISA_SER_INFORMADA = 'A chave precisa ser informada.';
	CNPJ_INVALIDO = 'CNPJ inválido.';
	CNPJ_JA_ADICIONADO = 'Este CNPJ já foi adicionado.';
	CNPJ_NAO_ENCONTRADO = 'CNPJ não foi encontrado.';
	CONFIRMAR_ALTERACAO = 'Confirma a alteração?';
	CONFIRMAR_ATUALIZACAO = 'Confirmar atualização das informações?';
	CONFIRMAR_CORRECAO = 'Confirmar correção das informações?';
	CONFIRMAR_ENVIO_RECURSO = 'Confirmar envio de recurso?';
	CONFIRMAR_EXCLUSAO = 'Confirma a exclusão?';
    CONFIRMAR_FINALIZACAO_DILIGENCIA = 'Confirma Finalização da Diligência?';
	CONFIRMAR_OPERACAO = 'Confirma a operação?';
	CANCELAR_AGENDAMENTO = 'Existe agendamento de inatividade em aberto, deseja encerrar?';
    CONFIRMAR_NOVO_CADASTRO = 'Confirma o novo cadastro?';
    CONFIRMAR_INATIVAR_ANALISTA = 'A inativação irá desvincular todos os PLIs que estão sob responsabilidade do analista @nomeAnalista.Confirma a operação?';
	CPF_INVALIDO = 'CPF inválido.';
	CHAVE_ACESSO_INVALIDA = 'Chave de acesso é inválida.';
	INFORME_A_DATA_DE_EMISSAO = 'Informe a Data de Emissão.';
	DATA_DE_EMISSAO_INICAL_FINAL = 'Data inicial não pode ser maior que a final.';
	O_VALOR_TOTAL_PARTICIPACAO_NAO_PODE_ULTRAPASSAR_100_PORCENTO = 'O valor total de participação não pode ultrapassar 100%.';
	CPF_JA_ADICIONADO = 'Este CPF já foi adicionado.';
	DADO_NAO_ENCONTRADO = 'Dado não encontrado.';
	DILIGENCIA_FINALIZADA_COM_SUCESSO = 'Diligência finalizada com sucesso.';
	ESTA_ATIVIDADE_JA_FOI_INCLUIDA = 'Esta atividade já foi incluída.';
	EXCLUSAO_REALIZADA_COM_SUCESSO = 'Exclusão realizada com sucesso.';
	EXISTEM_ADMINISTRADORES_COM_QUALIFICACOES_QUE_NAO_PERTENCEM_A_NATUREZA_JURIDICA_ESCOLHIDA = 'Existem administradores com qualificações que não pertencem a Natureza Jurídica escolhida.';
	EXISTE_DOCUMENTOS_OBRIGATORIOS_NAO_ANEXADOS = 'Existem documentos obrigatórios não anexados. Favor anexar!';
	FAVOR_SELECIONAR_UM_ARQUIVO_NO_FORMATO = 'Favor selecionar um arquivo no formato {0}.';
	FAVOR_SELECIONAR_UM_ARQUIVO_NO_FORMATO_PDF = 'Favor selecionar um arquivo no formato PDF.';
	FAVOR_SELECIONAR_UM_ARQUIVO_NO_FORMATO_PDF_OU_JPG = 'Favor selecionar um arquivo no formato PDF ou JPG.';
	IMPRIMIR_GRU_EM_INSTANTES = 'Por favor, tente imprimir a GRU referente ao pagamento da taxa de serviço dentro de alguns instantes no link abaixo: http://www4.suframa.gov.br/arrecadacao/gru/gru_reimpressao.aspx';
	INFORMATIVO_PRIMEIRO_ACESSO = "<b class='text-primary'>CAROS USUÁRIOS,</b><br /><br /> Para <b class='text-primary'>CADA TIPO</b> de <b class='text-primary'>DOCUMENTO COMPROBATÓRIO</b> exigido na solicitação de Inscrição Cadastral ou Credenciamento deverá ser anexado um <b class='text-primary'>ÚNICO</b> arquivo PDF.<br /><br /> O documento comprovante de domicílio na solicitação de <b class='text-primary'>INSCRIÇÃO CADASTRAL DE PESSOA JURÍDICA</b> deverá ser o <b class='text-primary'>CONTRATO DE LOCAÇÃO, ESCRITURA PUBLICA, CERTIDÃO DO CARTÓRIO DE IMOVEIS, OU OUTRO QUE COMPROVE A POSSE OU USO DO IMÓVEL</b>.";
	INFORMACOES_SALVAS = 'Informações salvas';
	INFORME_A_NOTA_FISCAL = 'Informe o número da Nota Fiscal.';
	INSCRICAO_ESTADUAL_JA_ADICIONADA = 'Inscrição estadual já adicionada.';
	MUNICIPIO_JA_CADASTRADO = 'Já existe uma unidade principal cadastrada para esse município.';
	NAO_FOI_INFORMADO_ANALISTA_PARA_ASSOCIAR_PROTOCOLOS = 'Não foi informado um analista para associar protocolos.';
	NAO_FOI_INFORMADO_ANALISTA_PARA_DESASSOCIAR_PROTOCOLOS = 'Não foi informado um analista para desassociar protocolos.';
	NAO_FOI_POSSIVEL_CONCLUIR_OPERACAO = 'Não foi possível concluir a operação.';
	NAO_FORAM_INCLUIDOS_ARQUIVOS_SOLICITANDO_RECURSO = 'Não foram incluídos arquivos solicitando recurso.';
	NAO_FORAM_INFORMADOS_CAMPOS_PARA_CONSULTA = 'Não foram informados campos para consulta.';
	NAO_PERMITIDO_MAIS_DE_UM_ARQUIVO = 'Não é permitido incluir mais de um arquivo.';
	NAO_PERMITIDO_MAIS_DE_UMA_ATIVIDADE_PRINCIPAL = 'Não é permitido mais de uma atividade principal.';
	NAO_HA_REGISTROS_DE_PROCESSO_DE_VISTORIA_PARA_ESTA_NOTA = 'Não há registros de processo de vistoria para esta Nota.'
	NECESSARIO_ANEXAR_COMPROVANTE_RESTRICAO_COM_EXTENSAO_PNG = 'É necessário anexar o comprovante de restrição com extensão .png.';
	NENHUM_REGISTRO_ENCONTRADO = 'Nenhum registro encontrado.';
	USUARIO_POSSUI_PENDENCIAS = 'Usuário possui pendências junto a Suframa. Consulte o Sistema de Cadastro Antigo para maiores informações.';
	NENHUM_CADASTRO_FOI_ENCONTRADO_HISTORICO_SITUACAO_INSCRICAO = 'Não foi encontrado nenhum registro no histórico da situação cadastral no período informado.';
	NENHUM_DOCUMENTO_ADICIONADO = 'Nenhum documento foi adicionado.';
    NENHUM_REGISTRO_SELECIONADO = 'Nenhum registro selecionado.';
    INFORME_DADOS_TRANSPORTE = 'Informe registro de transporte das notas.'
	NENHUM_FORMATO_DE_ARQUIVO_CONFIGURADO = 'Nenhum formato de arquivo configurado.';
	NENHUM_PROTOCOLO_FOI_ENCONTRADO = 'Nenhum protocolo foi encontrado.';
	NUMERO_CNPJ_PRECISA_SER_INFORMADO = 'O número CNPJ precisa ser informado.';
	OPERACAO_REALIZADA_COM_SUCESSO = 'Operação realizada com sucesso!';
    INFORME_OS_FILTROS_DE_PESQUISA = 'Informe os filtros para pesquisa.';
	PROTOCOLO_ANALISE_ATRASO = 'O protocolo está em atraso. Para continuar, informe uma justificativa para realizar a análise.';
	PROTOCOLO_ANALISE_EXISTENTE = 'Já existe um protocolo em análise. Para continuar, informe uma justificativa para realizar a análise de um novo protocolo.';
	PROTOCOLO_CONTINUAR_ANALISE = 'Deseja continuar a análise deste protocolo?';
	PROTOCOLO_ESTA_COM_PENDENCIA_DE_DADOS = 'O protocolo está com pendencia de dados a serem corrigidos, solicite primeiro a correção dos dados, antes de solicitar a conferência.';
	PROTOCOLO_ESTA_COM_PENDENCIA_DE_DADOS_DEFERIR = 'O protocolo está com pendencia de {0} e não pode ser deferido.';
	PROTOCOLO_ESTA_COM_PENDENCIA_DE_DADOS_INDEFERIR = 'O protocolo está com pendencia de {0} e não pode ser indeferido.';
	PROTOCOLO_INICIAR_ANALISE = 'Deseja iniciar a análise deste protocolo?';
	PROTOCOLO_INVALIDO = 'Não é possível realizar agendamento para o protocolo informado, favor consultar a situação do protocolo ou entrar em contado com a Unidade Cadastradora.';
	PENA_COBRANCA_TCIF = "O destinatário declara que as mercadorias constantes na Nota Fiscal Eletrônica assinaladas ao setor <b>{0}</b> estão de acordo com seu setor de atuação e que está ciente de que em caso de sinalização inadequada incorre na possibilidade de cobrança da TCIF com acréscimo moratórios, sem prejuízo de outras ações penais e administrativas cabíveis."
	REGISTRO_DUPLICADO = 'Registro duplicado, não pode ser inserido.';
	REGISTRO_JA_EXISTENTE = 'O código da Natureza Jurídica já foi cadastrado.';
	REPRESENTANTE_ENTIDADE_JA_CADASTRADO = 'Este representante da entidade já foi cadastrado.';
	REPRESENTANTE_JA_EXISTENTE = 'Este representante da entidade já foi cadastrado.';
	SERVICO_INDISPONIVEL = 'Serviço indisponível, tente novamente mais tarde.';
    SETOR_JA_EXISTENTE = 'Código do Setor já cadastrado na base de dados da Suframa.';
    SERVICO_COM_PSS_INDISPONIVEL = 'Serviço com o sistema PSS está indisponível.';
	SOLICITACAO_ENVIADA_COM_SUCESSO = 'Solicitação enviada com sucesso';
	SUBCLASSE_JA_CADASTRADA_PARA_ESTE_SETOR = 'Subclasse já cadastrada para este setor.';
	SUBCLASSE_JA_EXISTENTE = 'Código de Subclasse já cadastrado na base de dados da Suframa.';
	SUBCLASSE_NAO_ENCONTRADA = 'Código da Subclasse informada não foi encontrado.';
	TIPO_DOCUMENTO_JA_ADICIONADO = 'Já foi adicionado um documento para este tipo de documento.';
	TAMANHO_DOCUMENTO_INVALIDO = 'O Documento para ser anexado deve conter no máximo 20 MB';
	TODOS_OS_DOCUMENTOS_LISTADOS_SAO_OBRIGATORIOS = 'Todos os documentos listados são obrigatórios';
	USUARIO_NAO_CADASTRADO = 'Usuário não cadastrado ou credenciado para solicitar o serviço selecionado.';
	VALIDAR_CAMPO = 'Validar o campo {0}.';
	VOCE_DEVE_INCLUIR_UMA_JUSTIFICATIVA_OU_UM_ARQUIVO = 'Você deve incluir uma justificativa ou um arquivo.';
	VISTORIA_DOCUMENTAL_LOCAL_DE_VISTORIA_NO_POSTO_SUFRAMA = 'Vistoria Documental: local de vistoria no posto SUFRAMA.';
	JA_EXISTE_UMA_CAPACIDADE_CADASTRADA_PARA_POSTO_PERFIL_CARGA = 'Já existe uma capacidade cadastrada para o Posto de Vistoria, Perfil e Carga Horária informados.';
	EXISTE_UM_VISTORIADOR_CADASTRADO_PARA_POSTO_PERFIL_CARGA = 'Existe Vistoriador cadastrado para o Posto de Vistoria, Perfil e Carga Horária selecionado.';
	NFE_NÃO_RECUPERADAS = '{0} registro(s) não processados. Data limite de vistoria ultrapassado.';
	SEM_RESSALVAS = 'Nenhum registro de ressalvas encontrado.';
	VISTORIADOR_INDISPONIVEL = 'Não há vistoriadores disponíveis para o local selecionado.';
	POSTO_VISTORIA_SEM_CAPACIDADE_OPERACIONAL = 'O Posto de Vistoria está sem <strong>Capacidade Operacional</strong> para receber novos PINs para vistoria. Caso prossiga com a ação, todos os PINs serão encaminhados para a Vistoria Eletrônica do Canal Azul.<br><br>Deseja prosseguir?';
	EMPRESA_PILOTO_REMETENTE = 'Prezado Usuário, seu acesso para está funcionalidade somente estará habilitado após a implantação nacional do sistema, previsto para 21/10/2019.<br>Portanto, até esta data, utilize o sistema WS Sinal 6.0/PMN e acompanhe as orientações diretamente no site da Suframa.';
	ACIMA_DO_LIMITE_DE_CONSULTA = 'A pesquisa ultrapassou o limite de 2000 registros recuperados. Caso não encontre a sua informação abaixo, por gentileza, refaça a pesquisa utilizando os filtros disponíveis na tela.'
	ANEXO_ACIMA_DO_LIMITE = 'Anexo acima do limite máximo (($) MB).';
	ERRO_500  = 'Erro 500 - Internal Server Error';

}
