import { Injectable } from '@angular/core';

@Injectable()
export class OrderGrid {

    constructor(){
        
    }

	public ordenarItensGrid(lista: any[], indexAtual: number, indexAlvoOrdenacao: number){
		// Realiza a troca de posição dos elementos ordenados
        var auxiliar = lista[indexAtual];
        lista[indexAtual] = lista[indexAlvoOrdenacao];
        lista[indexAlvoOrdenacao] = auxiliar;

        // Realiza a troca do numeroSequencia dos elementos ordenados   			
        var auxiliarNumeroSequencia = 0; 

        auxiliarNumeroSequencia = lista[indexAtual].numeroSequencia;
        lista[indexAtual].numeroSequencia = lista[indexAlvoOrdenacao].numeroSequencia;
        lista[indexAlvoOrdenacao].numeroSequencia = auxiliarNumeroSequencia;

        return lista;
	}
}
