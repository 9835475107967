export class EnviaParametrosSelecaoItemVM
{
  numeroSequencia : number;
  idItemOrigem : number;
  idItemDestino : number;
  descricaoItemOrigem : any;
  idPpb : number;
  tipoOperacao : string;
  descricaoPpbMultiplo : string;
  tituloItemSelecionado : string;
}
