import { FinalizacaoPpbResponseVM } from './../../../../../view-model/FinalizacaoPpbResponseVM';
import { Component, Output, Input, OnInit, EventEmitter, ViewChild } from '@angular/core';
import { ApplicationService } from '../../../../../shared/services/application.service';
import { MessagesService } from '../../../../../shared/services/messages.service';
import { ModalService } from '../../../../../shared/services/modal.service';
import { Router } from '@angular/router';
import { PPBVM } from '../../../../../view-model/PPBVM';
import { ValidationService } from '../../../../../shared/services/validation.service';
import { EnumStatusPPB } from '../../../../../shared/enums/EnumStatusPPB';
import { EnumTipoEtapa } from './../../../../../shared/enums/EnumTipoEtapa';
import { AnexoPPBVM } from '../../../../../view-model/AnexoPPBVM';
import { VincularProdutoVM } from '../../../../../view-model/VincularProdutoVM';
import { ViewMccpProdutoEntityVM } from '../../../../../view-model/ViewMccpProdutoEntityVM';
import { DesvinculoProdutoVM } from '../../../../../view-model/DesvinculoProdutoVM';
import { ControleMigracaoProdutoVM } from '../../../../../view-model/ControleMigracaoProdutoVM';

@Component({
	selector: 'app-grid-produtos2',
	templateUrl: './grid.component.html'
})

export class Grid2ProdutosComponent {

	ocultarTituloGrid = true;

	constructor(
		private applicationService: ApplicationService,
		private modal: ModalService,
		private msg: MessagesService,
		private router: Router,
		private validation: ValidationService,
	) { }

  FIXACAO_PPB_ESPECIFICO = 5;
	@Input() lista: any = {};
	@Input() total: number;
	@Input() size: number;
	@Input() page: number;
	@Input() sorted: string;
	@Input() isUsuarioInterno: boolean = false;
	@Input() exportarListagem: boolean;
	@Input() parametros: any = {};
	@Input() formPai: any;
  @Input() idPpb: any;
	@Input() somenteLeitura: boolean;
	@Input() mostrarDocumento: boolean;
	@Input() tipoPublicacaoPpb : any;
  @Input() isVisualizar: boolean = false;

	@Output() onChangeSort: EventEmitter<any> = new EventEmitter();
	@Output() onChangeSize: EventEmitter<any> = new EventEmitter();
	@Output() onChangePage: EventEmitter<any> = new EventEmitter();
	@Output() onChange: EventEmitter<any> = new EventEmitter();

  @Output() enviaProdutosMigrados : EventEmitter<Array<VincularProdutoVM>> = new EventEmitter();
  arrayProdutosMigrados : Array<VincularProdutoVM> = new Array<VincularProdutoVM>();
	@Output() atualizarListagem : EventEmitter<any> = new EventEmitter();
  @Output() changePropertyModal : EventEmitter<string> = new EventEmitter();

	@ViewChild('appModalEtapasCondicoesPPB') appModalEtapasCondicoesPPB;
	@ViewChild('appModalFinalizar') appModalFinalizar;
	@ViewChild('appModalVinculos') appModalVinculos;
	@ViewChild('appModalHistorico') appModalHistorico;
	@ViewChild('appModalBaixarAnexo') appModalBaixarAnexo;


	tituloGrid = "Produtos Vinculados"

	changeSize($event) {
		this.onChangeSize.emit($event);
	}

	changeSort($event) {
		this.sorted = $event.field;
		this.onChangeSort.emit($event);
		this.changePage(this.page);
	}

	changePage($event) {
		this.page = $event;
		this.onChangePage.emit($event);
	}

  desvincularProdutoEAdicionarAoCorrente(item : ViewMccpProdutoEntityVM)
  {
    this.changePropertyModal.emit("none");
    this.modal.confirmacao("Deseja realmente migrar o produto selecionado para o documento atual?").subscribe((confirmacao:any)=>{
      if(confirmacao)
      {
        let produtoJaExisteNoArrayDeMigracao = false;

        if(!this.arrayProdutosMigrados || !this.arrayProdutosMigrados.length){
          produtoJaExisteNoArrayDeMigracao = false;
        }
        else
        {
          let arrayFilter = this.arrayProdutosMigrados.filter(x => x.idPpbFromMigracao == item.idPpbProduto &&
                                                                   x.idProduto == item.idProduto);
          (!arrayFilter || !arrayFilter.length) ?
            produtoJaExisteNoArrayDeMigracao = false :
              produtoJaExisteNoArrayDeMigracao = true;
        }

        if(!produtoJaExisteNoArrayDeMigracao)
        {
          let objMigracao = new VincularProdutoVM();
          objMigracao.idPPB = this.idPpb;
          objMigracao.descricaoGrupo = item.descricaoGrupo;
          objMigracao.descricaoProduto = item.descricaoProduto;
          objMigracao.idProduto = item.idProduto;
          objMigracao.idGrupo = this.parametros.idGrupo;
          objMigracao.tituloPPB = item.documento;
          objMigracao.idPpbFromMigracao = item.idPpbProduto;
          objMigracao.fromMigracao = true;
          this.arrayProdutosMigrados = [...this.arrayProdutosMigrados, objMigracao]; //Append
        }

        var objeto : DesvinculoProdutoVM = new DesvinculoProdutoVM();
        objeto.idPpbDestino = this.idPpb;
        objeto.idPpbItemSelecionado = item.idPpbProduto;
        objeto.idProduto = item.idProduto;

        this.applicationService.post("FinalizacaoPPBComDesvinculamentoDeProdutos/PostAdicionarNovoProdutoAoPpbComDesvinculacao", objeto).subscribe( (retorno : FinalizacaoPpbResponseVM) => {
          if(retorno.statusResponse == 200)
          {
            if(!produtoJaExisteNoArrayDeMigracao){
              this.enviaProdutosMigrados.emit(this.arrayProdutosMigrados);
            }
            this.atualizarListagem.emit(true);
            this.changePropertyModal.emit("block");
          }
          else
          {
            this.modal.error("FALHA AO MIGRAR PRODUTO! " + retorno.textResponse, "Erro").subscribe((result : any)=> {this.changePropertyModal.emit("block");});
            return false;
          }
        });
      } else {
        this.changePropertyModal.emit("block");
      }
    });
	}

}
