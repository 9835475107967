<div *ngIf="!carregarGridCronograma" class="row form-group" style="margin-left: 2px;">
  <div class="panel panel-default no-margin-bottom">
    <header class="panel-heading"
            style="display: flex;
            justify-content: space-between;
            align-items: center;
            height: 35px">

      <h2 class="panel-title h5" style="margin: 0">
        Cronograma Anual - Pontuação Mínima
      </h2>
      <div [ngClass]="(validacao.pendenciaGrupoPPB) ? 'pendencia-validacao' : '' ">
        <a
          style="margin-right: 10px; margin-top: 5px; padding-right:10px;margin-right: 0px"
          class="btn btn-primary btn-sm pull-right"
          data-toggle="tooltip"
          title=""
          (click)="incluirPontuacaoMinima()"
          *ngIf="!_fromModalListagemPpb"
          data-original-title="">
          <i class="fa fa-plus"></i> Incluir na Lista
        </a>
      </div>
    </header>
    <div class="card-body">
      <div class="row">
        <div class="col-md-12" style="height: 35px;">
          <div class="form-group">
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<div *ngIf="carregarGridCronograma" class="row form-group" style="margin-left: 2px;">

  <div [ngClass]="(validacao.pendenciaGrupoPPB) ? 'pendencia-validacao' : '' ">
    <a
      style="margin-right: 10px; margin-top: 5px;"
      class="btn btn-primary btn-sm pull-right"
      data-toggle="tooltip"
      title=""
      (click)="incluirPontuacaoMinima()"
      *ngIf="!_fromModalListagemPpb"
      data-original-title="">
      <i class="fa fa-plus"></i> Incluir na Lista

    </a>
    <!--        <app-grid-cronograma-ppb-->
    <!--            [(dataVigenciaInicialPPB)]="dataVigenciaInicialPPB"-->
    <!--            [(idPPB)]="idPPB"-->
    <!--            [(habilitarInputs)]="habilitarInputs"-->
    <!--            (atualizaListagemGrid)="atualizaListagem($event)"-->
    <!--            [(isAlteracaoParaRevogacao)]="isAlteracaoParaRevogacao"-->
    <!--            [(lista)]="init"-->
    <!--            [(total)]="init"-->

    <!--            [(parametros)]="parametros"-->
    <!--            [(isRetificacao)]="isRetificacao"-->
    <!--            (onChangeSort)="onChangeSort($event)"-->
    <!--            (onChangeSize)="onChangeSize($event)"-->
    <!--            (onChangePage)="onChangePage($event)"-->
    <!--            [isVisualizar]="_fromModalListagemPpb"-->
    <!--            *ngIf="!carregarGridCronograma">-->

    <!--          </app-grid-cronograma-ppb>-->

    <app-grid-cronograma-ppb
      [(dataVigenciaInicialPPB)]="dataVigenciaInicialPPB"
      [(idPPB)]="idPPB"
      [(habilitarInputs)]="habilitarInputs"
      (atualizaListagemGrid)="atualizaListagem($event)"
      [(isAlteracaoParaRevogacao)]="isAlteracaoParaRevogacao"
      [(lista)]="grid.lista"
      [(total)]="grid.total"
      [_isPorPontuacao]="_isPorPontuacao"
      [(parametros)]="parametros"
      [(isRetificacao)]="isRetificacao"
      (onChangeSort)="onChangeSort($event)"
      (onChangeSize)="onChangeSize($event)"
      (onChangePage)="onChangePage($event)"
      *ngIf="carregarGridCronograma && !_isPorPontuacao"
      [(formPai)]="form"
      [isVisualizar]="_fromModalListagemPpb"
    >
    </app-grid-cronograma-ppb>

    <!--        <app-grid-cronograma-ppb-sem-grupo-->
    <!--            [(dataVigenciaInicialPPB)]="dataVigenciaInicialPPB"-->
    <!--            [(idPPB)]="idPPB"-->
    <!--            [(habilitarInputs)]="habilitarInputs"-->
    <!--            (atualizaListagemGrid)="atualizaListagem($event)"-->
    <!--            [(isAlteracaoParaRevogacao)]="isAlteracaoParaRevogacao"-->
    <!--            [(lista)]="grid.lista"-->
    <!--            [(total)]="grid.total"-->
    <!--            [_isPorPontuacao]="_isPorPontuacao"-->
    <!--            [(parametros)]="parametros"-->
    <!--            [(isRetificacao)]="isRetificacao"-->
    <!--            (onChangeSort)="onChangeSort($event)"-->
    <!--            (onChangeSize)="onChangeSize($event)"-->
    <!--            (onChangePage)="onChangePage($event)"-->
    <!--            *ngIf="carregarGridCronograma && _isPorPontuacao"-->
    <!--            [(formPai)]="form"-->
    <!--            [isVisualizar]="_fromModalListagemPpb"-->
    <!--            >-->
    <!--        </app-grid-cronograma-ppb-sem-grupo>-->
  </div>
</div>

<app-modal-incluir-cronograma
  [(idPPB)]="idPPB"
  (atualizaListagem)="atualizaListagem($event)"
  #appModalIncluirCronograma
>
  TODO: ALTERAR PARA PUBLICAR
  <!--  VERSÃO QUE ESTAVA FEITO PELA FABRICA ANTIGA-->
  <!--    [(idPPB)]="idPPB"-->
  <!--    (atualizaListagem)="atualizaListagem($event)"-->
  <!--    [_isPorPontuacao]="_isPorPontuacao"-->
  <!--    #appModalIncluirCronograma>-->
</app-modal-incluir-cronograma>


