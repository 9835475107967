import { PagedItems } from "./PagedItems";
import { PPBVM } from './PPBVM';

export class PPBMultiploVM extends PagedItems 
{

    id : number;
    PPB : PPBVM = new PPBVM();   
    idPPB : number;
    descricao : string;
    descricaProdutos : string;
    dataVigenciaInicial : any;
    dataVigenciaFinal : any;
    statusPPBMultiplo : number;
    numeroSequencia : number;
    idCriacao : number;
    descricaoTopico : string;

    //flags
    buscarVinculos : boolean;
    indicadorParaTaxarItem : boolean;
    habilitaBotoes : boolean;
    dataVigenciaConcatenada : string;
    dataInicioVigenciaString : string;
    dataFimVigenciaString : string;
    descricaoDocumento : string;
}