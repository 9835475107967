<div style="margin-left: 10px;">

  <div class="row">
      <div class="col-lg-12">
        <div class="col-sm-6">
            <label>Tipo de Publicação:</label>
            {{
              getTipoPublicacao(objetoExportacao.informacoesPPB.ppbVM.tipoPublicacao)
            }}
        </div>
        <div class="col-sm-6">
          <label>Situação:</label>
          {{ definirStatus(statusPPB) }}
        </div>
      </div>

  </div>

  <div class="row" *ngIf="objetoExportacao.informacoesPPB.ppbVM.tipoPublicacao != 1">
    <div class="col-lg-12">
      <div class="col-sm-6">
          <label>Documento de referência antigo</label>
          {{ objetoExportacao.informacoesPPB.ppbVM.descricaoDocReferencia ? objetoExportacao.informacoesPPB.ppbVM.descricaoDocReferencia : 'n/a' }}
      </div>
      <div class="col-sm-6">
          <label>Início de Vigência:</label>
          {{_dataVigencia | date: 'dd/MM/yyyy'}}
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-lg-12">
      <div class="col-sm-6" >
              <label>Data Publicação:</label>
              {{ _dataPublicacao | date: 'dd/MM/yyyy' }}
      </div>
      <div class="col-sm-6" >
          <label>Tipo de Documento:</label>
                  {{ objetoExportacao.informacoesPPB.descricaoTipoDocumento }}
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-lg-12">
      <div class="col-sm-6">
          <label>Número:</label>
          <!-- {{ objetoExportacao.informacoesPPB.ppbVM.numeroDocumento | number:'':'es' }} -->
              {{ objetoExportacao.informacoesPPB.ppbVM.numeroDocumento }}
      </div>
      <div class="col-sm-6">
          <label>Data:</label>
              {{ _dataDocumento | date: 'dd/MM/yyyy' }}
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-lg-12">
      <div class="col-sm-6">
          <label>Órgãos do PPB:</label>
              {{ objetoExportacao.informacoesPPB.ppbVM.descricaOrgao ? objetoExportacao.informacoesPPB.ppbVM.descricaOrgao : 'n/a' }}
      </div>
      <div class="col-lg-6">
          <label>Tipo da Etapa:</label>
              {{
                objetoExportacao.informacoesPPB.ppbVM.tipoEtapa == 1 ? 'SEM PONTUAÇÃO' :
                objetoExportacao.informacoesPPB.ppbVM.tipoEtapa == 2 ? 'POR PONTUAÇÃO' :
                objetoExportacao.informacoesPPB.ppbVM.tipoEtapa == 3 ? 'POR GRUPO COM PONTUAÇÃO' :
                objetoExportacao.informacoesPPB.ppbVM.tipoEtapa == 4 ? 'PPB MÚLTIPLO SEM PONTUAÇÃO' :
                      'n/a'
              }}
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-lg-12">
      <div class="col-sm-12">
        <label for="resumo-tecnico">Resumo Técnico:</label>
            {{ objetoExportacao.informacoesPPB.ppbVM.descricaResumoTecnico }}
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-lg-12">
      <div class="col-sm-6">
          <label>Anexos do PPB:</label>
          <div *ngIf="listaAnexos != null && listaAnexos != undefined">
              <div  *ngFor="let item of listaAnexos">
                  <b style="color:red; margin-left: 15px;">Arquivo: </b> {{ item.descricaoArquivo }}
              </div>
          </div>
          {{ (listaAnexos == null && listaAnexos == undefined) ? 'n/a' : '' }}
      </div>
      <div style="margin-top: -1px;" class="col-sm-6" *ngIf="objetoExportacao.informacoesPPB.ppbVM.tipoPublicacao != 1">
          <label>Documentos de Referência:</label>
              <div *ngIf="arrayDocumentosVinculados">
                  <div *ngFor="let item of arrayDocumentosVinculados; let i = index ; " [attr.data-index]="i">
                      <b style="color:red; margin-left: 15px;">Documento: </b> {{ item.descricaoDocumentoVinculado }}
                  </div>
              </div>
              {{ !arrayDocumentosVinculados ? 'n/a' : '' }}
      </div>
    </div>
  </div>

  <div class="row" *ngIf="isDocPosterior==1">
    <div class="col-lg-12">
      <div class="col-sm-6">
          <label>Documento Posterior:</label>
          <div *ngIf="arrayDocumentoPosteriores != null && arrayDocumentoPosteriores != undefined">
              <div  *ngFor="let item of arrayDocumentoPosteriores">
                  <b style="color:red; margin-left: 15px;">Arquivo: </b> {{ item.descricaoDocumentoVinculado }}
              </div>
          </div>
          {{ !arrayDocumentoPosteriores ? 'n/a' : '' }}
      </div>
    </div>
  </div>

</div>
