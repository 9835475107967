<div #appModalIncluirCronograma aria-hidden="false" class="modal modal-wide-2 fade in" role="dialog"
     style="display: auto; overflow: scroll;"
     tabindex="-1">
  <div class="modal-dialog modal-sm">
    <div class="modal-content">
      <div class="modal-header bg-primary" style="padding: 10px 15px; color: white;">
        <h4 class="modal-title" id="incluir_metas">{{titulo}} Cronograma - Pontuação Mínima</h4>
      </div>

      <div class="modal-body" style="overflow-y: auto;">
        <div class="col-lg-12">
          <div class="col-lg-12" *ngIf="!_isPorPontuacao">
            <label for="codigo">PPB Grupo:</label>
            <app-drop-list
              *ngIf="carregarDrop && _isIncluir"
              (itemSelecionado)="buscarEtapas($event)" name="ppb-grupo"
              [(ngModel)]="idGrupo"
              [(idChamadaServico)]="idPPB" [(idSelected)]="idGrupo" id="ppb-grupo"
              [(servico)]="serviceDropBox" [(placeholder)]="placeholder"
              [(exibirPlaceholder)]="exibirPlaceholder"
              [(notifyAfterSelect)]="notifyAfterSelect"
              #ppbGrupoDrop>
            </app-drop-list>
            <app-drop-list
              *ngIf="carregarDrop && !_isIncluir"
              (itemSelecionado)="idGrupo = $event" name="ppb-grupo"
              [isDisabled]="_isAlterar || _isExcluir"
              [(ngModel)]="idGrupo"
              [(idChamadaServico)]="idPPB" [(idSelected)]="idGrupo" id="ppb-grupo"
              [(servico)]="serviceDropBox" [(placeholder)]="placeholder"
              #ppbGrupoDrop>
            </app-drop-list>
          </div>

        </div>
        <div class="col-lg-12" style="margin-top: 50px; padding: 30px;"
             *ngIf="(idGrupo != null || _isPorPontuacao) && this.carregarGridEtapas">
          <app-grid-etapa-cronograma
            (changeParametros)="changeParametros($event)"
            [(idPPB)]="idPPB"
            [(lista)]="listaEtapas"
            [(operation)]="operation">
          </app-grid-etapa-cronograma>
        </div>


        <div class="row form-group col-lg-12" style="margin-left: 15px; margin-top: 65px;">
          <div id="campos-1" class="row form-group">

            <div class="col-lg-2">
              <label>2023</label>
              <input type="text" class="form-control"
                     maxlength="11"
                     currencyMask
                     [(ngModel)]="itemSelecionado.ano2023"
                     min="0" max="999.999.999,999"
                     [options]="{ prefix: '', suffix: '', thousands: '.', decimal: ',', precision: '2'}"
              />
            </div>

            <div class="col-lg-2">
              <label >2024</label>
              <input type="text" class="form-control"
                     maxlength="11"
                     currencyMask
                     min="0" max="999.999.999,999"
                     [(ngModel)]="itemSelecionado.ano2024"
                     [options]="{ prefix: '', suffix: '', thousands: '.', decimal: ',', precision: '2'}"
              />
            </div>

            <div class="col-lg-2">
              <label>2025</label>
              <input type="text" class="form-control"
                     maxlength="11"
                     currencyMask
                     min="0" max="999.999.999,999"
                     [(ngModel)]="itemSelecionado.ano2025"
                     [options]="{ prefix: '', suffix: '', thousands: '.', decimal: ',', precision: '2'}"
              />
            </div>

            <div class="col-lg-2">
              <label>2026</label>
              <input type="text" class="form-control"
                     maxlength="11"
                     currencyMask
                     min="0" max="999.999.999,999"
                     [(ngModel)]="itemSelecionado.ano2026"
                     [options]="{ prefix: '', suffix: '', thousands: '.', decimal: ',', precision: '2'}"
              />
            </div>

            <div class="col-lg-2">
              <label>2027 em diante</label>
              <input type="text" class="form-control"
                     name="codigo" id="codigo"

                     [(ngModel)]="parametros.valorPontuacaoMinima"
                     maxlength="11"
                     currencyMask
                     min="0" max="999.999.999,999"
                     [(ngModel)]="itemSelecionado.ano2027"
                     [options]="{ prefix: '', suffix: '', thousands: '.', decimal: ',', precision: '2'}"
              />
            </div>

          </div>
        </div>
        <div class="row form-group col-lg-12" style="padding: 30px;" *ngIf="_ExbirParametrosAlterar">
          <ng-container *ngFor="let item of listaPpbPontuacaoMinima; let i = index">
            <div class="col-lg-6">
              <label for="cnpj" class="">Período:</label>
              <div class="input-group" style="display: flex;">
                <input
                  autocomplete="off"
                  class="form-control date ng-pristine ng-valid ng-touched"
                  id="dataVigenciaInicialEtapaString"
                  maxlength="10"
                  name="dataVigenciaInicialEtapaString"
                  style="width: 48%;"
                  [(ngModel)]="item.dataVigenciaInicialEtapaString"
                  type="date"
                  ng-reflect-name="dataInicio">
                <span style="padding-right: 11px; padding-top: 8px; padding-left: 10px;">a</span>
                <input
                  autocomplete="off"
                  class="form-control date ng-pristine ng-valid ng-touched"
                  id="dataVigenciaFinalEtapaString"
                  maxlength="10"
                  name="dataVigenciaFinalEtapaString"
                  style="width: 48%;"
                  [(ngModel)]="item.dataVigenciaFinalEtapaString"
                  type="date"
                  ng-reflect-name="dataFim">
              </div>

            </div>
            <div class="col-lg-6">
              <label>Valor Pontuação Minima:</label>
              <input type="text" [(ngModel)]="item.valorPontuacaoMinima"
                     name="codigo" id="codigo" class="form-control"
                     [(ngModel)]="item.valorPontuacaoMinima"
                     maxlength="11"
                     currencyMask
                     min="0" max="999.999.999,999"
                     [options]="{ prefix: '', suffix: '', thousands: '.', decimal: ',', precision: '2'}"
              />
            </div>
          </ng-container>


        </div>
        <div class="row form-group col-lg-12" style="padding: 30px;" *ngIf="_ExbirParametrosExcluir">
          <ng-container *ngFor="let item of listaPpbPontuacaoMinima; let i = index">
            <div class="row col-lg-12">
              <div class="col-lg-1" style="margin-top: 25px;padding-right: 0;margin-right: 0;width: 40px;">
                <span>
                  <a class="btn btn-default btn-sm btn-danger"
                     data-original-title="Excluir"
                     (click)="excluirPontuacaoMinima(item.id)">
                      <i class="fa fa-trash-o"></i>
                  </a>
                </span>
              </div>
              <div class="col-lg-3">
                <label for="cnpj" class="">Período:</label>
                <div class="input-group" style="display: flex;">
                  <!--                  <input-->
                  <!--                    autocomplete="off"-->
                  <!--                    class="form-control date ng-pristine ng-valid ng-touched"-->
                  <!--                    id="dataVigenciaInicialEtapaString"-->
                  <!--                    maxlength="10"-->
                  <!--                    name="dataVigenciaInicialEtapaString"-->
                  <!--                    style="width: 48%;"-->
                  <!--                    [(ngModel)]="item.dataVigenciaInicialEtapaString"-->
                  <!--                    type="date"-->
                  <!--                    ng-reflect-name="dataInicio"-->
                  <!--                    disabled-->
                  <!--                    >-->
                  <span style="padding-right: 11px; padding-top: 8px; padding-left: 10px;">a</span>
                  <input
                    autocomplete="off"
                    class="form-control date ng-pristine ng-valid ng-touched"
                    id="dataVigenciaFinalEtapaString"
                    maxlength="10"
                    name="dataVigenciaFinalEtapaString"
                    style="width: 48%;"
                    [(ngModel)]="item.dataVigenciaFinalEtapaString"
                    type="date"
                    ng-reflect-name="dataFim"
                    disabled
                  >
                </div>
              </div>
              <div class="col-lg-2">
                <label>Valor Pontuação Minima:</label>
                <input type="text" [(ngModel)]="item.valorPontuacaoMinima"
                       name="codigo" id="codigo" class="form-control"
                       [(ngModel)]="item.valorPontuacaoMinima"
                       maxlength="11"
                       currencyMask
                       min="0" max="999.999.999,999"
                       [options]="{ prefix: '', suffix: '', thousands: '.', decimal: ',', precision: '2'}"
                       disabled
                />
              </div>
            </div>
          </ng-container>
        </div>
      </div>

      <div class="modal-footer" style="padding: 8px 15px;">
        <div class="pull-left" style="margin-left:20px">
          <input id="cadastro-continuo-etapa-ppb" name="cadastro-continuo-etapa-ppb" type="checkbox"
                 [(ngModel)]="flagCadastroContinuo">
          <label for="cadastro-continuo-etapa-ppb">Cadastro Contínuo</label>
        </div>
        <button
          id="fechar-modal-etapa"
          name="fechar-modal-etapa"
          (click)="fechar()"
          class="btn btn-sm btn-default"
          data-dismiss="modal">
          <i class="fa fa-times"></i> Fechar
        </button>
        <button
          color="primary" type="button" class="btn btn-primary"
          *ngIf="_ExbirParametrosAlterar || _ExbirParametrosIncluir" (click)="validar()" style="cursor: pointer;"
          ng-reflect-type="button"
          ng-reflect-dialog-result="true"><i class="fa fa-save" style="color: white;"></i> Salvar
        </button>
      </div>

    </div>


  </div>
</div>
<div #appModalIncluirCronogramaBackground class="modal-backdrop fade in" style="display: none;"></div>
