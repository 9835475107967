<div class="slim-scroll padder-h" data-height="auto" data-disable-fade-out="true" data-distance="0" data-size="10px"
    data-railOpacity="0.2">

    <div class="row m-b-md">
        <div class="col-lg-12">
            <h1 class="m-b-xs text-black">Consultar Tipo de Documento</h1>
            <small>Consultar</small>
        </div>
    </div>

    <div class="e row">
        <div class="col-lg-12">
            <section class="panel panel-default">
                <header class="panel-heading FixPanelHeading">
                    <div class="pull-right">
                        <a (click)="gerarNovoDocumentoPPB()" class="btn btn-primary btn-sm">
                            <i class="fa fa-plus"></i> Novo
                        </a>

                    </div>
                    <h2 class="panel-title h5">Filtros de Pesquisa</h2>
                </header>
                <article *ngIf="!ocultarFiltros" class="panel-body">
                    <form class="form" role="form">
                        <article class="panel-body">
                            <div class="row form-group">
                                <!--<div class="col-lg-4">
                                    <label for="descricao" class="">Tipo de Documento:</label>
                                    <select name="moeda" id="moeda" [(ngModel)]="parametros.id"  class="form-control">
                                        <option value="-1" ng-reflect-value="">Selecione...</option>
                                        <option *ngFor="let item of listaTipoDocumento" [ngValue]="item.id">
                                            {{ item.descricao }}
                                        </option>
                                    </select>
                                </div> -->

                                <div class="col-lg-8 text-nowrap">
                                    <label for="codigo">Tipo de Documento:</label>
                                    <input type="text" [(ngModel)]="parametros.descricao" name="descricao" id="descricao" class="form-control"/>
                                    <div class="radio i-checks radio-sm" style="margin-right: 1px;">
                                        <label class="input-sm">
                                            <input name="radio[custom]" type="radio" value="0" [(ngModel)]="parametros.posicaoPalavra"><i></i> Começa por
                                        </label>
                                        <label class="input-sm">
                                            <input name="radio[custom]" type="radio" value="1" [(ngModel)]="parametros.posicaoPalavra"><i></i> Qualquer parte
                                        </label>
                                        <label class="input-sm">
                                            <input name="radio[custom]" type="radio" value="2" [(ngModel)]="parametros.posicaoPalavra"><i></i> Palavra Exata
                                        </label>
                                    </div>
                                </div>

                                <div class="col-lg-3">
                                    <label for="situacao" class="">Situação:</label>
                                    <select name="situacao" [(ngModel)]="parametros.status" id="situacao" class="form-control" >
                                        <option value="-2" ng-reflect-value="">Todos</option>
                                        <option value="1">Vigente</option>
                                        <option value="0">Inativo</option>
                                    </select>
                                </div>

                            </div>

                        </article>
                    </form>
                </article>
                <footer class="panel-footer clearfix" style="padding-top: 5px; padding-bottom: 5px;">
                    <div class="pull-right">
                        <button
                            *ngIf="!ocultarFiltros"
                            class="btn btn-sm btn-primary"
                            type="submit" disableOnClick
                            (click)="onClickBtnBuscar()">
                                <i class="fa fa-search"></i> Buscar
                        </button>
                        <button
                            *ngIf="!ocultarFiltros"
                            #btnlimpar
                            class="btn btn-sm btn-default"
                            type="reset"
                            (click)="limpar()">
                                <i class="fa fa-eraser"></i> Limpar
                        </button>
                        <button
                            type="button"
                            class="btn btn-sm btn-default"
                            (click)=" ocultarFiltros ? ocultarFiltros = false : ocultarFiltros = true "
                            style="margin-left: -1px;">
                                <span class="fa fa-magic m-r-xs"></span>
                                {{ ocultarFiltros ? 'Exibir Filtros' : 'Ocultar Filtros' }}
                        </button>
                    </div>
                </footer>
            </section>
        </div>
    </div>

    <div class="row" *ngIf="grid.total > 0">
		<div class="col-lg-12">
			<app-grid-manter-documento-ppb
            [(lista)]="grid.lista"
                [(total)]="grid.total"
                [(size)]="grid.size"
                [(page)]="grid.page"
                [(parametros)]="parametros"
                (onChangeSort)="onChangeSort($event)"
                (onChangeSize)="onChangeSize($event)"
                (onChangePage)="onChangePage($event)">
			</app-grid-manter-documento-ppb>
		</div>
	</div>

</div>
