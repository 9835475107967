<div #appModalHistoricoExclusao class="modal modal-wide-2 fade in centro" tabindex="-1" role="dialog" aria-hidden="false"
  style="display: auto; overflow: scroll; overflow-y: auto;">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header bg-primary" style="padding: 10px 15px; color: white;">
        <h4 class="modal-title" id="incluir_etapas">Histórico de Exclusão dos PPB's</h4>
      </div>
      <div class="modal-body">
        <div style="margin-left: 10px;">
          <form *ngIf="grid.total > 0">
            <app-grid-historico-ppb
              [(parametros)]="parametros"
              [(lista)]="grid.lista"
              [(total)]="grid.total"
              [(size)]="grid.size"
              [(page)]="grid.page"
              (onChangeSort)="onChangeSort($event)"
              (onChangeSize)="onChangeSize($event)"
              (onChangePage)="onChangePage($event)">
            </app-grid-historico-ppb>
          </form>
          <h2 *ngIf="grid.total == 0" class="text-center">O Sistema não possui histórico de Exclusão</h2>
        </div>
      </div>
      <div class="modal-footer" style="padding: 10px 15px;">
        <a (click)="fechar()" class="btn btn-sm btn-default" data-dismiss="modal"><i class="fa fa-times"></i> Fechar </a>
      </div>
    </div>
  </div>
</div>
<div #appModalHistoricoExclusaoBackground class="modal-backdrop fade in" style="display: none;"></div>
