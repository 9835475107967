import { Meta } from '@angular/platform-browser';
import { Component, Injectable, OnInit, ViewChild, Input, Output, EventEmitter, AfterViewInit  } from "@angular/core";
import { MessagesService } from "../../../../../shared/services/messages.service";
import { ValidationService } from "../../../../../shared/services/validation.service";
import { ApplicationService } from "../../../../../shared/services/application.service";
import { ActivatedRoute} from "@angular/router";
import { ControleEtapasPPBComponent } from "../etapas-ppb.component";
import { Validators, FormBuilder } from '@angular/forms';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { EtapaVM } from '../../../../../view-model/EtapaVM';
import { EnumTipoEtapa } from '../../../../../shared/enums/EnumTipoEtapa';
import { ModalService } from '../../../../../shared/services/modal.service';
import { ValoresPorGrupoVM } from '../../../../../view-model/ValoresPorGrupoVM';
import { registerLocaleData } from '@angular/common';
import es from '@angular/common/locales/es';

interface AbirModalListagemVM {
  isPorPontuacao : boolean;
  isPPBMultiplo : boolean;
  isPorGrupo : boolean;
}

@Component({
	selector: 'app-modal-incluir-etapas-ppb',
	templateUrl: './modal-incluir-etapas-ppb.component.html'
})
export class ModalIncluirEtapasPPBAtuacao implements OnInit {

	listaDadosAtuacao: any;
	ocultarInputAnexo = false;
  existePontuacaoMinima :boolean = false;
	parametros: EtapaVM = new EtapaVM();
	isDesabilitado: boolean = true;
	ocultaCombos: boolean = true;
	operation : string = "";

  enumGrupo : EnumTipoEtapa.POR_GRUPO;
  enumPontuacao : EnumTipoEtapa.POR_PONTUACAO;
  enumMultiplo : EnumTipoEtapa.PPB_MULTIPLO;

  _flagTrouxeItensGrupo : boolean = false;
	_flagTrouxeItensPPBMultiplo : boolean = false;
	_flagMostraTxtPontuacao : boolean = false;
	flagCadastroContinuo : boolean = false;
	_flagDesabilitaTopico : boolean = false;
	_flagErroPontuacaoNaoInformada : boolean = false;

	_idPPBRota : number;

	flagFinalizouConsultaGrupos;

	_errorDataFinalMaiorInicial : boolean = false;
	_errorDataInicioNaoPreenchida : boolean = false;
	_errorDataFinalMenorDataCorrente : boolean = false;

	servico = 'EtapasPPB';
	name = 'Angular 12.1.2';
	idEtapa;
	idPPB : number;
	htmlContent = '';

	serviceDropBox = '';

	flagExibeModal : boolean = false;

	@Input() dataVigenciaInicialPPB : string;
  _isPorPontuacao: boolean;
  _isPPBMultiplo: boolean;
  _isPorGrupo: boolean;
  exibeConteudoModal : boolean = false;
  @Input() tipoEtapaPpbCorrente: number;
	@Input() habilitarInputs: boolean;
	@Output() atualizaListagem: EventEmitter<any> = new EventEmitter();

	listaPPBMultiplo = new Array<ValoresPorGrupoVM>();

	@ViewChild('ppbMultiplo') ppbMultiplo : any;
  @ViewChild('appModalIncluirEtapasPPB') appModalIncluirEtapasPPB : any;
	@ViewChild('appModalIncluirEtapasPPBBackground') appModalIncluirEtapasPPBBackground : any;

	constructor(
		private modal : ModalService,
		private ControleEtapasPPBComponent: ControleEtapasPPBComponent,
		private applicationService: ApplicationService,
		private fb: FormBuilder,
		private msg: MessagesService,
		private validation: ValidationService,
		private route: ActivatedRoute
	) {
		this._idPPBRota = Number(this.route.snapshot.params['idPpb']);
    registerLocaleData(es);
  }

	/************PROPRIEDAS FORM GROUP*************/
	formGroup = this._initializeValidations();
	get topico(){
		return this.formGroup.get('topico');
	}
	get etapa(){
		return this.formGroup.get('etapa');
	}
	get dataInicial(){
		return this.formGroup.get('dataInicial');
	}
	get dataFinal(){
		return this.formGroup.get('dataFinal');
	}
	get pontuacao(){
		return this.formGroup.get('pontuacao');
	}
	get pontuacaoGrupo(){
		return this.formGroup.get('pontuacaoGrupo');
	}
	get checkEmPontuacao(){
		return this.formGroup.get('checkEmPontuacao');
	}
	get cadastroContinuo(){
		return this.formGroup.get('cadastroContinuo');
	}

	_initializeValidations(){
		return this.fb.group({
			topico : ['', null],
			etapa : ['', null],
			dataInicial : [ '', [Validators.required]],
			dataFinal : [ '', null],
			pontuacao : ['' , [Validators.minLength(1), Validators.maxLength(7)]],
			pontuacaoGrupo : ['', null],
			checkEmPontuacao : [ '', null],
			cadastroContinuo : [ '', null],
      existePontuacaoMinima: [ '', null]
		});
	}
	/**********************************************/

	/************************************************/

	config: AngularEditorConfig = {
		editable: true,
		height: '15rem',
		minHeight: '5rem',
		placeholder: 'Enter text here...',
		translate: 'no',
		defaultParagraphSeparator: 'p',
		defaultFontName: 'Arial',
		toolbarHiddenButtons: [
		['bold']
		],
		customClasses: [
		{
			name: "quote",
			class: "quote",
		},
		{
			name: 'redText',
			class: 'redText'
		},
		{
			name: "titleText",
			class: "titleText",
			tag: "h1",
		},
		]
	};

  ngOnInit() {}

	abrir(idPPB : number, operation : string, idEtapa? : number)
	{
    this.applicationService.get('AbrirModalListagens',idPPB).subscribe((result:AbirModalListagemVM)=>{
      if(result)
      {
        this._isPPBMultiplo = result.isPPBMultiplo;
        this._isPorGrupo = result.isPorGrupo;
        this._isPorPontuacao = result.isPorPontuacao;
        this.flagCadastroContinuo = false;
        this.iniciaFlags();
        this.parametros.idPPB = idPPB;
        this.idPPB = idPPB;
        this.idEtapa = idEtapa;
        this.formGroup = this._initializeValidations();
        if(operation == 'new')
        {
          this.getGrupos(this.idPPB);
          this.parametros.dataInicioVigenciaString = this.dataVigenciaInicialPPB;
        }
        else
        {
          this.buscar(this.idEtapa);
        }
        this.operation = operation;
        this.appModalIncluirEtapasPPBBackground.nativeElement.style.display = 'block';
        this.appModalIncluirEtapasPPB.nativeElement.style.display = 'block';
        this.exibeConteudoModal = true;
      }
    })
  }

  fechar()
  {
    if(this._isPPBMultiplo && this._flagTrouxeItensPPBMultiplo){
      this.serviceDropBox = null;
    }
    this.iniciaFlags();
    this.flagCadastroContinuo = false;
    this.formGroup.get('dataInicial').reset();
    this.formGroup.get('cadastroContinuo').reset();
    this.operation = "";
    this.exibeConteudoModal = false;
    this.flagExibeModal  = false;
    this.appModalIncluirEtapasPPBBackground.nativeElement.style.display = 'none';
    this.appModalIncluirEtapasPPB.nativeElement.style.display = 'none';
  }

	buscar(idEtapa : number){

		var objeto : any = {};
		objeto.id = idEtapa;
		this.applicationService.get(this.servico, objeto).subscribe((retorno : EtapaVM) => {

			this.parametros = retorno;

      if(this.parametros.gruposEtapaPPB){

      }

			if(this.parametros.valorPontuacao > 0)
			{
				this._flagMostraTxtPontuacao = true;
			}

			if(this.parametros.gruposEtapaPPB && this.parametros.gruposEtapaPPB.length)
			{
				this._flagTrouxeItensGrupo = true;
			}

			if(retorno.statusEtapa == 2) //SUBSTITUTO
			{
				this._flagDesabilitaTopico = true;
			}

			this.parametros.idPPBMultiplo ?
				this.parametros.idPPBMultiplo = String(this.parametros.idPPBMultiplo) :
					null;

			this._isPPBMultiplo ?
				this.getPPBMultiplo(this.idPPB) :
					this.flagFinalizouConsultaGrupos = true;

			this.flagExibeModal = true;

			//this.getGrupos(this.idPPB);
		});
	}

	validar(){

		if(this._isPorPontuacao){
			if(!this.validarPontuacao()){
				return false;
			}
		}

		if(!this.parametros.descricaTopico){
			this.modal.informacao("Campo Obrigatório não Informado: <b>Etapa</b>", "Atenção");
			return false;
		}

		var dataInicioString = this.formGroup.get('dataInicial').value;
		var dataFimString = this.formGroup.get('dataFinal').value;

		if(!dataInicioString){

			this._errorDataInicioNaoPreenchida = true;
			this.formGroup.get('dataInicial').reset();
			this.formGroup.get('dataFinal').reset();

			return false;
		} else{
			this._errorDataInicioNaoPreenchida = false;
		}


		if(dataInicioString && dataFimString){

			if(this.validation.validaDataInicialMaiorDataFinal(dataInicioString , dataFimString) == true){

				this._errorDataFinalMaiorInicial = true;
				this.formGroup.get('dataInicial').reset();
				this.formGroup.get('dataFinal').reset();

				return false;
			} else {
				this._errorDataFinalMaiorInicial = false;
			}
		}

		this.parametros.valorPontuacao ?
			this.parametros.valorPontuacao = Number(this.parametros.valorPontuacao) :
				null;

		this.parametros.idPPBMultiplo ?
			this.parametros.idPPBMultiplo = Number(this.parametros.idPPBMultiplo) :
				null;

    if(this.parametros.gruposEtapaPPB != null && this.parametros.gruposEtapaPPB != undefined && this.parametros.gruposEtapaPPB.length > 0){
      this.parametros.gruposEtapaPPB.forEach(x => {
        if(x.totalPontuacao != null){
          x.totalPontuacao = Number(x.totalPontuacao);
        }
      });
    }

		this.salvar()
	}
  changePontuacao(){
    if(this.existePontuacaoMinima){
      this.parametros.existePontuacaoMinima = 1;
    }else{
      this.parametros.existePontuacaoMinima = 0;
    }
  }

	salvar(){

		var idPPB = this.parametros.idPPB;
    this.parametros.existePontuacaoMinima = this.parametros.existePontuacaoMinima ? 1 : 0;
		this.applicationService.post(this.servico, this.parametros).subscribe((retorno : number) => {

			if(retorno > 0) {

				this.modal.infoSucess(this.msg.OPERACAO_REALIZADA_COM_SUCESSO, "Success");

				if(!this.flagCadastroContinuo){

					this.fechar();

				} else {

					this.iniciaFlags();

					this.parametros.idPPB = idPPB;

					if(this._isPPBMultiplo){
						this.getPPBMultiplo(idPPB);
					}

					this.getGrupos(this.idPPB);

					if(this.operation == 'new')
						this.parametros.dataInicioVigenciaString = this.dataVigenciaInicialPPB;
				}

				this.atualizaListagem.emit(idPPB);
			}
		});
	}

	getGrupos(idPPB : number){
		this.applicationService.get(this.servico+"/GetGrupos", {idPPB : idPPB}).subscribe( (retorno : []) =>{
			if(retorno){
				this.parametros.gruposEtapaPPB = retorno;
        this.parametros.gruposEtapaPPB.forEach(e => {
          if (e.totalPontuacao == 0){
            e.totalPontuacao = null;
          }
        })
				this._flagTrouxeItensGrupo = true;
				this._isPPBMultiplo ?	this.getPPBMultiplo(idPPB) : '';
        this.flagFinalizouConsultaGrupos = true;
			}
			else
			{
        this._flagTrouxeItensGrupo = false;
				this._isPPBMultiplo ?	this.getPPBMultiplo(idPPB) : '';
				this.flagFinalizouConsultaGrupos = true;
			}

		});
	}

	getPPBMultiplo(idPPB : number)
  {
		var objeto : EtapaVM = new EtapaVM();
		objeto.idPPB = idPPB;
		this.applicationService.get(this.servico+"/GetPPBMultiplos", objeto).subscribe( (retorno : Array<ValoresPorGrupoVM>) =>{
			if(retorno.length != undefined && retorno.length > 0){
				this.listaPPBMultiplo = retorno;
				this._flagTrouxeItensPPBMultiplo = true;
				this.serviceDropBox = 'EtapasPPB';
			} else{
				this._flagTrouxeItensPPBMultiplo = false;
			}
		});
	}

	iniciaFlags()
  {
		this.parametros = new EtapaVM();
		this.flagFinalizouConsultaGrupos = false;
		this._flagDesabilitaTopico = false;
		this._flagTrouxeItensGrupo = false;
		this._flagMostraTxtPontuacao = false;
		this._errorDataFinalMaiorInicial = false;
		this._flagErroPontuacaoNaoInformada = false;
		this._flagTrouxeItensPPBMultiplo = false;
		this.limparCamposFormulario();
	}

	limparCamposFormulario(){
		this.formGroup.get('topico').reset();
		this.formGroup.get('etapa').reset();
		this.formGroup.get('dataFinal').reset();
		this.formGroup.get('pontuacao').reset();
		this.formGroup.get('pontuacaoGrupo').reset();
		this.formGroup.get('checkEmPontuacao').reset();
	}

	trackByIndex(index: number, obj: any): any {
		return index;
	}

	validarPontuacao()
  {
		if(!this.parametros.valorPontuacao){
			this.parametros.valorPontuacao = null;
			return false;
		}
		if(isNaN(this.parametros.valorPontuacao)){
			this.parametros.valorPontuacao = null;
			return false;
		}
		return true;
	}


}
