import { Component, Output, Input, EventEmitter } from '@angular/core';

@Component({
	selector: 'app-acoes',
	templateUrl: './acoes.component.html',
})
export class AcoesComponent {

	
}
