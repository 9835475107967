import { Component, OnInit, ViewChild, ElementRef, Input, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface AlertaModel {
	title: string;
	message: string;
}

@Component({
	selector: 'app-modal',
	templateUrl: './modal-error.component.html',
})

export class ModalErrorComponent implements OnInit {

	@ViewChild('ok') button!: ElementRef;

	constructor(@Inject(MAT_DIALOG_DATA) public data : AlertaModel) {}

	ngOnInit() {}

}
