<div class="table-responsive no-margin-bottom no-border" style="max-height: 300px; overflow-y: auto;">
  <table class="table table" >
    <thead class="table-header-color" style="position: sticky;top: 0; ">
      <!-- TO FORA GRUPO -->
      <tr>
          <th class="th-100 text-left" >Ações</th>
          <th class="th-sortable unset-nowrap text-left">
              Etapa
          </th>
           <th class="th-sortable unset-nowrap text-left">
              Descrição das Etapas
          </th>
          <th class="th-sortable unset-nowrap text-left">
              Documento
          </th>
      </tr>
    </thead>
    <ng-container>
      <tbody *ngFor="let item of lista; let i = index">
        <tr>
          <td class="text-left">
              <input  style="cursor: pointer;" type="radio" [checked]="item.radioCheck" [id]="'radioSelect'+i" name="radioSelect"(change)="carregarParametros(item)">
          </td>
          <td class="text-left">
            {{item.descricaTopico}}
          </td>

          <td class="text-left">
            <!-- <span>{{item.descricaoEtapa}}</span> -->
            <span *ngIf="!item.descricaoEtapa"> -- </span>
            <a
                *ngIf="item.descricaoEtapa"
                style="color: blue;"> Clique em Expandir para Visualizar
            </a>
            <app-collapse-row-button
               (click)="buscarDescricaoEtapa(item, i)"
                style="cursor: pointer;"
                *ngIf="item.descricaoEtapa"
                target="{{'etapaPMin-' + i}}">
            </app-collapse-row-button>
          </td>
          <td class="text-left">
            <span>{{item.documento}}</span>
          </td>
        </tr>
        <td colspan="5" id="{{'etapaPMin-' + i}}" style="display:none" >
          <div class="row" style="margin-left: 30px; height: 500px; overflow: scroll;">
              <legend style="margin-top: 15px;">Descrição da Etapa</legend>
              <angular-editor [(ngModel)]="item.descricaoEtapa" [config]="config"></angular-editor>
          </div>
        </td>
      </tbody>
    </ng-container>
  </table>
</div>


