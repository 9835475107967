import { Component, Input, OnInit, ElementRef, OnChanges, Output, EventEmitter } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { Observable } from 'rxjs';
import { ArrayService } from '../../services/array.service';
import { ApplicationService } from '../../services/application.service';

@Component({
	selector: 'app-drop-list-select',
	templateUrl: './drop-list-select.component.html',
	providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: DropListSelectComponent, multi: true }],
})

export class DropListSelectComponent implements OnInit, OnChanges {
	@Input() isDisabled = false;
	@Input() isRequired = false;
	@Input() lazy = false;
	@Input() id : number = 0; 
	@Input() loadWhenParameterEmpty!: boolean;
	@Input() parametro: any;
	@Input() parametroChave!: string;
	@Input() placeholder: string;
	@Input() servico!: string;

	@Input("valorSelecionadoParent")
	valorSelecionado!: string;

	@Output()
	valorModificado: EventEmitter<string> = new EventEmitter<string>();

	list: any = new Array<any>();
	listAutoComplete: any = new Array<any>();
	required: any;
	settings: any = { placeholder: 'Selecione uma opção' };
	data: any = [];
	options: any;

	model: any;

	constructor(
		private applicationService: ApplicationService,
		private arrayService: ArrayService,
		private elm: ElementRef,
	) {
		this.placeholder = 'Informe o código ou a descrição';
	}

	ngOnInit() {

		this.required = this.elm.nativeElement.attributes['required'];

		// Se for especificado parametro chave
		if (!this.parametroChave) {
			this.loadWhenParameterEmpty = true; // carregar quando a propriedade parametro estiver vazia
			this.lazy = false; // ativa automaticamente o serviço de carregar dados
		} else {
			this.loadWhenParameterEmpty = false; // não carrega os ítens quando a propriedade parametro estiver vazia
			this.lazy = true; // mantem o componente preguiçoso até a propriedade parametro ser modificada ou o métodos listItems ser acionado
		}

		if (!this.lazy) {
			this.listItems();
		}
	}

	getCount() {
		return this.list ? this.list.length : 0;
	}

	reset() {
		this.list = null;
		this.listAutoComplete = null;
		this.model = '';
	}

	listItemsObservable() {
		return Observable.create((observer : any) => {
			this.callService(this.urlService(), observer);
		});
	}

	urlService() {
		
		let url = `${this.servico}DropDown/`;
				
		this.id ? url = (url + this.id) : '';

		if (this.parametroChave && this.parametro) {
			url = url + '?' + this.parametroChave + '=' + this.parametro;
		} else {
			if (!this.parametroChave && this.parametro) {
				url = url + this.parametro;
			}
		}

		return url;
	}

	callService(url : any, observer? : any) {

		return this.applicationService.get(url).subscribe(result => {
			//this.list = result;            
			//this.listAutoComplete = this.list;            
			this.settings = {
				placeholder: this.placeholder,
				data: result,
				minimumResultsForSearch: 3,
				minimumInputLength: 3,
				disabled: this.isDisabled,
				language: {
					inputTooShort: function () {
						return "Digite 3 ou mais caracteres";
					},
					loadingMore: function () {
						return "Carregando mais resultados";
					},
					noResults: function () {
						return "Nenhum registro encontrado";
					},
					searching: function () {
						return "Pesquisando...";
					},
				}
			};

			if (observer) {
				observer.next(result);
				observer.complete();
			}
		});
	}

	listItems() {
		return this.callService(this.urlService());
	}

	removeItem(item: string) {
		this.arrayService.remove(this.list, 'id', item);

		if (this.list.length == 1) {
			this.model = this.list[0].id;
			this.onChange(this.list[0].id);
		}
	}

	addItem(item: any) {
		this.arrayService.add(this.list, 'id', item, false);
	}

	getAllItems() {
		return this.list;
	}

	getSelectedKey() { return this.model; }

	getSelectedItem() {
		const empty = { value: this.getSelectedKey() };

		if (!this.list) { return empty; }

		const item = this.list.filter((x : any) => x.id == this.getSelectedKey());

		if (!item) { return empty; }

		return item[0];
	}

	getSelectedValue() {
		if (this.getSelectedItem()) {
			return this.getSelectedItem().value;
		}
	}

	writeValue(obj: any): void {
		if (!obj) { obj = ''; }
		this.model = obj;
	}

	ngOnChanges(changes: any) {
		if (changes.parametro) {
			if (!changes.parametro.firstChange) {
				if (changes.parametro.currentValue || this.loadWhenParameterEmpty) {
					this.listItems();
				}
			}
		}
	}

	onChange(value : any) {
		alert(value);
		this.writeValue(value);
	}

	registerOnChange(fn: any): void { this.onChange = fn; }

	registerOnTouched(fn: any): void { }

	setDisabledState(isDisabled: boolean): void { }

	onInput(value : any) {
		if (!value) {
			this.list = this.listAutoComplete;
			return;
		}

		if (this.list && this.list.length > 1) {
			this.list = this.listAutoComplete.filter(function (el : any) {
				return el.value.toLowerCase().indexOf(value.toLowerCase()) != -1;
			});
		}
	}

	onSelect(obj : any) {
		//this.model = `${id}`;   
		if (!obj) { obj = ''; }
		this.model = obj;

		this.valorSelecionado = obj;
		this.valorModificado.emit(obj);
	}
}
