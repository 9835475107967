<div id="loading" class="loading-background">
	<div class="message">
		<div class="text-center">
			<div class="block m-b">
				<i class="fa fa-spinner fa-5x fa-spin"></i>
			</div>
			<h3>Carregando...</h3>
		</div>
	</div>
</div>

<style>
	.loading-background {
		background-color: #247d4a;
		background-color: rgba(36, 125, 74, .7);
		bottom: 0;
		display: none;
		height: 100%;
		left: 0;
		position: fixed;
		right: 0;
		top: 0;
		width: 100%;
		z-index: 9999;
	}

	.loading-content {
		border-color: #ccc transparent;
		border-radius: 50%;
		border-style: double;
		border-width: 30px;
		height: 20px;
		left: 50%;
		margin-left: -40px;
		margin-top: -40px;
		position: absolute;
		top: 50%;
		animation: spin 1s linear infinite;
		width: 20px;
	}

	.loading-background .message {
		position: absolute;
		top: 50%;
		width: 100%;
		margin-top: -100px;
		color: white;
	}

	@keyframes spin {
		0% {
			transform: rotate(0);
		}

		100% {
			transform: rotate(-360deg);
		}
	}
</style>
