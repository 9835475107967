<div class="row form-group" style="margin-left: 2px;">

    <div [ngClass]="(validacao.pendenciaCondicionantePPB) ? 'pendencia-validacao' : '' ">
        <a
            *ngIf="habilitarInputs"
            style="margin-right: 10px; margin-top: 5px;"
            class="btn btn-primary btn-sm pull-right"
            data-toggle="tooltip"
            (click)="adicionarCondicionantes()"
            title=""
            data-original-title="">
                <i class="fa fa-plus"></i> Incluir na Lista
        </a>
        <app-grid-condicionantes-ppb
            [(idPPB)]="idPPB"
            [(dataVigenciaInicialPPB)]="dataVigenciaInicialPPB"
            [(isAlteracaoParaRevogacao)]="isAlteracaoParaRevogacao"
            (atualizaListagemGrid)="atualizaListagem($event)"
            [(habilitarInputs)]="habilitarInputs"
            [(lista)]="grid.lista"
            [(total)]="grid.total"
            [(size)]="grid.size"
            [(page)]="grid.page"
            [(parametros)]="parametros"
            (onChangeSort)="onChangeSort($event)"
            (onChangeSize)="onChangeSize($event)"
            (onChangePage)="onChangePage($event)"
            [(isRetificacao)]="isRetificacao">
        </app-grid-condicionantes-ppb>
    </div>
</div>

<app-modal-incluir-condicionantes-ppb
    [(dataVigenciaInicialPPB)]="dataVigenciaInicialPPB"
    #appModalIncluirCondicionantesPPB
    (atualizaListagem)="atualizaListagem($event)">
</app-modal-incluir-condicionantes-ppb>
