import { Component, Output, Input, EventEmitter } from '@angular/core';

@Component({
	selector: 'app-collapse-row-button',
	templateUrl: './collapse-row-button.component.html',

})
export class CollapseRowButtonComponent {

	@Input() isCollapsedLegenda: boolean;

	@Input() target: string;
	
	
	habilitaDetalheDupla() {
		if(this.isCollapsedLegenda){
			document.getElementById(this.target).style.display = '';
		}else{
			document.getElementById(this.target).style.display = 'none';
		}
		
	}
}
