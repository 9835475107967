import { Component, Injectable, OnInit, ViewChild, Input, Output, EventEmitter } from "@angular/core";
import { MessagesService } from "../../../../../shared/services/messages.service";
import { ValidationService } from "../../../../../shared/services/validation.service";
import { ApplicationService } from "../../../../../shared/services/application.service";
import { ActivatedRoute} from "@angular/router";
import { Validators, FormBuilder } from '@angular/forms';
import { EnumRestResponse } from '../../../../../shared/enums/EnumRestResponse';
import { GrupoPPBVM } from "../../../../../view-model/GrupoPPBVM";
import { ModalService } from '../../../../../shared/services/modal.service';
import { ControleEtapasPPBComponent } from '../../etapas-ppb/etapas-ppb.component';
import { ChangeEtapaPPBService } from '../../../../../shared/services/changeEtapaPPB.service';
import { ServiceTipoListagemVM } from "../../../../../view-model/ServiceTipoListagemVM";

@Component({
	selector: 'app-modal-incluir-grupos-ppb',
	templateUrl: './modal-incluir-grupos-ppb.component.html'
})

@Injectable()
export class ModalIncluirGruposPPBComponent implements OnInit {

	listaDadosAtuacao: any;
	ocultarInputAnexo = false;
	parametros: GrupoPPBVM = new GrupoPPBVM();
	isDesabilitado: boolean = true;
	ocultaCombos: boolean = true;
	_errorDataFinalMaiorInicial : boolean = false;
	_errorDataFinalMenorDataCorrente : boolean = false;
	_errorDataInicioNaoPreenchida : boolean = false;
	flagCadastroContinuo : boolean = false;
	servico = "GrupoPPB";
	operation : string = "";
	title = 'appBootstrap';

	model:any;

	@Input() idPPB: number;
	@Input() habilitarInputs: boolean;
	@Input() dataVigenciaInicialPPB : string;

	@Output() atualizaListagem: EventEmitter<any> = new EventEmitter();

	@ViewChild('formBusca') formBusca;
	@ViewChild('produtosGrupo') produtosGrupo;
	@ViewChild('dataRecebimentoInicial') dataRecebimentoInicial;
	@ViewChild('dataRecebimentoFinal') dataRecebimentoFinal;
    @ViewChild('appModalIncluirGruposPPB') appModalIncluirGruposPPB : any;
	@ViewChild('appModalIncluirGruposPPBBackground') appModalIncluirGruposPPBBackground : any;

	constructor(
		private ChangeEtapaPPBService : ChangeEtapaPPBService,
		private modal : ModalService,
		private applicationService: ApplicationService,
		private fb: FormBuilder,
		private msg: MessagesService,
		private validation: ValidationService,
		private route: ActivatedRoute
	) {

    }

	/***********************************************/
	userprofileForm = this._initializeValidations();
	get descricao(){
		return this.userprofileForm.get('descricao');
	}
	get produto(){
		return this.userprofileForm.get('produto');
	}
	// get metasGrupo(){
	// 	return this.userprofileForm.get('metasGrupo');
	// }
	get dataInicial(){
		return this.userprofileForm.get('dataInicial');
	}
	get dataFinal(){
		return this.userprofileForm.get('dataFinal');
	}
	get cadastroContinuo(){
		return this.userprofileForm.get('cadastroContinuo');
	}
	_initializeValidations(){
		return this.fb.group({
			descricao : ['', [Validators.required]],
			produto : ['', [Validators.required]],
			//metasGrupo : ['', [Validators.required]],
			dataInicial : [ '', Validators.required],
			dataFinal : [ '', null],
			cadastroContinuo : [ '', null]
		});
	}
	/************************************************/

    ngOnInit() {
	}

	public abrir(idPPB : number, operation : string, idGrupo? : number){

		this.operation = operation;
		this.flagCadastroContinuo = false;
		this.limparVariaveis();
		this.parametros.idPPB = idPPB;
		this.userprofileForm = this._initializeValidations();

		if(operation == "new"){
			this.parametros.dataInicioVigenciaString = this.dataVigenciaInicialPPB;
			//this.buscarProxLetraPPB();
		} else{
			this.parametros.id = idGrupo;
			this.buscar();
		}

		this.operation = operation;
        this.appModalIncluirGruposPPBBackground.nativeElement.style.display = 'block';
		this.appModalIncluirGruposPPB.nativeElement.style.display = 'block';
    }

    public fechar(){
		this.flagCadastroContinuo = false;
		this.limparVariaveis();
		this.userprofileForm.get('dataInicial').reset();
		this.userprofileForm.get('cadastroContinuo').reset();
        this.appModalIncluirGruposPPBBackground.nativeElement.style.display = 'none';
		this.appModalIncluirGruposPPB.nativeElement.style.display = 'none';
    }

	setValueInForm(objeto : GrupoPPBVM){
		this.userprofileForm.setValue({
			dataInicial : objeto.dataVigenciaInicial ,
			dataFinal : objeto.dataVigenciaFinal ,
			produto : objeto.descricaProdutos ,
			metasGrupo : objeto.descricaoMetas
		});
	}

	buscar(){
		this.applicationService.get(this.servico, this.parametros).subscribe((retorno : GrupoPPBVM) => {
			this.parametros = retorno;
		});
	}

	validar(){

		var dataInicioString = this.userprofileForm.get('dataInicial').value;
		var dataFimString = this.userprofileForm.get('dataFinal').value;

		if(!dataInicioString){

			this._errorDataInicioNaoPreenchida = true;
			this.userprofileForm.get('dataInicial').reset();
			this.userprofileForm.get('dataFinal').reset();

			return false;
		} else{

			this._errorDataInicioNaoPreenchida = false;
		}


		if(dataInicioString && dataFimString){

			// if(this.validation.validaDataMenorDataVigente(dataFimString)){

			// 	this._errorDataFinalMenorDataCorrente = true;
			// 	this.userprofileForm.get('dataInicial').reset();
			// 	this.userprofileForm.get('dataFinal').reset();

			// 	return false;
			// } else {
			// 	this._errorDataFinalMenorDataCorrente = false;
			// }

			if(this.validation.validaDataInicialMaiorDataFinal(dataInicioString , dataFimString) == true){

				this._errorDataFinalMaiorInicial = true;
				this.userprofileForm.get('dataInicial').reset();
				this.userprofileForm.get('dataFinal').reset();

				return false;
			} else {
				this._errorDataFinalMaiorInicial = false;
			}
		}

		this.salvar()
	}

	validaDataInicioMenorDataAtual(){
		var dataInicioString = this.userprofileForm.get('dataInicial').value;
		var dataInicioDate = this.validation.getDateWhithChangeFormat('YYYY-MM-DD' , new Date(dataInicioString), true);
		return true;
	}

	//NA SPRINT 02 A ORDENAÇÃO ALFABÉTICA DOS GRUPOS FOI RETIRADA.
	//
	//buscarProxLetraPPB(){
	// 	this.applicationService.get(this.servico+"/GetProxLetraGrupoPPB", this.parametros).subscribe((retorno : GrupoPPBVM) => {
	// 		this.parametros.descricaGrupo = retorno.descricaGrupo;
	// 	});
	// }

	salvar(){

		var idPPB = this.parametros.idPPB;

		this.applicationService.post(this.servico, this.parametros).subscribe((retorno : EnumRestResponse) => {

			if(retorno == EnumRestResponse.OK) {

				this.modal.infoSucess(this.msg.OPERACAO_REALIZADA_COM_SUCESSO, "Success");

				if(!this.flagCadastroContinuo){

					this.fechar();

				} else {

					this.limparVariaveis();

					this.parametros.idPPB = idPPB;

					if(this.operation == 'new')
						this.parametros.dataInicioVigenciaString = this.dataVigenciaInicialPPB;

				}

        const obj = new ServiceTipoListagemVM();
        obj.idPpb = Number(idPPB);
        obj.getFromSessionStorage = true;
				this.ChangeEtapaPPBService.atualizaListagemPPB(obj);

				this.atualizaListagem.emit(idPPB);

			} else if(retorno == EnumRestResponse.BAD_REQUEST) {

				this.modal.informacao("O PPB Já Possui um Grupo com essa Descrição.", "Atenção");
				return false;

			} else {

				this.modal.infoError("Falha ao Executar Operação..", "Error");
				return false;

			}
		});
	}

	limparVariaveis(){
		this.parametros = new GrupoPPBVM();
		this._errorDataFinalMaiorInicial = false;
		this.limparCamposFormulario();
	}

	limparCamposFormulario(){
		this.userprofileForm.get('produto').reset();
		this.userprofileForm.get('descricao').reset();
		this.userprofileForm.get('dataFinal').reset();
	}

	formatDate(data: string){
		return this.validation.getDateWhithChangeFormat('YYYY-MM-DD' , new Date(data), true);
	}


}
