import { environment } from './../../../../../environments/environment';
import { Component, Injectable, OnInit, ViewChild, Input, Output, EventEmitter } from "@angular/core";
import { MessagesService } from "../../../../shared/services/messages.service";
import { ValidationService } from "../../../../shared/services/validation.service";
import { ApplicationService } from "../../../../shared/services/application.service";
import { ActivatedRoute } from "@angular/router";
import { PPBVM } from '../../../../view-model/PPBVM';
import { ValoresPorGrupoVM } from '../../../../view-model/ValoresPorGrupoVM';
import { EnumTipoEtapa } from '../../../../shared/enums/EnumTipoEtapa';
import { VincularProdutoVM } from '../../../../view-model/VincularProdutoVM';
import { ModalService } from '../../../../shared/services/modal.service';
import { RequestResponseVM } from '../../../../view-model/RequestResponseVM';
import { PagedItems } from "../../../../view-model/PagedItems";
import { ViewMccpProdutoEntityVM } from "../../../../view-model/ViewMccpProdutoEntityVM";
import { ControleMigracaoProdutoVM } from "../../../../view-model/ControleMigracaoProdutoVM";

@Component({
	selector: 'app-modal-vinculo-de-produtos',
	templateUrl: './modal-vincular-produtos.component.html'
})
export class ModalFinalizar implements OnInit {

	grid: any = { sort: {} };
	flagGrupoNaoInformado: boolean = false;
	flagItemJaVinculado: boolean = false;
	flagProdutoNcmVazio: boolean = false;
  arrayVinculoInconsistencias : Array<VincularProdutoVM> = new Array<VincularProdutoVM>();
	arrayVinculosProduto: Array<VincularProdutoVM> = new Array<VincularProdutoVM>();
	arrayProdutosParaMigracao: Array<VincularProdutoVM> = new Array<VincularProdutoVM>();
	parametros: VincularProdutoVM = new VincularProdutoVM();
	arrayGrupos: Array<ValoresPorGrupoVM> = new Array<ValoresPorGrupoVM>();
  arrayMultiplos: Array<ValoresPorGrupoVM> = new Array<ValoresPorGrupoVM>();
	arrayProdutosMigrados: Array<VincularProdutoVM> = new Array<VincularProdutoVM>();
	selectFirstItem: boolean = true;
	tituloPPB: string = "";
	servicoGruposPPB = 'EtapasPPB/GetGrupos';
  servicoMultiplosPPB = "CondicionantesPPB/GetPPBMultiplos";
  servicoVisualizarInconsistencias = "VinculosInconsistentesNormaComplementarAlteracaoParcialComDocOrigem";
	objetoParametro: PPBVM = new PPBVM();
	flagIsPorGrupo: boolean = false;
  flagIsPorMultiplo:boolean = false;
	flagFromRetificacao: boolean = false;
	flagFromRevogacao: boolean = false;
	flagFromFinalizacao: boolean = false;
	flagUsuarioNaoVinculouProduto: boolean = false;
  flagPermiteVisualizarGridDeInconsistencias : boolean = false;

	//Flag indicadora que o modal foi aberto a partir de um revogação
	isRevogacaoPpb: boolean = false;

	@Input() _fromVinculos: boolean = false;
	@Input() isVisualizar: boolean = false;
	servico = 'ProdutosVinculados';
	grid2: any = { sort: {} };
	@Input() _mostrarCampoDocumento?: boolean = false;
	isAlteracaoParcial: boolean = false;
	flagExibeGridVinculos: boolean = false;
	@Output() atualizarListagem: EventEmitter<any> = new EventEmitter();

	@ViewChild('ViewMcppProdutoNCM') ViewMcppProdutoNCM: any;
	@ViewChild('ComboBoxGrupo') ComboBoxGrupo: any;
  @ViewChild("ComboBoxMultiplo") ComboBoxMultiplo:any;

	@ViewChild('appModalFinalizar') appModalFinalizar: any;
	@ViewChild('appModalFinalizarBackground') appModalFinalizarBackground: any;

	constructor(
		private applicationService: ApplicationService,
		private modalService: ModalService,
		private msg: MessagesService,
		private validation: ValidationService,
		private route: ActivatedRoute
	) {
	}

	ngOnInit() { }

	onChangeSort($event: any) {
		this.grid2.sort = $event;
		this.listarProdutosDosVinculos(this.objetoParametro.id);
	}

	onChangeSize($event: any) {
		this.grid2.size = $event;
		this.grid2.page = 1;
		this.listarProdutosDosVinculos(this.objetoParametro.id);
	}

	onChangePage($event: any) {
		this.grid2.page = $event;
		this.listarProdutosDosVinculos(this.objetoParametro.id);
	}

	abrir(objeto: PPBVM, fromRevogacao?: boolean, fromRetificacaoPPB?: boolean, fromFinalizacaoPpb?: boolean) {


		this.parametros = new VincularProdutoVM();

		this.isRevogacaoPpb = fromRevogacao;

		this.objetoParametro = objeto;

		this.tituloPPB = objeto.descricaoTitulo;

		fromFinalizacaoPpb ? this.flagFromFinalizacao = true : '';

		if (objeto.tipoEtapa == EnumTipoEtapa.POR_GRUPO) {
			this.listarGruposPPB(objeto.id);
		}
    if(objeto.tipoEtapa == EnumTipoEtapa.PPB_MULTIPLO){
      this.listaMultiplosPPB(objeto.id);
    }

		if (fromRetificacaoPPB)
			this.flagFromRetificacao = true;

		this.buscarProdutosPpbCorrente();

		// 3 == ALTEREAÇÃO PARCIAL || 5 == FIXACAO PPB ESPECIFICO || 4 == NORMA COMPLEMENTAR
		if (objeto.tipoPublicacao == 3 || objeto.tipoPublicacao == 5 || objeto.tipoPublicacao == 4) {
			this.listarProdutosDosVinculos(objeto.id);
			this.isAlteracaoParcial = true;
		}

    // 3 == ALTEREAÇÃO PARCIAL || 4 == NORMA COMPLEMENTAR
    // if((objeto.tipoPublicacao == 3 || objeto.tipoPublicacao == 4) && environment.permiteVisualizarGridInconsistencias)
    //   this.listarInconsistencias(objeto.id);


		this.changeDisplayModal('block')
	}

	changeDisplayModal(displayProperty: string) {
		this.appModalFinalizarBackground.nativeElement.style.display = displayProperty;
		this.appModalFinalizar.nativeElement.style.display = displayProperty;
	}

	fechar() {
		this.limparFlags();
		this.atualizarListagem.emit(true);
		this.changeDisplayModal('none');
	}

	listarGruposPPB(idPPB: number) {
		this.applicationService.get(this.servicoGruposPPB, { idPPB: idPPB }).subscribe((result: Array<ValoresPorGrupoVM>) => {
			if (result) {
				this.arrayGrupos = result;
				this.flagIsPorGrupo = true;
				this.selectFirstItem = true;
			}
		});
	}
  listaMultiplosPPB(idPPB: number){
    this.applicationService.get(this.servicoMultiplosPPB, { idPPB: idPPB }).subscribe((result: Array<ValoresPorGrupoVM>) => {
			if (result) {
				this.arrayMultiplos = result;
				this.flagIsPorMultiplo = true;
				this.selectFirstItem = true;
			}
		});

  }

	buscarProdutosPpbCorrente() {
		var objeto: any = {};
		objeto.idPpb = this.objetoParametro.id;
		objeto.page = this.grid.page ? this.grid.page : 1;
		objeto.size = this.grid.size ? this.grid.size : 10;
		objeto.sort = this.grid.sort.field;
		objeto.reverse = this.grid.sort.reverse;
		this.grid = { sort: {} };
		this.applicationService.get('ProdutosVinculados', objeto).subscribe((retorno: any) => {
			if (retorno) {
				this.arrayVinculosProduto = retorno;
			} else {
				this.arrayVinculosProduto = new Array<VincularProdutoVM>();
			}
		});
	}

	validar() {
		if (this.arrayVinculosProduto.length > 0) {
			this.arrayVinculosProduto.forEach(element => {
				if (!element.tituloPPB) {
					element.tituloPPB = this.objetoParametro.descricaoTitulo;
				}
			});
			this.arrayVinculosProduto[0].flagFromFinalizacao = this.flagFromFinalizacao;
			this.salvar();
		} else {
			this.flagUsuarioNaoVinculouProduto = true;
			return false;
		}
	}

	salvar() {

		this.applicationService.post("VincularProduto", this.arrayVinculosProduto).subscribe((result: RequestResponseVM) => {
			if (result.statusResponse === 200) {
				this.fechar();
				this.modalService.infoSucess("Operação Realizada com Sucesso", "Success");
				this.atualizarListagem.emit(true);
			} else {
				this.modalService.error("Não foi possível executar a operação: " + result.textResponse, "Atenção");
				return false;
			}
		});
	}

	vincular() {

		if (!this.ViewMcppProdutoNCM.model.text) {
			this.flagProdutoNcmVazio = true;
			return false;
		} else {
			this.flagProdutoNcmVazio = false;
		}

		this.parametros.descricaoProduto = this.ViewMcppProdutoNCM.model.text.replace(" ", "");//split(" | ")[1].replace(" ", "");
		this.parametros.idProduto = this.ViewMcppProdutoNCM.model.id;

		if (this.flagIsPorGrupo && !this.parametros.idGrupo) {
			this.flagGrupoNaoInformado = true;
			return false;
		} else {
			this.flagGrupoNaoInformado = false;
		}

		let novoVinculo: VincularProdutoVM = new VincularProdutoVM();
		novoVinculo.idPPB = this.objetoParametro.id;
		novoVinculo.descricaoGrupo = this.parametros.descricaoGrupo;
    novoVinculo.descricaoMultiplo = this.parametros.descricaoMultiplo;
		novoVinculo.descricaoProduto = this.parametros.descricaoProduto;
		novoVinculo.idProduto = this.parametros.idProduto;
		novoVinculo.idGrupo = this.parametros.idGrupo;
    novoVinculo.idMultiplo = this.parametros.idMultiplo
		novoVinculo.tituloPPB = this.objetoParametro.descricaoTitulo;
		novoVinculo.fromBackEnd = false;

		if (this.arrayVinculosProduto.length) {

			var itemJaVinculado = this.arrayVinculosProduto.filter(o => o.idProduto == novoVinculo.idProduto);

			if (itemJaVinculado.length > 0) {
				this.flagItemJaVinculado = true;
				return false;
			} else {
				this.flagItemJaVinculado = false;
				this.arrayVinculosProduto = [...this.arrayVinculosProduto, novoVinculo]; //Append
			}
		} else {
			this.arrayVinculosProduto = [...this.arrayVinculosProduto, novoVinculo]; //Append
		}
	}

	desvincular(index: number, item: any) {

    let FIXACAO_PPB_ESPECIFICO = 5;

    if (!item.fromBackEnd) {
      this.arrayVinculosProduto.splice(index, 1);
    }
    else {

      let obj: VincularProdutoVM = new VincularProdutoVM();
      obj.idPPB = this.objetoParametro.id;
      obj.idProduto = item.idProduto;
      obj.tituloPPB = this.objetoParametro.descricaoTitulo;

      this.applicationService.post("DesvincularProduto", obj).subscribe((result: RequestResponseVM) => {
        if (result.statusResponse == 200) {
          this.modalService.infoSucess("Operação realizada com sucesso", "Sucesso").subscribe((result: any) => {

              this.arrayVinculosProduto.splice(index, 1);

              //Verificar se o desvinculo do produto foi feito após o usuario selecionar a opção 'migrar' do grid de vinculos...
              if (this.objetoParametro.tipoPublicacao == FIXACAO_PPB_ESPECIFICO) {
                var itemMigrado = this.arrayProdutosMigrados.find(x => x.idPPB == this.objetoParametro.id &&
                  x.idProduto == item.idProduto &&
                  x.fromMigracao == true);

                let novoVinculo: VincularProdutoVM = new VincularProdutoVM();
                novoVinculo.idPPB = itemMigrado.idPpbFromMigracao;
                novoVinculo.descricaoGrupo = itemMigrado.descricaoGrupo;
                novoVinculo.descricaoMultiplo = itemMigrado.descricaoMultiplo;
                novoVinculo.descricaoProduto = itemMigrado.descricaoProduto;
                novoVinculo.idProduto = itemMigrado.idProduto;
                novoVinculo.idGrupo = itemMigrado.idGrupo;
                novoVinculo.tituloPPB = itemMigrado.tituloPPB;

                this.applicationService.post("VincularProduto", [novoVinculo]).subscribe((result: RequestResponseVM) => {
                  if (result.statusResponse === 200) {
                    this.listarProdutosDosVinculos(this.objetoParametro.id);
                  }
                  else {
                    this.modalService.error("Falha ao Migrar produto para o PPB Anterior!  " + result.textResponse, "Error");
                    return false;
                  }
                });
            }
          });
        } else {
          this.modalService.infoError("Erro ao desvincular produto! [MessageError]: " + result.textResponse, "Erro");
          return false;
        }

      });
    }
	}

  desvincularInconsistencia(item: any){
    let obj: VincularProdutoVM = new VincularProdutoVM();
    obj.idPPB = this.objetoParametro.id;
    obj.idProduto = item.idProduto;
    obj.tituloPPB = item.documento;
    this.applicationService.post(this.servicoVisualizarInconsistencias, obj).subscribe((result: RequestResponseVM) => {
      if (result.statusResponse == 200) {
        this.modalService.infoSucess("Operação realizada com sucesso", "Sucesso").subscribe((result: any) => {
          this.listarInconsistencias(this.objetoParametro.id);
          this.produtoMigrado();
        });
      }
      else {
        this.modalService.infoError("Erro ao desvincular produto! [MessageError]: " + result.textResponse, "Erro");
      }
    });

  }


	selectGrupo() {
		var indiceSelecionado = this.ComboBoxGrupo.nativeElement.options.selectedIndex;
		if (indiceSelecionado >= 0)
			this.parametros.descricaoGrupo = this.ComboBoxGrupo.nativeElement.options[indiceSelecionado].label;
	}
  selectMultiplo(){
    var indiceSelecionado = this.ComboBoxMultiplo.nativeElement.options.selectedIndex;
		if (indiceSelecionado >= 0)
			this.parametros.descricaoMultiplo = this.ComboBoxMultiplo.nativeElement.options[indiceSelecionado].label;

  }

	recebeArrayProdutosMigrados($event: Array<VincularProdutoVM>) {
		this.arrayProdutosMigrados = $event;
	}

	limparFlags() {
		this.isRevogacaoPpb = false;
		this.isAlteracaoParcial = false;
		this.flagExibeGridVinculos = false;
		this.flagFromRetificacao = false;
		if(!this.isVisualizar){
			this.ViewMcppProdutoNCM.clear();
		}
		this.flagIsPorGrupo = false;
		this.flagItemJaVinculado = false;
		this.flagGrupoNaoInformado = false;
		this.flagProdutoNcmVazio = false;
		this.flagFromFinalizacao = false;
		this.flagUsuarioNaoVinculouProduto = false;
		this.objetoParametro = new PPBVM();
		this.parametros = new VincularProdutoVM();
		this.selectFirstItem = true;
		this.arrayVinculosProduto = new Array<VincularProdutoVM>();
		this.tituloPPB = "";
		this.flagFromRevogacao = false;
    this.flagPermiteVisualizarGridDeInconsistencias = false;
	}

	listarProdutosDosVinculos(idPPB: number) {
		var objeto: any = {};
		objeto.idPpb = idPPB;
		objeto.page = this.grid2.page ? this.grid2.page : 1;
		objeto.size = this.grid2.size ? this.grid2.size : 10;
		objeto.sort = this.grid2.sort.field;
		objeto.reverse = this.grid2.sort.reverse;
		objeto.somenteVinculo = true;
		this.applicationService.get(this.servico + "/PaginadoVinculos", objeto).subscribe((retorno: PagedItems) => {
			if (retorno.total > 0) {
				this.grid2.lista = retorno.items;
				this.grid2.total = retorno.total;
			} else {
				this.grid2 = { sort: {} };
			}
		});
	}

  listarInconsistencias(idPpb : number){
    this.applicationService.get(this.servicoVisualizarInconsistencias, idPpb).subscribe((result:any)=> {
      if(result) {
        this.arrayVinculoInconsistencias = result;
        this.flagPermiteVisualizarGridDeInconsistencias = true;
      }
      else {
        this.flagPermiteVisualizarGridDeInconsistencias = true;
        this.arrayVinculoInconsistencias = null;
      }
    })
  }

	produtoMigrado() {
		this.buscarProdutosPpbCorrente();
		this.listarProdutosDosVinculos(this.objetoParametro.id);
	}

}
