

<select2-custom
			#valorInput
			[required]="isRequired"
			[options]="options"
			[settings]="settings"
			[(ngModel)]="model"
			(onSelect)="onSelect(model)">
</select2-custom>





