import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface AlertaModel {
	title: string;
	message: string;
}

@Component({
	selector: 'app-modal',
	templateUrl: './modal-resposta-error.component.html',
})

export class ModalRespostaErrorComponent implements OnInit {

	constructor(public dialogRef : MatDialogRef<ModalRespostaErrorComponent>,
				@Inject(MAT_DIALOG_DATA) public data : AlertaModel) { }

	ngOnInit() {
		setTimeout(() => {
			this.confirm();
		}, 2500);
	}

	confirm() {
		this.dialogRef.close(true);
	}
}