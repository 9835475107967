import { PagedItems } from "./PagedItems";
import { PPBVM } from "./PPBVM";

export class ConsultarPPBVM extends PagedItems{
    TipoPublicacao: number = 0;
    TipoDocumento: number = 0;
    TipoEtapa:number = 0;
    idProduto : any;
    Numero: number;
    Ano: number;
    PalavraChave: string;
    PosicaoPalavra: number = 0;
    Situacao: number = 0;
    lista: Array<any>;
}
