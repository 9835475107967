import { Injectable } from '@angular/core';

@Injectable()
export class DiffDateDaysService {
	constructor() { }

	diffDate(initialDate : any, finalDate : any) {
		const DAY = 1000 * 60 * 60 * 24;
		initialDate = new Date(initialDate);
		finalDate = new Date(finalDate);

		try {
			const utc1 = Date.UTC(initialDate.getFullYear(), initialDate.getMonth(), initialDate.getDate());
			const utc2 = Date.UTC(finalDate.getFullYear(), finalDate.getMonth(), finalDate.getDate());

			return Math.floor((utc2 - utc1) / DAY);
		} catch (e) {
			return false;
		}
	}
}
