import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { IndexComponent } from "../views/index/index.component";

//Componentes PRJ - Mpbb
import { ManterPPBComponent } from '../views/manter-ppb/manter-ppb.component';
import { ControleSolicitacoesComponent } from '../views/manter-ppb/solicitacoes-ppb/controle-solicitacoes-ppb.component';
import { VisualizarCadastrosDePPBComponent } from '../views/manter-ppb/visualizar-cadastros-de-ppb/visualizar-cadastros-de-ppb.component'
import { ManterDocumentoPPBComponent } from '../views/manter-documento-do-ppb/manter-documento-do-ppb.component';
import {ControleSolicitacoesDocumentoComponent} from '../views/manter-documento-do-ppb/solicitacoes-documento-ppb/controle-solicitacoes-documento-ppb.component';

const routes: Routes = [
    //MANTER PPB
	{ path: "manter-ppb", component: ManterPPBComponent},
	{ path: "solicitacoes-ppb/novo", component: ControleSolicitacoesComponent},
	{ path: "solicitacoes-ppb/:idPpb",
		children : [
			{path: 'editar', component: ControleSolicitacoesComponent},
			{path: 'visualizar', component: ControleSolicitacoesComponent},
			{path: 'validacao', component: ControleSolicitacoesComponent}
		]
	},
	{ path: "visualizar-cadastro-ppb/:id", component: VisualizarCadastrosDePPBComponent},
    //
    //MANTER TIPO DOCUMENTO PPB
	 { path: "manter-documento-ppb", component: ManterDocumentoPPBComponent},
	{ path: "solicitacoes-documento-ppb/novo", component: ControleSolicitacoesDocumentoComponent},
	{ path: "solicitacoes-documento-ppb/:idDoc",
		children : [
			{path: 'editar', component: ControleSolicitacoesDocumentoComponent}
		]
	},
	//

	{ path: "", component: IndexComponent, pathMatch: "full" },
	{ path: "**", redirectTo: "" }

];

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule]
})
export class AppRoutingModule { }
