
export class FiltrosModalListagemVM {
  tipoEtapa: number = 99;
  idEtapa: number;
  idPpbMultiplo: number;
  idCondicionante: number;
  idGrupo: number;
  idMeta: number;
  dataInicio: any;
  dataFim: any;
  descricaoPrincipal: string;
  tpPesquisaDescricao: any = '1';
  flagIsSomenteVigente: boolean;
}
