import { Injectable, Output, EventEmitter } from '@angular/core';
import { ServiceTipoListagemVM } from '../../view-model/ServiceTipoListagemVM';

@Injectable()
export class ChangeListagemProdutosService {

  AtualizaListagemProdutos : EventEmitter<ServiceTipoListagemVM> = new EventEmitter()

	constructor() {
	}

	public atualizaListagemProdutos(obj: ServiceTipoListagemVM) {
		this.AtualizaListagemProdutos.emit(obj);
	}

    //ASSISTA https://www.youtube.com/watch?v=R9afVKty3Dg&ab_channel=LoianeGroner

}
