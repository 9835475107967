import {
  Component,
  Injectable,
  OnInit,
  ViewChild,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';
import {ModalService} from "../../../../../../shared/services/modal.service";
import {ApplicationService} from "../../../../../../shared/services/application.service";
import {ValidationService} from "../../../../../../shared/services/validation.service";
import {CondicionanteVM} from "../../../../../../view-model/CondicionanteVM";
import {CronogramaItemVM} from "../../../../../../view-model/CronogramaItemVM";
import {CronogramaPontuacaoPPBComponent} from "../../cronograma-pontuacao-ppb.component";

@Component({
  selector: 'app-modal-editar-cronograma',
  templateUrl: './modal-editar-cronograma.component.html',
  styleUrls: ['./modal-editar-cronograma.component.css'],
})



@Injectable()
export class ModalEditarCronogramaComponent implements OnInit {
  @Input() idPPB: number;
  @Input() item: any;
  descricaoItemSelecionado: string;
  idGrupo : any = null;
  titulo = 'Editar';
  parametros: CronogramaItemVM = new CronogramaItemVM();

  @ViewChild('appModalEditarCronograma') appModalEditarCronograma: any;
  @ViewChild('appModalEditarCronogramaBackground') appModalEditarCronogramaBackground: any;
  constructor(
    private modal: ModalService,
    private applicationService: ApplicationService,
    private validation: ValidationService,
    private gruposPPBComponent : CronogramaPontuacaoPPBComponent ) { }

  ngOnInit(): void { }

  salvar() {

    this.applicationService.put("PontuacaoMinima/AtualizarCronograma", this.parametros).subscribe((retorno : any) => {
      if(retorno == 1 ){

            this.modal.infoSucess('Atualizado com Sucesso!', 'Sucesso');
            this.gruposPPBComponent.listar(this.parametros.idPPB)
         }
      this.fechar();
    });

  }

  public abrir(sel:any) {

    this.descricaoItemSelecionado = sel.grupoDescricao;

    this.applicationService.get("PontuacaoMinima/cronograma-anual/recuperar-item", sel.croID).subscribe((retorno : any) => {

      this.parametros.descricaoEtapa=retorno.descricao;
      this.parametros.idCriacao=retorno.croID;
      this.parametros.idPPB = retorno.ppbId;
      this.parametros.idPPBGrupo = retorno.grpId;
      this.parametros.ano2023=retorno.ano2023;
      this.parametros.ano2024=retorno.ano2024;
      this.parametros.ano2025=retorno.ano2025;
      this.parametros.ano2026=retorno.ano2026;
      this.parametros.ano2027=retorno.ano2027;
      this.parametros.isSomatorio = retorno.isSomatorio;

    });

    this.appModalEditarCronogramaBackground.nativeElement.style.display = 'block';
    this.appModalEditarCronograma.nativeElement.style.display = 'block';

  }
    public fechar() {
        this.appModalEditarCronogramaBackground.nativeElement.style.display =  'none';
        this.appModalEditarCronograma.nativeElement.style.display = 'none';

    }

}
