<div #appModalBaixarAnexo
    class="modal modal-wide-2 fade in"
    tabindex="-1"
    role="dialog"
    aria-hidden="false"
    style="display: auto; overflow: scroll;">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header bg-primary" style="padding: 10px 15px; color: white;">
        <h4 class="modal-title" id="anexoModalLabel"><b>Baixar Anexos</b></h4>
      </div>
      <div class="modal-body">

        <div class="row form-group" style="margin-left: 2px;">
          <div style="margin-top: 5px;" class="col-sm-12">
              <div class="col-lg-6">
                  <label for="descricao-titulo-ppb">Descrição Documento:</label>
                  {{titulo}}
              </div>
          </div>
        </div>

        <div class="row form-group">
          <div *ngIf="listaAnexos != null && listaAnexos.length > 0" class="col-lg-12">
            <div style="margin-top: 15px;" *ngFor="let item of listaAnexos">
              <div>
                  <button
                      name="btn-download-arquivo-solicitacoes-ppb"
                      id="btn-download-arquivo-solicitacoes-ppb"
                      type="button"
                      class="btn btn-primary-real btn-sm m-r-sm"
                      data-toggle="tooltip"
                      title="Baixar"
                      (click)="baixarAnexo(item)">
                          <i class="fa fa-download"></i>
                  </button>
                  <b style="color:red; margin-left: 15px;">Nome do arquivo: </b> {{ item.descricaoArquivo }}
              </div>
            </div>
          </div>

          <div *ngIf="listaAnexos == null || listaAnexos.length == 0" class="col-lg-12">
            <h4 class="text-center"><strong>O registro não possui anexos.</strong></h4>
          </div>
        </div>
      </div>
      <div class="modal-footer" style="padding: 10px 15px;">
        <button
          (click)="fechar()"
          class="btn btn-sm btn-default">
            <i class="fa fa-times"></i> Fechar
        </button>
      </div>

    </div>
  </div>
</div>
<div #appModalBaixarAnexobackground class="modal-backdrop fade in" style="display: none;"></div>
