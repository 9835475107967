import { Component, Inject, OnInit, Output, Input, ViewChild, ElementRef, EventEmitter } from '@angular/core';
import { ApplicationService } from "../../../../shared/services/application.service";
import { PagedItems } from '../../../../view-model/PagedItems';
import { PPBHistorico } from '../../../../view-model/PPBHistoricoVM';
import { PPBListagensHistoricoEntityVM } from '../../../../view-model/PPBListagensHistoricoEntityVM';

@Component({
	selector: 'app-modal-historico',
	templateUrl: './modal-historico.component.html'
})

export class ModalHistoricoComponent implements OnInit {

	//----GRID ITENS-----
	@Input() lista: any = {};
	@Input() total: number;
	@Input() size: number;
	@Input() page: number;
	@Input() sorted: string;
	parametrosGridPrincipal: any = {};
	gridPpb: any = { sort: {} };
  parametrosHistoricoListagens: any = {};
  gridHistoricoListagem: any = { sort: {} };
	tituloGrid = "Histórico Produto Padrão";
	tituloPPB : string;
	ativarDescricao : boolean = true;
	//--------------------

  exibirGridPpbCorrente : boolean = false;
  exibirGridListagens : boolean = false;

	//---------- Dados do Grid -------
	listaHistoricoPPB: Array<PPBHistorico>;
	idPPB: number;
	//------------


	//--------- Serviços ---------
	servicoHistoricoPpbCorrente = "HistoricoPPB";
  servicoHistoricoListagens = "HistoricoListagens";

	@Input() data: any = {} = null;
	@Output() changeOperation: EventEmitter<any> = new EventEmitter();

	@ViewChild('appModalHistorico') appModalHistorico : any;
	@ViewChild('appModalHistoricoBackground') appModalHistoricoBackground : any;

	@ViewChild('close') close!: ElementRef;
	@ViewChild('ok') ok!: ElementRef;

	constructor(
		private applicationService : ApplicationService
	) {}

	ngOnInit() {}

	onChangeSort($event: any) {
		this.gridPpb.sort = $event;
		this.listarHistoricoPpb();
	}

	onChangeSize($event: any) {
		this.gridPpb.size = $event;
		this.gridPpb.page = 1;
		this.listarHistoricoPpb();
	}

	onChangePage($event: any) {
    this.gridPpb.page = $event;
		this.listarHistoricoPpb();
	}

  onChangeSortHistoricoListagem($event: any) {
		this.gridHistoricoListagem.sort = $event;
		this.listarHistoricoListagem();
	}

	onChangeSizeHistoricoListagem($event: any) {
		this.gridHistoricoListagem.size = $event;
		this.gridHistoricoListagem.page = 1;
		this.listarHistoricoListagem();
	}

	onChangePageHistoricoListagem($event: any) {
    this.gridHistoricoListagem.page = $event;
		this.listarHistoricoListagem();
	}

	abrir(idPPB: number, tituloPPB : string){
		this.appModalHistorico.nativeElement.style.display = 'block';
		this.appModalHistoricoBackground.nativeElement.style.display = 'block';
		this.idPPB = idPPB;
		this.tituloPPB = tituloPPB;
		this.listarHistoricoPpb();
    this.listarHistoricoListagem();
	}

	fechar(){
    this.gridPpb = { sort: {} };
    this.gridHistoricoListagem = { sort: {} };
    this.parametrosGridPrincipal = {};
    this.parametrosHistoricoListagens = {};
    this.exibirGridPpbCorrente = false;
    this.exibirGridListagens = false;
		this.tituloPPB = "";
		this.appModalHistoricoBackground.nativeElement.style.display = 'none';
		this.appModalHistorico.nativeElement.style.display = 'none';
	}

	listarHistoricoPpb(){
		var objeto = {
			page : this.gridPpb.page ? this.gridPpb.page : 1,
			size : this.gridPpb.size ? this.gridPpb.size : 10,
			sort : this.gridPpb.sort.field,
			reverse : this.gridPpb.sort.reverse,
			idPPB: this.idPPB
		};
    this.parametrosGridPrincipal = objeto;
		this.applicationService.get(this.servicoHistoricoPpbCorrente, objeto).subscribe((retorno : PagedItems) => {
			if(retorno.items != null && retorno){
        this.prencheParametrosDeExportacao();
				this.gridPpb.lista = retorno.items;
				this.gridPpb.total = retorno.total;
			}else{
        this.parametrosGridPrincipal.exportarListagem = false;
				this.gridPpb.total = 0;
			}
		});
	}

  listarHistoricoListagem(){
    var objeto = {
			page : this.gridHistoricoListagem.page ? this.gridHistoricoListagem.page : 1,
			size : this.gridHistoricoListagem.size ? this.gridHistoricoListagem.size : 10,
			sort : this.gridHistoricoListagem.sort.field,
			reverse : this.gridHistoricoListagem.sort.reverse,
			idPPB: this.idPPB
		};
    this.parametrosHistoricoListagens = objeto;
		this.applicationService.get(this.servicoHistoricoListagens, objeto).subscribe((retorno : PagedItems) => {
			if(retorno.total != null && retorno.total > 0){
        this.prencheParametrosDeExportacaoListagens();
				this.gridHistoricoListagem.lista = retorno.items;
				this.gridHistoricoListagem.total = retorno.total;
			}else{
        this.parametrosHistoricoListagens.exportarListagem = false;
				this.gridHistoricoListagem.total = 0;
			}
		});
	}

	prencheParametrosDeExportacao(){
		this.parametrosGridPrincipal.exportarListagem = true;
		this.parametrosGridPrincipal.exportarPdf = true;
		this.parametrosGridPrincipal.servico = this.servicoHistoricoPpbCorrente;
		this.parametrosGridPrincipal.titulo = "Histórico do PPB: " + this.tituloPPB;
		this.parametrosGridPrincipal.columns = ["Descrição Ação", "Usuário","Data e Hora"];
		this.parametrosGridPrincipal.fields = ["descricaoAcao", "nomeUsuario", "dataHoraAcaoString"];
		this.parametrosGridPrincipal.width = { 0: { columnWidth: 420 },
                                           1: { columnWidth: 200 },
                                           2: { columnWidth: 150 } };
	}

  prencheParametrosDeExportacaoListagens(){
		this.parametrosHistoricoListagens.exportarListagem = true;
		this.parametrosHistoricoListagens.exportarPdf = true;
		this.parametrosHistoricoListagens.servico = this.servicoHistoricoListagens;
		this.parametrosHistoricoListagens.titulo = "Histórico das Listagens do PPB: " + this.tituloPPB;
		this.parametrosHistoricoListagens.columns = ["Descrição Ação", "Usuário","Data e Hora"];
		this.parametrosHistoricoListagens.fields = ["descricaoAcao", "nomeUsuario", "dataHoraAcaoString"];
		this.parametrosHistoricoListagens.width = { 0: { columnWidth: 420 },
                                                1: { columnWidth: 200 },
                                                2: { columnWidth: 150 } };
	}


}
