<div
  #appModalMoverPorPosicao
  class="modal modal-wide-2 fade in"
  tabindex="-1"
  role="dialog"
  aria-hidden="false"
  style="display: auto; overflow: scroll"
>
  <div class="modal-dialog">
    <div class="modal-content">
      <div
        class="modal-header bg-primary"
        style="padding: 10px 15px; color: white"
      >
        <h4 class="modal-title" id="anexoModalLabel">
          <b>Mover por seleção</b>
        </h4>
      </div>
      <div class="modal-body" *ngIf="exibirComponente">
        <article class="panel-body">
          <div class="row form-group" style="background-color: lightyellow">
            <div style="margin-left: 15px">
              <h4>Item Selecionado</h4>
              <div class="col-lg-2">
                <label for="descricao" class=""> N° Sequencia: </label>
                {{ itemSelecionado.numeroSequencia }}
              </div>
              <!-- -->
              <div class="col-lg-2" *ngIf="_exibeColunaPpbMultiplo" >
                <label for="descricao" class=""> PPB Multiplo: </label>
                {{ itemSelecionado.descricaoPpbMultiplo }}
              </div>
              <div class="col-lg-3" style="background-color: #00552b;">
                <label for="descricao" style="color: white;"> {{viewRef}}: </label>
                <span style="color: white; margin-left: 10px;">
                  {{ itemSelecionado.tituloItemSelecionado }}
                </span>
              </div>
            </div>
          </div>
          <div class="row form-group">
            <div style="margin-top: 10px; margin-left: 10px;">
              <h4>
                <a style="color:red; cursor:pointer;" (click)="exibirDescricao ? exibirDescricao = false : exibirDescricao = true">
                  {{exibirDescricao?'Ocultar':'Exibir'}} Descrição
                </a>
              </h4>
            </div>
            <div class="col-lg-12" *ngIf="exibirDescricao">
              <div *ngIf="viewRef == 'Etapa' || viewRef == 'Condicionante'">
                <angular-editor
                  *ngIf="itemSelecionado.descricaoItemOrigem"
                  [(ngModel)]="itemSelecionado.descricaoItemOrigem"
                  [config]="config">
                </angular-editor>
                <div *ngIf="!itemSelecionado.descricaoItemOrigem"> <b>--</b> </div>
              </div>
              <p *ngIf="viewRef != 'Etapa' && viewRef != 'Condicionante'">
                {{ itemSelecionado.descricaoItemOrigem ? itemSelecionado.descricaoItemOrigem : '--' }}
              </p>
            </div>
          </div>
          <div class="row form-group" style="background-color: lightblue">
            <div style="margin-left: 15px">
              <h4>Destino</h4>
            </div>
            <grid-mover-por-selecao
              [(viewRef)]="viewRef"
              [(itemSelecionado)]="itemSelecionado"
              [_exibeColunaPpbMultiplo]="_exibeColunaPpbMultiplo"
              (onFinalizar)="alterarPosicao($event)">
            </grid-mover-por-selecao>
          </div>
        </article>
      </div>
      <div class="modal-footer" style="padding: 10px 15px">
        <button (click)="fechar()" class="btn btn-sm btn-default">
          <i class="fa fa-times"></i> Fechar
        </button>
      </div>
    </div>
  </div>
</div>
<div
  #appModalMoverPorPosicaoBackground
  class="modal-backdrop fade in"
  style="display: none"
></div>
