<app-grid
  [(titulo)]="tituloGrid"
  [(page)]="page"
  [(size)]="size"
  [(total)]="total"
  [(parametros)]="parametros"
  (onChangeSize)="changeSize($event)"
  (onChangePage)="changePage($event)">

  <div class="table-responsive no-margin-bottom no-border">
    <table class="table table">

      <thead class="table-header-color">
        <tr>
          <th class="th-100 text-left" *ngIf="desabilitarInputs">
            Ações
          </th>
          <th class="th-sortable unset-nowrap text-left" *ngIf="desabilitarInputs">
            Nº Sequencial
          </th>
          <th class="th-sortable unset-nowrap text-left">
            Pontuação da Meta
          </th>
          <th class="th-sortable unset-nowrap text-left">
            Vigência
          </th>
          <th class="th-sortable unset-nowrap text-left">
            Documento
          </th>
          <th class="th-sortable unset-nowrap text-left">
            Descrição
          </th>
          <th *ngIf=" _isPorGrupo " class="th-sortable unset-nowrap text-left">
            Grupo
          </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of lista; let i = index;">
          <td
            *ngIf="desabilitarInputs"
            [ngStyle]="{'background-color': item.idPPB != idPPB ? '#ffff99' : ''  }">
              <a
                *ngIf="validacaoIsRetificacao(item) && item.habilitaBotoes && desabilitarInputs"
                (click)="validarSePermiteAbrirModal(item)"
                class="btn btn-default btn-sm"
                data-toggle="tooltip"
                title="Alterar"
                data-original-title="Alterar">
                  <i class="fa fa-pencil"></i>
              </a>
              <!--
                ######################################################
                    No Tipo de Publicação "Alteração para Revogação",
                    as linhas amarelas não deverão mostrar o botão de excluir.
                ######################################################
              -->
              <a
                *ngIf="validacaoIsRetificacao(item) && item.habilitaBotoes && item.exibeBotaoExclusao && desabilitarInputs"
                (click)="excluir(item)"
                class="btn btn-danger btn-sm"
                data-toggle="tooltip"
                title="Excluir"
                data-original-title="Excluir">
                  <i class="fa fa-trash-o"></i>
              </a>
          </td>
          <td class="text-center" [ngStyle]="{'background-color': item.idPPB != idPPB ? '#ffff99' : ''  }" *ngIf="desabilitarInputs">
            <a
              *ngIf="desabilitarInputs"
              (click)="abrirModalMoverPorSelecao(item)"
              data-toggle="tooltip"
              title="Mover por seleção"
              data-original-title="Mover por seleção"
              style="color: blue; cursor: pointer;">
                {{item.numeroSequencia}}
            </a>
            <span *ngIf="!desabilitarInputs"> {{item.numeroSequencia}} </span>
          </td>
          <td class="text-left" [ngStyle]="{'background-color': item.idPPB != idPPB ? '#ffff99' : ''  }">
            <!--  OS_10027 [texto]="(item.valorMeta | number:'':'es')" -->
            <app-taxa-texto
              [texto]="item.valorMeta ? formatter(item.valorMeta) : '0'"
              [(indicadorParaTaxarItem)]="item.indicadorParaTaxarItem">
            </app-taxa-texto>
          </td>
          <td class="text-left" [ngStyle]="{'background-color': item.idPPB != idPPB ? '#ffff99' : ''  }">
            <app-taxa-texto
              [(texto)]="item.descricaoDataVigencia"
              [(indicadorParaTaxarItem)]="item.indicadorParaTaxarItem">
            </app-taxa-texto>
          </td>
          <td class="text-left" [ngStyle]="{'background-color': item.idPPB != idPPB ? '#ffff99' : ''  }">
            <app-taxa-texto
              [(texto)]="item.ppb.descricaoTitulo"
              [(indicadorParaTaxarItem)]="item.indicadorParaTaxarItem">
            </app-taxa-texto>
          </td>
          <td class="text-left" [ngStyle]="{'background-color': item.idPPB != idPPB ? '#ffff99' : ''  }">
            <app-taxa-texto
              [(texto)]="item.descricaoMeta"
              [backgroundAmarelo]="item.idPPB != idPPB"
              [(indicadorParaTaxarItem)]="item.indicadorParaTaxarItem">
            </app-taxa-texto>
          </td>
          <!-- <td
            *ngIf=" _isPorGrupo "
            class="text-left"
            [ngStyle]="{'background-color': item.idPPB != idPPB ? '#ffff99' : ''  }"
          > -->
          <td *ngIf="!_isPorPontuacao" class="text-left"
            [ngStyle]="{'background-color': item.idPPB != idPPB ? '#ffff99' : ''  }">

            <app-taxa-texto
              [(texto)]="item.grupo.descricaGrupo "
              [(indicadorParaTaxarItem)]="item.indicadorParaTaxarItem">
            </app-taxa-texto>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</app-grid>

<app-modal-incluir-metas-ppb
  [(dataVigenciaInicialPPB)]="dataVigenciaInicialPPB"
  #appModalIncluirMetasPPB
  (atualizarListagem)="changeListagem()">
<app-modal-incluir-metas-ppb>
