<app-grid [(titulo)]="tituloGrid" [(page)]="page" [(size)]="size" [(total)]="total" [(parametros)]="parametros"
  (onChangeSize)="changeSize($event)" (onChangePage)="changePage($event)">

  <div class="table-responsive no-margin-bottom no-border">
    <table class="table">
      <thead class="table-header-color">
        <tr>
          <th class="th-100 text-left">Ações</th>
          <th class="th-sortable unset-nowrap text-left">
            <app-ordenacao field="StatusPPB" [sorted]="sorted"
              (onChangeSort)="changeSort($event)">Situação</app-ordenacao>
          </th>
          <th class="th-sortable unset-nowrap text-left">
            <app-ordenacao field="DescricaoTitulo" [sorted]="sorted"
              (onChangeSort)="changeSort($event)">Documento</app-ordenacao>
          </th>
          <th class="th-sortable unset-nowrap text-left">
            <app-ordenacao field="DataPublicacao" [sorted]="sorted"
              (onChangeSort)="changeSort($event)">Publicação</app-ordenacao>
          </th>
          <th class="th-sortable unset-nowrap text-left">
            <app-ordenacao field="DataInicioVigencia" [sorted]="sorted" (onChangeSort)="changeSort($event)">Início
              Vigência</app-ordenacao>
          </th>
          <th class="th-sortable unset-nowrap text-left">
            <app-ordenacao field="TipoEtapa" [sorted]="sorted" (onChangeSort)="changeSort($event)">Tipo Etapa</app-ordenacao>
          </th>
          <th class="th-sortable unset-nowrap text-left">
            <app-ordenacao field="TipoPublicacao" [sorted]="sorted" (onChangeSort)="changeSort($event)">Tipo de
              Vínculo</app-ordenacao>
          </th>
          <th class="th-sortable unset-nowrap text-left">
            Documento de Refêrencia
          </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of lista">
          <td *ngIf="!isUsuarioExterno" class="text-left">
            <a class="btn btn-default btn-sm" data-toggle="tooltip" (click)="abrirListagens(item)" title="Listagens"
              data-original-title="Listagens">
              <i class="fa fa-list-ul"></i>
            </a>
            <a (click)="buscarAnexo(item)" class="btn btn-default btn-sm" data-toggle="tooltip" title="Abrir PPB"
              data-original-title="Abrir PPB">
              <i class="i i-publish"></i>
            </a>
            <a *ngIf=" item.statusPPB == EmElaboracao || item.statusPPB == EmRetificacao "
              (click)="abrirEditar(item.id)" class="btn btn-default btn-sm" data-toggle="tooltip" title="Alterar"
              data-original-title="Alterar">
              <i class="fa fa-pencil"></i>
            </a>
            <a *ngIf=" item.statusPPB == EmElaboracao " (click)="excluir(item.id)" class="btn btn-danger btn-sm"
              data-toggle="tooltip" title="Excluir" data-original-title="Excluir">
              <i class="fa fa-trash-o"></i>
            </a>
            <a *ngIf=" item.statusPPB == EmElaboracao || item.statusPPB == EmRetificacao " (click)="validar(item.id)"
              title="Validar" data-original-title="Validar" data-toggle="tooltip" class="btn btn-default btn-sm">
              <i class="fa fa-check-square-o"></i>
            </a>
            <a (click)="abrirHistorico(item.id, item.descricaoTitulo)" class=" btn btn-default btn-sm "
              data-toggle="tooltip" title="Histórico" data-original-title="Histórico">
              <i class="i i-history"></i>
            </a>
            <a *ngIf=" item.statusPPB == Vigente || item.statusPPB == VigenciaFutura ||
                                    item.statusPPB == Revogado || item.statusPPB == EmElaboracao "
              (click)="buscarDadosExportacao(item.id)" title="Relatório" data-original-title="Relatório"
              data-toggle="tooltip" class="btn btn-default btn-sm">
              <i class="fa fa-file-text-o"></i>
            </a>

            <a *ngIf=" item.statusPPB == EmElaboracao || item.statusPPB == EmRetificacao "
              (click)="abrirFinalizar(item)" class="btn btn-default btn-sm" data-toggle="tooltip" title="Finalizar"
              data-original-title="Finalizar">
              <i class="i i-paperplane"></i>
            </a>
            <a *ngIf=" item.statusPPB == Vigente || item.statusPPB == VigenciaFutura || item.statusPPB == Revogado "
              (click)="abrirVinculos(item)" class="btn btn-default btn-sm" data-toggle="tooltip" title="Vínculos"
              data-original-title="Vínculos">
              <i class="fa fa-link "></i>
            </a>
            <a *ngIf=" item.statusPPB == Vigente || item.statusPPB == VigenciaFutura || item.statusPPB == Revogado "
              (click)="retificar(item.id)" class="btn btn-default btn-sm" data-toggle="tooltip" title="Retificar"
              data-original-title="Retificar">
              <i class="fa fa-files-o"></i>
            </a>
            <a *ngIf=" item.statusPPB == Vigente || item.statusPPB == VigenciaFutura "
              (click)="abrirModalProdutosVinculados(item)" class="btn btn-default btn-sm" data-toggle="tooltip"
              title="Vincular Produto" data-original-title="Vincular Produto">
              <i class="fa fa-compress"></i>
            </a>
          </td>
          <td *ngIf="isUsuarioExterno" class="text-left">
            <a class="btn btn-default btn-sm" data-toggle="tooltip" (click)="abrirListagens(item)" title="Listagens"
              data-original-title="Listagens">
              <i class="fa fa-list-ul"></i>
            </a>
            <a (click)="buscarAnexo(item)" class="btn btn-default btn-sm" data-toggle="tooltip" title="Abrir PPB"
              data-original-title="Abrir PPB">
              <i class="i i-publish"></i>
            </a>

            <a *ngIf=" item.statusPPB == Vigente || item.statusPPB == VigenciaFutura ||
                                    item.statusPPB == Revogado || item.statusPPB == EmElaboracao "
              (click)="buscarDadosExportacao(item.id)" title="Relatório" data-original-title="Relatório"
              data-toggle="tooltip" class="btn btn-default btn-sm">
              <i class="fa fa-file-text-o"></i>
            </a>

            <a *ngIf=" item.statusPPB == Vigente || item.statusPPB == VigenciaFutura || item.statusPPB == Revogado "
              (click)="abrirVinculos(item)" class="btn btn-default btn-sm" data-toggle="tooltip" title="Vínculos"
              data-original-title="Vínculos">
              <i class="fa fa-link "></i>
            </a>

            <a *ngIf=" item.statusPPB == Vigente || item.statusPPB == VigenciaFutura "
              (click)="abrirModalProdutosVinculados(item)" class="btn btn-default btn-sm" data-toggle="tooltip"
              title="Visualizar Produtos Vinculados" data-original-title="Visualizar Produtos Vinculados">
              <i class="fa fa-compress"></i>
            </a>
          </td>

					<td class="text-left">{{ item.descricaoStatus }}</td>
          <td class="text-left">{{ item.descricaoTitulo }}</td>
          <td class="text-left">{{ item.dataPublicacaoString }}</td>
          <td class="text-left">{{ item.dataInicioVigenciaString }}</td>
          <td class="text-left">{{ tipoEtapasDs[item.tipoEtapa]}}</td>
          <td class="text-left">{{ item.definirVinculo }}</td>
          <td class="text-left">
            <ul *ngIf=" item.objetoDescricaoDocsReferencia.length ">
              <li *ngFor="let item of item.objetoDescricaoDocsReferencia">
                {{item}}
              </li>
            </ul>
            <ul *ngIf=" !item.objetoDescricaoDocsReferencia.length ">
              <li>n/a</li>
            </ul>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</app-grid>

<app-modal-etapas-condicoes-ppb #appModalEtapasCondicoesPPB></app-modal-etapas-condicoes-ppb>
<app-modal-vinculo-de-produtos #appModalVinculoProdutos [(isVisualizar)]="isUsuarioExterno"
  (atualizarListagem)="changeListagem($event)"></app-modal-vinculo-de-produtos>
<app-modal-vinculos #appModalVinculos></app-modal-vinculos>
<app-modal-historico #appModalHistorico></app-modal-historico>
<app-modal-baixar-anexos #appModalBaixarAnexo></app-modal-baixar-anexos>

<app-relatorio-listagens-ppb
*ngIf="mostraComponenteRelatorio" #appRelatorioConsolidado
  [(_tituloPPB)]="_tituloPPB"
  [(_idPPBRota)]="_idPPBRota"
  [(_isSempontuacao)]="_isSempontuacao"
  [(_isPorPontuacao)]="_isPorPontuacao"
  [(_isPorGrupo)]="_isPorGrupo"
  [(_isPPBMultiplo)]="_isPPBMultiplo"
  [(arrayDocumentosVinculados)]="arrayDocumentosVinculados"
  [(arrayDocumentoPosteriores)]="_arrayDocumentoPosteriores"
  [(listaAnexos)]="listaAnexos"
  [(temArquivo)]="temArquivo"
  [(_dataDocumento)]="_dataDocumento"
  [(_dataPublicacao)]="_dataPublicacao"
  [(_dataVigenciaParams)]="_dataVigenciaParams"
  [(_dataVigencia)]="_dataVigencia"
  [(objetoExportacao)]="objetoExportacao"
  [(flagAtivarTxtDocReferencia)]="flagAtivarTxtDocReferencia"
  [(isDocPosterior)]="_isDocPosterior"
  (DownloadFinalizado)="BaixouPdf($event)">
</app-relatorio-listagens-ppb>
