import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable()
export class DataSharedService {
	item: any;
	private dataSource = new BehaviorSubject<any>(null/*this.item*/);
	data = this.dataSource.asObservable();

	constructor() {}

	updatedDataSelection(data: any) {
		this.dataSource.next(data);
	}
}
