import { Component, Injectable, OnInit, Pipe, ViewChild } from "@angular/core";
import { PagedItems } from "../../../view-model/PagedItems";
import { MessagesService } from "../../../shared/services/messages.service";
import { ActivatedRoute, Router } from '@angular/router';
import { ValidationService } from "../../../shared/services/validation.service";
import { ApplicationService } from "../../../shared/services/application.service";
import { ModalService } from '../../../shared/services/modal.service';
import { EnumRestResponse } from '../../../shared/enums/EnumRestResponse';
import { SolicitarCadastroDocumentoPPBVM } from '../../../view-model/SolicitarCadastroDocumentoPPBVM';
import { PPBVM } from '../../../view-model/PPBVM';
import moment = require("moment");
import * as $ from 'jquery';
import { NgLocalization } from "@angular/common";
import { ValidacaoMPPBVM } from "../../../view-model/ValidacaoMPPBVM";
import { ChangeEtapaPPBService } from '../../../shared/services/changeEtapaPPB.service';
import { ChangeCondicionantePPBService } from '../../../shared/services/changeCondicionantePPB.service';
import { ChangeGrupoPPBService } from '../../../shared/services/changeGrupoPPB.service';
import { isNull } from "@angular/compiler/src/output/output_ast";
import { isEmpty } from "rxjs/operators";


@Component({
	selector: "app-controle-solicitacoes-documento",
	templateUrl: "./controle-solicitacoes-documento-ppb.component.html"
})

@Injectable()
export class ControleSolicitacoesDocumentoComponent implements OnInit {


    habilitarInputs: boolean = true;
    parametros : SolicitarCadastroDocumentoPPBVM = new SolicitarCadastroDocumentoPPBVM();

    servicoCadastroDocumentoPPB = 'TipoDocumento';

    idDocPPB : number;
    path : string;

    tituloTelaPath : string 
    subtituloPath: string 

	constructor(
        private router: Router,
        private modal: ModalService,
        private applicationService: ApplicationService,
        private msg: MessagesService,
        private route: ActivatedRoute,
	) { 
	
		this.path = this.route.snapshot.url[this.route.snapshot.url.length - 1].path;
        if(this.path!="novo"){
            this.idDocPPB = Number(this.route.snapshot.params['idDoc']);
        }	
	}

	ngOnInit() { 
		if(this.path != 'novo'){
            this.tituloTelaPath = 'Alterar de Tipo de Documento' 
            this.subtituloPath = 'Incluir Alterações';
			this.buscar(this.idDocPPB);
		}else{
            this.tituloTelaPath = 'Cadastro de Tipo de Documento' 
            this.subtituloPath = 'Incluir Tipo de Documento';
        }	
	}
	
    voltar(){
		this.router.navigate(['/manter-documento-ppb']);
    }

    salvar(){    
        this.parametros.status = Number(this.parametros.status);
        this.parametros.posicaoPalavra = 0;
        if(this.path != 'novo'){
            this.applicationService.put(this.servicoCadastroDocumentoPPB, this.parametros).subscribe((success : boolean) =>{
                if (success) {
                    this.modal.sucesso(this.msg.OPERACAO_REALIZADA_COM_SUCESSO, "Success")
                        .subscribe(() => this.router.navigate(['manter-documento-ppb']));
                } else {
                    this.modal.infoError("Ocorreu um erro ao atualizar o registro.", "Erro");
                    return false;
                }
            });
        }else{
            this.parametros.status = 1;
            this.applicationService.post(this.servicoCadastroDocumentoPPB, this.parametros).subscribe((idPPb : number) =>{
                if (idPPb > 0) {
                    this.modal.sucesso(this.msg.OPERACAO_REALIZADA_COM_SUCESSO, "Success")
                        .subscribe(() => this.router.navigate(['manter-documento-ppb']));
                } else {
                    this.modal.infoError("Ocorreu um erro ao realizar a inclusão.", "Erro");
                    return false;
                }
            });
        }
       
    }



    validar(){
        if(!this.parametros.descricao == null || this.parametros.descricao.trim().length == 0){
			this.modal.informacao("Campo Obrigatório Não Informado: <b>Descrição</b>", "Atenção");
			return false;
		} 


        if(this.path != 'novo'){
            if(this.parametros.justificativa == null || this.parametros.justificativa.trim().length == 0){
                this.modal.informacao("Campo Obrigatório Não Informado: <b>Justificativa</b>", "Atenção");
                return false;
            }
        }

		this.salvar();
	}



  buscar(idDocPPB: number) {
    var objeto: SolicitarCadastroDocumentoPPBVM = new SolicitarCadastroDocumentoPPBVM();
    objeto.id = idDocPPB;

    this.applicationService.get(this.servicoCadastroDocumentoPPB+'/'+idDocPPB).subscribe((retorno: SolicitarCadastroDocumentoPPBVM) => {
      if (retorno) {

        this.parametros = retorno;
        this.parametros.status = String(this.parametros.status);
      }
    });
  }

}
