import { Component, Output, Input, OnInit, EventEmitter, ViewChild } from '@angular/core';
import { ValidationService } from '../../../../../shared/services/validation.service';


@Component({
	selector: 'app-grid-historico-listagens-ppb',
	templateUrl: './grid-historico-listagem.component.html'
})

export class GridHistoricoListagensPPBComponent {

	constructor(
		private validation: ValidationService,
	) { }

	@Input() lista: any = {};
	@Input() total: number;
	@Input() size: number;
	@Input() page: number;
	@Input() sorted: string;
  @Input() parametros: string;
	@Output() onChangeSort: EventEmitter<any> = new EventEmitter();
	@Output() onChangeSize: EventEmitter<any> = new EventEmitter();
	@Output() onChangePage: EventEmitter<any> = new EventEmitter();
	@Output() onChange: EventEmitter<any> = new EventEmitter();

	changeSize($event) {
		this.onChangeSize.emit($event);
	}

	changeSort($event) {
		this.sorted = $event.field;
		this.onChangeSort.emit($event);
	}

	changePage($event) {
		this.page = $event;
		this.onChangePage.emit($event);
	}
}
