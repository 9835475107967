import { DropDownVM } from './DropDownVM'

export class DropDownsModalListagemVM {
  etapas: Array<DropDownVM> = new Array<DropDownVM>();
  condicionante: Array<DropDownVM> = new Array<DropDownVM>();
  ppbMultiplo: Array<DropDownVM> = new Array<DropDownVM>();
  metas: Array<DropDownVM> = new Array<DropDownVM>();
  grupo: Array<DropDownVM> = new Array<DropDownVM>();
  idPpb: number;
  isSempontuacao: boolean;
  idPpbEscolhido: number;
  isPorPontuacao: boolean;
  isPorGrupo: boolean;
  isPPBMultiplo: boolean;
  existeItemVigente: boolean;
  tipoEtapa: number;
  idGrupoEscolhido: number;
  idMultiploEscolhido: number;
}
