export class VincularProdutoVM {
  idProduto : number;
  idPPB : number;
  idGrupo : number;
  idMultiplo?:number;
  descricaoGrupo : string = null;
  descricaoMultiplo:string = null;
  descricaoProduto : string;
  tituloPPB : string;
  fromBackEnd : boolean = false;
  flagFromFinalizacao : boolean = false;

  //flags usadas somente no front
  fromMigracao : boolean = false;
  idPpbFromMigracao
}
