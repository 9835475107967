import { GridExportVM } from './GridExportVM';

export class PagedItems extends GridExportVM{
    [x: string]: any;
	total!: number;
	page!: number;
	size!: number;
	filter: any;
	dacte: any;
	items!: any[]
	sort : { sort: {} };
}
