import { TipoDocumentoVM } from './../../../../../view-model/TipoDocumentoVM';
import { Component, Output, Input, OnInit, EventEmitter, ViewChild } from '@angular/core';
import { ApplicationService } from '../../../../../shared/services/application.service';
import { MessagesService } from '../../../../../shared/services/messages.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalService } from '../../../../../shared/services/modal.service';
import { CondicionanteVM } from '../../../../../view-model/CondicionanteVM';
import { EnumRestResponse } from '../../../../../shared/enums/EnumRestResponse';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { ValidationService } from '../../../../../shared/services/validation.service';
import { ItensPPBVM } from '../../../../../view-model/ItensPPBVM';
import { OrderGrid } from '../../../../../shared/services/orderGridByNumeroSequencia.service';
import { RequestResponseVM } from '../../../../../view-model/RequestResponseVM';
import { EnviaParametrosSelecaoItemVM } from '../../../../../view-model/EnviaParametrosSelecaoItemVM';

enum EnumControllPPB{
	OK = 1,
	ERR_DATA_VIGENCIA_VENCIDA = 2,
	ERR_EXISTE_COPIA_EM_ANDAMENTO = 3,
	ERR_ITEM_MARCADO_COMO_EXCLUIDO = 4
}

@Component({
	selector: 'app-grid-condicionantes-ppb',
	templateUrl: './grid.component.html'
})

export class GridCondicionantesPPBComponent {

	ocultarTituloGrid = true;
	path : string;
	flagHabilitaComponenteTextual : boolean = false;

	constructor(
		private validation : ValidationService,
		private route: ActivatedRoute,
		private modal : ModalService,
		private applicationService: ApplicationService,
		private msg: MessagesService,
		private router: Router,
		private ordenarGrid: OrderGrid
	) {
		this.path = this.route.snapshot.url[this.route.snapshot.url.length - 1].path;
		this._idPPBRota = Number(this.route.snapshot.params['idPpb']);
	}

	_idPPBRota : number;

	@Input() dataVigenciaInicialPPB : string;
	@Input() lista!: any[];
	@Input() total: number;
	@Input() size: number;
	@Input() page: number;
	@Input() sorted: string;
	@Input() habilitarInputs: boolean;
	@Input() exportarListagem!: boolean;
	@Input() isAlteracaoParaRevogacao : boolean;
	@Input() parametros: any = {};
	@Input() formPai: any;
	@Input() idPPB: any;
	@Input() isRetificacao : boolean;

	@Input() isUsuarioInterno: boolean = false;
	@Output() onChangeSort: EventEmitter<any> = new EventEmitter();
	@Output() onChangeSize: EventEmitter<any> = new EventEmitter();
	@Output() onChangePage: EventEmitter<any> = new EventEmitter();
	@Output() onChange: EventEmitter<any> = new EventEmitter();
	@Output() atualizaListagemGrid: EventEmitter<any> = new EventEmitter();

	servico = "CondicionantesPPB";
	tituloGrid = 'Condicionantes do PPB';

  @ViewChild ('appModalMoverPorPosicao') appModalMoverPorPosicao : any;
	@ViewChild('appModalIncluirCondicionantesPPB') appModalIncluirCondicionantesPPB : any;
  @ViewChild('appModalHistoricoListagemCondicionante') appModalHistoricoListagemCondicionante : any;

	config: AngularEditorConfig = {
		height: '700px',
		minHeight: '100px',
		placeholder: 'Enter text here...',
		showToolbar: false, //flag oculta barra de edição
		translate: 'no',
		defaultParagraphSeparator: 'p',
		defaultFontName: 'Arial',
		toolbarHiddenButtons: [
		  ['bold']
		],
		customClasses: [
		  {
			name: "quote",
			class: "quote",
		  },
		  {
			name: 'redText',
			class: 'redText'
		  },
		  {
			name: "titleText",
			class: "titleText",
			tag: "h1",
		  },
		]
	};

	changeSize($event : any) {
		this.onChangeSize.emit($event);
	}

	changeSort($event : any) {
		this.sorted = $event.field;
		this.onChangeSort.emit($event);
		this.changePage(this.page);
	}

	changePage($event : any) {
		this.page = $event;
		this.onChangePage.emit($event);
		console.log($event);
	}

	validarSePermiteAbrirModal(item : any){

		let serviceObject : ItensPPBVM = new ItensPPBVM();
		serviceObject.idItemCorrente = Number(item.id);
		serviceObject.idPPBCorrente = Number(this._idPPBRota);
		serviceObject.idPPBVinculo = Number(item.idPPB);

		this.applicationService.post(this.servico+"/ValidacaoAbrirModalEdicaoCondicionantes", serviceObject).subscribe((result : ItensPPBVM) => {

			if(result.statusControllModal == EnumControllPPB.OK){

				let obj : any = {};
				obj.idPPB = Number(this._idPPBRota);
				obj.id = result.idItemCorrente;
				this.atualizaListagem(obj.idPPB);
				this.abrirCondicionantes(obj);

			} else if(result.statusControllModal == EnumControllPPB.ERR_DATA_VIGENCIA_VENCIDA) {
				this.modal.informacao("Item está com a Data de Vigência Inferior a Data Corrente!", "Informação");
				return false;
			} else if(result.statusControllModal == EnumControllPPB.ERR_EXISTE_COPIA_EM_ANDAMENTO){
				this.modal.informacao("Existe uma cópia em Andamento! Apague a cópia para Executar essa Operação.", "Informação");
				return false;
			}
		});
	}

	excluir(item : any){
		this.modal.confirmacao("Deseja Excluir o Registro?").subscribe( (isConfirmado : any) => {
			if(isConfirmado) {
				this.apagarBackEnd(item);
			}
		});
	}

	apagarBackEnd(item: any){

		let serviceObject : ItensPPBVM = new ItensPPBVM();
		serviceObject.idItemCorrente = Number(item.id);
		serviceObject.idPPBCorrente = Number(this._idPPBRota);
		serviceObject.idPPBVinculo = Number(item.idPPB);

		this.applicationService.post(this.servico+"/PostApagarCondicionante", serviceObject).subscribe( (result : EnumControllPPB) => {
			if(result == EnumControllPPB.OK){
				this.modal.sucesso(this.msg.OPERACAO_REALIZADA_COM_SUCESSO, "Success").subscribe(() => {
					this.atualizaListagem(serviceObject.idPPBCorrente);
				});
			}
			else if(result == EnumControllPPB.ERR_DATA_VIGENCIA_VENCIDA) {
				this.modal.informacao("Item está com a Data de Vigência Inferior a Data Corrente!", "Informação");
				return false;
			} else if(result == EnumControllPPB.ERR_EXISTE_COPIA_EM_ANDAMENTO) {
				this.modal.informacao("Existe uma cópia em Andamento! Apague a cópia para Executar essa Operação.", "Informação");
				return false;
			}
			else if(result == EnumControllPPB.ERR_ITEM_MARCADO_COMO_EXCLUIDO) {
				this.modal.informacao("Item Ja Está Marcado Como excluído!", "Informação");
				return false;
			} else {
				this.modal.infoError("Falha ao Apagar Registro!", "Error");
				return false;
			}
		});
	}

	abrirCondicionantes(item : CondicionanteVM){
		this.appModalIncluirCondicionantesPPB.abrir(item.idPPB, "edit", item.id);
	}

	atualizaListagem($event){
		this.atualizaListagemGrid.emit($event);
	}

  ordenarItemGrid(item, tipoOperacao)
  {
    let objetoOrdenacao = new ItensPPBVM();
    objetoOrdenacao.idItemCorrente = item.id;
    objetoOrdenacao.idPPBCorrente = this._idPPBRota;
    objetoOrdenacao.tipoOperacao = tipoOperacao;
    this.applicationService.post("CondicionantesPPB/OrdenarCondicionante",objetoOrdenacao).subscribe((result:RequestResponseVM)=>{
        if(result.statusResponse==200)
        {
          this.atualizaListagem(this._idPPBRota);
        }
        else if(result.statusResponse == 300)
        {
          this.modal.informacao("Esse registro é o primeiro da lista", "Atenção");
        }
        else if(result.statusResponse == 403)
        {
          this.modal.informacao("Esse registro é o ultimo da lista", "Atenção");
        }
        else
        {
          this.modal.error(result.textResponse, "Error");
        }
    });
  }

	abrirModalMoverPorSelecao(item){
    let _obj : EnviaParametrosSelecaoItemVM = new EnviaParametrosSelecaoItemVM();
    _obj.tituloItemSelecionado = item.descricaTopico;
    _obj.descricaoItemOrigem = item.descricaCondicaoString;
    _obj.numeroSequencia = item.numeroSequencia;
    _obj.idItemOrigem = item.id;
    _obj.idPpb = Number(this.route.snapshot.params['idPpb']);
    const viewRef = "Condicionante"
    this.appModalMoverPorPosicao.abrir(_obj,viewRef);
  }

  validacaoIsRetificacao(item : any) : boolean{
    if(this.isRetificacao){
      if(item.idPPB == this.idPPB){
        return true
      }
      else{
        return false
      };
    }
    return true;
  }

  abrirHistorico(item: any){
    this.appModalHistoricoListagemCondicionante.abrir(item.descricaTopico, 'Histórico da Condicionante', item.idPPB, item.id);
  }

}
