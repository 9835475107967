import { Component, Inject, OnInit, Output, Input, ViewChild, ElementRef, EventEmitter } from '@angular/core';
import { ApplicationService } from "../../../../shared/services/application.service";
import { PagedItems } from '../../../../view-model/PagedItems';
import { PPBHistorico } from '../../../../view-model/PPBHistoricoVM';
import { PPBListagensHistoricoEntityVM } from '../../../../view-model/PPBListagensHistoricoEntityVM';

@Component({
	selector: 'app-modal-historico-exclusao',
	templateUrl: './modal-historico-exclusao.component.html'
})

export class ModalHistoricoExclusaoComponent implements OnInit {

	//----GRID ITENS-----
	@Input() lista: any = {};
	@Input() total: number;
	@Input() size: number;
	@Input() page: number;
	@Input() sorted: string;
	grid: any = { sort: {} };
	tituloGrid = "Histórico Exclusao MPPB";
	tituloPPB : string;
	parametros: PPBHistorico = new PPBHistorico();
	ativarDescricao : boolean = true;
	//--------------------

  exibirGridPpbCorrente : boolean = false;
  exibirGridListagens : boolean = false;

	//---------- Dados do Grid -------
	listaHistoricoPPB: Array<PPBHistorico>;
	idPPB: number;
	//------------


	//--------- Serviços ---------
	servicoHistoricoPpbCorrente = "HistoricoPPB";

	@Input() data: any = {} = null;
	@Output() changeOperation: EventEmitter<any> = new EventEmitter();

	@ViewChild('appModalHistoricoExclusao') appModalHistoricoExclusao : any;
	@ViewChild('appModalHistoricoExclusaoBackground') appModalHistoricoExclusaoBackground : any;

	@ViewChild('close') close!: ElementRef;
	@ViewChild('ok') ok!: ElementRef;

	constructor(
		private applicationService : ApplicationService
	) {}

	ngOnInit() {
		//this.parametros.exportarListagem = true;
	}

	onChangeSort($event: any) {
		this.grid.sort = $event;
		this.listarExclusaoPPB();
	}

	onChangeSize($event: any) {
		this.grid.size = $event;
		this.grid.page = 1;
		this.listarExclusaoPPB();
	}

	onChangePage($event: any) {
    this.grid.page = $event;
		this.listarExclusaoPPB();
	}

	abrirFromExclusaoPpb(){
    this.appModalHistoricoExclusao.nativeElement.style.display = 'block';
		this.appModalHistoricoExclusaoBackground.nativeElement.style.display = 'block';
		this.tituloPPB = "Histórico de Exclusão de PPBs";
		this.ativarDescricao = false;
		this.listarExclusaoPPB();
	}

	fechar(){
    this.exibirGridPpbCorrente = false;
    this.exibirGridListagens = false;
		this.tituloPPB = "";
		this.appModalHistoricoExclusao.nativeElement.style.display = 'none';
		this.appModalHistoricoExclusaoBackground.nativeElement.style.display = 'none';
	}

	listarExclusaoPPB(){
		var objeto = {
			page : this.grid.page ? this.grid.page : 1,
			size : this.grid.size ? this.grid.size : 10,
			sort : this.grid.sort.field,
			reverse : this.grid.sort.reverse,
			idPPB: null
		};
		this.applicationService.get(this.servicoHistoricoPpbCorrente+"/GetHistoricoExclusao", objeto).subscribe((retorno : PagedItems) => {
			if(retorno.items != null && retorno){
				this.grid.lista = retorno.items;
				this.grid.total = retorno.total;
				this.parametros.exportarListagem = true;
				this.parametros.exportarPdf = true;
				this.parametros.servico = this.servicoHistoricoPpbCorrente;
				this.tituloGrid = "Histórico de Exclusão Produto Padrão";
				this.prencheParametrosDeExportacao();
      }else{
        this.parametros.exportarListagem = false;
				this.grid.total = 0;
			}
    });
  }

	prencheParametrosDeExportacao(){
		this.parametros.exportarListagem = true;
		this.parametros.servico = this.servicoHistoricoPpbCorrente+"/GetHistoricoExclusao";
		this.parametros.titulo = "Histórico de Exclusão de PPBs"
		this.parametros.columns = [
									"Nome do PPB", "Usuário","Data e Hora"
								  ];

		this.parametros.fields = [
									"descricaoAcao", "nomeUsuario", "dataHoraAcaoString"
								 ];
		this.parametros.width = {
									0: { columnWidth: 420 }, 1: { columnWidth: 200 },
									2: { columnWidth: 150 }
								};
	}


}
