import { Component, OnInit, Input, Output, EventEmitter, Injectable } from '@angular/core';
import { ApplicationService } from '../../../../shared/services/application.service';
import * as html2pdf from 'html2pdf.js';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { DocumentosVinculadosPPBVM } from '../../../../view-model/DocumentosVinculadorPPBVM';
import { PPBAnexoVM } from '../../../../view-model/PPBAnexoVM';
import { RelatorioListagensPPBVM } from "../../../../view-model/RelatorioListagensPPBVM";
import { ModalService } from '../../../../shared/services/modal.service';
import * as $ from 'jquery';
import { registerLocaleData } from '@angular/common';
import es from '@angular/common/locales/es';
import { EnumStatusPPB } from '../../../../shared/enums/EnumStatusPPB';
import { EnumTipoEtapa } from '../../../../shared/enums/EnumTipoEtapa';
import { ValidacaoMPPBVM } from '../../../../view-model/ValidacaoMPPBVM';

@Component({
	selector: 'app-relatorio-listagens-ppb',
	templateUrl: './relatorio-listagens-ppb.component.html',
	styleUrls: ['./rel-style.css']
})

@Injectable()
export class RelatorioListagensPPBComponent implements OnInit {

	//status ppb
	EmElaboracao = 1;
  Vigente = 2;
  VigenciaFutura = 3;
  Revogado = 4;
  EmRetificacao = 5;

	arquivoRelatorio : any;
	hashPDF: any;
	linkSource: any;
	downloadLink: any;
	fileName: any;

  indicesEtapas : Array<number> = new Array<number>();
  indicesCondifionantes : Array<number> = new Array<number>();

	@Input()
		objetoExportacao: RelatorioListagensPPBVM = new RelatorioListagensPPBVM();

	@Input()
		_tituloPPB: string;

	@Input()
		listaTipoDocumento : any = {};

    @Input()
		_isSempontuacao : boolean;

	@Input()
		_isPorPontuacao : boolean;

	@Input()
		_isPorGrupo: boolean;

	@Input()
		_isPPBMultiplo: boolean;

	@Input()
		arrayDocumentoPosteriores : Array<DocumentosVinculadosPPBVM> = new Array<DocumentosVinculadosPPBVM>();

	@Input()
		arrayDocumentosVinculados: Array<DocumentosVinculadosPPBVM> = new Array<DocumentosVinculadosPPBVM>();

	@Input()
		listaAnexos = new Array<PPBAnexoVM>();

	@Input()
		temArquivo : boolean = false;

	@Input()
		_dataDocumento : string;

	@Input()
		_dataPublicacao : string;

	@Input()
		_dataVigenciaParams : string;

	@Input()
		_dataVigencia : string;

	@Input()
		flagAtivarTxtDocReferencia : boolean = false;

	@Input()
		_idPPBRota : number;

  @Input()
		isDocPosterior : number;

	@Output()
		DownloadFinalizado: EventEmitter<any> = new EventEmitter();

	terminouConsulta : boolean = true;
	AtivaRelatorio : boolean = true;
	habilitarInputs : boolean = false;

	config: AngularEditorConfig = {
		minHeight: '100px',
		width: '450px',
		placeholder: '',
		showToolbar: false,
		translate: 'no',
		defaultParagraphSeparator: 'p',
		defaultFontName: 'Arial',
		toolbarHiddenButtons: [
		  ['bold']
		],
		customClasses: [
		  {
			name: "quote",
			class: "quote",
		  },
		  {
			name: 'redText',
			class: 'redText'
		  },
		  {
			name: "titleText",
			class: "titleText",
			tag: "h1",
		  },
		]
	};
  objetoValidacao: ValidacaoMPPBVM = new ValidacaoMPPBVM();

  isAlteracaoParaRevogacao:boolean;
  isRetificacao:boolean;


  listaPontuacaoMinimaSomatorio : any = [];
  parametros2: any = {};
  listaDadosSomatorio = []
  optionsFormatNum = {
    prefix: '',
    suffix: '',
    thousands: '',
    decimal: ',',
    precision: 2
  };

  formatter(value){
    var test = (Math.round(value * 100) / 100).toFixed(2).toString().replace(".", ",");
    const thousant = test.replace(/\B(?=(\d{3})+(?!\d))/g, ".")

    if(thousant.substr(-2) == '00'){
      return thousant.slice(0, -3);
    }

    return thousant;
  }

	constructor (private modal : ModalService) { }

	ngOnInit () {
    registerLocaleData(es);
		this.AtivaRelatorio = false;
		setTimeout(() => {
      if(this.objetoExportacao.pontuacaoMinima){
        this.objetoExportacao.pontuacaoMinima.forEach((registro, indexGrupo) => {

          let qtdInputsSomatorio = registro.listaGrupo[0].qtdAnosUnicos

          for (let indexEtapa = 0; indexEtapa < qtdInputsSomatorio; indexEtapa++) {
            let dadosEtapa = registro.listaGrupo[0].listaEtapas[indexEtapa];
            let dataInicio = 'dataInicio-'+indexGrupo+"0"+indexEtapa;

            let dtInicio = dadosEtapa.dataVigenciaInicialSomatorioFormatado;
            if(dtInicio){
              dtInicio = this.formatDate(dtInicio)
            }else{
              dtInicio = "---"
            }
            this.parametros2[dataInicio]= dtInicio;

            let dataInicioFim = 'dataFim-'+indexGrupo+"0"+indexEtapa;

            let dtFim = dadosEtapa.dataVigenciaFinalSomatorioFormatado;
            if(dtFim){
              dtFim = this.formatDate(dtFim)
            }else{
              dtFim = "---"
            }

            this.parametros2[dataInicioFim]= dtFim;

            let valorSomaMinima = 'valorSomaMinima-'+indexGrupo+"0"+indexEtapa;

            this.parametros2[valorSomaMinima]= dadosEtapa.valorSomaMinima != null ? Number(dadosEtapa.valorSomaMinima) : null;

            this.listaDadosSomatorio.push(this.parametros2);

            let newPontuacaoMin = {
              id: dadosEtapa.idPontuacao,
              idGrupo: registro.idGrupo,
              idEtapa: registro.id,
              dataVigenciaInicialSomatorioFormatado : dadosEtapa.dataVigenciaInicialSomatorioFormatado,
              dataVigenciaFinalSomatorioFormatado : dadosEtapa.dataVigenciaFinalSomatorioFormatado,
              valorSomaMinima : dadosEtapa.valorSomaMinima != null ? Number(dadosEtapa.valorSomaMinima) : null
            }

            registro.listaGrupo[0].listaEtapas.forEach(element => {
              if(element.dataVigenciaInicialEtapaFormatado){
                element.dataVigenciaInicialEtapaFormatado = this.formatDate(element.dataVigenciaInicialEtapaFormatado)
              }
              if(element.dataVigenciaFinalEtapaFormatado){
                element.dataVigenciaFinalEtapaFormatado = this.formatDate(element.dataVigenciaFinalEtapaFormatado)
              }
            });

            this.listaPontuacaoMinimaSomatorio.push(newPontuacaoMin)

          }
       });
      }

			this.exportPDF();
		}, 1200);
	}
  formatDate(dateString) {
    if(dateString.includes("/")){
      return dateString;
    }
    return dateString.split("-").reverse().join("/");

  }
  formatNumber(number) {
    // Set default options if not provided
    const options = Object.assign({
      prefix: '',
      suffix: '',
      thousands: '.',
      decimal: ',',
      precision: '2'
    }, this.optionsFormatNum);

    if(!number){
      return "---"
    }


    // Convert number to string and split into integer and decimal parts
    var parts = number.toFixed(options.precision).split('.');

    // Format the integer part with thousands separator
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, options.thousands);

    // Join the integer and decimal parts with the decimal separator
    var formattedNumber = parts.join(options.decimal);

    // Add prefix and suffix
    formattedNumber = options.prefix + formattedNumber + options.suffix;

    return formattedNumber;
  }

  definirStatus(tipo: number): string {
    if (tipo == EnumStatusPPB.EM_ELABORACAO) {
      return "Em Elaboração";
    }

    if (tipo == EnumStatusPPB.REVOGADO) {
      return "Revogado";
    }

    if (tipo == EnumStatusPPB.VIGENCIA_FUTURA) {
      return "Vigencia Futura";
    }

    if (tipo == EnumStatusPPB.VIGENTE) {
      return "Vigente";
    }

    if (tipo == EnumStatusPPB.EM_RETIFICACAO) {
      return "Em Retificação";
    }
  }

	exportPDF(){

		new Promise<void>(resolve=>{
      setTimeout(() => {
        resolve();
     }, 2200);
		}).then(()=>{

			  const elements = document.getElementById('conteudoPdf').innerHTML;

        var win = window.open("#","_blank");

        if(!win || win.closed || typeof win.closed=='undefined')
        {
          this.modal.error("O relatório não pode ser automaticamente exibido, pois o bloqueador de pop-ups do navegador está ativo.", "Informação");
        }
        else
        {

          var layer = jQuery(win.document);

          win.document.write( '<html>' );
          win.document.write(   '<head>');
          win.document.write(      '<link rel="stylesheet" href="styles.css"' );
          win.document.write(      '<link rel="shortcut icon" href="./assets/images/suframa.ico">' );
          win.document.write(      '<link rel="stylesheet" href="assets/styles/main.css">' );
          win.document.write(   '</head>');
          win.document.write(   '<title> RELATORIO: '+ this._tituloPPB+'</title>');
          win.document.write(   '<body>');
          win.document.write(   elements  );
          win.document.write(   '</body>');
          win.document.write(  '</html>');

          var layer = jQuery(win.document);
        }

		}).then(()=>{
			setTimeout(() => {
				 this.AtivaRelatorio = true;
				 this.DownloadFinalizado.emit(false);
			}, 1200);
		});
	}

	salvarArquivoRelSemAssinatura(){
		new Promise(resolve=>{
			btoa(JSON.stringify(this.arquivoRelatorio.then(pdf=>{
				resolve(btoa(pdf));
			})));
		}).then((data)=>{

			this.linkSource = 'data:' + 'application/pdf' + ';base64,' + data;
			this.downloadLink = document.createElement('a');
			this.fileName = "RelatorioInclusaoInsumo";

			document.body.appendChild(this.downloadLink);

			this.downloadLink.href = this.linkSource;
			this.downloadLink.download = this.fileName;

			this.downloadLink.target = '_self';
			this.downloadLink.click();

			this.AtivaRelatorio = true;
			this.DownloadFinalizado.emit(false);
		});
	}

	mostraDataTitulo(data : string){
		return new Date(data).getUTCDate() +" DE "+ this.transformaMes(new Date(data).getUTCMonth()+1) +" DE "+ new Date(data).getFullYear()
	}

	transformaMes(mes: number): string{
		switch (mes) {
			case 1:

				return 'JANEIRO';

			case 2:
				return 'FEVEREIRO';

			case 3:
				return 'MARÇO';

			case 4:
				return 'ABRIL';

			case 5:
				return 'MAIO';

			case 6:
				return 'JUNHO';

			case 7:
				return 'JULHO';

			case 8:
				return 'AGOSTO';

			case 9:
				return 'SETEMBRO';

			case 10:
				return 'OUTUBRO';

			case 11:
				return 'NOVEMBRO';

			case 12:
				return 'DEZEMBRO';
		}
	}

	public quebrarLinhaCond(index){
		if ((index != 0 && index <= 2 && index % 2 === 0) || (index != 0 && index > 2 && index % 6 === 0)){
			return true;
		}else{
			return false;
		}
	}

	public quebrarLinhaEtapa(index){
		if (index != 0 && index % 8 === 0){
			return true;
		}else{
			return false;
		}
	}

	PdfNovaAba(str)
  {
		var objbuilder = '';
			  objbuilder += ('<object width="100%" height="100%" data="data:application/pdf;base64,');
			  objbuilder += (str);
			  objbuilder += ('" type="application/pdf" class="internal">');
			  objbuilder += ('<embed src="data:application/pdf;base64,');
			  objbuilder += (str);
		  	objbuilder += ('" type="application/pdf"  />');
			  objbuilder += ('</object>');

			  var win = window.open("#","_blank");

        if(!win || win.closed || typeof win.closed=='undefined')
        {
          this.modal.error("O relatório não pode ser automaticamente exibido, pois o bloqueador de pop-ups do navegador está ativo.", "Informação");
        } else{
          var title = "Visualização Relatório";
          win.document.write('<html><title>'+ title +'</title><body style="margin-top: 0px; margin-left: 0px; margin-right: 0px; margin-bottom: 0px;">');
          win.document.write(objbuilder);
          win.document.write('</body></html>');
          var layer = jQuery(win.document);
        }
	}

  appendChildEtapa(idDiv, conteudo)
  {
    if(this.indicesEtapas == null || this.indicesEtapas == undefined || this.indicesEtapas.length == 0){
      this.indicesEtapas.push(idDiv);
      $(".relatorio-etapa-"+idDiv).append(String(conteudo));
    } else{
      var existe = this.indicesEtapas.find(element => element == idDiv);
      if(existe != idDiv){
        this.indicesEtapas.push(idDiv);
        $(".relatorio-etapa-"+idDiv).append(String(conteudo));
      }
    }

     // const box = document.getElementById(idDiv);
     // box.removeAttribute('style');
  }

  getTipoEtapaDs(tipoEtapa:number){
    switch(tipoEtapa){
      case EnumTipoEtapa.SEM_PONTUACAO:
        return 'SEM PONTUAÇÃO';
      case EnumTipoEtapa.POR_GRUPO:
        return 'POR GRUPO COM PONTUAÇÃO';
      case EnumTipoEtapa.POR_PONTUACAO:
        return 'POR PONTUAÇÃO';
      case EnumTipoEtapa.PPB_MULTIPLO:
        return 'PPB MÚLTIPLO SEM PONTUAÇÃO';
      default:
        return 'n/a';
    }
  }

  isMultiplo(){
    if(this.objetoExportacao.informacoesPPB.ppbVM.tipoEtapa == EnumTipoEtapa.PPB_MULTIPLO){
      return true;
    }
    return false;
  }
  isGrupo(){
    if(this.objetoExportacao.informacoesPPB.ppbVM.tipoEtapa == EnumTipoEtapa.POR_GRUPO){
      return true;
    }
    return false;
  }

  exibirGridPontMinima(){
    if(this.objetoExportacao.informacoesPPB.ppbVM.tipoEtapa == EnumTipoEtapa.POR_GRUPO ||
      this.objetoExportacao.informacoesPPB.ppbVM.tipoEtapa == EnumTipoEtapa.POR_PONTUACAO){
        return true;
      }
      return false;
  }

  appendChildCondicionante(idDiv, conteudo)
  {
    if(this.indicesCondifionantes == null || this.indicesCondifionantes == undefined || this.indicesCondifionantes.length == 0){
      this.indicesCondifionantes.push(idDiv);
      $(".relatorio-condicionante-"+idDiv).append(String(conteudo));
    } else{
      var existe = this.indicesCondifionantes.find(element => element == idDiv);
      if(existe != idDiv){
        this.indicesCondifionantes.push(idDiv);
        $(".relatorio-condicionante-"+idDiv).append(String(conteudo));
      }
    }
  }

  obterRowspan(item){
    let value = item.listaGrupo[0].listaEtapas.length + 2;
    return value
  }
  obterDescricaoEtapasConcat(itemEtapa){
    return itemEtapa.descricaoEtapasConcat;
  }

  obterDescricaoEtapas(item){
    return item.descricaTopico;
  }

  obterRepeticaoInputs(item){
    let value = []
    let qtd = item.listaGrupo[0].qtdAnosUnicos

    for (let index = 0; index < qtd; index++) {
      value.push(index)
    }
    return value
  }

}
