import { Injectable } from '@angular/core';
import { ApplicationService } from './application.service';

@Injectable()
export class AuthenticationService {
	authenticated = false;
	isUsuarioInterno = false;

	constructor(private applicationService: ApplicationService) {
	}

	public IsUsuarioInterno(): boolean {
		return this.isUsuarioInterno;
	}

	public IsAuthenticated(): boolean {
		return this.authenticated;
	}
}
