export class MotivoDesoneracaoService {
	1 = 'Táxi';
	3 = 'Produtor Agropecuário';
	4 = 'Frotista/Locadora';
	5 = 'Diplomático / Consular';
	6 = 'Utilitários e Motocicletas da Amazônia Ocidental e Áreas de Livre Comércio(Resolução 714 / 88 e 790 / 94 = CONTRAN e suas alterações)';
	7 = 'SUFRAMA';
	8 = 'Venda a Órgão Público';
	9 = 'Outros. (NT 2011 / 004)';
	10 = 'Deficiente Condutor(Convênio ICMS 38 / 12)';
	11 = 'Deficiente Não Condutor(Convênio ICMS 38 / 12)';
}
