<app-grid [(titulo)]="tituloGrid" [(page)]="page" [(size)]="size" [(total)]="total" [(parametros)]="parametros"
  (onChangeSize)="changeSize($event)" (onChangePage)="changePage($event)">

  <div class="table-responsive no-margin-bottom no-border">
    <table class="table table">
      <thead class="table-header-color">
        <tr>
          <th class="th-100 text-left" *ngIf="habilitarInputs">
            Ações
          </th>
          <th class="th-sortable unset-nowrap text-left" *ngIf="habilitarInputs">
            Nº Sequencial
          </th>
          <th class="th-sortable unset-nowrap text-left">
            Grupo
          </th>
          <th class="th-sortable unset-nowrap text-left">
            Vigência
          </th>
          <th class="th-sortable unset-nowrap text-left">
            Documento
          </th>
          <th class="th-sortable unset-nowrap text-left">
            Produto
          </th>
        </tr>
      </thead>
      <tbody>

        <tr *ngFor="let item of lista; let j = index">
          <td
            *ngIf="habilitarInputs"
            class="text-left"
            [ngStyle]="{'background-color': item.idPPB != idPPB ? '#ffff99' : ''  }">
            <a
              *ngIf="validacaoIsRetificacao(item) && item.habilitaBotoes && habilitarInputs"
              (click)="validarSePermiteAbrirModal(item)"
              class="btn btn-default btn-sm"
              data-toggle="tooltip"
              title="Alterar"
              data-original-title="Alterar">
                <i class="fa fa-pencil"></i>
            </a>
              <!--
                ######################################################
                    No Tipo de Publicação "Alteração para Revogação",
                    as linhas amarelas não deverão mostrar o botão de excluir.
                ######################################################
              -->
            <a
              *ngIf="validacaoIsRetificacao(item) && item.habilitaBotoes && item.exibeBotaoExclusao && habilitarInputs"
              (click)="excluir(item)"
              class="btn btn-danger btn-sm"
              data-toggle="tooltip"
              title="Excluir"
              data-original-title="Excluir">
                <i class="fa fa-trash-o"></i>
            </a>
          </td>
          <td class="text-center" [ngStyle]="{'background-color': item.idPPB != idPPB ? '#ffff99' : ''  }" *ngIf="habilitarInputs">
            <a
              *ngIf="habilitarInputs"
              (click)="abrirModalMoverPorSelecao(item)"
              data-toggle="tooltip"
              title="Mover por seleção"
              data-original-title="Mover por seleção"
              style="color: blue; cursor: pointer;">
                {{item.numeroSequencia}}
            </a>
            <span *ngIf="!habilitarInputs"> {{item.numeroSequencia}} </span>
          </td>
          <td class="text-left" [ngStyle]="{'background-color': item.idPPB != idPPB ? '#ffff99' : ''  }">
            <p style="white-space: pre-line;">
              <app-taxa-texto
                [(texto)]="item.descricaGrupo"
                [backgroundAmarelo]="item.idPPB != idPPB"
                [(indicadorParaTaxarItem)]="item.indicadorParaTaxarItem">
              </app-taxa-texto>
            </p>
          </td>
          <td class="text-left" [ngStyle]="{'background-color': item.idPPB != idPPB ? '#ffff99' : ''  }">
            <app-taxa-texto [(texto)]="item.dataInicioVigenciaString"
              [(indicadorParaTaxarItem)]="item.indicadorParaTaxarItem">
            </app-taxa-texto>
          </td>
          <td class="text-left" [ngStyle]="{'background-color': item.idPPB != idPPB ? '#ffff99' : ''  }">
            <app-taxa-texto [(texto)]="item.descricaoDocumento"
              [(indicadorParaTaxarItem)]="item.indicadorParaTaxarItem">
            </app-taxa-texto>
          </td>
          <td class="text-left" [ngStyle]="{'background-color': item.idPPB != idPPB ? '#ffff99' : ''  }">
            <app-taxa-texto [(texto)]="item.descricaProdutos" [(indicadorParaTaxarItem)]="item.indicadorParaTaxarItem">
            </app-taxa-texto>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</app-grid>

<app-modal-incluir-grupos-ppb [(dataVigenciaInicialPPB)]="dataVigenciaInicialPPB" #appModalIncluirGruposPPB
  (atualizaListagem)="atualizaListagem($event)" [(idPPB)]="idPPB">
</app-modal-incluir-grupos-ppb>

<app-modal-mover-por-selecao
  [_servicoDropDown]="'OrdenacaoGrupos'"
  [_servicoParaSalvar]="'GrupoPPB'"
  (atualizaListagemGrid)="atualizaListagem($event)"
  #appModalMoverPorPosicao>
</app-modal-mover-por-selecao>
