<app-grid [(titulo)]="tituloGrid" [(page)]="page" [(size)]="size" [(total)]="total" [(parametros)]="parametros"
  (onChangeSize)="changeSize($event)" (onChangePage)="changePage($event)">

  <div class="table-responsive no-margin-bottom no-border">
    <table class="table">
      <thead class="table-header-color">
        <tr>
          <th *ngIf="mostrarDocumento" class="th-100 text-left">
            <app-ordenacao field="Documento" [sorted]="sorted" (onChangeSort)="changeSort($event)">
              Documento
            </app-ordenacao>
          </th>
          <th class="th-100 text-left">
            <app-ordenacao field="NumeroCodigo" [sorted]="sorted" (onChangeSort)="changeSort($event)">
              Código
            </app-ordenacao>
          </th>
          <th class="th-sortable unset-nowrap text-left">
            <app-ordenacao field="DescricaoProduto" [sorted]="sorted" (onChangeSort)="changeSort($event)">
              Descrição Produto
            </app-ordenacao>
          </th>
          <th class="th-sortable unset-nowrap text-left">
            <app-ordenacao field="DescricaoMultiplo" [sorted]="sorted" (onChangeSort)="changeSort($event)" *ngIf="lista && lista[0].exibirColunaMultiplo">
              Múltiplo
            </app-ordenacao>
          </th>
          <th class="th-sortable unset-nowrap text-left">
            <app-ordenacao field="DescricaoGrupo" [sorted]="sorted" (onChangeSort)="changeSort($event)" *ngIf="lista && lista[0].exibirColunaGrupo">
              Grupo
            </app-ordenacao>
          </th>
        </tr>
      </thead>
      <tbody *ngFor=" let item of lista ; let i = index ; " [attr.data-index]="i">
        <tr>
          <td class="text-left" *ngIf="mostrarDocumento"> {{item.documento }}</td>
          <td class="text-left"> {{ item.numeroCodigo }} </td>
          <td class="text-left"> {{ item.descricaoProduto }} </td>
          <td class="text-left" *ngIf="item.exibirColunaMultiplo"> {{ item.descricaoMultiplo }} </td>
          <td class="text-left" *ngIf="item.exibirColunaGrupo"> {{ item.descricaoGrupo }} </td>
        </tr>
      </tbody>
    </table>
  </div>
</app-grid>
