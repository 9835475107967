import { RequestResponseVM } from '../../../../../../view-model/RequestResponseVM';
import { Component, Output, Input, OnInit, EventEmitter, ViewChild, AfterViewInit, AfterViewChecked } from '@angular/core';
import { ApplicationService } from '../../../../../../shared/services/application.service';
import { ValidationService } from '../../../../../../shared/services/validation.service';
import { MessagesService } from '../../../../../../shared/services/messages.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalService } from '../../../../../../shared/services/modal.service';
import { PPBGrupoVM } from "../../../../../../view-model/PPBGrupoVM";
import { EnumRestResponse } from '../../../../../../shared/enums/EnumRestResponse';
import { CronogramaPontuacaoPPBComponent } from '../../cronograma-pontuacao-ppb.component'
import { ChangeEtapaPPBService } from '../../../../../../shared/services/changeEtapaPPB.service';
import { ItensPPBVM } from '../../../../../../view-model/ItensPPBVM';
import { OrderGrid } from '../../../../../../shared/services/orderGridByNumeroSequencia.service';
import { EnviaParametrosSelecaoItemVM } from '../../../../../../view-model/EnviaParametrosSelecaoItemVM';
import { ServiceTipoListagemVM } from '../../../../../../view-model/ServiceTipoListagemVM';

enum EnumControllPPB{
	OK = 1,
	ERR_DATA_VIGENCIA_VENCIDA = 2,
	ERR_EXISTE_COPIA_EM_ANDAMENTO = 3,
	ERR_ITEM_MARCADO_COMO_EXCLUIDO = 4
}

@Component({
  selector: 'app-grid-cronograma-ppb-sem-grupo',
  templateUrl: './grid-cronograma-sem-grupo.component.html'
})
export class GridCronogramaSemGrupoComponent implements OnInit {
  listaChavesVigenciaSomatorio : any = [];
  listaChavesColunaVigencia = [];
  listaChavesColunaValorSomatorio = [];
	ocultarTituloGrid = true;
	path : string;
  ocultarPaginacaoCabecalho = true;
  listaPontuacaoMinimaSomatorio : any = [];
  parametros2: any = {};
  listaDadosSomatorio = []

	constructor(
		private changeEtapaPPBService : ChangeEtapaPPBService,
		private gruposPPBComponent : CronogramaPontuacaoPPBComponent,
		private modal : ModalService,
		private validation : ValidationService,
		private route: ActivatedRoute,
		private applicationService: ApplicationService,
		private msg: MessagesService,
		private router: Router,
		private ordenarGrid: OrderGrid
	) {
		this.path = this.route.snapshot.url[this.route.snapshot.url.length - 1].path;
		this._idPPBRota = Number(this.route.snapshot.params['idPpb']);
	 }

	@Input()
		lista: any = [];

	@Input()
		total: number;

	@Input()
		size: number;

	@Input()
		page: number;

	@Input()
		sorted: string;

	@Input()
		habilitarInputs: boolean;

	@Input()
		exportarListagem: boolean;

	@Input()
		parametros: any = {};

	@Input()
		formPai: any;

	@Input()
		idPPB: any;

	@Input()
		dataVigenciaInicialPPB : string;

	@Input()
		isAlteracaoParaRevogacao : boolean;

		@Input() isRetificacao : boolean;


    @Input()
    isVisualizar:boolean;


	EXCLUIDO : number = 4;
	_idPPBRota : number;
  @Input() _isPorPontuacao: boolean;
	@Output() onChangeSort: EventEmitter<any> = new EventEmitter();
	@Output() onChangeSize: EventEmitter<any> = new EventEmitter();
	@Output() onChangePage: EventEmitter<any> = new EventEmitter();
	@Output() onChange: EventEmitter<any> = new EventEmitter();
	@Output() atualizaListagemGrid: EventEmitter<any> = new EventEmitter();

	servico = "GrupoPPB";
	tituloGrid = 'Cronograma Anual - Pontuação Mínima';

	@ViewChild('appModalIncluirGruposPPB') appModalIncluirGruposPPB : any;
	@ViewChild ('appModalMoverPorPosicao') appModalMoverPorPosicao : any;
  @ViewChild ('appModalHistoricoListagemGrupos') appModalHistoricoListagemGrupos : any;
  @ViewChild('appModalIncluirCronograma2') appModalIncluirCronograma2 : any;
	changeSize($event : any) {
		this.onChangeSize.emit($event);
	}

	changeSort($event : any) {
		this.sorted = $event.field;
		this.onChangeSort.emit($event);
		this.changePage(this.page);
	}

	changePage($event : any) {
		this.page = $event;
		this.onChangePage.emit($event);
	}

	atualizaListagem($event){
		this.atualizaListagemGrid.emit($event);
	}

  // carregarDadosSomatorio(): void {
  ngOnInit(): void {

    if(this.lista != null && this.lista != undefined && this.lista != 0) {
      this.lista.forEach((registro, indexGrupo) => {

        let qtdInputsSomatorio = registro.listaGrupo[0].qtdAnosUnicos

        for (let indexEtapa = 0; indexEtapa < qtdInputsSomatorio; indexEtapa++) {
          let dadosEtapa = registro.listaGrupo[0].listaEtapas[indexEtapa];
          let dataInicio = 'dataInicio-'+indexGrupo+"0"+indexEtapa;

          this.parametros2[dataInicio]= dadosEtapa.dataVigenciaInicialSomatorioFormatado;

          let dataInicioFim = 'dataFim-'+indexGrupo+"0"+indexEtapa;

          this.parametros2[dataInicioFim]= dadosEtapa.dataVigenciaFinalSomatorioFormatado;

          let valorSomaMinima = 'valorSomaMinima-'+indexGrupo+"0"+indexEtapa;

          this.parametros2[valorSomaMinima]= dadosEtapa.valorSomaMinima != null ? Number(dadosEtapa.valorSomaMinima) : null;

          this.listaDadosSomatorio.push(this.parametros2);

          let newPontuacaoMin = {
            id: dadosEtapa.idPontuacao,
            idGrupo: registro.idGrupo,
            idEtapa: registro.id,
            dataVigenciaInicialSomatorioFormatado : dadosEtapa.dataVigenciaInicialSomatorioFormatado,
            dataVigenciaFinalSomatorioFormatado : dadosEtapa.dataVigenciaFinalSomatorioFormatado,
            valorSomaMinima : dadosEtapa.valorSomaMinima != null ? Number(dadosEtapa.valorSomaMinima) : null
          }
          this.listaPontuacaoMinimaSomatorio.push(newPontuacaoMin)

        }
     });
    }
  }

	formatDate(date : Date){
		return this.validation.getDateWhithChangeFormat('DD/MM/YYYY', date);
	}

	excluir(item : PPBGrupoVM){
		this.modal.confirmacao("Deseja Excluir o Registro?").subscribe( (isConfirmado : any) => {
			if(isConfirmado) {
				this.apagarBackEnd(item);
			}
		});
	}


	validarSePermiteAbrirModal(item : PPBGrupoVM){

		let serviceObject : ItensPPBVM = new ItensPPBVM();
		serviceObject.idItemCorrente = Number(item.id);
		serviceObject.idPPBCorrente = Number(this._idPPBRota);
		serviceObject.idPPBVinculo = Number(item.idPPB);

		this.applicationService.post(this.servico+"/PostValidacaoModalEdicao", serviceObject).subscribe((result : ItensPPBVM) => {
			if(result.statusControllModal == EnumControllPPB.OK){
				let obj : PPBGrupoVM = new PPBGrupoVM()
				obj.idPPB = Number(this._idPPBRota);
				obj.id = result.idItemCorrente;
				this.atualizaListagens(obj.idPPB);
				this.abrirModal(obj);
			} else if(result.statusControllModal == EnumControllPPB.ERR_DATA_VIGENCIA_VENCIDA) {
				this.modal.informacao("Item está com a Data de Vigência Inferior a Data Corrente!", "Informação").subscribe( () => false);
			} else if(result.statusControllModal == EnumControllPPB.ERR_EXISTE_COPIA_EM_ANDAMENTO){
				this.modal.informacao("Existe uma cópia em Andamento! Apague a cópia para Executar essa Operação.", "Informação").subscribe( () => false);
			}
		});
	}

	abrirModal(item : PPBGrupoVM){
		this.appModalIncluirGruposPPB.abrir(item.idPPB, "editar", item.id);
	}


	apagarBackEnd(item: PPBGrupoVM){

		let serviceObject : ItensPPBVM = new ItensPPBVM();
		serviceObject.idItemCorrente = Number(item.id);
		serviceObject.idPPBCorrente = Number(this._idPPBRota);
		serviceObject.idPPBVinculo = Number(item.idPPB);

		this.applicationService.post(this.servico+"/PostApagarGrupo", serviceObject).subscribe( (result : EnumControllPPB) => {
			if(result == EnumControllPPB.OK){
				this.modal.sucesso(this.msg.OPERACAO_REALIZADA_COM_SUCESSO, "Success").subscribe(() => {
					this.atualizaListagens(serviceObject.idPPBCorrente);
				});
			} else if(result == EnumControllPPB.ERR_EXISTE_COPIA_EM_ANDAMENTO){
				this.modal.informacao("Existe uma cópia em Andamento! Apague a cópia para Executar essa Operação.", "Informação").subscribe( () => false);
			}
			else if(result == EnumControllPPB.ERR_ITEM_MARCADO_COMO_EXCLUIDO){
				this.modal.informacao("Item Ja Está Marcado Como excluído!", "Informação").subscribe( () => false);
			} else {
				this.modal.infoError("Falha ao Apagar Registro!", "Error").subscribe( () => false);
			}
		});
	}

  incluirPontuacaoMinima(rota : string, item: any){
    let idGrupo = item.idGrupo;
    if(!this._isPorPontuacao){
      let idGrupoString = idGrupo.toString()
      this.appModalIncluirCronograma2.abrir(this.idPPB, rota, idGrupoString, this.formPai);
    }else{
      this.appModalIncluirCronograma2.abrir(this.idPPB, rota, null, this.formPai);
    }

    this.lista;
	}

  validarSalvarPontuacaoMinima(item : any, indexListaGrupo){

    let listaRegistroSomatorio = this.listaPontuacaoMinimaSomatorio.filter(q => q.idEtapa == item.id);

    if (listaRegistroSomatorio.length > 0) {

      let falhas = []

      listaRegistroSomatorio.forEach(objPontuacao => {
        let falhaDataInicial = false;
        let falhaValorPontuacao = false;
        let falhaDataFinal = false;
        let falhaDataFinal2 = false;

        if(objPontuacao != null || objPontuacao != undefined){

          if (
            !((objPontuacao.dataVigenciaInicialSomatorioFormatado == null
            || objPontuacao.dataVigenciaInicialSomatorioFormatado == undefined
            || objPontuacao.dataVigenciaInicialSomatorioFormatado == ''
            )
            &&
            (
              objPontuacao.valorSomaMinima == null || objPontuacao.valorSomaMinima == undefined || objPontuacao.valorSomaMinima == 0
            )
            &&
            (
              objPontuacao.dataVigenciaFinalSomatorioFormatado == null
            || objPontuacao.dataVigenciaFinalSomatorioFormatado == undefined
            || objPontuacao.dataVigenciaFinalSomatorioFormatado == ''
            )
            )
            ){
              if(objPontuacao.dataVigenciaInicialSomatorioFormatado == null
                || objPontuacao.dataVigenciaInicialSomatorioFormatado == undefined
                || objPontuacao.dataVigenciaInicialSomatorioFormatado == ''){
                  falhaDataInicial = true;
              }
              if(objPontuacao.valorSomaMinima == null || objPontuacao.valorSomaMinima == undefined || objPontuacao.valorSomaMinima == 0){
                falhaValorPontuacao = true;
              }

              if(objPontuacao.dataVigenciaFinalSomatorioFormatado != null
                && objPontuacao.dataVigenciaFinalSomatorioFormatado != undefined
                && objPontuacao.dataVigenciaFinalSomatorioFormatado != ''
                ){
                const dateFinal = new Date(objPontuacao.dataVigenciaFinalSomatorioFormatado);
                let tt = isNaN(dateFinal.getTime());
                if(!isNaN(dateFinal.getTime())){
                  const dateInicial = new Date(objPontuacao.dataVigenciaInicialSomatorioFormatado == '' ? new Date(1,1,1): objPontuacao.dataVigenciaInicialSomatorioFormatado);
                  if(dateFinal <= dateInicial)
                  {
                    falhaDataFinal = true;
                  }
                }
              }
            }

            falhas.push({
              falhaDataInicial: falhaDataInicial,
              falhaValorPontuacao: falhaValorPontuacao,
              falhaDataFinal: falhaDataFinal,
            });
        }
      });

      let falhasDataInicial =  falhas.filter(q=> q.falhaDataInicial == true);
      let falhasDataFinal=  falhas.filter(q=> q.falhaDataFinal == true);
      let falhasValorPontuacao =  falhas.filter(q=> q.falhaValorPontuacao == true);

      if (falhasDataInicial.length > 0)
        return this.modal.error("Preencha o Período Inicial corretamente","Atenção")

      if (falhasValorPontuacao.length > 0)
      return this.modal.error("Preencha o Valor Pontuação Minima corretamente","Atenção")

      if (falhasDataFinal.length > 0)
      return this.modal.error("O Período Final deve ser maior que o Periodo Inicial","Atenção")

      this.salvarPontuacaoMinima(listaRegistroSomatorio);
    }

  }
  salvarPontuacaoMinima(listaParaSalvar : any){
    this.applicationService.post("PontuacaoMinima/SalvarPontuacaoMinimaSomatorio", {listaPPBPontuacaoMinima : listaParaSalvar}).subscribe((retorno : any) => {
      if(retorno == 1 ){
        this.modal.infoSucess('Registros atualizados com Sucesso!', 'Sucesso');
      }
    });
  }

  obterRowspan(item){
    let value = item.listaGrupo[0].listaEtapas.length + 2;
    return value
  }

  obterRepeticaoInputs(item){
    let value = []
    let qtd = item.listaGrupo[0].qtdAnosUnicos

    for (let index = 0; index < qtd; index++) {
      value.push(index)
    }
    return value
  }
  changeInputData(item : any, identity){

    let valueInput = (<HTMLInputElement>document.getElementById(identity)).value;

    let indiceEtapaListagem = Number(identity.substring(identity.length-1,identity.length));

    let idPontuacao = item.listaGrupo[0].listaEtapas[indiceEtapaListagem].idPontuacao

    let selectPontuacaoMin = this.listaPontuacaoMinimaSomatorio.find(x => x.id == idPontuacao);

    if(selectPontuacaoMin != null && selectPontuacaoMin != undefined){
      this.listaPontuacaoMinimaSomatorio.forEach(pontuacaoMinima => {
        if(pontuacaoMinima.id == selectPontuacaoMin.id){

          if (identity.includes('dataInicio')){
            pontuacaoMinima.dataVigenciaInicialSomatorioFormatado = valueInput != '' ?  valueInput : null;
          }else if (identity.includes('dataFim')){
            pontuacaoMinima.dataVigenciaFinalSomatorioFormatado = valueInput != '' ?  valueInput : null;
          }else{
            valueInput = valueInput.replace('.','');
            valueInput = valueInput.replace(',','.');
            pontuacaoMinima.valorSomaMinima = Number(valueInput);
          }
        }
      });
    }else{
      let newPontuacaoMin = {
        id: idPontuacao,
        idGrupo: item.idGrupo,
        idEtapa: item.id,
        dataVigenciaInicialSomatorioFormatado : identity.includes('dataInicio') ? valueInput != ''
                                                                                        ?  valueInput
                                                                                        : null
                                                                                : null,
        dataVigenciaFinalSomatorioFormatado : identity.includes('dataFim') ? valueInput != ''
                                                                                    ?  valueInput
                                                                                    : null
                                                                            : null,
        valorSomaMinima : identity.includes('valorSomaMinima') ? Number(valueInput) : null
      }
      this.listaPontuacaoMinimaSomatorio.push(newPontuacaoMin)
    }

  }

  obterValorInicial(i,j,num, id){
    let el = document.getElementById('dataInicio-'+i+j+num);

  }
  obterValorFinal(i,j,num, id){
    let el = document.getElementById('dataInicio-'+i+j+num);
  }

	atualizaListagens(idPPB){
		this.gruposPPBComponent.listar(idPPB);

    const obj = new ServiceTipoListagemVM();
    obj.idPpb = Number(idPPB);
    obj.getFromSessionStorage = true;
    this.changeEtapaPPBService.atualizaListagemPPB(obj);
	}

  buscarDescricaoGrupo(item){
    return item.listaGrupo[0].descricaGrupo;
  }
  obterDescricaoEtapasConcat(itemEtapa){
    return itemEtapa.descricaoEtapasConcat;
  }

  obterDescricaoEtapas(item){
    return item.descricaTopico;
  }

  ordenarItemGrid(item, tipoOperacao)
  {
    let objetoOrdenacao = new ItensPPBVM();
    objetoOrdenacao.idItemCorrente = item.id;
    objetoOrdenacao.idPPBCorrente = this._idPPBRota;
    objetoOrdenacao.tipoOperacao = tipoOperacao;
    this.applicationService.post("GrupoPPB/OrdenarGrupo",objetoOrdenacao).subscribe((result:RequestResponseVM)=>{
        if(result.statusResponse==200)
        {
          this.atualizaListagem(this._idPPBRota);
        }
        else if(result.statusResponse == 300)
        {
          this.modal.informacao("Esse registro é o primeiro da lista");
        }
        else if(result.statusResponse == 403)
        {
          this.modal.informacao("Esse registro é o ultimo da lista");
        }
        else
        {
          this.modal.error(result.textResponse, "Error");
        }
    });
  }

  validacaoIsRetificacao(item : any) : boolean{
	if(this.isRetificacao){
		if(item.idPPB == this.idPPB){
			return true
		}
		else{
			return false
		};
	}
	return true;
  }

  abrirModalMoverPorSelecao(item){
    let _obj : EnviaParametrosSelecaoItemVM = new EnviaParametrosSelecaoItemVM();
    _obj.tituloItemSelecionado = item.descricaGrupo;
    _obj.descricaoItemOrigem = item.descricaProdutos;
    _obj.numeroSequencia = item.numeroSequencia;
    _obj.idItemOrigem = item.id;
    _obj.idPpb = Number(this.route.snapshot.params['idPpb']);
    const viewRef = "Grupo"
    this.appModalMoverPorPosicao.abrir(_obj,viewRef);
  }

  abrirHistorico(item: any){
    this.appModalHistoricoListagemGrupos.abrir(item.descricaGrupo, 'Histórico do Grupo', item.idPPB, item.id);
  }

}
