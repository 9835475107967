import { Component, Injectable, OnInit, ViewChild, Input } from "@angular/core";
import { PagedItems } from "../../../../view-model/PagedItems";
import { MessagesService } from "../../../../shared/services/messages.service";
import { ActivatedRoute, Router } from '@angular/router';
import { ValidationService } from "../../../../shared/services/validation.service";
import { ApplicationService } from "../../../../shared/services/application.service";
import { Location } from '@angular/common';
import { PPBGrupoVM } from "../../../../view-model/PPBGrupoVM";
import { ChangeGrupoPPBService } from '../../../../shared/services/changeGrupoPPB.service';
import { ServiceTipoListagemVM } from "../../../../view-model/ServiceTipoListagemVM";
import { FiltrosModalListagemVM } from "../../../../view-model/FiltrosModalListagemVM";

@Component({
	selector: "app-grupos-ppb",
	templateUrl: "./grupos-ppb.component.html"
})
@Injectable()

export class GruposPPBComponent implements OnInit {

	form = this;
	grid: any = { sort: {} };
	parametros: PPBGrupoVM = new PPBGrupoVM();
	ocultarFiltro: boolean = false;
	ocultarGrid: boolean = true;
	path: string;
	servico = "GrupoPPB";
	isBuscaSalva : boolean = false;
	objetoSession : any = {} = null;

	@Input() habilitarInputs: boolean;
	@Input() idPPB: number;
	@Input() validacao : any = {} = null;
	@Input() dataVigenciaInicialPPB : string;
	@Input() isAlteracaoParaRevogacao : boolean;
	@Input() buscarVinculos: boolean;
	@Input() _fromTelaElaboracao : boolean;
	@Input() isRetificacao : boolean;
  @Input() exibeRevogados:boolean;
  flagIsSomenteVigente: boolean = false;
  @Input() objConsultaFiltradaModalListagem: FiltrosModalListagemVM;
  @Input() isConsultaFiltradaModalListagem: boolean = false;
  @ViewChild('appModalIncluirGruposPPB') appModalIncluirGruposPPB : any;

	constructor(
    private ChangeGrupoPPBService: ChangeGrupoPPBService,
    private route: ActivatedRoute,
    private applicationService: ApplicationService,
    private msg: MessagesService,
    private validation: ValidationService,
    private router: Router,
    private _location: Location
	) {
		this.path = this.route.snapshot.url[this.route.snapshot.url.length - 1].path;
	}

	ngOnInit() {
		this.listar(this.idPPB);

		this.ChangeGrupoPPBService.AtualizaListagemGrupoPPB.subscribe(
      (obj: ServiceTipoListagemVM) => {
        if(obj != null){
          //console.log('to aqui - grupos');
          this.flagIsSomenteVigente = obj.isSomenteVigente;
          this.listar(obj.idPpb, obj.getFromSessionStorage);
        }
      }
		);

	 }

	ocultar() { }

	onChangeSort($event : any) {
		this.grid.sort = $event;
		this.listar(this.idPPB);
	}

	onChangeSize($event : any) {
		this.grid.size = $event;
    this.grid.page = 1;
		this.listar(this.idPPB);
	}

	onChangePage($event : any) {
		this.grid.page = $event;
		this.listar(this.idPPB);
  }

	incluirGrupoPPB(){
		this.appModalIncluirGruposPPB.abrir(this.idPPB, "new");
	}

	public listar(idPPB : Number, getConsultaFromSessionStorage? : boolean){

		var objeto : PPBGrupoVM = new PPBGrupoVM();
		objeto.idPPB = idPPB;
		objeto.fromGridPorGrupo = true;

		if(getConsultaFromSessionStorage)
		{
			this.retornaValorSessao();
			if(this.isBuscaSalva){
				objeto.page = this.objetoSession.page || 1;
				objeto.size = this.objetoSession.size || 10;
				objeto.sort = this.objetoSession.sort || "NumeroSequencia";
				objeto.reverse = this.objetoSession.reverse || false;
			} else {
				objeto.page = 1;
				objeto.size = 10;
			}
		}
		else
		{
			objeto.page = this.grid.page || 1;
			objeto.size = this.grid.size || 10;
			objeto.sort = this.grid.sort.field || "NumeroSequencia";
			objeto.reverse = this.grid.sort.reverse || false;
		}
		objeto.buscarVinculos = this.buscarVinculos;
		objeto.fromTelaElaboracao = this._fromTelaElaboracao;
    objeto.isSomenteVigente = this.flagIsSomenteVigente;
    objeto.objetoConsultaFiltrada = this.objConsultaFiltradaModalListagem;
    objeto.isConsultaFiltradaModalListagem = this.isConsultaFiltradaModalListagem;
    objeto.exibirRevogados = this.exibeRevogados;

		this.applicationService.get(this.servico+"/GetPaginado", objeto).subscribe((retorno : PagedItems) => {
			if(retorno.total!= null && retorno.total > 0){
				this.grid.lista = retorno.items;
				this.grid.total = retorno.total;
				this.gravarBusca(objeto);
			} else {
				this.grid = { sort: {} };
			}
		});
	}

	atualizaListagem(idPPB){
		this.listar(idPPB, true);
	}

	gravarBusca(objeto : any) {
		sessionStorage.removeItem(this.router.url);
		sessionStorage.setItem("grupo-ppb-"+this.idPPB , JSON.stringify(objeto));
	}

	retornaValorSessao() {
		var router = sessionStorage.getItem("grupo-ppb-"+this.idPPB);
		if (router) {
			this.isBuscaSalva = true;
			this.objetoSession = JSON.parse(sessionStorage.getItem("grupo-ppb-"+this.idPPB));
		} else {
			this.isBuscaSalva = false;
			return null;
		}
	}

}
