<div class="row wrapper" style="padding: 10px 15px;">
	<div class="col-lg-6"></div>
    <div class="col-lg-6 text-right">
        <app-resultado-por-paginas 
            [setPage]="setPage"
            [size]="size" 
            (onChangeSize)="changeSize($event)">
        </app-resultado-por-paginas>
    </div>
</div>
