import { Injectable, Output, EventEmitter } from '@angular/core';
import { ServiceTipoListagemVM } from '../../view-model/ServiceTipoListagemVM';

@Injectable()
export class ChangePpbMultiploService {

  AtualizaListagemPpbMultiplo : EventEmitter<ServiceTipoListagemVM> = new EventEmitter()

	constructor() {
	}

	public atualizaListagemPPB(obj: ServiceTipoListagemVM) {
		this.AtualizaListagemPpbMultiplo.emit(obj);
	}

    //ASSISTA https://www.youtube.com/watch?v=R9afVKty3Dg&ab_channel=LoianeGroner

}
