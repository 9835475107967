import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

@Injectable()
export class SharedDataService {
	dataChangeObserver: any;
	dataChange: Observable<any>;

	constructor() {
		this.dataChange = new Observable((observer: any) => {
			this.dataChangeObserver = observer;
		});
	}

	setData(data: any) {
		this.dataChangeObserver.next(data);
	}
}
