import { SolicitarCadastroPPBVM } from "./SolicitarCadastroPPBVM";
import { GrupoPPBVM } from "./GrupoPPBVM";
import { ListagemEtapaPPBVM } from "./ListagemEtapaPPBVM";
import { MetaVM } from './MetaVM';
import { CondicionanteVM } from "./CondicionanteVM";
import { ViewMccpProdutoEntityVM } from './ViewMccpProdutoEntityVM';
import { PPBMultiploVM } from './PPBMultiploVM';
import { DocumentosVinculadosPPBVM } from "./DocumentosVinculadorPPBVM";

export class RelatorioListagensPPBVM {
    tituloPPB : string;
    informacoesPPB : SolicitarCadastroPPBVM = new SolicitarCadastroPPBVM();
    ppbMultiplo : Array<PPBMultiploVM> = new Array<PPBMultiploVM>();
    grupoPPB : Array<GrupoPPBVM> = new Array<GrupoPPBVM>();
    etapaPPB : ListagemEtapaPPBVM = new ListagemEtapaPPBVM();
    metasPPB : Array<MetaVM> = new Array<MetaVM>();
    condicionantePPB : Array<CondicionanteVM> = new Array<CondicionanteVM>();
    produtosVinculadoPPB : Array<ViewMccpProdutoEntityVM> = new Array<ViewMccpProdutoEntityVM>();
    documentosPosteriores: Array<DocumentosVinculadosPPBVM> = new Array<DocumentosVinculadosPPBVM>();
    pontuacaoMinima:any;
}
