import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface AlertaModel {
	title: string;
	message: string;
}

@Component({
	selector: 'app-modal',
	templateUrl: './modal-informacao.component.html',
})

export class ModalInformacaoComponent implements OnInit {

	constructor(public dialogRef : MatDialogRef<ModalInformacaoComponent>,
				@Inject(MAT_DIALOG_DATA) public data : AlertaModel) { }

	ngOnInit() {
		setTimeout(() => {
			this.confirm();
		}, 3500);
	}

	confirm() {
		this.dialogRef.close(true);
	}

	/*
	 onClose(){
    	this.dialogRef.close();
  	}
	*/
}
