<app-grid 
    [(titulo)]="tituloGrid"
    [(page)]="page"
    [(size)]="size"
    [(total)]="total"
    [(parametros)]="parametros"
    (onChangeSize)="changeSize($event)"
    (onChangePage)="changePage($event)"
    [(isHidePaginacao)]="desabilitarPaginacaoECabecalho"
    >

	<div class="table-responsive no-margin-bottom no-border">
		<table class="table">
			<thead class="table-header-color">
				<tr>
					<th class="th-sortable unset-nowrap text-left">
						<app-ordenacao 
                            field="PPBAtual.DescricaoTitulo" 
                            [sorted]="sorted" 
                            (onChangeSort)="changeSort($event)">
                                Documento
                        </app-ordenacao>
					</th>					
					<th class="th-sortable unset-nowrap text-left" style="width: 45px;">
						<app-ordenacao 
                            field="TipoPublicacao" 
                            [sorted]="sorted" 
                            (onChangeSort)="changeSort($event)">
                                Tipo de Publicação
                        </app-ordenacao>
					</th>
                    <th class="th-sortable unset-nowrap text-left" style="width: 45px;">
						<app-ordenacao 
                            field="StatusPPBAtual" 
                            [sorted]="sorted" 
                            (onChangeSort)="changeSort($event)">
                                Situação
                        </app-ordenacao>
					</th>                    
				</tr>
			</thead>
			<tbody>				
				<tr *ngFor="let item of lista">
					<td class="text-left">{{ item.ppb.descricaoTitulo }}</td>
                    <td class="text-left">{{ item.descricaoTipoPPB  }}</td>
                    <td class="text-left">{{ item.descricaoStatusPPB }}</td>
				</tr>
			</tbody>
		</table>
	</div>
</app-grid>