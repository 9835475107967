import { Component, OnInit } from '@angular/core';
import { environment } from '../../../environments/environment';

@Component({
	selector: 'app-index',
	templateUrl: './index.component.html'
})
export class IndexComponent implements OnInit {

	_exibirNotificacoes : boolean = false;
	_urlServico : any;
    _identificadorModuloPRJ : any;
	jsonMinhasNotificacoes : any = {};

	constructor() {}

	ngOnInit() {
		// this._urlServico = environment.prjGeral;
		// this._identificadorModuloPRJ = environment.identificadorModuloPRJ;
		// this._exibirNotificacoes = true;
	}

	// recebeNotificacoes($event){
	// 	this.jsonMinhasNotificacoes = $event;
	// }

}
