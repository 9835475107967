/* IMPORTAÇÃO DE MODULOS */
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { DropListSelect2Component } from '../../shared/components/drop-list-2/drop-list-2.component';
import { SharedModule } from '../../shared/shared.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

/* COMPONENTES PPB HISTORIA */
import { ManterDocumentoPPBComponent } from './manter-documento-do-ppb.component';
import { ControleSolicitacoesDocumentoComponent } from './solicitacoes-documento-ppb/controle-solicitacoes-documento-ppb.component'; 
import { GridManterDocumentoPPBComponent } from './grid/grid.component';
import { ModalHistoricoTipoDocumentoComponent } from './modais/modal-historico/historico-tipo-documento.component';

@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		RouterModule,
		SharedModule,
		AngularEditorModule,
		NgbModule
	],
	declarations: [
		ManterDocumentoPPBComponent,
		ControleSolicitacoesDocumentoComponent,
		GridManterDocumentoPPBComponent,
		ModalHistoricoTipoDocumentoComponent
	],
})
export class ManterDocumentoPPBModule { }
