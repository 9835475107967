import { Component, Injectable, OnInit, ViewChild, Output, EventEmitter, Input } from "@angular/core";
import { MessagesService } from "../../../../shared/services/messages.service";
import { ValidationService } from "../../../../shared/services/validation.service";
import { ApplicationService } from "../../../../shared/services/application.service";
import { ActivatedRoute } from "@angular/router";
import { ModalService } from '../../../../shared/services/modal.service';
import { EnviaParametrosSelecaoItemVM } from "../../../../view-model/EnviaParametrosSelecaoItemVM";
import { RequestResponseVM } from "../../../../view-model/RequestResponseVM";
import { EtapaVM } from "../../../../view-model/EtapaVM";
import { AngularEditorConfig } from "@kolkov/angular-editor";

@Component({
  selector: 'app-modal-mover-por-selecao',
  templateUrl: './mover-por-selecao.component.html'
})
export class ModalMoverPorSelecao implements OnInit {

  idPpbCorrente : number = 0;
  exibirComponente : boolean = false;
  exibirDescricao : boolean = false;
  itemSelecionado : EnviaParametrosSelecaoItemVM = new EnviaParametrosSelecaoItemVM();
  viewRef = ""
  config: AngularEditorConfig = {
		height: '200px',
		minHeight: '100px',
		placeholder: 'Enter text here...',
		showToolbar: false, //flag oculta barra de edição
		translate: 'no',
		defaultParagraphSeparator: 'p',
		defaultFontName: 'Arial',
		toolbarHiddenButtons: [
		  ['bold']
		],
		customClasses: [
		  {
			name: "quote",
			class: "quote",
		  },
		  {
			name: 'redText',
			class: 'redText'
		  },
		  {
			name: "titleText",
			class: "titleText",
			tag: "h1",
		  },
		]
	};

  @Input() _servicoDropDown : string = "";
  @Input() _servicoParaSalvar : string = "";
  @Input() _exibeColunaPpbMultiplo : boolean = false;
	@Output() atualizaListagemGrid: EventEmitter<any> = new EventEmitter();

  @ViewChild('appModalMoverPorPosicao') appModalMoverPorPosicao: any;
  @ViewChild('appModalMoverPorPosicaoBackground') appModalMoverPorPosicaoBackground: any;

  constructor(
    private modal: ModalService,
    private applicationService: ApplicationService,
    private msg: MessagesService,
    private validation: ValidationService,
    private route: ActivatedRoute
  ) {
    this.idPpbCorrente = Number(this.route.snapshot.params['idPpb']);
  }

  ngOnInit() {}

  public abrir(item: EnviaParametrosSelecaoItemVM,viewRef="")
  {
    this.itemSelecionado = item;
    this.exibirComponente = true;
    this.changeDisplayModal('block');
    this.viewRef = viewRef;
  }

  public fechar()
  {
    this.itemSelecionado = new EnviaParametrosSelecaoItemVM();
    this.exibirComponente = false;
    this.exibirDescricao = false;
    this.viewRef = "";
    this.changeDisplayModal('none');
  }

  public changeDisplayModal(cssProperty : string)
  {
    this.appModalMoverPorPosicaoBackground.nativeElement.style.display = cssProperty;
    this.appModalMoverPorPosicao.nativeElement.style.display = cssProperty;
  }

  alterarPosicao(idParamDest)
  {
    this.itemSelecionado.idItemDestino = Number(idParamDest);
    this.applicationService.post(this._servicoParaSalvar+"/OrdenarPorSelecao", this.itemSelecionado).subscribe((result : RequestResponseVM) => {
      if(result.statusResponse == 200)
      {
        this.fechar();
        this.modal.infoSucess("Operação realizada com Sucesso!", "Sucesso").subscribe((result:any)=>{
          this.atualizaListagemGrid.emit(this.idPpbCorrente);
        })
      }
      else
      {
        this.modal.error(result.textResponse, "Atenção");
        return false;
      }
    });
  }

}
