<div #appModalEditarCronograma aria-hidden="false"  class="modal modal-wide-2 fade in" role="dialog"
     style="display: auto; overflow: scroll;"
     tabindex="-1">
    <div class="modal-dialog modal-fullscreen-sm-down">
        <div class="modal-content">
            <div class="modal-header bg-primary" style="padding: 10px 15px; color: white;">
                <h4 class="modal-title" id="editar_cronograma">{{titulo}} Cronograma - Pontuação Mínima</h4>
            </div>

            <div class="modal-body" style="overflow-y: auto;">
              <div>
                <input type="text" class="form-control"
                       [(ngModel)]="parametros.descricaoEtapa" />
              </div>
                <div class="row"  style="margin-left: 15px; margin-top: 5px;">

                    <div class="col-lg-2">
                        <label>2023</label>
                        <input type="text" class="form-control"
                               maxlength="11"
                               currencyMask
                               min="0" max="999.999.999,999"
                               [(ngModel)]="parametros.ano2023"
                               [options]="{ prefix: '', suffix: '', thousands: '.', decimal: ',', precision: '2'}"
                        />
                    </div>
                    <div class="col-lg-2">
                        <label>2024</label>
                        <input type="text" class="form-control"
                               maxlength="11"
                               currencyMask
                               min="0" max="999.999.999,999"
                               [(ngModel)]="parametros.ano2024"
                               [options]="{ prefix: '', suffix: '', thousands: '.', decimal: ',', precision: '2'}"
                        />
                    </div>
                    <div class="col-lg-2">
                        <label>2025</label>
                        <input type="text" class="form-control"
                               maxlength="11"
                               currencyMask
                               min="0" max="999.999.999,999"
                               [(ngModel)]="parametros.ano2025"
                               [options]="{ prefix: '', suffix: '', thousands: '.', decimal: ',', precision: '2'}"
                        />
                    </div>
                    <div class="col-lg-2">
                        <label>2026</label>
                        <input type="text" class="form-control"
                               maxlength="11"
                               currencyMask
                               min="0" max="999.999.999,999"
                               [(ngModel)]="parametros.ano2026"
                               [options]="{ prefix: '', suffix: '', thousands: '.', decimal: ',', precision: '2'}"
                        />
                    </div>
                    <div class="col-lg-3">
                        <label>2027 em diante</label>
                        <input type="text" class="form-control"
                               maxlength="11"
                               currencyMask
                               min="0" max="999.999.999,999"
                               [(ngModel)]="parametros.ano2027"
                               [options]="{ prefix: '', suffix: '', thousands: '.', decimal: ',', precision: '2'}"
                               name="codigo" id="codigo_2027"/>
                    </div>
                </div>
                <div class="modal-footer" style="padding: 8px 15px;">
                    <button
                            (click)="fechar()"
                            id="fechar-modal-etapa"
                            name="fechar-modal-etapa"
                            class="btn btn-sm btn-default"
                            data-dismiss="modal">
                        <i class="fa fa-times"></i> Fechar
                    </button>
                    <button
                            color="primary" type="button" class="btn btn-primary"
                            (click)="salvar()"
                            ng-reflect-type="button"
                            ng-reflect-dialog-result="true"><i class="fa fa-save" style="color: white;"></i> Salvar
                    </button>
                </div>

            </div>


        </div>


    </div>
</div>
<div #appModalEditarCronogramaBackground class="modal-backdrop fade in" style="display: none;"></div>
