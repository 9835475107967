import { Component, Output, Input, OnInit, EventEmitter, ViewChild } from '@angular/core';
import { ApplicationService } from '../../../../../shared/services/application.service';
import { MessagesService } from '../../../../../shared/services/messages.service';
import { ModalService } from '../../../../../shared/services/modal.service';
import { Router } from '@angular/router';
import { ValidationService } from '../../../../../shared/services/validation.service';
import { PagedItems } from '../../../../../view-model/PagedItems';

@Component({
	selector: 'app-grid-doc-referencia-ppb',
	templateUrl: './grid-doc-referencia.component.html'
})

export class GridDocReferenciaComponent implements OnInit{

	ocultarTituloGrid = true;
	desabilitarPaginacaoECabecalho = true;

    @Input() idPPB: number;

    lista: Array<any>;
	total: number;
	size: number;
	page: number;
	sorted: any = {};
	isUsuarioInterno: boolean = false;
	exportarListagem: boolean;
	parametros: any = {};
	formPai: any;
	somenteLeitura: boolean;


	tituloGrid = "Documentos de Referência"
	servico = "Validar";
	servicoFinalizar = "FinalizarPPB";

	constructor(
		private applicationService: ApplicationService,
		private modal: ModalService,
		private msg: MessagesService,
		private router: Router,
		private validation: ValidationService
	) {
        
	}

    ngOnInit(): void {
        this.buscar();
    }  

	changeSize($event) {
		this.sorted  = $event;
        this.buscar();
	}

	changeSort($event) {
		this.sorted = $event;
		this.changePage(this.page);
	}

	changePage($event) {
		this.page = $event;
        this.buscar();
	}		

    buscar(){

        var objeto = {
            page : this.page ? this.page : 1,
			size : this.size ? this.size : 10,
			sort : this.sorted.field,
			reverse : this.sorted.reverse,
			idPPB: this.idPPB
        }

        this.applicationService.get("ModalVinculos/ListarPaginado",objeto).subscribe((result: PagedItems) => {
            if(result != null && result.total > 0){
                this.lista = result.items;
                this.total = result.total;
            }else{
                this.lista = new Array<any>();
                this.total = 0;
            }
        });
    }
}
