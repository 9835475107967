import { Component, Input, OnInit, ElementRef, OnChanges, ViewChild, Output, EventEmitter } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { ArrayService } from '../../services/array.service';
import { ApplicationService } from '../../services/application.service';

@Component({
	selector: 'app-drop-list',
	templateUrl: './drop-list.component.html',
	providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: DropListComponent, multi: true }]
})

export class DropListComponent implements ControlValueAccessor, OnInit, OnChanges {

	@Input() isDisabled = false;
	@Input() isRequired = false;
	@Input() lazy = false;
	@Input() loadWhenParameterEmpty!: boolean;
	@Input() parametro: any;
	@Input() parametroOpcional: any;
	@Input() parametroChave!: string;
	@Input() parametroChaveOpcional!: string;
	@Input() placeholder: string;
	@Input() servico!: string;
	@Input() idSelected : any;
	@Input() idChamadaServico : any;
	@Input() descricao!: string;
	@Input() exibirPlaceholder: boolean;
	@Input() notifyAfterSelect: boolean;
	@Output() itemSelecionado: EventEmitter<any> = new EventEmitter();

	@ViewChild('select') select : any;

	list: any = new Array<any>();
	listAutoComplete: any = new Array<any>();
	model: any;
	required: any;

	constructor(
		private applicationService: ApplicationService,
		private arrayService: ArrayService,
		private elm: ElementRef,
	) {
		this.placeholder = 'Selecione uma opção';
	}

	ngOnInit() {
		this.required = this.elm.nativeElement.attributes['required'];
		//// Se for especificado parametro chave
		//if (!this.parametroChave || !this.parametroChaveOpcional) {
		//	this.loadWhenParameterEmpty = true; // carregar quando a propriedade parametro estiver vazia
		//	this.lazy = false; // ativa automaticamente o serviço de carregar dados
		//} else {
		//	this.loadWhenParameterEmpty = false; // não carrega os ítens quando a propriedade parametro estiver vazia
		//	this.lazy = true; // mantem o componente preguiçoso até a propriedade parametro ser modificada ou o métodos listItems ser acionado
		//}

		//if (!this.lazy) {
		this.listItems();
		//}
	}

	getCount() {
		return this.list ? this.list.length : 0;
	}

	getNameValue(id?: number): string {
		if (id) {
			return this.list.find((s : any) => s.id == id).text;
		}
		else {
			return this.list.find((s : any) => s.id == this.getSelectedValue()).text;
		}
	}

	getIsDisable() {
		return this.isDisabled || !this.list || this.list.length == 0;
	}

	reset() {
		this.list = null;
		this.listAutoComplete = null;
		this.model = '';
	}

	listItemsObservable() {
		return Observable.create((observer : any) => {
			this.callService(this.urlService(), observer);
		});
	}

	urlService() {
		let url = `${this.servico}DropDown/`;

		if (this.parametroChave && this.parametroChaveOpcional && this.parametro && this.parametroOpcional) {
			url = url + '?' + this.parametroChave + '=' + this.parametro + '&' + this.parametroChaveOpcional + '=' + this.parametroOpcional;
		} else if (this.parametroChave && this.parametro) {
			url = url + this.parametro + '/' + this.parametroOpcional;
		}
		else if (!this.parametroChave && this.parametro) {
			url = url + this.parametro;
		}

		this.idChamadaServico ? url = url + String(this.idChamadaServico)  : '';

		return url;
	}

	callService(url : any, observer? : any) {
		return this.applicationService.get(url).subscribe(result => {
			this.list = result;
			this.listAutoComplete = this.list;

			// Selecionar o primeiro ítem quando retornar somente 1 linha
			//if (this.list.length == 1) {
			//	this.model = this.list[0].id;
			//	this.onChange(this.list[0].id);
			//}

			this.idSelected ? this.model  = String(this.idSelected) : '';


			if (observer) {
				observer.next(result);
				observer.complete();
			}
		});
	}

	listItems() {
		return this.callService(this.urlService());
	}

	removeItem(item: string) {
		this.arrayService.remove(this.list, 'id', item);

		if (this.list.length == 1) {
			this.model = this.list[0].id;
			this.onChange(this.list[0].id);
		}
	}

	addItem(item: any) {
		this.arrayService.add(this.list, 'id', item, false);
	}

	getAllItems() {
		return this.list;
	}

	getSelectedKey() { return this.model; }

	getSelectedItem() {
		const empty = { value: this.getSelectedKey() };

		if (!this.list) { return empty; }

		const item = this.list.filter((x : any) => x.id == this.getSelectedKey());

		if (!item) { return empty; }

		return item[0];
	}

	getSelectedValue() {
		if (this.getSelectedItem()) {
			return this.getSelectedItem().value;
		}
	}

	writeValue(obj: any): void {
    if (!obj) { obj = ''; }
      this.model = obj;
      this.itemSelecionado.emit(this.model);

	}

	ngOnChanges(changes: any) {
		if (changes.parametro) {
			if (!changes.parametro.firstChange) {
				if (changes.parametro.currentValue || this.loadWhenParameterEmpty) {
					this.listItems();
				}
			}
		}
		if (changes.parametroOpcional) {
			if (!changes.parametroOpcional.firstChange) {
				if (changes.parametroOpcional.currentValue || this.loadWhenParameterEmpty) {
					this.listItems();
				}
			}
		}
	}

	onChange(value : any) {
		this.writeValue(value);
	}

  onChange2(value : any) {
    if (this.notifyAfterSelect){
      this.writeValue(value);
    }
	}

	registerOnChange(fn: any): void { this.onChange = fn; }

	registerOnTouched(fn: any): void { }

	setDisabledState(isDisabled: boolean): void { }

	onInput(value : any) {
		if (!value) {
			this.list = this.listAutoComplete;
			return;
		}

		if (this.list && this.list.length > 1) {
			this.list = this.listAutoComplete.filter(function (el : any) {
				return el.value.toLowerCase().indexOf(value.toLowerCase()) != -1;
			});
		}
	}
}
