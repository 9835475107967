<div #appModalFinalizar class="modal modal-wide-2 fade in" tabindex="-1" role="dialog" aria-hidden="false"
    style="display: auto; overflow: scroll;">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header bg-primary" style="padding: 10px 15px;">
                <h4 *ngIf="!isVisualizar" class="modal-title" style="color: White;" id="modalParecer">Vincular Produto</h4>
                <h4 *ngIf="isVisualizar" class="modal-title" style="color: White;" id="modalParecer">Produtos Vinculados</h4>
            </div>
            <div class="modal-body">
                <form>
                    <div class="row form-group" style="margin-left: 2px;">
                        <div style="margin-top: 5px;" class="col-sm-12">
                            <div class="col-lg-12">
                                <label for="descricao-titulo-ppb">Descrição Documento:</label>
                                {{tituloPPB}}
                            </div>
                        </div>
                    </div>

                    <!-- #################################### -->
                    <!-- ## FORMULARIO CADASTRO DE PRODUTO ## -->
                    <!-- #################################### -->
                    <div class="row form-group" style="margin-left: 2px;">
                        <div style="margin-top: 5px;" class="col-sm-12">
                            <div class="col-lg-10">
                                <label  *ngIf="!isVisualizar" for="descricao" class="">Produto para Vincular:</label>
                                <app-drop-list-select2
                                    *ngIf="!isVisualizar"
                                    #ViewMcppProdutoNCM
                                    id = "produto-condicoes-ppb-ncm"
                                    name = "produto-condicoes-ppb-ncm"
                                    (valorModificado) = "parametros.idProduto = $event;"
                                    servico = "ViewMccpProdutoNcm"
                                    [placeholder] = "'Pesquisar Produto (Código Produto, Código NCM ou Descrição)'">
                                </app-drop-list-select2>
                                <div class="alert-danger" *ngIf="flagItemJaVinculado">
                                    <div>
                                        <button type="button" class="close" [attr.disabled]="isVisualizar ? true : null" (click)=" flagItemJaVinculado = false; "><b style="color:black;">&times;</b></button>
                                        <b style="margin-left: 15px;">Produto Já Foi Adicionado a Lista!</b>
                                    </div>
                                </div>
                                <div class="alert-danger" *ngIf=" flagProdutoNcmVazio ">
                                    <div>
                                        <button type="button" class="close"  [attr.disabled]="isVisualizar ? true : null" (click)=" flagProdutoNcmVazio = false; "><b style="color:black;">&times;</b></button>
                                        <b style="margin-left: 15px;"> Pesquise o Produto </b>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-3" style="margin-top: 25px;">
                                <button
                                    *ngIf="!isVisualizar"
                                    [attr.disabled]="(
                                                        ( flagIsPorGrupo && (!parametros.idProduto || !parametros.idGrupo) ) ||
                                                        ( !flagIsPorGrupo && !parametros.idProduto )
                                                     ) ? true : null "
                                    class="btn btn-sm btn-default"
                                    (click)="vincular()">
                                        <i class="fa fa-link"></i> Vincular
                                </button>
                            </div>
                        </div>
                        <div *ngIf="arrayGrupos.length && flagIsPorGrupo" style="margin-top: 5px;" class="col-sm-12">
                            <div *ngIf="!isVisualizar" class="col-lg-2">
                                <label for="descricao" class="">Grupo:</label>
                                <select
                                    (change)="selectGrupo()"
                                    [attr.disabled]="isVisualizar ? true : null"
                                    #ComboBoxGrupo
                                    class="form-control"
                                    [(ngModel)]="parametros.idGrupo"
                                    name="combo-box-grupo"
                                    id="combo-box-grupo">
                                        <option
                                            value="undefined"
                                            [selected]="selectFirstItem ? true : null"
                                            disabled>
                                            Selecione um Grupo ...
                                        </option>
                                        <option
                                            *ngFor="let item of arrayGrupos"
                                            [ngValue]="item.idGrupo">
                                            {{ item.letraGrupo }}
                                        </option>
                                </select>
                                <div class="alert-danger" *ngIf="flagGrupoNaoInformado">
                                    <div>
                                        <button type="button" class="close" [attr.disabled]="isVisualizar ? true : null" (click)=" flagGrupoNaoInformado = false; "><b style="color:black;">&times;</b></button>
                                        <b style="margin-left: 15px;">Informe o Grupo</b>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="arrayMultiplos.length && flagIsPorMultiplo" style="margin-top: 5px;" class="col-sm-12">
                          <div *ngIf="!isVisualizar" class="col-lg-2">
                              <label for="descricao" class="">Multiplo:</label>
                              <select
                                  (change)="selectMultiplo()"
                                  [attr.disabled]="isVisualizar ? true : null"
                                  #ComboBoxMultiplo
                                  class="form-control"
                                  [(ngModel)]="parametros.idMultiplo"
                                  name="combo-box-multiplo"
                                  id="combo-box-multiplo">
                                      <option
                                          value="undefined"
                                          [selected]="selectFirstItem ? true : null"
                                          disabled>
                                          Selecione um Multiplo...
                                      </option>
                                      <option
                                          *ngFor="let item of arrayMultiplos"
                                          [ngValue]="item.id">
                                          {{ item.text }}
                                      </option>
                              </select>



                              <div class="alert-danger" *ngIf="flagGrupoNaoInformado">
                                  <div>
                                      <button type="button" class="close" [attr.disabled]="isVisualizar ? true : null" (click)=" flagGrupoNaoInformado = false; "><b style="color:black;">&times;</b></button>
                                      <b style="margin-left: 15px;">Informe o Grupo</b>
                                  </div>
                              </div>
                          </div>
                      </div>
                    </div>

                    <!-- ########################### -->
                    <!-- ## PRODUTOS PPB CORRENTE ## -->
                    <!-- ########################### -->
                    <div class="row form-group" style="margin-left: 2px;">
                        <div style="margin-top: 5px;" class="col-sm-12">
                            <div class="table-responsive no-margin-bottom no-border">
                                <table class="table">
                                    <thead class="table-header-color">
                                        <tr>
                                            <th *ngIf="!isVisualizar"class="text-left">Ações</th>
                                            <th class="text-left">Produto</th>
                                            <th *ngIf="flagIsPorGrupo" class="text-left">Grupo</th>
                                            <th *ngIf="flagIsPorMultiplo" class="text-left">Multiplo</th>
                                        </tr>
                                    </thead>
                                    <tbody *ngIf="!arrayVinculosProduto.length" >
                                        <div class="col-sm-12">
                                            <p class="pull-right font-bold m-t-sm m-b-sm">Nenhum registro Vinculado</p>
                                        </div>
                                    </tbody>
                                    <tbody *ngIf="arrayVinculosProduto.length">
                                        <tr
                                            *ngFor="let item of arrayVinculosProduto; let i = index; "
                                            [attr.data-index]="i"
                                            [ngStyle]="{'background-color': item.fromBackEnd ? '#fffc47' : ''  }"
                                        >
                                                <td *ngIf="!isVisualizar" class="text-left">
                                                    <button

                                                        class="btn btn-sm btn-default"
                                                        type="button"
                                                        (click)="desvincular(i, item)">
                                                            <i class="fa fa-unlink"></i> Desvincular
                                                    </button>
                                                </td>
                                                <td class="text-left">{{item.descricaoProduto}}</td>
                                                <td *ngIf="flagIsPorGrupo" >
                                                    <select
                                                        [attr.disabled]="isVisualizar ? true : null"
                                                        (change)="selectGrupo()"
                                                        class="form-control"
                                                        style="width: min-content;"
                                                        [(ngModel)]="item.idGrupo"
                                                        name="combo-box-grupo-{{i}}"
                                                        id="combo-box-grupo-{{i}}"
                                                        >
                                                        <option value="undefined" [selected]="item.idGrupo" disabled>
                                                            Selecione um Grupo ...
                                                        </option>
                                                        <option *ngFor="let item of arrayGrupos" [ngValue]="item.idGrupo">
                                                            {{ item.letraGrupo }}
                                                        </option>
                                                    </select>
                                                </td>
                                                <td *ngIf="flagIsPorMultiplo" >
                                                  <select
                                                      [attr.disabled]="isVisualizar ? true : null"
                                                      (change)="selectMultiplo()"
                                                      class="form-control"
                                                      style="width: min-content;"
                                                      [(ngModel)]="item.idMultiplo"
                                                      name="combo-box-multipl-{{i}}"
                                                      id="combo-box-multipl-{{i}}"
                                                      >
                                                      <option [ngValue]="null">
                                                          Selecione um Multiplo...
                                                      </option>
                                                      <option *ngFor="let item of arrayMultiplos" [ngValue]="item.id">
                                                          {{ item.text }}
                                                      </option>
                                                  </select>
                                              </td>
                                           <!--     <td *ngIf="flagIsPorGrupo && !fromRetificacaoPPB" class="text-left">{{item.descricaoGrupo}}</td>  -->
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                    <!-- ########################### -->
                    <!-- ### PRODUTOS VINCULADOS ### -->
                    <!-- ########################### -->
                    <div *ngIf="isAlteracaoParcial">
                      <h4 style="margin-left: 18px;">{{flagExibeGridVinculos ? 'Ocultar' : 'Exibir'}} Produtos dos PPBs de Referência</h4>
                      <div class="row" style="margin-top: -32px; margin-left: 65px;">
                        <div class="col-sm-3">
                          <app-collapse-row-button
                            (click)="flagExibeGridVinculos ? flagExibeGridVinculos = false : flagExibeGridVinculos = true;"
                            style="cursor: pointer;"
                            target="grid-produtos-referencia">
                          </app-collapse-row-button>
                        </div>
                      </div>
                      <div *ngIf="flagExibeGridVinculos" class="row form-group" id="grid-produtos-referencia" tyle="margin-left: 2px; display: none;">
                          <div style="margin-top: 5px;" class="col-sm-12">
                              <app-grid-produtos2
                                  [(lista)]="grid2.lista"
                                  [(total)]="grid2.total"
                                  [(size)]="grid2.size"
                                  [(page)]="grid2.page"
                                  [(parametros)]="parametros"
                                  [(mostrarDocumento)]="_mostrarCampoDocumento"
                                  (onChangeSort)="onChangeSort($event)"
                                  (onChangeSize)="onChangeSize($event)"
                                  (onChangePage)="onChangePage($event)"
                                  (atualizarListagem)="produtoMigrado()"
                                  (changePropertyModal)="changeDisplayModal($event)"
                                  (enviaProdutosMigrados)="recebeArrayProdutosMigrados($event)"
                                  [idPpb]="objetoParametro.id"
                                  [(isVisualizar)]="isVisualizar"
                                  [tipoPublicacaoPpb]="objetoParametro.tipoPublicacao">
                              </app-grid-produtos2>
                          </div>
                      </div>
                    </div>

                    <!-- ########################### -->
                    <!-- ### GRID INCONSISTENCIAS ## -->
                    <!-- ########################### -->

                    <!-- <div class="row form-group" style="margin-left: 2px;" *ngIf="flagPermiteVisualizarGridDeInconsistencias">
                      <h3 style="margin-left: 18px;"> Inconsistências - Produtos vinculados ao PPB de Origem </h3>
                      <div *ngIf="!arrayVinculoInconsistencias" style="margin-left: 25px;"><b style="color:red;">Nenhuma inconsistência encontrada</b></div>
                      <div style="margin-top: 5px;" class="col-sm-12" *ngIf="arrayVinculoInconsistencias">
                          <div class="table-responsive no-margin-bottom no-border">
                              <table class="table">
                                  <thead class="table-header-color">
                                      <tr>
                                          <th class="text-left">Ações</th>
                                          <th class="text-left">Produto</th>
                                          <th class="text-left">Descrição Documento</th>
                                      </tr>
                                  </thead>
                                  <tbody>
                                      <tr *ngFor="let item of arrayVinculoInconsistencias; let i = index; " [attr.data-index]="i">
                                        <td class="text-left">
                                            <button
                                                class="btn btn-sm btn-default"
                                                type="button"
                                                (click)="desvincularInconsistencia(item)">
                                                    <i class="fa fa-unlink"></i> Desvincular
                                            </button>
                                        </td>
                                        <td class="text-left">{{item.descricaoProduto}}</td>
                                        <td class="text-left">{{item.documento}}</td>
                                      </tr>
                                  </tbody>
                              </table>
                          </div>
                      </div>
                  </div> -->


                </form>
            </div>
            <div class="modal-footer" style="padding: 10px 15px;">
                <div class="col-lg-12">
                    <a
                        *ngIf="!isVisualizar"
                        (click)="fechar()"
                        class="btn btn-sm btn-default pull-right"
                        data-dismiss="modal">
                            <i class="fa fa-times"></i> Não há produto a ser Vinculado
                    </a>
                    <a
                        *ngIf="isVisualizar"
                        (click)="fechar()"
                        class="btn btn-sm btn-default pull-right"
                        data-dismiss="modal">
                            <i class="fa fa-times"></i> Fechar
                    </a>
                    <a
                        *ngIf="!isVisualizar"
                        (click)="validar()"
                        class="btn btn-sm btn-primary pull-right"
                        data-dismiss="modal"
                        data-toggle="modal"
                        data-backdrop="static"
                        data-keyboard="false">
                            <i class="fa fa-save m-r-xs"></i> Salvar
                    </a>
                    <div class="alert-danger" *ngIf=" flagUsuarioNaoVinculouProduto ">
                        <div>
                            <button type="button" class="close" [attr.disabled]="isVisualizar ? true : null" (click)=" flagUsuarioNaoVinculouProduto = false; "><b style="color:black;">&times;</b></button>
                            <b style="margin-left: 15px;"> Vincule Algum Produto! </b>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div #appModalFinalizarBackground class="modal-backdrop fade in" style="display: none;"></div>
