import { Injectable, Output, EventEmitter } from '@angular/core';
import { ServiceTipoListagemVM } from '../../view-model/ServiceTipoListagemVM';
import { TiposReferenciaVM } from '../../view-model/TiposReferenciaVM';
import { EnumTipoEtapa } from '../enums/EnumTipoEtapa';
import { EnumTipoPublicacao } from '../enums/EnumTipoPublicacao';

interface TiposEtapa {
  isMultiplo: boolean;
  isPorGrupo: boolean;
  isPorPontuacao: boolean;
  isSemPontuacao: boolean;
}

@Injectable()
export class ControladorTiposEtapaPPB {
  constructor() {}


  /***
   * @description metodo valida por tipo da etapa do ppb atual e dos ppb de referencia
   */
  public definirTiposEtapa(
    tipoPublicacao: number,
    tipoEtapa: number,
    tiposPublicacaoReferencia: TiposReferenciaVM,
    considerarReferencia=true
  ): TiposEtapa {
    const retorno: TiposEtapa = {
      isMultiplo: false,
      isPorGrupo: false,
      isPorPontuacao: false,
      isSemPontuacao: false,
    };

    retorno.isMultiplo = (tipoEtapa == EnumTipoEtapa.PPB_MULTIPLO)
    retorno.isPorGrupo = (tipoEtapa == EnumTipoEtapa.POR_GRUPO)
    retorno.isPorPontuacao = (tipoEtapa == EnumTipoEtapa.POR_PONTUACAO)
    retorno.isSemPontuacao = (tipoEtapa == EnumTipoEtapa.SEM_PONTUACAO)

    if (
      tipoPublicacao != EnumTipoPublicacao.FIXAÇÃO
      && considerarReferencia
    ) {
      retorno.isMultiplo = retorno.isMultiplo ? true : tiposPublicacaoReferencia.existeMultiploSemPontuacao;
      retorno.isPorGrupo = retorno.isPorGrupo ? true : tiposPublicacaoReferencia.existePorGrupoComPontuacao;
      retorno.isPorPontuacao = retorno.isPorPontuacao? true : tiposPublicacaoReferencia.existePorPontuacao;
      retorno.isSemPontuacao = retorno.isSemPontuacao  ? true  : tiposPublicacaoReferencia.existeSemPontuacao;
    }

    return retorno;

  }
}
