<div (click)="changeSort(field)">
	<span *ngIf="field == sorted">
		<span class="sort-1" [ngSwitch]="reverse">
			<i *ngSwitchCase="1" class="fa fa-sort-asc" style="padding-right: 5px; max-height: 50px; min-height: 20px;"></i>
			<i *ngSwitchCase="0" class="fa fa-sort-desc" style="padding-right: 5px; max-height: 50px; min-height: 20px;"></i>
		</span>
	</span>
	<span *ngIf="field != sorted" class="m-n">
		<span class="sort-1">
			<i class="fa fa-sort" style="padding-right: 5px; max-height: 50px; min-height: 20px;"></i>
		</span>
	</span>
	<ng-content></ng-content>
</div>
