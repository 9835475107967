<app-grid [(titulo)]="tituloGrid" [(page)]="page" [(size)]="size" [(total)]="total" [(parametros)]="parametros"
    (onChangeSize)="changeSize($event)" (onChangePage)="changePage($event)">

    <div class="table-responsive no-margin-bottom no-border">
        <table class="table">
            <thead class="table-header-color">
                <tr>
                    <th class="th-100 text-left">Ações</th>
                    <th class="th-sortable unset-nowrap text-left">
                        <app-ordenacao field="Descricao" [sorted]="sorted" (onChangeSort)="changeSort($event)">Tipo de Documento
                        </app-ordenacao>
                    </th>
                    <th class="th-sortable unset-nowrap text-left">
                        <app-ordenacao field="Status" [sorted]="sorted" (onChangeSort)="changeSort($event)">
                            Situação</app-ordenacao>
                    </th>
                   
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of lista">
                    <td class="text-left">
                        <a 
                            class="btn btn-default btn-sm" 
                            data-toggle="tooltip"                             
                            data-original-title="Alterar"
                            (click)="abrirEditar(item.id)">
                                <i class="fa fa-pencil"></i>
                        </a>                            
                        <a 
                            *ngIf="item.status == 1"
                            class="btn btn-rounded btn-warning btn-sm btn-icon btn-primary" 
                            data-toggle="tooltip" 
                            data-original-title="Inativar"
                            (click)="MudarStatusDocumentoPPB(item.id, item.descricaoStatus)">
                                <i class="fa fa-times"></i>
                        </a>
                        <a 
                            *ngIf="item.status == 0"
                            class="btn btn-rounded btn-sm btn-icon btn-primary" 
                            data-toggle="tooltip" 
                            data-original-title="Reativar"
                            (click)="MudarStatusDocumentoPPB(item.id, item.descricaoStatus)">
                                <i class="fa fa-check"></i>
                        </a>
                        <a 
                            class="btn btn-danger btn-sm" 
                            data-toggle="tooltip" 
                            data-original-title="Excluir"
                            (click)="excluir(item.id)">
                                <i class="fa fa-trash-o"></i>
                        </a>
                        <a 
                            class="btn btn-default btn-sm" 
                            data-toggle="tooltip" 
                            data-original-title="Histórico"
                            (click)="abrirHistorico(item.id)"
                            >
                                <i class="i i-history"></i>
                        </a>                        
                    </td>                    
                    <td class="text-left">{{ item.descricao }}</td>
                    <td class="text-left">{{ item.descricaoStatus }}</td>
                </tr>
            </tbody>
        </table>
    </div>
</app-grid>
<app-modal-historico-tipo-documento
    #modalHistorico
></app-modal-historico-tipo-documento>