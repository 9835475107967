import { Component, Injectable, OnInit, ViewChild, Input, Output, EventEmitter } from "@angular/core";
import { MessagesService } from "../../../../../shared/services/messages.service";
import { ValidationService } from "../../../../../shared/services/validation.service";
import { ApplicationService } from "../../../../../shared/services/application.service";
import { Validators, FormBuilder } from '@angular/forms';
import { ModalService } from '../../../../../shared/services/modal.service';
import { MetaVM } from "../../../../../view-model/MetaVM";

interface AbirModalListagemVM {
  isPorPontuacao : boolean;
  isPPBMultiplo : boolean;
  isPorGrupo : boolean;
}

@Component({
	selector: 'app-modal-incluir-metas-ppb',
	templateUrl: './modal-incluir-metas-ppb.component.html'
})

@Injectable()
export class ModalIncluirMetasPPBComponent implements OnInit {

	parametros = new MetaVM();
  flagCadastroContinuo : boolean = false;
  @ViewChild('appModalIncluirMetasPPB') appModalIncluirMetasPPB : any;
	@ViewChild('appModalIncluirMetasPPBBackground') appModalIncluirMetasPPBBackground : any;
	@Input() dataVigenciaInicialPPB : string;
	@Output() atualizarListagem : EventEmitter<any> = new EventEmitter();
	_isPorGrupo: boolean;
  exibeConteudoModal: boolean = false;
  operation : string;
	idPPB: number;
	idMeta = undefined;
	listaEtapas: Array<any>;
	listaGrupos: Array<any>;
  _flagTrouxeItensGrupo : boolean = false;

	servicoCombo = "MetasPPB/ListarCombo";
	servico = "MetasPPB";

	constructor(
		private modal : ModalService,
    	private fb: FormBuilder,
		private applicationService: ApplicationService,
		private msg: MessagesService,
		private validation: ValidationService,
	) {

    }

    userprofileForm = this._initializeValidations();
    get cadastroContinuo(){
      return this.userprofileForm.get('cadastroContinuo');
    }
    _initializeValidations(){
      return this.fb.group({
        cadastroContinuo : [ '', null]
      });
    }
    /************************************************/

  ngOnInit() {}

	abrir(idPPB: number, idMeta: number){

    this.applicationService.get('AbrirModalListagens',idPPB).subscribe((result:AbirModalListagemVM)=>{
      if(result){
        this._isPorGrupo = result.isPorGrupo;
        this.flagCadastroContinuo = false;
        this.parametros = new MetaVM();
        this.idPPB = idPPB;
        this.userprofileForm = this._initializeValidations();
        this.buscarCombo();
        if(idMeta != undefined){
          this.operation = 'edit';
          this.idMeta = idMeta;
          this.buscar(idMeta);
        } else{
          this.operation = 'new';
          this.parametros.dataVigenciaInicialString = this.dataVigenciaInicialPPB;
        }

        this.appModalIncluirMetasPPB.nativeElement.style.display = 'block';
        this.appModalIncluirMetasPPBBackground.nativeElement.style.display = 'block';
        this.exibeConteudoModal = true;
      }
    });
	}

	fechar(){
    this.userprofileForm.get('cadastroContinuo').reset();
    this.flagCadastroContinuo = false;
		this.parametros = new MetaVM();
		this.appModalIncluirMetasPPB.nativeElement.style.display = 'none';
		this.appModalIncluirMetasPPBBackground.nativeElement.style.display = 'none';
	}

	buscarCombo(){
		this.applicationService.get(this.servicoCombo, {idPPB: this.idPPB}).subscribe((result: any) => {
			this.listaEtapas = result.etapas;

      if(result.grupos != null && result.grupos != undefined && result.grupos.length > 0){
        this.listaGrupos = result.grupos;
        this._flagTrouxeItensGrupo = true;
      } else{
        this._flagTrouxeItensGrupo = false;
      }
		});
	}

	validar(){

		if(!this.parametros.dataVigenciaInicialString){
			this.modal.infoError("Campo Obrigatório não Informado: <b>Data Vigência Inicial</b>","Atenção");
			return false;
		}

		if(this.parametros.dataVigenciaInicialString && this.parametros.dataVigenciaFinalString){
			if(this.validation.validaDataInicialMaiorDataFinal(this.parametros.dataVigenciaInicialString , this.parametros.dataVigenciaFinalString) == true){
				this.modal.infoError("A Data Inicial não pode ser Superior a Data Final", "Atenção");
				return false;
			}
		}

		if(this.parametros.valorMeta == null || this.parametros.valorMeta == undefined){
			this.modal.infoError("Campo Obrigatório não Informado: <b>Valor</b>","Atenção");
			return false;
		}
		else
		{
			this.parametros.valorMeta = Number(this.parametros.valorMeta);
		}

    if(!this._flagTrouxeItensGrupo && this._isPorGrupo)
    {
      this.modal.infoError("É necessário cadastrar <b>Grupo</b>.","Atenção")
      return false;
    }

    if(this._isPorGrupo &&
       !this.parametros.idGrupo &&
       this.listaGrupos.length > 0)
    {
      this.modal.infoError("Campo Obrigatório não Informado: <b>Grupo</b>","Atenção");
			return false;
    }

		if(!this.parametros.idEtapa)
			this.parametros.idEtapa = null;

		if(!this.parametros.idGrupo)
			this.parametros.idGrupo = null;

		this.salvar();

		return true;
	}

	salvar(){
		this.parametros.idPPB = this.idPPB;
		this.applicationService.post(this.servico, this.parametros).subscribe( (result: any) => {
			if(result == 200){
				this.modal.infoSucess(this.msg.CADASTRO_REALIZADO_COM_SUCESSO,"Sucesso");

        if(!this.flagCadastroContinuo){

					this.fechar();

				} else {

					this.limparVariaveis();

					this.parametros.idPPB = this.idPPB;

					if(this.operation == 'new')
						this.parametros.dataVigenciaInicialString = this.dataVigenciaInicialPPB;
				}

				this.changeListagem();
			}else{
				this.modal.infoError(this.msg.NAO_FOI_POSSIVEL_CONCLUIR_OPERACAO, "Erro")
			}
		});
	}

	buscar(id: number){
		this.applicationService.get(this.servico+"/Buscar", {id}).subscribe((result: MetaVM) => {
			this.parametros = result;

			if(result.valorMeta)
				this.parametros.valorMeta = String(this.parametros.valorMeta);
		});
	}

  limparVariaveis(){
		this.parametros = new MetaVM();
	}

	changeListagem(){
		this.atualizarListagem.emit();
	}
}
