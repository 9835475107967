<app-grid [(titulo)]="tituloGrid" [(page)]="page" [(size)]="size" [(total)]="total" [(parametros)]="parametros"
  (onChangeSize)="changeSize($event)" (onChangePage)="changePage($event)"
  [(isHidePaginacao)]="ocultarPaginacaoCabecalho" [(isHideCabecalho)]="ocultarPaginacaoCabecalho">

  <div class="table-responsive no-margin-bottom no-border">
    <table class="table table">
      <thead class="table-header-color">
        <tr>
          <th class="th-100 text-center" *ngIf="!isVisualizar">
            Ações
          </th>
<!--
          <th class="th-sortable unset-nowrap text-center">
            Grupo
          </th> -->

          <th class="th-sortable unset-nowrap text-center">
            Etapa
          </th>
          <th class="th-sortable unset-nowrap text-center">
            Vigência
          </th>
          <th class="th-sortable unset-nowrap text-center">
            Pontuação Minima
          </th>
        </tr>
      </thead>
      <tbody style="border: 1px solid black;" *ngFor="let item of lista; let i = index">
        <tr align="center">

          <!-- ACAO -->
          <td *ngIf="!isVisualizar" [attr.rowspan]="obterRowspan(item)" class="text-center"
            style="text-align: center; vertical-align: middle; border: 1px solid black;">
            <span data-toggle="modal" data-backdrop="static" data-keyborad="false">
              <a (click)="incluirPontuacaoMinima('alterar',item)" class="btn btn-default btn-sm"
                data-toggle="tooltip" title=""
                data-original-title="Alterar"><i class="fa fa-pencil fa-lg"></i></a></span>

            <span data-toggle="modal" data-target="#excluir_cronograma" data-backdrop="static" data-keyborad="false"><a
                (click)="incluirPontuacaoMinima('excluir',item)" class="btn btn-default btn-sm btn-danger"
                data-toggle="tooltip" title="" data-original-title="Excluir"><i class="fa fa-trash-o"></i></a></span>
            <span data-toggle="modal" data-backdrop="static" data-keyborad="false"><a
                (click)="validarSalvarPontuacaoMinima(item, i)" class="btn btn-default btn-sm btn-primary" data-toggle="tooltip"
                title="" data-original-title="Salvar"><i class="fa fa-save"></i></a></span>

          </td>

          <!-- GRUPO -->
<!--
          <td [attr.rowspan]="obterRowspan(item)"  class="text-center"
            style="text-align: center; vertical-align: middle; border: 1px solid black;">
            <p>-</p>
          </td> -->
        </tr>


        <ng-container #container1 *ngFor="let itemEtapas of item.listaGrupo[0].listaEtapas; let j = index">
          <tr *ngIf="j == 0">
            <!-- VIGENCIAS/DATAS SOMATORIO -->
            <td style="border: 1px solid black; vertical-align: middle;">
              {{'Somatória mínima das pontuações das etapas '+obterDescricaoEtapasConcat(item)}}
            </td>
            <!-- VIGENCIA / SOMATORIO-->
            <td style="border: 1px solid black; vertical-align: middle;">


               <ng-container #container2 *ngFor="let objVigencia of obterRepeticaoInputs(item); let num = index">
                <div class="input-group" style="display: flex;">

                  <input [disabled]="isVisualizar" autocomplete="off" class="form-control date ng-pristine ng-valid ng-touched" id="{{'dataInicio-'+i+j+num}}"
                    maxlength="10" name="dataInicio" style="width: 50%;" type="date" ng-reflect-maxlength="10"
                    (change)="changeInputData(item, 'dataInicio-'+i+j+num)"
                    [(ngModel)]="parametros2['dataInicio-'+i+j+num]"
                    ng-reflect-name="'dataInicio-'+i+j+num">

                  <span style="padding-right: 11px; padding-top: 8px; padding-left: 10px;">a</span>

                  <input [disabled]="isVisualizar" autocomplete="off" class="form-control date ng-pristine ng-valid ng-touched" id="{{'dataFim-'+i+j+num}}"
                    maxlength="10" name="dataFim" style="width: 50%;" type="date" ng-reflect-maxlength="10"
                    (change)="changeInputData(item, 'dataFim-'+i+j+num)"
                    [(ngModel)]="parametros2['dataFim-'+i+j+num]"
                    ng-reflect-name="'dataFim-'+i+j+num">

                </div>
              </ng-container>
            </td>

            <!-- VALOR MINIMA SOMATORIO -->
            <td class="text-center" style="border: 1px solid black;">
                <ng-container #container2 *ngFor="let objVigencia of obterRepeticaoInputs(item); let num2 = index">
                <div  class="input-group" style="display: block; margin-top: 7px;">
                  <!-- (change)="changeInputData(item, 'valorSomaMinima-'+i+j+num2)" -->
                  <input
                  class="form-control"
                  [disabled]="isVisualizar"
                  id="{{'valorSomaMinima-'+i+j+num2}}"
                  type="text"
                  (ngModelChange)="changeInputData(item, 'valorSomaMinima-'+i+j+num2)"
                  [(ngModel)]="parametros2['valorSomaMinima-'+i+j+num2]"
                  style="text-align: center; width: 120px; "
                  ng-reflect-name="'valorSomaMinima-'+i+j+num2"
                  maxlength="11"
                  currencyMask
                  min="0" max="999.999.999,999"
                  [options]="{ prefix: '', suffix: '', thousands: '.', decimal: ',', precision: '2'}"
                    >
                </div>
              </ng-container>
            </td>
          </tr>


          <tr>
            <!-- ETAPAS -->
            <td style="border: 1px solid black; vertical-align: middle;">
              {{'Pontuação mínima para a etapa '+obterDescricaoEtapas(itemEtapas)}}
            </td>
            <!-- VIGENCIA / DATAS ETAPAS-->
            <td style="border: 1px solid black; vertical-align: middle;">
              <div class="input-group" style="display: flex;">
                <input  [disabled]="isVisualizar" autocomplete="off" class="form-control date ng-pristine ng-valid ng-touched" id="dataInicio"
                  maxlength="10" name="dataInicio" style="width: 50%;" type="date" ng-reflect-maxlength="10"
                  ng-reflect-name="dataInicio" [(ngModel)]="itemEtapas.dataVigenciaInicialEtapaFormatado" disabled>
                <span style="padding-right: 11px; padding-top: 8px; padding-left: 10px;">a</span>
                <input  [disabled]="isVisualizar" autocomplete="off" class="form-control date ng-pristine ng-valid ng-touched" id="dataFim"
                  maxlength="10" name="dataFim" style="width: 50%;" type="date" ng-reflect-maxlength="10"
                  ng-reflect-name="dataFim" [(ngModel)]="itemEtapas.dataVigenciaFinalEtapaFormatado" disabled>
              </div>
            </td>

            <!-- VALOR MINIMA ETAPA -->
            <td class="text-center" style="border: 1px solid black;">
              <input class="form-control" type="text" [disabled]="isVisualizar" value="" [(ngModel)]="itemEtapas.valorPontuacaoMinima"
                style="text-align: center; width: 120px;" currencyMask max="999.999.999,999"
                [options]="{ prefix: '', suffix: '', thousands: '.', decimal: ',', precision: '2'}"
                disabled>
            </td>
          </tr>
        </ng-container>

      </tbody>
    </table>
  </div>
</app-grid>

<app-modal-incluir-cronograma
[(idPPB)]="idPPB"
(atualizaListagem)="atualizaListagem($event)"
[_isPorPontuacao]="_isPorPontuacao"
  #appModalIncluirCronograma2>
</app-modal-incluir-cronograma>
