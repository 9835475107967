import { Component, Injectable, OnInit, ViewChild, Input } from "@angular/core";
import { PagedItems } from "../../../../view-model/PagedItems";
import { MessagesService } from "../../../../shared/services/messages.service";
import { ActivatedRoute, Router } from '@angular/router';
import { ValidationService } from "../../../../shared/services/validation.service";
import { ApplicationService } from "../../../../shared/services/application.service";
import { Location } from '@angular/common';
import { CondicionanteVM } from "../../../../view-model/CondicionanteVM";
import { ChangeCondicionantePPBService } from '../../../../shared/services/changeCondicionantePPB.service';
import { ServiceTipoListagemVM } from "../../../../view-model/ServiceTipoListagemVM";
import { FiltrosModalListagemVM } from "../../../../view-model/FiltrosModalListagemVM";

@Component({
	selector: "app-condicionantes-ppb",
	templateUrl: "./condicionantes-ppb.component.html"
})
@Injectable()

export class CondicionantesPPBComponent implements OnInit {

	form = this;
	grid: any = { sort: {} };
	parametros: CondicionanteVM = new CondicionanteVM();
	ocultarFiltro: boolean = false;
	ocultarGrid: boolean = true;
	path: string;
	servico = "CondicionantesPPB";
	isBuscaSalva : boolean = false;
	objetoSession : any = {} = null;

	@Input() dataVigenciaInicialPPB : string;
	@Input() isAlteracaoParaRevogacao : boolean;
	@Input() habilitarInputs: boolean;
	@Input() idPPB: number;
	@Input() validacao : any = {};
	@Input() buscarVinculos: boolean;
	@Input() _fromTelaElaboracao : boolean;
	@Input() isRetificacao : boolean;
  @Input() flagIsSomenteVigente: boolean = false;
  @Input() objConsultaFiltradaModalListagem: FiltrosModalListagemVM;
  @Input() isConsultaFiltradaModalListagem: boolean = false;
  @Input() exibeRevogados:boolean;



  @ViewChild ('appModalMoverPorPosicao') appModalMoverPorPosicao : any;
	@ViewChild('appModalIncluirCondicionantesPPB') appModalIncluirCondicionantesPPB : any;

	constructor(
    private ChangeCondicionantePPBService: ChangeCondicionantePPBService,
    private route: ActivatedRoute,
    private applicationService: ApplicationService,
    private msg: MessagesService,
    private validation: ValidationService,
    private router: Router,
    private _location: Location
	) {
		this.path = this.route.snapshot.url[this.route.snapshot.url.length - 1].path;
	}

	ngOnInit() {
		this.listar(this.idPPB);
		this.ChangeCondicionantePPBService.AtualizaListagemCondicionantePPB.subscribe(
			(obj: ServiceTipoListagemVM) => {
        if(obj != null){
          //console.log('to aqui - condicionante');
          // const carregando = localStorage.getItem("isLoadingCond");
          // if(!(carregando=="true") ){

          // }

          if(obj.filtro){
            this.objConsultaFiltradaModalListagem = obj.filtro;
          }
          this.flagIsSomenteVigente = obj.isSomenteVigente;

          this.listar(obj.idPpb, obj.getFromSessionStorage);

        }
      }
		);
	}

	ocultar() { }

	onChangeSort($event : any) {
		this.grid.sort = $event;
		this.listar(this.idPPB);
	}

	onChangeSize($event : any) {
		this.grid.size = $event;
    this.grid.page = 1;
		this.listar(this.idPPB);
	}

	onChangePage($event : any) {
		this.grid.page = $event;
		this.listar(this.idPPB);
    }

    Voltar(){
    }

	adicionarCondicionantes(){
		this.appModalIncluirCondicionantesPPB.abrir(this.idPPB, "new");
	}

	atualizaListagem($event){
    this.listar($event, true);
	}

	listar(idPPB : number, getConsultaFromSessionStorage? : boolean){
    let objeto : CondicionanteVM = new CondicionanteVM();
		objeto.idPPB = idPPB;
		if(getConsultaFromSessionStorage)
		{
			this.retornaValorSessao();
			if(this.isBuscaSalva){
				objeto.page = this.objetoSession.page || 1;
				objeto.size = this.objetoSession.size || 10;
				objeto.sort = this.objetoSession.sort || "NumeroSequencia";
				objeto.reverse = this.objetoSession.reverse || false;
			} else {
				objeto.page = 1;
				objeto.size = 10;
			}
		}
		else
		{
			objeto.page = this.grid.page || 1;
			objeto.size = this.grid.size || 10;
			objeto.sort = this.grid.sort.field || "NumeroSequencia";
			objeto.reverse = this.grid.sort.reverse;
		}
		objeto.buscarVinculos = this.buscarVinculos;
		objeto.fromTelaElaboracao = this._fromTelaElaboracao;
    objeto.isSomenteVigente = this.flagIsSomenteVigente;
    objeto.objetoConsultaFiltrada = this.objConsultaFiltradaModalListagem;
    objeto.isConsultaFiltradaModalListagem = this.isConsultaFiltradaModalListagem;
    objeto.exibirRevogados = this.exibeRevogados;
    localStorage.setItem("isLoadingCond","true");
		this.applicationService.get(this.servico+"/GetPaginado", objeto).subscribe((retorno : PagedItems) => {
			if(retorno.total > 0){
				this.grid.lista = retorno.items;
				this.grid.total = retorno.total;
				this.gravarBusca(objeto);
			} else {
				this.grid = { sort: {} };
			}
      localStorage.removeItem("isLoadingCond")
		});
	}

	gravarBusca(objeto : any) {
		sessionStorage.removeItem(this.router.url);
		sessionStorage.setItem("condicionantes-ppb-"+this.idPPB , JSON.stringify(objeto));
	}

	retornaValorSessao() {
		var router = sessionStorage.getItem("condicionantes-ppb-"+this.idPPB);
		if (router) {
			this.isBuscaSalva = true;
			this.objetoSession = JSON.parse(sessionStorage.getItem("condicionantes-ppb-"+this.idPPB));
		} else {
			this.isBuscaSalva = false;
			return null;
		}
	}

  abrirPorSelecao(){
    this.appModalMoverPorPosicao.abrir();
  }


}
