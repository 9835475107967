import { PPBVM } from './PPBVM';
import { PagedItems } from './PagedItems'

export class PPBListagensHistoricoEntityVM extends PagedItems {
  id : number;
  statusAnterior : number;
  statusAtual : number;
  numeroUsuario : string;
  nomeUsuario: string;
  dataHoraAcao : any;
  descricaoAcao : string;
  ppb : PPBVM = new PPBVM();
  idPPB : number;
  idListagem : number;
  descricaoTabela : string;

  //descricoes extras
  descricaoStatusAnterior : string;
  descricaoStatusAtual : string;
  dataHoraAcaoString : string;
}
