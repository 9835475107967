<app-grid 
    [(titulo)]="tituloGrid"
    [(page)]="page"
    [(size)]="size"
    [(total)]="total"
    [(parametros)]="parametros"
    (onChangeSize)="changeSize($event)"
    (onChangePage)="changePage($event)"
    [(isHidePaginacao)]="desabilitarPaginacaoECabecalho"
    [(isHideCabecalho)]="desabilitarPaginacaoECabecalho"
    >

	<div class="table-responsive no-margin-bottom no-border">
		<table class="table">
			<thead class="table-header-color">
				<tr>
					<th class="th-sortable unset-nowrap text-left">
						<app-ordenacao field="DescricaoTitulo" [sorted]="sorted" (onChangeSort)="changeSort($event)">Documento</app-ordenacao>
					</th>					
					<th class="th-sortable unset-nowrap text-left" style="width: 45px;">
						<app-ordenacao field="DescricaResumoTecnico" [sorted]="sorted" (onChangeSort)="changeSort($event)">Resumo Técnico</app-ordenacao>
					</th>
				</tr>
			</thead>
			<tbody>				
				<tr *ngFor="let item of lista">
					<td class="text-left">{{ item.descricaoTitulo }}</td>
                    <td class="text-left" style="width: 45px;">
						<!-- <a  
							class="azul-link font-bold btn-outline-secondary mr-2" 
							style="cursor: pointer;" 
							data-toggle="tooltip" 
							[attr.title]="item.descricaResumoTecnico" 
							[attr.data-original-title]="item.descricaResumoTecnico">
								{{ item.descricaResumoTecnico.substring(0,20) }} ...
						</a> -->
						{{ item.descricaResumoTecnico }}
					</td>
				</tr>
			</tbody>
		</table>
	</div>
</app-grid>