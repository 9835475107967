import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CollapseDirective } from './collapse.directive';
import { CpfCnpjDirective } from './cpf-cnpj.directive';
import { DateDirective } from './date.directive';
import { DisableOnClickDirective } from './disable-onclick.directive';
import { EmailDirective } from './email.directive';
import { OnlyNumbersDirective } from './only-numbers.directive';
import { OrdenacaoDirective } from './ordenacao.directive';
import { PorcentagemDirective } from './porcentagem.directive';
import { RemoveHostDirective } from './remove-host.directive';
import { TelefoneDirective } from './telefone.directive';
import { NumeroProtocoloDirective } from './numero-protocolo.directive';
import { FormatarNumeroDirective } from './formatar-numero.directive';
import { CargaHorariaDirective } from './carga-horaria.directive';
import { PlacaCarroDirective } from './placa-carro.directive';

@NgModule({
	imports: [
		CommonModule
	],
	declarations: [
		CollapseDirective,
		CpfCnpjDirective,
		DateDirective,
		DisableOnClickDirective,
		EmailDirective,
		NumeroProtocoloDirective,
		OnlyNumbersDirective,
		OrdenacaoDirective,
		PorcentagemDirective,
		RemoveHostDirective,
		FormatarNumeroDirective,
		TelefoneDirective,
		CargaHorariaDirective,
		PlacaCarroDirective
	],
	exports: [
		CollapseDirective,
		CpfCnpjDirective,
		DateDirective,
		DisableOnClickDirective,
		EmailDirective,
		NumeroProtocoloDirective,
		OnlyNumbersDirective,
		OrdenacaoDirective,
		PorcentagemDirective,
		RemoveHostDirective,
		FormatarNumeroDirective,
		TelefoneDirective,
		CargaHorariaDirective,
		PlacaCarroDirective
	]
})
export class DirectivesModule { }
