import { Directive, ElementRef, HostListener, OnChanges, Input, SimpleChanges } from '@angular/core';

@Directive({
	selector: '[appCargaHoraria]',
})
export class CargaHorariaDirective implements OnChanges {

	regexStr = '^[0-9]*$';

	constructor(private el: ElementRef) { }

	@Input() ngModel: string = "";

	ngOnChanges(changes: SimpleChanges) {
		if (changes && changes.ngModel && changes.ngModel.currentValue && !changes.ngModel.previousValue) {
			this.format(changes.ngModel.currentValue);
		}
	}

	@HostListener('keyup') format(value : any) {
		const regEx = new RegExp(this.regexStr);

		if (!value) { value = this.el.nativeElement.value; }

		if (regEx.test(value)) {
			/*if (value.toString().substr(0, 1) == 0 || value.toString().substr(0, 1) == '00') {
				value = value.toString().replace(0, '');
			}
			if (value <= 0 || value > 12) {
				value = value.toString().replace(value, '');
			}*/

			setTimeout(() => this.el.nativeElement.value = value, 0);
		} else {
			value = value.toString().replace(value, '');
			setTimeout(() => this.el.nativeElement.value = value, 0);
		}
	}
}
