import { Component, Output, Input, OnInit, EventEmitter, ViewChild } from '@angular/core';
import { ApplicationService } from '../../../../../shared/services/application.service';
import { MessagesService } from '../../../../../shared/services/messages.service';
import { Router } from '@angular/router';

@Component({
	selector: 'app-grid-produtos-vinculados-ppb',
	templateUrl: './grid.component.html'
})

export class GridProdutosVinculadosAoPPBComponent {

	ocultarTituloGrid = true;

	constructor(
		private applicationService: ApplicationService,
		private msg: MessagesService,
		private router: Router,
	) { }

	@Input() lista!: any[];
	@Input() total!: number;
	@Input() size!: number;
	@Input() page!: number;
	@Input() sorted!: string;
	@Input() isUsuarioInterno: boolean = false;
	@Input() exportarListagem!: boolean;
	@Input() parametros: any = {};
	@Input() formPai: any;
	@Input() somenteLeitura!: boolean;
	
	tituloGrid = 'Produtos Vinculados ao PPB';

	@Output() onChangeSort: EventEmitter<any> = new EventEmitter();
	@Output() onChangeSize: EventEmitter<any> = new EventEmitter();
	@Output() onChangePage: EventEmitter<any> = new EventEmitter();
	@Output() onChange: EventEmitter<any> = new EventEmitter();

	changeSize($event : any) {
		this.onChangeSize.emit($event);
	}

	changeSort($event : any) {
		this.sorted = $event.field;
		this.onChangeSort.emit($event);
		this.changePage(this.page);
	}

	changePage($event : any) {
		this.page = $event;
		this.onChangePage.emit($event);
		console.log($event);
	}

}
