<app-grid
    [(titulo)]="tituloGrid"
    [(lista)]="lista"
    [(page)] = "page"
    [(size)] = "size"
    [(total)] = "total"
    [(parametros)]="parametros"
    (onChangeSize) = "changeSize($event)"
    (onChangePage) = "changePage($event)"
    (onChangeSort) = "changeSort($event)">

    <div class="table-responsive no-margin-bottom no-border">
        <table class="table table-striped">
            <thead class="table-header-color">
                <tr>
                    <th class="th-100 text-left">
                        <app-ordenacao
                            field="DataHoraAcao"
                            [sorted]="sorted"
                            (onChangeSort)="changeSort($event)">
                            Data/Hora
                        </app-ordenacao>
                    </th>
                    <th class="th-100 text-left">
                        <app-ordenacao
                            field="NumeroUsuario"
                            [sorted]="sorted"
                            (onChangeSort)="changeSort($event)">
                            Login
                        </app-ordenacao>
                    </th>
                    <th class="th-100 text-left">
                        <app-ordenacao
                            field="NomeUsuario"
                            [sorted]="sorted"
                            (onChangeSort)="changeSort($event)">
                            Responsável
                        </app-ordenacao>
                    </th>
                    <th class="th-100 text-left">
                        <app-ordenacao
                            field="DescricaoAcao"
                            [sorted]="sorted"
                            (onChangeSort)="changeSort($event)">
                        Ação
                        </app-ordenacao>
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of lista">
                    <td class="text-left">{{ item.dataHoraAcaoString }}</td>
                    <td class="text-left">{{ item.numeroUsuario }}</td>
                    <td class="text-left">{{ item.nomeUsuario }}</td>
                    <td class="text-left">{{ item.descricaoAcao }}</td>
                </tr>
            </tbody>
        </table>
    </div>
</app-grid>
