import { Component, OnInit, ViewEncapsulation, Input } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser'; // https://angular.io/guide/set-document-title // https://angular.io/api/platform-browser/Meta
import { ApplicationService } from "./shared/services/application.service";
import { ActivatedRoute } from '@angular/router';
import { environment } from '../environments/environment';
import { EnumPerfil } from './shared/enums/EnumPerfil';
import { subscribeOn } from 'rxjs/operators';
import { ModalService } from './shared/services/modal.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css']
})
export class AppComponent {
    public constructor(
        private modal : ModalService,
        private titleService: Title,
        private Meta: Meta,
        private applicationService: ApplicationService,
        private route: ActivatedRoute,
    ) { }

    private sub: any;

    /*
    usuario: string = "2222222222";
    senha: string = "testeteste";
    */

    usuario: string = "";
    senha: string = "";
    isExibirLogin: boolean = false;
    mensagem: string = "";
    ano: number = 0;

    public setTitle(newTitle: string) {
        this.titleService.setTitle(newTitle);
    }

    @Input() authenticated: boolean = false;

    click() {
        this.applicationService.post("Login", { usuario: this.usuario, senha: this.senha }).subscribe((data: any) => {
            if(data.statusCode == 200)
			{
				this.mensagem = 'Login realizado com sucesso';
				this.applicationService.setToken(data.token);
                this.authenticated = true;
			}
			else if(data.statusCode == 403)
			{
                this.authenticated = false;
				this.modal.infoError("Usuário nao Encontrado! Verifique o Login e Senha e tente novamente.", "Atenção").subscribe( () => { window.location.reload; });
			} else {
                this.authenticated = false;
				this.modal.error(data.message, "Falha ao Efetuar Login - " + data.statusCode).subscribe( () => { window.location.reload; });
			}
        });
    }

    ngOnInit() {
       //console.log(environment)
        if(this.applicationService.getToken()) //se ja estiver autenticado
        {
            this.authenticated = true;
        }
        else
        {
            this.Meta.addTag({ name: 'description', content: 'Sistema de Ingresso de Mercadoria Nacional' });
            this.Meta.addTag({ name: 'keywords', content: 'ingresso, suframa, simnac, mercadoria, nacional' });
            this.titleService.setTitle("PRJ - Mppb");
            this.isExibirLogin = environment.developmentMode;

            this.ano = new Date().getFullYear();

            let hash = window.location.hash.toLowerCase();

            var certificado = false;

            if (window.location.hash.includes("?cert=true")){
                certificado = true;
            } else if (window.location.href.includes("acesso-anonimo")){
              window.location.replace(environment.serviceUrl+'CertificadoAnonRedirect');
            } else if (window.location.href.includes("anonimo=true")){
              certificado = true;
            }
            else{
            window.location.replace(environment.serviceUrl+'CertificadoRedirect');
            }


            if(!window.location.href.includes("anonimo=true")) {
              sessionStorage.removeItem("anon");
              this.applicationService.removeToken();
              document.body.style.backgroundImage = "url('assets/images/bg.png')";

              if (window.location.search.startsWith("?ticket=")) {

                  window.location.replace(environment.mppbUrl);
              }else if (certificado == true && window.location.search.startsWith("?token=")){
                  var token = window.location.search.substr(7);
                  this.applicationService.setToken(token);
                  this.authenticated = true;
              }
            }else{
              this.authenticated = true;
              this.usuario = "anon";
              this.senha = "anon";
              sessionStorage.setItem("anon","true");
              this.click();
            }
        }
    }

    logout($event){
        this.ngOnInit();
        this.authenticated = $event; //false
    }

}
