<div
  class="slim-scroll padder-h"
  data-height="auto"
  data-disable-fade-out="true"
  data-distance="0"
  data-size="10px"
  data-railopacity="0.2"
>
  <div class="row m-b-md">
    <div class="col-lg-12">
      <app-titulo>Solicitar Cadastro de PPB</app-titulo>
      <app-sub-titulo>Manter PPB</app-sub-titulo>
    </div>
  </div>

  <section class="panel panel-default">
    <header class="panel-heading">
      <div class="pull-right">
        <a
          [ngClass]="
            exibirFormulario
              ? 'btn btn-info btn-sm pull-left'
              : 'btn btn-warning btn-sm pull-left'
          "
          *ngIf="path == 'editar' || path == 'validacao'"
          (click)="changeMudarFormularios()"
          data-toggle="tooltip"
        >
          <i class="fa fa-eye"></i>
          {{
            exibirFormulario
              ? "Visualizar Listagens do PPB"
              : "Alterar Formulário"
          }}
        </a>
        <a
          id="btn-voltar-solicitacoes-ppb"
          name="btn-voltar-solicitacoes-ppb"
          (click)="voltar()"
          class="btn btn-default btn-sm"
        >
          <i class="fa fa-arrow-left"></i> Voltar
        </a>
        <a
          id="btn-salvar-solicitacoes-ppb"
          name="btn-salvar-solicitacoes-ppb"
          *ngIf="habilitarInputs && exibirFormulario"
          (click)="validar()"
          class="btn btn-primary btn-sm"
        >
          <i class="fa fa-save"></i> Salvar
        </a>
        <a
          *ngIf="!exibirFormulario"
          (click)="validaPpb()"
          title="Validar"
          data-original-title="Validar"
          data-toggle="tooltip"
          class="btn btn-default btn-sm"
        >
          <i class="fa fa-check-square-o"></i> Validar
        </a>
        <a
          *ngIf="!exibirFormulario"
          (click)="abrirFinalizar(parametros.ppbVM)"
          class="btn btn-default btn-sm"
          data-toggle="tooltip"
          title="Finalizar"
          data-original-title="Finalizar"
        >
          <i class="i i-paperplane"></i> Finalizar
        </a>
      </div>

      <div class="pull-right">
        <a
          *ngIf="!exibirFormulario && (path == 'editar' || path == 'validacao')"
          class="btn btn-default btn-sm"
          data-toggle="tooltip"
          (click)="abrirHistorico()"
        >
          <i class="i i-history"></i> Histórico das Listagens
        </a>
      </div>

      <h2 class="panel-title h5">Formulário</h2>
    </header>

    <div class="panel-body">
      <!--###################################-->
      <!--##### FORMULÁRIO DE CADASTRO  #####-->
      <!--###################################-->
      <div>
        <div class="row form-group">
          <div class="col-lg-3">
            <label for="descricao" class="required">Tipo de Publicação: </label>
            <select
              name="tipo-publicacao-solicitacoes-ppb"
              id="tipo-publicacao-solicitacoes-ppb"
              class="form-control"
              (change)="usuarioAlterouFormulario = true"
              [ngClass]="
                objetoValidacao.pendenciaTipoPublicacao
                  ? 'pendencia-validacao'
                  : ''
              "
              [attr.disabled]="
                !habilitarInputs || !exibirFormulario ? true : null
              "
              [(ngModel)]="parametros.ppbVM.tipoPublicacao"
            >
              <option
                value="undefined"
                [selected]="path === 'novo' ? true : null"
                disabled
              >
                Selecione uma opção ...
              </option>
              <option value="1">FIXAÇÃO (NOVO)</option>
              <option value="2">ALTERAÇÃO POR MEIO DE REVOGAÇÃO</option>
              <option value="3">ALTERAÇÃO PARCIAL</option>
              <option value="4">NORMA COMPLEMENTAR</option>
              <option value="5">
                FIXAÇÃO DE PPB (Genérico para Especifico)
              </option>
            </select>
          </div>

          <!-- ################################# -->
          <!-- #### DOCUMENTO DE REFERENCIA #### -->
          <!-- ################################# -->

          <div
            style="margin-top: -1px"
            class="col-sm-9"
            *ngIf="parametros.ppbVM.tipoPublicacao != 1"
          >
            <label
              for="descricao"
              [ngClass]="
                parametros.ppbVM.tipoPublicacao == 5 &&
                !flagAtivarTxtDocReferencia
                  ? 'required'
                  : ''
              "
              >Informe o Documento de Referência:</label
            >
            <app-drop-list-select2
              [isDisabled]="
                !habilitarInputs ||
                !exibirFormulario ||
                !habInputDocumentoReferencia
              "
              [InputValue]="parametros.descricao"
              #DocumentoReferencia
              id="documentoReferencia-solicitacoes-ppb"
              name="documentoReferencia-solicitacoes-ppb"
              (valorModificado)="
                parametros.idPPBDocumentoSelecionado = $event;
                preencheArrayDocReferencia($event);
                usuarioAlterouFormulario = true
              "
              servico="DocumentoDeReferencia"
              [placeholder]="habilitarInputs ? 'Selecione o documento ...' : ''"
              [(ngModel)]="parametros.idPPBDocumentoSelecionado"
            >
            </app-drop-list-select2>

            <div *ngIf="arrayDocumentosVinculados">
              <ul>
                <li
                  *ngFor="let item of arrayDocumentosVinculados; let i = index"
                  [attr.data-index]="i"
                >
                  {{ item.descricaoDocumentoVinculado }}
                  <a
                    style="color: red; cursor: pointer; margin-left: 10px"
                    *ngIf="habilitarInputs && exibirFormulario"
                    (click)="excluirDocumento(i)"
                  >
                    <b>remover</b>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <!-- ######################################## -->
        <!-- ####### CHECK DOCUMENTO PRINCIPAL ###### -->
        <!-- ######################################## -->
        <div
          class="row form-group"
          *ngIf="
            parametros.ppbVM.tipoPublicacao == 2 &&
            arrayDocumentosVinculados != null &&
              arrayDocumentosVinculados != undefined &&
              arrayDocumentosVinculados.length == 0
          "
        >
          <div class="col-lg-6">
            <div style="margin-top: -1px">
              <input
                [attr.disabled]="
                  !habilitarInputs || !exibirFormulario ? true : null
                "
                id="check-cadastrar-doc-principal"
                (change)="usuarioAlterouFormulario = true"
                name="check-cadastrar-doc-principal"
                [ngModelOptions]="{ standalone: false }"
                type="checkbox"
                [(ngModel)]="parametros.flagIsDocumentoPrincipal"
              />
              <label for="Pesquisa">
                PPB corresponde a Fixação por meio de Revogação?
              </label>
            </div>
          </div>
        </div>

        <!-- ######################################## -->
        <!-- #### DOCUMENTO DE REFERENCIA ANTIGO #### -->
        <!-- ######################################## -->

        <div
          class="row form-group"
          *ngIf="parametros.ppbVM.tipoPublicacao != 1"
        >
          <div style="margin-top: -1px" class="col-sm-3">
            <input
              id="check-cadastrar-manualmente-doc-referencia"
              name="check-cadastrar-manualmente-doc-referencia"
              [ngModelOptions]="{ standalone: false }"
              type="checkbox"
              (click)="usuarioAlterouFormulario = true"
              [attr.disabled]="
                !habilitarInputs || !exibirFormulario ? true : null
              "
              [(ngModel)]="flagAtivarTxtDocReferencia"
            />
            <label for="Pesquisa">Documento de referência antigo?</label>
          </div>
          <div style="margin-top: -1px" class="col-sm-9">
            <textarea
              *ngIf="flagAtivarTxtDocReferencia"
              [attr.disabled]="
                !habilitarInputs || !exibirFormulario ? true : null
              "
              type="text"
              [(ngModel)]="parametros.ppbVM.descricaoDocReferencia"
              class="form-control"
              (change)="usuarioAlterouFormulario = true"
              id="desc-doc-referencia"
              maxlength="1000"
              name="desc-doc-referencia"
              style="resize: none"
            >
            </textarea>
            <div
              class="alert-danger"
              *ngIf="
                !parametros.ppbVM.descricaoDocReferencia &&
                flagAtivarTxtDocReferencia
              "
            >
              <div>
                <b style="margin-left: 15px"
                  >Informe o Documento de Referência!</b
                >
              </div>
            </div>
          </div>
        </div>

        <!-- ############################# -->
        <!-- #### DOCUMENTO POSTERIOR  ####  -->
        <!-- ############################# -->

        <div class="row form-group">
          <div style="margin-top: -1px" class="col-sm-3">
            <input
              [ngModelOptions]="{ standalone: false }"
              id="check-cadastrar-documento-posterior"
              name="check-cadastrar-documento-posterior"
              [attr.disabled]="
                !habilitarInputs || !exibirFormulario ? true : null
              "
              type="checkbox"
              [(ngModel)]="parametros.flagIsDocumentoPosterior"
              (click)="usuarioAlterouFormulario = true"
            />
            <label for="Pesquisa">Há documento posterior?</label>
          </div>
          <div style="margin-top: -1px" class="col-sm-9">
            <app-drop-list-select2
              *ngIf="parametros.flagIsDocumentoPosterior"
              [isDisabled]="!habilitarInputs || !exibirFormulario"
              [InputValue]="parametros.descricao"
              #DocumentoPosterior
              id="documento-referencia-solicitacoes-ppb"
              name="documento-referencia-solicitacoes-ppb"
              (valorModificado)="
                parametros.idPPBDocumentoSelecionado = $event;
                preencheArrayDocPosterior($event);
                usuarioAlterouFormulario = true
              "
              servico="DocumentosPosteriores"
              [placeholder]="habilitarInputs ? 'Selecione o documento ...' : ''"
              [(ngModel)]="parametros.idPPBDocumentoSelecionado"
            >
            </app-drop-list-select2>
            <div
              class="alert-danger"
              *ngIf="
                !arrayDocumentoPosteriores.length && flagAtivaTxtDocPosterior
              "
            >
              <div>
                <b style="margin-left: 15px">Informe o Documento Posterior!</b>
              </div>
            </div>
            <div *ngIf="arrayDocumentoPosteriores">
              <ul>
                <li
                  *ngFor="let item of arrayDocumentoPosteriores; let i = index"
                  [attr.data-index]="i"
                >
                  {{ item.descricaoDocumentoVinculado }}
                  <a
                    style="color: red; cursor: pointer; margin-left: 10px"
                    *ngIf="habilitarInputs && exibirFormulario"
                    (click)="excluirDocumentoPosterior(i)"
                  >
                    <b>remover</b>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div class="row form-group">
          <div class="col-lg-12">
            <label>
              <input name="pontminima" type="checkbox" value="true"
              (click)="parametros.ppbVM.existePontuacaoMinima = !parametros.ppbVM.existePontuacaoMinima"
              [(ngModel)]="parametros.ppbVM.existePontuacaoMinima"
              [attr.disabled]="
                !habilitarInputs || !exibirFormulario ? true : null
              "
              />Existe pontuação mínima?
            </label>
          </div>
        </div>
        <div class="row form-group">

          <div class="col-lg-3 text-nowrap">
            <label for="codigo" class="required">Início de Vigência:</label>
            <input
              #dataInicioVigencia
              type="date"
              [ngClass]="
                objetoValidacao.pendenciaInicioVigencia
                  ? 'pendencia-validacao'
                  : ''
              "
              id="dataInicioVigencia-solicitacoes-ppb"
              name="dataInicioVigencia-solicitacoes-ppb"
              min="0001-01-01"
              max="9999-12-31"
              class="form-control"
              maxlength="10"
              [(ngModel)]="_dataVigencia"
              [attr.disabled]="
                !habilitarInputs || !exibirFormulario ? true : null
              "
              value="{{ _dataVigencia }}"
              (change)="
                transformDataVigencia(); usuarioAlterouFormulario = true
              "
            />
          </div>
          <div class="col-lg-8">
            <b>Anexar Arquivos</b>
            <b style="color: red"> (Somente PDF! Max 10MB) </b>
            <div style="margin-top: 15px" *ngFor="let item of listaAnexos">
              <div>
                <button
                  name="btn-download-arquivo-solicitacoes-ppb"
                  id="btn-download-arquivo-solicitacoes-ppb"
                  type="button"
                  class="btn btn-primary-real btn-sm m-r-sm"
                  data-toggle="tooltip"
                  title="Baixar"
                  (click)="buscarAnexoParaDownload(item)"
                >
                  <i class="fa fa-download"></i>
                </button>
                <button
                  [attr.disabled]="
                    !habilitarInputs || !exibirFormulario ? true : null
                  "
                  name="btn-excluir-arquivo-solicitacoes-ppb"
                  id="btn-excluir-arquivo-solicitacoes-ppb"
                  type="button"
                  class="btn btn-danger btn-sm"
                  data-toggle="tooltip"
                  title="Excluir"
                  (click)="confirmarExclusao(item)"
                >
                  <i class="fa fa-trash-o"></i>
                </button>
                <b style="color: red; margin-left: 15px">Nome do arquivo: </b>
                {{ item.descricaoArquivo }}
              </div>
            </div>
            <div>
              <div style="margin-top: 6px; margin-left: -10px">
                <input
                  [ngClass]="
                    objetoValidacao.pendenciaAnexarDocumentoComprobatorio
                      ? 'pendencia-validacao'
                      : ''
                  "
                  (change)="
                    onFileChange($event); usuarioAlterouFormulario = true
                  "
                  accept="application/pdf"
                  id="arquivo-solicitacoes-ppb"
                  [attr.disabled]="
                    !habilitarInputs || !exibirFormulario ? true : null
                  "
                  name="arquivo-solicitacoes-ppb"
                  style="display: block; padding: 15px; width: 100%"
                  type="file"
                  #anexoPPB
                />
              </div>
            </div>
          </div>
        </div>

        <div class="row form-group">
          <div class="col-lg-3 text-nowrap">
            <label for="codigo" class="required">Data Publicação:</label>
            <input
              #dataPublicacao
              [attr.disabled]="
                !habilitarInputs || !exibirFormulario ? true : null
              "
              type="date"
              id="dataPublicacao-arquivo-solicitacoes-ppb"
              name="dataPublicacao-arquivo-solicitacoes-ppb"
              min="0001-01-01"
              max="9999-12-31"
              class="form-control date"
              maxlength="10"
              [(ngModel)]="_dataPublicacao"
              value="{{ _dataPublicacao }}"
              autocomplete="off"
              (change)="
                transformDataPublicacao(); usuarioAlterouFormulario = true
              "
            />
          </div>
          <div class="col-lg-9 text-nowrap">
            <label for="codigo">Título:</label>
            <div>
              <span id="descricao-titulo">
                {{
                  parametros.descricaoTipoDocumento
                    ? parametros.descricaoTipoDocumento
                    : ""
                }}
                {{
                  parametros.ppbVM.descricaOrgao
                    ? parametros.ppbVM.descricaOrgao.trim()
                    : ""
                }}
                {{
                  parametros.ppbVM.numeroDocumento != undefined &&
                  parametros.ppbVM.numeroDocumento != null &&
                  parametros.ppbVM.numeroDocumento > 0
                    ? "N° " +
                      (parametros.ppbVM.numeroDocumento.toString().trim()
                        | number : "" : "es") +
                      ","
                    : ""
                }}
                {{ _dataDocumento ? mostraDataTitulo(_dataDocumento) : "" }}
              </span>
            </div>
          </div>
        </div>

        <div class="row form-group" class="id_100" style="margin-left: -15px">
          <div class="col-lg-3">
            <label for="descricao" class="required">Tipo de Documento:</label>
            <a
              *ngIf="parametros.ppbVM.idTipoDocumento && habilitarInputs"
              class="pull-right"
              style="color: red; cursor: pointer"
              (click)="limparTipoDocumento()"
            >
              Limpar
            </a>
            <select
              (change)="
                selectTipoDocumento($event); usuarioAlterouFormulario = true
              "
              *ngIf="listaTipoDocumento.length"
              #tipoDocumento
              [ngClass]="
                objetoValidacao.pendenciaTipoDocumento
                  ? 'pendencia-validacao'
                  : ''
              "
              [attr.disabled]="
                !habilitarInputs || !exibirFormulario ? true : null
              "
              class="form-control"
              [(ngModel)]="parametros.ppbVM.idTipoDocumento"
              name="tipo-de-Documento-arquivo-solicitacoes-ppb"
              id="tipo-de-Documento-arquivo-solicitacoes-ppb"
            >
              <option
                value="undefined"
                [selected]="path === 'novo' ? true : null"
                disabled
              >
                Selecione uma opção ...
              </option>
              <option
                *ngFor="let item of listaTipoDocumento"
                [ngValue]="item.id"
              >
                {{ item.descricao }}
              </option>
            </select>
          </div>

          <div class="col-lg-2">
            <label for="codigo" class="required">Número:</label>
            <a
              *ngIf="
                parametros.ppbVM.numeroDocumento &&
                habilitarInputs && exibirFormulario
              "
              class="pull-right"
              style="color: red; cursor: pointer"
              (click)="parametros.ppbVM.numeroDocumento = ''"
            >
              Limpar
            </a>
            <input
              [attr.disabled]="
                !habilitarInputs || !exibirFormulario ? true : null
              "
              type="text"
              (change)="usuarioAlterouFormulario = true"
              name="numero-documento-solicitacoes-ppb"
              id="numero-documento-solicitacoes-ppb"
              class="form-control"
              mask="separator"
              thousandSeparator="."
              maxlength="13"
              [(ngModel)]="parametros.ppbVM.numeroDocumento"
            />
            <!--
                           OS_10027
                          mask="separator"
                          thousandSeparator="."
                          maxlength="13"
                         -->
          </div>
          <div class="col-lg-2 text-nowrap">
            <label for="codigo" class="required">Data:</label>
            <a
              *ngIf="
                parametros.dataDocumentoString &&
                habilitarInputs && exibirFormulario
              "
              class="pull-right"
              style="color: red; cursor: pointer"
              (click)="limparDataDocumento()"
            >
              Limpar
            </a>
            <input
              [attr.disabled]="
                !habilitarInputs || !exibirFormulario ? true : null
              "
              #dataDocumento
              type="date"
              id="data-documento-solicitacoes-ppb"
              name="data-documento-solicitacoes-ppb"
              min="0001-01-01"
              max="9999-12-31"
              class="form-control date"
              maxlength="10"
              [(ngModel)]="_dataDocumento"
              value="{{ _dataDocumento }}"
              (change)="
                transformDataDocumento(); usuarioAlterouFormulario = true
              "
            />
          </div>

          <div class="col-sm-5">
            <label for="descricao">Órgãos do PPB:</label>
            <a
              *ngIf="
                parametros.ppbVM.descricaOrgao &&
                habilitarInputs && exibirFormulario
              "
              class="pull-right"
              style="color: red; cursor: pointer"
              (click)="parametros.ppbVM.descricaOrgao = ''"
            >
              Limpar
            </a>
            <input
              [attr.disabled]="
                !habilitarInputs || !exibirFormulario ? true : null
              "
              type="text"
              [(ngModel)]="parametros.ppbVM.descricaOrgao"
              class="form-control"
              placeholder="n/a"
              id="orgaos-ppb"
              name="orgaos-ppb"
              maxlength="50"
              (keypress)="validarCampoOrgao($event)"
              (change)="usuarioAlterouFormulario = true"
            />
          </div>
        </div>

        <div class="row form-group" style="margin-left: 2px">
          <div class="row form-group">
            <div style="margin-top: 10px" class="col-lg-12">
              <label for="resumo-tecnico" class="required"
                >Resumo Técnico:</label
              >
              <textarea
                [(ngModel)]="parametros.ppbVM.descricaResumoTecnico"
                class="form-control ng-pristine ng-valid ng-touched"
                maxlength="2000"
                [ngClass]="
                  objetoValidacao.pendenciaResumoTecnico
                    ? 'pendencia-validacao'
                    : ''
                "
                name="resumo-tecnico-solicitacoes-ppb"
                id="resumo-tecnico-solicitacoes-ppb"
                (change)="usuarioAlterouFormulario = true"
                rows="5"
                [attr.disabled]="
                  !habilitarInputs || !exibirFormulario ? true : null
                "
                style="resize: none"
              >
              </textarea>
            </div>
          </div>
        </div>

        <!-- ############################################################################## -->
        <!-- TIPO ETAPA FICA DISPONIVEL PARA PUBLICACAO DIFERENTE DO TIPO ALTERAÇÃO PARCIAL -->
        <!-- ############################################################################## -->

        <div class="row form-group" style="margin-left: 2px">
          <div class="col-lg-12">
            <div class="form-group" ng-reflect-klass="form-group">
              <label class="required">Tipo da Etapa:</label>
              <div class="radio i-checks" style="margin-right: 1px">
                <label>
                  <input
                    #radioSemPontuacao
                    (change)="
                      validaTipoEtapa(1); usuarioAlterouFormulario = true
                    "
                    [(ngModel)]="parametros.ppbVM.tipoEtapa"
                    name="change-grid-itens-sem-pontuacao"
                    id="change-grid-itens-sem-pontuacao"
                    type="radio"
                    [attr.disabled]="
                      !habilitarInputs ||
                      !exibirFormulario ||
                      parametros.ppbVM.tipoPublicacao == 3
                        ? true
                        : null
                    "
                    value="1"
                  /><i></i> Sem Pontuação
                </label>
                <label style="margin-left: 15px">
                  <input
                    #radioPorPontuacao
                    (change)="
                      validaTipoEtapa(2); usuarioAlterouFormulario = true
                    "
                    [(ngModel)]="parametros.ppbVM.tipoEtapa"
                    name="change-grid-itens-por-pontuacao"
                    id="change-grid-itens-por-pontuacao"
                    type="radio"
                    [attr.disabled]="
                      !habilitarInputs ||
                      !exibirFormulario ||
                      parametros.ppbVM.tipoPublicacao == 3
                        ? true
                        : null
                    "
                    value="2"
                  /><i></i> Por Pontuação
                </label>
                <label style="margin-left: 15px">
                  <input
                    #radioPorGrupo
                    (change)="
                      validaTipoEtapa(3); usuarioAlterouFormulario = true
                    "
                    [(ngModel)]="parametros.ppbVM.tipoEtapa"
                    name="change-grid-itens-grupo-pontuacao"
                    id="change-grid-itens-grupo-pontuacao"
                    type="radio"
                    [attr.disabled]="
                      !habilitarInputs ||
                      !exibirFormulario ||
                      parametros.ppbVM.tipoPublicacao == 3
                        ? true
                        : null
                    "
                    value="3"
                  /><i></i> Por Grupo Com Pontuação
                </label>
                <label style="margin-left: 15px">
                  <input
                    #radioPorGrupo
                    (change)="
                      validaTipoEtapa(4); usuarioAlterouFormulario = true
                    "
                    [(ngModel)]="parametros.ppbVM.tipoEtapa"
                    name="change-grid-itens-sem-pontuacao"
                    id="change-grid-itens-sem-pontuacao"
                    type="radio"
                    [attr.disabled]="
                      !habilitarInputs ||
                      !exibirFormulario ||
                      parametros.ppbVM.tipoPublicacao == 3
                        ? true
                        : null
                    "
                    value="4"
                  /><i></i> PPB Multiplo Sem Pontuação
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!--###################################-->
      <!--####### LISTAGENS DOS GRIDS #######-->
      <!--###################################-->
      <div class="row form-group" *ngIf="idPPB > 0 && !exibirFormulario">
        <div class="col-lg-12">
          <app-grupos-ppb
            *ngIf="_isPorGrupo"
            [habilitarInputs]="habilitaBotoesDosGrupos"
            [dataVigenciaInicialPPB]="_dataVigenciaParams"
            [idPPB]="idPPB"
            [buscarVinculos]="buscarVinculos"
            [validacao]="objetoValidacao"
            [isAlteracaoParaRevogacao]="isAlteracaoParaRevogacao"
            [isRetificacao]="isRetificacao"
            [_fromTelaElaboracao]="fromTelaElaboracao"
            [exibeRevogados]="exibeRevogados"
          >
          </app-grupos-ppb>
          <br />
          <app-ppb-multiplo
            *ngIf="_isPPBMultiplo"
            [habilitarInputs]="habilitaBotoesDosPpbsMultiplos"
            [dataVigenciaInicialPPB]="_dataVigenciaParams"
            [idPPB]="idPPB"
            [buscarVinculos]="buscarVinculos"
            [validacao]="objetoValidacao"
            [isAlteracaoParaRevogacao]="isAlteracaoParaRevogacao"
            [isRetificacao]="isRetificacao"
            [_fromTelaElaboracao]="fromTelaElaboracao"
            [exibeRevogados]="exibeRevogados"
          >
          </app-ppb-multiplo>
          <br />
          <app-etapas-ppb
            [dataVigenciaInicialPPB]="_dataVigenciaParams"
            [habilitarInputs]="habilitaBotoesDasEtapas"
            [_isSempontuacao]="_isSempontuacao"
            [_isPorPontuacao]="_isPorPontuacao"
            [_isPorGrupo]="_isPorGrupo"
            [_isPPBMultiplo]="_isPPBMultiplo"
            [idPPB]="idPPB"
            [buscarVinculos]="buscarVinculos"
            [validacao]="objetoValidacao"
            [isAlteracaoParaRevogacao]="isAlteracaoParaRevogacao"
            [isRetificacao]="isRetificacao"
            [_fromTelaElaboracao]="fromTelaElaboracao"
            [exibeRevogados]="exibeRevogados"
          >
          </app-etapas-ppb>
          <br />
          <app-metas-ppb
            *ngIf="_isPorGrupo || _isPorPontuacao"
            [habilitarInputs]="habilitaBotoesDasMetas"
            [idPPB]="idPPB"
            [_isPorPontuacao]="_isPorPontuacao"
            [_isPorGrupo]="_isPorGrupo"
            [buscarVinculos]="buscarVinculos"
            [dataVigenciaInicialPPB]="_dataVigenciaParams"
            [validacao]="objetoValidacao"
            [isAlteracaoParaRevogacao]="isAlteracaoParaRevogacao"
            [isRetificacao]="isRetificacao"
            [_fromTelaElaboracao]="fromTelaElaboracao"
            [exibeRevogados]="exibeRevogados"
          >
          </app-metas-ppb>
          <br />
          <app-cronograma-pontuacao-minima-ppb
            *ngIf="exibirGridPontuacaoMinima"
            [habilitarInputs]="habilitaBotoesDosGrupos"
            [dataVigenciaInicialPPB]="_dataVigenciaParams"
            [idPPB]="idPPB"
            [_isPorPontuacao]="_isPorPontuacao"
            [buscarVinculos]="buscarVinculos"
            [validacao]="objetoValidacao"
            [isAlteracaoParaRevogacao]="isAlteracaoParaRevogacao"
            [isRetificacao]="isRetificacao"
            [_fromTelaElaboracao]="fromTelaElaboracao"
          >
          </app-cronograma-pontuacao-minima-ppb>
          <br />
          <app-condicionantes-ppb
            [dataVigenciaInicialPPB]="_dataVigenciaParams"
            [habilitarInputs]="habilitaBotoesDasCondicionantes"
            [idPPB]="idPPB"
            [buscarVinculos]="buscarVinculos"
            [validacao]="objetoValidacao"
            [isAlteracaoParaRevogacao]="isAlteracaoParaRevogacao"
            [isRetificacao]="isRetificacao"
            [_fromTelaElaboracao]="fromTelaElaboracao"
            [exibeRevogados]="exibeRevogados"
          >
          </app-condicionantes-ppb>
          <br />
          <app-produtos-vinculados
            *ngIf="path == 'visualizar'"
            [habilitarInputs]="habilitarInputs"
            [idPPB]="idPPB"
            [_fromModalListagemPpb]="false"
          >
          </app-produtos-vinculados>
        </div>

        <!--###################################-->
        <!--##### BTN FINALIZAR E VALIDAR #####-->
        <!--###################################-->
        <div
          *ngIf="path == 'editar'"
          style="margin-right: 10px"
          class="pull-right"
        >
          <a
            id="btn-voltar-solicitacoes-ppb"
            name="btn-voltar-solicitacoes-ppb"
            (click)="voltar()"
            class="btn btn-default btn-sm"
          >
            <i class="fa fa-arrow-left"></i> Voltar
          </a>
          <a
            (click)="validaPpb()"
            title="Validar"
            data-original-title="Validar"
            data-toggle="tooltip"
            class="btn btn-default btn-sm"
          >
            <i class="fa fa-check-square-o"></i> Validar
          </a>
          <a
            (click)="abrirFinalizar(parametros.ppbVM)"
            class="btn btn-default btn-sm"
            data-toggle="tooltip"
            title="Finalizar"
            data-original-title="Finalizar"
          >
            <i class="i i-paperplane"></i> Finalizar
          </a>
        </div>
      </div>
    </div>
  </section>
</div>

<app-modal-vinculo-de-produtos
  #appModalVinculoProdutos
  (atualizarListagem)="changeListagem($event)"
>
</app-modal-vinculo-de-produtos>

<app-modal-historico-listagens #appModalHistorico>
</app-modal-historico-listagens>
