import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'mesMeses' })
export class MesMesesPipe implements PipeTransform {
	transform(value: any, args?: any): any {
		value = Number(value);

		if (value == 1) {
			return "1 mês";
		} else if (value > 1) {
			return value + " meses";
		} else {
			return "";
		}
	}
}
