import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ConverterService } from './converter.service';
import { environment } from '../../../environments/environment';

@Injectable()
export class PssService {
	constructor(
		private converterService: ConverterService,
		private http: HttpClient,
	) { }

	createAuthorizationHeader(): HttpHeaders {
		return new HttpHeaders();
	}

	get<T>() {
		let url = environment.menuPssUrl;

		return this.http.get<T>(url, {
			withCredentials: true,
			headers: this.createAuthorizationHeader()
		});
	}

}
