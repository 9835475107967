import { Directive, ElementRef, HostListener, OnChanges, Input, SimpleChanges } from '@angular/core';

@Directive({
	selector: '[ngModel][cpfCnpj]',
})
export class CpfCnpjDirective implements OnChanges {
	@Input() ngModel: string = "";

	constructor(private elementRef: ElementRef) { }

	ngOnChanges(changes: SimpleChanges) {
		if (changes && changes.ngModel && changes.ngModel.currentValue &&
			!changes.ngModel.previousValue && changes.ngModel.currentValue.length > 10) {
			this.format(changes.ngModel.currentValue);
		}
	}

	@HostListener('blur') format(value : any) {
		if (!value) { value = this.elementRef.nativeElement.value; }

		value = value.toString().replace(/\W/g, '');

		if (value.toString().length == 11) {
			value = value.toString().replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, '\$1.\$2.\$3\-\$4');
		} else if (value.toString().length == 14) {
			value = value.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');
		} else {
			value = '';
		}

		setTimeout(() => this.elementRef.nativeElement.value = value, 0);
	}
}
