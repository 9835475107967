import { PPBVM } from './PPBVM';
import { PPBVinculoVM } from './PPBVinculoVM';
import { PPBAnexoVM } from './PPBAnexoVM';
import { DocumentosVinculadosPPBVM } from './DocumentosVinculadorPPBVM';
import { TiposReferenciaVM } from './TiposReferenciaVM';
import {PPBCronogramaVM} from "./PPBCronogramaVM";

export class SolicitarCadastroPPBVM extends PPBAnexoVM {

    ppbVM : PPBVM = new PPBVM();
    ppbVinculoVM : PPBVinculoVM = new PPBVinculoVM();
    ppbAnexoVM : Array<PPBAnexoVM> = new Array<PPBAnexoVM>();
    ppbCronogramaVM: Array<PPBCronogramaVM> = new Array<PPBCronogramaVM>();
    idPPBDocumentoSelecionado : number;
    descricao : string;
    adicionarAnexo : boolean;
    descricaoTipoDocumento : string;
    flagIsNovoAnexo : boolean = true;

    flagIsDocumentoPrincipal : boolean;
    flagIsDocumentoPosterior : boolean;

    documentosVinculados: Array<DocumentosVinculadosPPBVM> = new Array<DocumentosVinculadosPPBVM>();
    documentosPosteriores: Array<DocumentosVinculadosPPBVM> = new Array<DocumentosVinculadosPPBVM>();

    dataPublicacaoString : string;
    dataDocumentoString : string;
    dataInicioVigenciaString : string;

    tipoPublicacoesDasReferencias : TiposReferenciaVM = new TiposReferenciaVM();

}
