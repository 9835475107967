import { Component, Output, Input, OnInit, EventEmitter, ViewChild } from '@angular/core';
import { ApplicationService } from '../../../../../../shared/services/application.service';
import { MessagesService } from '../../../../../../shared/services/messages.service';
import { ModalService } from '../../../../../../shared/services/modal.service';
import { Router } from '@angular/router';
import { PPBVM } from '../../../../../../view-model/PPBVM';
import { ValidationService } from '../../../../../../shared/services/validation.service';
import { EnumStatusPPB } from '../../../../../../shared/enums/EnumStatusPPB';
import { EnumTipoEtapa } from '../../../../../../shared/enums/EnumTipoEtapa';
import { AnexoPPBVM } from '../../../../../../view-model/AnexoPPBVM';

@Component({
	selector: 'app-grid-etapas-produtos',
	templateUrl: './grid.component.html'
})

export class GridEtapaProdutosComponent {

	ocultarTituloGrid = true;

	constructor(
		private applicationService: ApplicationService,
		private modal: ModalService,
		private msg: MessagesService,
		private router: Router,
		private validation: ValidationService,
	) { }

	@Input() lista: any = {};
	@Input() total: number;
	@Input() size: number;
	@Input() page: number;
	@Input() sorted: string;
	@Input() isUsuarioInterno: boolean = false;
	@Input() exportarListagem: boolean;
	@Input() parametros: any = {};
	@Input() formPai: any;
	@Input() somenteLeitura: boolean;
	@Input() mostrarDocumento: boolean;

	@Output() onChangeSort: EventEmitter<any> = new EventEmitter();
	@Output() onChangeSize: EventEmitter<any> = new EventEmitter();
	@Output() onChangePage: EventEmitter<any> = new EventEmitter();
	@Output() onChange: EventEmitter<any> = new EventEmitter();

	@Output() atualizarListagem : EventEmitter<any> = new EventEmitter();

	@ViewChild('appModalEtapasCondicoesPPB') appModalEtapasCondicoesPPB;
	@ViewChild('appModalFinalizar') appModalFinalizar;
	@ViewChild('appModalVinculos') appModalVinculos;
	@ViewChild('appModalHistorico') appModalHistorico;
	@ViewChild('appModalBaixarAnexo') appModalBaixarAnexo;


	tituloGrid = "Produtos Vinculados"

	changeSize($event) {
		this.onChangeSize.emit($event);
		this.changePage(this.page);
	}

	changeSort($event) {
		this.sorted = $event.field;
		this.onChangeSort.emit($event);
		this.changePage(this.page);
	}

	changePage($event) {
		this.page = $event;
		this.onChangePage.emit($event);
	}
}
