import { Component, OnInit, ViewEncapsulation, Input,ViewChild, Output, EventEmitter } from "@angular/core";
import { ApplicationService } from "../shared/services/application.service";
import { AuthenticationService } from "../shared/services/authentication.service";
import { MenuComponent } from "./menu/menu.component";
import { environment } from '../../environments/environment';
import { EnumPerfil } from '../shared/enums/EnumPerfil';
import { ModalService } from '../shared/services/modal.service';


@Component({
	selector: "app-layout",
	templateUrl: "./layout.component.html"
})
export class LayoutComponent implements OnInit {
	versao!: string;
	isSidebar = false;
	username!: string;
	razaoSocial!: string;
	logoutUrl!: string;
	isUsuarioInterno = false;
	isExibirRepresentante: boolean = false;

	isUsuarioSuframa = false;

	@ViewChild(MenuComponent) menutt!:MenuComponent
	@Output() changeUserAutentication: EventEmitter<any> = new EventEmitter();

	@Input()
	authenticated!: boolean;

	@Input()
	intranet!: boolean;

	constructor(
		private modalService : ModalService,
		private applicationService: ApplicationService,
		private authenticationService: AuthenticationService,
		private menu: MenuComponent
	) {}

	ngOnInit() {
		this.logoutUrl = environment.logout
		this.applicationService.get("UsuarioLogado").subscribe((result: any) => {
			if(result.usuCpfCnpj.length == 11) {
				this.username = result.usuCpfCnpj;
				this.razaoSocial = result.usuNomeVistoriador;
				this.isUsuarioInterno = true;
			}
			else if(result.usuCpfCnpj.length == 14) {
				this.username = result.usuCpfCnpj;
				this.razaoSocial = result.usuNomeVistoriador;
				this.isUsuarioInterno = false;
			} else {
				this.username = result.usuCpfCnpj;
				this.razaoSocial = result.usuNomeVistoriador;
				this.isUsuarioInterno = false;
			}
		});
		document.body.style.backgroundImage = ""; // remover o fundo verde do login
	}

	logout(){

		this.modalService.confirmacao("Deseja Sair?").subscribe(
			(isConfirmado : any) => {
				this.applicationService.removeToken();
				this.authenticated = false
        sessionStorage.clear();
				this.changeUserAutentication.emit(false);
			}
		)

	}

	onLogon() {
		this.authenticated = true;
	}

	recarregarLayout(){
		this.applicationService.get("UsuarioLogado").subscribe((result: any) => {
			if(result.usuCpfCnpj != result.usuCpfRepresentanteLogado){
				this.username = result.usuCpfCnpj;
				this.razaoSocial = result.usuNomeVistoriador;
				this.isExibirRepresentante = true;
			}else{
				this.isExibirRepresentante = false;
			}

			// if (result &&
			// 		result.perfis.includes(EnumPerfil.coordenadorCovis) ||
			// 		result.perfis.includes(EnumPerfil.coordenadorCodoc) ||
			// 		result.perfis.includes(EnumPerfil.coordenadorDescentralizado) ||
			// 		result.perfis.includes(EnumPerfil.coordenadorGeral) ||
			// 		result.perfis.includes(EnumPerfil.vistoriador) ||
			// 		result.perfis.includes(EnumPerfil.superintendenteAdjunto)) {
			// 			this.isUsuarioSuframa = true;
			// 		}
			// 		else {
			// 			this.isUsuarioSuframa = false;
			// 		}
		});
		this.menutt.recuperarMenu();
	}

}
