import { Component, Output, Input, EventEmitter } from '@angular/core';
import { ApplicationService } from '../../../../../../shared/services/application.service';
import { ValidationService } from '../../../../../../shared/services/validation.service';
import { MessagesService } from '../../../../../../shared/services/messages.service';
import { ActivatedRoute } from '@angular/router';
import { ModalService } from '../../../../../../shared/services/modal.service';
import { registerLocaleData } from '@angular/common';
import es from '@angular/common/locales/es';
import { EtapaVM } from '../../../../../../view-model/EtapaVM';
import { AngularEditorConfig } from '@kolkov/angular-editor';

@Component({
	selector: 'app-grid-etapa-cronograma',
	templateUrl: './grid-etapa-cronograma.component.html'
})

export class GridEtapaCronogramaComponent {
  dataHoraAtual = new Date(2001, 10, 11);
  radioSelecionado : any;
	ocultarTituloGrid = true;
  listaMock = [
    {
      id: 1,
      dataInicio: '2023-05-15',
      dataFim: '2023-05-15',
      valorPontuacaoMinima : 105
    },
    {
      id: 2,
      dataInicio:'2023-05-15',
      dataFim: '2023-05-15',
      valorPontuacaoMinima : 104
    },
    {
      id: 3,
      dataInicio: this.dataHoraAtual,
      dataFim: this.dataHoraAtual,
      valorPontuacaoMinima : 103
    }
  ]
	path : string;

	constructor(
		private modal : ModalService,
		private validation : ValidationService,
		private route: ActivatedRoute,
		private applicationService: ApplicationService,
		private msg: MessagesService
	) {
		this.path = this.route.snapshot.url[this.route.snapshot.url.length - 1].path;
		this._idPPBRota = Number(this.route.snapshot.params['idPpb']);
    registerLocaleData(es);
	 }

	@Input() lista!: any[];
	@Input() total!: number;
	@Input() size!: number;
	@Input() page!: number;
	@Input() sorted!: string;
	@Input() idPPB: number;
  @Input() operation: string;
	_idPPBRota : number;
  @Input()
  isVisualizar:boolean;


	@Output() onChange: EventEmitter<any> = new EventEmitter();

  @Output() changeParametros: EventEmitter<any> = new EventEmitter();

	@Output() atualizaListagemGrid: EventEmitter<any> = new EventEmitter();

  @Output() itemEnviado : EventEmitter<any> = new EventEmitter();


  config: AngularEditorConfig = {
		height: '700px',
		minHeight: '100px',
		placeholder: 'Enter text here...',
		showToolbar: false, //flag oculta barra de edição
		translate: 'no',
		defaultParagraphSeparator: 'p',
		defaultFontName: 'Arial',
		toolbarHiddenButtons: [
		  ['bold']
		],
		customClasses: [
		  {
			name: "quote",
			class: "quote",
		  },
		  {
			name: 'redText',
			class: 'redText'
		  },
		  {
			name: "titleText",
			class: "titleText",
			tag: "h1",
		  },
		]
	};

	servico = '';
	tituloGrid = 'Etapas do PPB';

	atualizaListagem($event){
		this.atualizaListagemGrid.emit($event);
	}

	formatDate(date : Date){
		return this.validation.getDateWhithChangeFormat('DD/MM/YYYY', date);
	}

  async buscarDescricaoEtapa(item : EtapaVM, indice : number){
    this.applicationService.get("DescricaoEtapa", {id : item.id}).subscribe((result : EtapaVM) => {
    this.lista[indice].descricaoEtapa = result.descricaoEtapa;
    });
	}


  carregarParametros(item : any){
    console.log(item);
    item.radioCheck = true;
    console.log(item);
    this.itemEnviado.emit(item);
    this.changeParametros.emit(item);
  }
}
