import { HTTP_INTERCEPTORS } from "@angular/common/http";
import {
	HttpEvent,
	HttpInterceptor,
	HttpHandler,
	HttpRequest,
	HttpErrorResponse
} from "@angular/common/http";
import { Injectable, NgModule } from "@angular/core";
import { Observable } from "rxjs";
import { Router } from "@angular/router";

import { ModalService } from "../services/modal.service";
import { GeneratorService } from "../services/generator.service";
import { LoadingService } from "../services/loading.service";
import { environment } from "../../../environments/environment";
import { tap } from "rxjs/operators";

@Injectable()
export class HttpsRequestInterceptor implements HttpInterceptor {
	baseUrl: string;

	constructor(
		private modal : ModalService,
		private generator: GeneratorService,
		private loadingService: LoadingService,
		private router: Router
	) {
		this.baseUrl = window.location.href.split("#")[0];
	}

	intercept(
		req: HttpRequest<any>,
		next: HttpHandler
	): Observable<HttpEvent<any>> {

		const dupReq = req.clone({
			headers: req.headers.set("FrontGuid", this.generator.guid())
		});

		return next.handle(dupReq).pipe(
			tap(
				(success: any) => { },
				(error:any) => {

					if (error) {

						const message = error.error || error.statusText || error.message;
	
						if (error.status === 302) {
							this.modal.error(error.error.message, "Error").subscribe((result) => {
								if (result) {
									this.router.navigate([error.error.url]);
								}
							});
						}
	
						if (error.status === 400 || error.status === 401) {
							 this.modal.error(message, 'Erro');
						}
	
						if (!environment.production && error.status == 500) 
						{	
							this.modal.error(message, 'Error - 500').subscribe((isConfirmado) => {	
								console.error(message);	
							});	
						}	

						if (error.status === 0) {
							this.modal.infoError("Error "+error.message, error.name);
						}
					}
				},
				() => {}
			)
		);
	}
}

@NgModule({
	providers: [
		GeneratorService,
		{
			provide: HTTP_INTERCEPTORS,
			useClass: HttpsRequestInterceptor,
			multi: true
		}
	]
})
export class InterceptorsModule { }
