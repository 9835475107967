import {Component, Injectable, OnInit, ViewChild, Input} from "@angular/core";
import {PagedItems} from "../../../../view-model/PagedItems";
import {MessagesService} from "../../../../shared/services/messages.service";
import {ActivatedRoute, Router} from '@angular/router';
import {ValidationService} from "../../../../shared/services/validation.service";
import {ApplicationService} from "../../../../shared/services/application.service";
import {Location} from '@angular/common';
import {PPBGrupoVM} from "../../../../view-model/PPBGrupoVM";
import {ChangeGrupoPPBService} from '../../../../shared/services/changeGrupoPPB.service';
import {ServiceTipoListagemVM} from "../../../../view-model/ServiceTipoListagemVM";
import {FiltrosModalListagemVM} from "../../../../view-model/FiltrosModalListagemVM";
import {GridCronogramaPontuacaoPPBComponent} from "./grid-cronograma/grid.component";

@Component({
  selector: "app-cronograma-pontuacao-minima-ppb",
  templateUrl: "./cronograma-pontuacao-ppb.component.html"
})
@Injectable()

export class CronogramaPontuacaoPPBComponent implements OnInit {

  init = 0;
  carregarGridCronograma = false;
  form = this;
  grid: any = {sort: {}};
  parametros: PPBGrupoVM = new PPBGrupoVM();
  ocultarFiltro: boolean = false;
  ocultarGrid: boolean = true;
  path: string;
  servico = "GrupoPPB";
  isBuscaSalva: boolean = false;
  objetoSession: any = {} = null;

  cronogramaEncontrado: boolean = false


  @Input() habilitarInputs: boolean;
  @Input() idPPB: number;
  @Input() validacao: any = {} = null;
  @Input() dataVigenciaInicialPPB: string;
  @Input() isAlteracaoParaRevogacao: boolean;
  @Input() buscarVinculos: boolean;
  @Input() _fromTelaElaboracao: boolean;
  @Input() isRetificacao: boolean;
  @Input() _isPorPontuacao: boolean;
  flagIsSomenteVigente: boolean = false;
  @Input() objConsultaFiltradaModalListagem: FiltrosModalListagemVM;
  @Input() isConsultaFiltradaModalListagem: boolean = false;
  @Input() _fromModalListagemPpb: boolean;
  @ViewChild('appModalIncluirCronograma') appModalIncluirCronograma: any;
  @ViewChild('gridCronograma') gridCronograma: GridCronogramaPontuacaoPPBComponent;

  constructor(
    private ChangeGrupoPPBService: ChangeGrupoPPBService,
    private route: ActivatedRoute,
    private applicationService: ApplicationService,
    private msg: MessagesService,
    private validation: ValidationService,
    private router: Router,
    private _location: Location
  ) {
    this.path = this.route.snapshot.url[this.route.snapshot.url.length - 1].path;
  }

  ngOnInit() {

    this.listar(this.idPPB);

    this.ChangeGrupoPPBService.AtualizaListagemGrupoPPB.subscribe(
      (obj: ServiceTipoListagemVM) => {
        if (obj != null) {
          //console.log('to aqui - grupos');
          this.flagIsSomenteVigente = obj.isSomenteVigente;
          this.listar(obj.idPpb, obj.getFromSessionStorage);
        }
      }
    );

  }

  ocultar() {
  }

  onChangeSort($event: any) {
    this.grid.sort = $event;
    this.listar(this.idPPB);
  }

  onChangeSize($event: any) {
    this.grid.size = $event;
    this.grid.page = 1;
    this.listar(this.idPPB);
  }

  onChangePage($event: any) {
    this.grid.page = $event;
    this.listar(this.idPPB);
  }

  incluirPontuacaoMinima() {
    this.appModalIncluirCronograma.abrir(this.idPPB, "incluir", null, this.form);
  }

  public callListar() {
    this.listar(this.idPPB);
  }

  public listar(idPPB?: Number, getConsultaFromSessionStorage?: boolean) {

    this.applicationService.get("PontuacaoMinima/ObterCronogramaAnualPBB", {ppbid: idPPB}).subscribe((retorno: any) => {
      if (retorno != null && retorno.length > 0) {

        this.grid.lista = retorno;
        this.grid.total = retorno.length;
        this.carregarGridCronograma = true;
        // this.gridCronograma.carregarDadosSomatorio();
      } else {
        this.grid = {sort: {}};
        this.carregarGridCronograma = false;
      }
    });
  }


  uniqueGroups(lst: any) {
    const uniqueNames = new Set(lst.map(item => item.grpId));
    return Array.from(uniqueNames);
  }

  atualizaListagem(idPPB) {
    this.listar(idPPB, true);
  }

  gravarBusca(objeto: any) {
    sessionStorage.removeItem(this.router.url);
    sessionStorage.setItem("grupo-ppb-" + this.idPPB, JSON.stringify(objeto));
  }

  retornaValorSessao() {
    var router = sessionStorage.getItem("grupo-ppb-" + this.idPPB);
    if (router) {
      this.isBuscaSalva = true;
      this.objetoSession = JSON.parse(sessionStorage.getItem("grupo-ppb-" + this.idPPB));
    } else {
      this.isBuscaSalva = false;
      return null;
    }
  }

}
