import {
  Component,
  Injectable,
  OnInit,
  ViewChild,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';
import { MessagesService } from '../../../../../shared/services/messages.service';
import { ValidationService } from '../../../../../shared/services/validation.service';
import { ApplicationService } from '../../../../../shared/services/application.service';
import { ActivatedRoute } from '@angular/router';
import { Validators, FormBuilder } from '@angular/forms';
import { GrupoPPBVM } from '../../../../../view-model/GrupoPPBVM';
import { ModalService } from '../../../../../shared/services/modal.service';
import { ChangeEtapaPPBService } from '../../../../../shared/services/changeEtapaPPB.service';
import {CronogramaItemVM} from "../../../../../view-model/CronogramaItemVM";

@Component({
  selector: 'app-modal-incluir-cronograma',
  templateUrl: './modal-incluir-cronograma.component.html',
})
@Injectable()
export class ModalIncluirCronogramaComponent implements OnInit {
  idGrupo : any = null;
  serviceDropBox = 'GrupoPontuacaoMinima';
  placeholder = 'Selecione um item ...';
  exibirPlaceholder = true;
  listaPpbPontuacaoMinima : any = [];
  etapaFromGrid: any = {};
  parametros: any = {};
  titulo: string = '';
  parametroIsCarregado: boolean;
  _isIncluir: boolean = false;
  _isAlterar: boolean = false;
  _isExcluir: boolean = false;


  _ExbirParametrosIncluir: boolean = false;
  _ExbirParametrosAlterar: boolean = false;
  _ExbirParametrosExcluir: boolean = false;
  listaEtapas: any = [];
  notifyAfterSelect: boolean = true;

  // grid: any = { sort: {} };

  flagCadastroContinuo: boolean = false;
  servico = 'ListarEtapasComPontuacao';
  operation: string = '';
  title = 'appBootstrap';

  model: any;
  formPaiListar: any;
  carregarDrop: boolean = false;

  @Input() idPPB: number;
  @Input() habilitarInputs: boolean;
  @Input() _isPorPontuacao: boolean;
  @Input() dataVigenciaInicialPPB: string;
  @Output() atualizaListagem: EventEmitter<any> = new EventEmitter();

  @ViewChild('appModalIncluirCronograma') appModalIncluirCronograma: any;
  @ViewChild('appModalIncluirCronogramaBackground')
  appModalIncluirCronogramaBackground: any;
  carregarGridEtapas: boolean = false;

  itemSelecionado = new CronogramaItemVM();

  constructor(
    private ChangeEtapaPPBService: ChangeEtapaPPBService,
    private modal: ModalService,
    private applicationService: ApplicationService,
    private fb: FormBuilder,
    private msg: MessagesService,
    private validation: ValidationService,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this._isIncluir = false;
    this._isAlterar = false;
    this._isExcluir = false;
    this.idGrupo = null;
    this.limparInputs();
  }

  limparInputs(){
    this.itemSelecionado.ano2023 = 0;
    this.itemSelecionado.ano2024 = 0;
    this.itemSelecionado.ano2025 = 0;
    this.itemSelecionado.ano2026 = 0;
    this.itemSelecionado.ano2027 = 0;
  }

  public abrir(idPPB: number, operation: string, idGrupo?: string , formPai?: any) {
    this.carregarDrop = true;
    this.formPaiListar = formPai;
    this.flagCadastroContinuo = false;
    this.limparVariaveis();
    this.parametros.idPPB = idPPB;
    this.operation = operation;
    if (operation == 'incluir') {
      this.titulo = 'Incluir';
      this._isIncluir = true;
      if(this._isPorPontuacao){
        this.buscarEtapasSemGrupo();
      }
    } else if (operation == 'alterar') {
      this.titulo = 'Alterar';
      this.idGrupo = idGrupo;
      this._isAlterar = true;
      if(this._isPorPontuacao){
        this.buscarEtapasSemGrupo();
      }else{
        this.buscar();
      }

    } else if (operation == 'excluir') {
      this.titulo = 'Excluir';
      this.idGrupo = idGrupo;
      this._isExcluir = true;
      if(this._isPorPontuacao){
        this.buscarEtapasSemGrupo();
      }else{
        this.buscar();
      }
    } else {
      this.fechar();
    }
    this.appModalIncluirCronogramaBackground.nativeElement.style.display =
      'block';
    this.appModalIncluirCronograma.nativeElement.style.display = 'block';

  }

  public fechar() {
    this.carregarDrop = false;
    this.flagCadastroContinuo = false;
    this.limparVariaveis();
    this.limparInputs();
    this.appModalIncluirCronogramaBackground.nativeElement.style.display =
      'none';
    this.appModalIncluirCronograma.nativeElement.style.display = 'none';
    this.limparCheck();
    this.formPaiListar.callListar();
  }

  limparCheck() {

    this._ExbirParametrosIncluir = false;
    this._ExbirParametrosAlterar = false;
    this._ExbirParametrosExcluir = false;
    this.listaEtapas.forEach((item, index) => {
      item.radioCheck = false;
    });
  }

  buscarEtapas(idGrupo: any){
    if (idGrupo != ''){
      this.idGrupo = idGrupo;
      this.buscar();
    }
  }
  buscar() {
    if (this.operation == 'incluir' && (this.idGrupo != '' || this.idGrupo != 0)) {
      this.parametros.idGrupo = this.idGrupo;
      this.applicationService.get("ListarEtapasComPontuacao/RecuperarEtapasPorGrupo", this.parametros).subscribe((result: any) => {
        if (result != null && result.length > 0) {
          this.listaEtapas = result;
        } else {
          this.listaEtapas = [];
        }
        this.carregarGridEtapas = true;
      });
    } else{
      let obj = {
        idGrupo : parseInt(this.idGrupo),
        idPPB : this.idPPB
      }
      this.parametros.idGrupo = parseInt(this.idGrupo);
      this.applicationService.get("ListarEtapasComPontuacao/ListarEtapasFiltradas", obj).subscribe((result: any) => {
        if (result != null && result.length > 0) {
          this.listaEtapas = result;
        } else {
          this.listaEtapas = [];
        }
        this.carregarGridEtapas = true;
      });
    }

  }

  buscarEtapasSemGrupo() {
    this.applicationService.get("ListarEtapasComPontuacao/ListarEtapasSemGrupo", this.parametros).subscribe((result: any) => {
      if (result != null && result.length > 0) {
        this.listaEtapas = result;
      } else {
        this.listaEtapas = [];
      }
      this.carregarGridEtapas = true;
    });
  }

  validar() {

    if (!this._isPorPontuacao && (this.idGrupo == null || this.idGrupo == '')) {
      return this.modal.error("Selecione algum Grupo.", "Atenção");
    }

    if(this._isAlterar){
      let erroDataInicial = false;
      let erroDataFinalMenorInicial = false;
      let erroValorPontuacao = false;
    }
    this.salvar();
  }
  salvar() {

    this.itemSelecionado.idPPB = this.etapaFromGrid.idPPB;
    this.itemSelecionado.idCriacao = this.etapaFromGrid.idCriacao;
    this.itemSelecionado.idPPBGrupo =parseInt(this.idGrupo);
    this.itemSelecionado.descricaoEtapa = this.etapaFromGrid.descricaoEtapa;
    this.itemSelecionado.descricaoTopico = this.etapaFromGrid.descricaTopico;

    this.parametros = this.itemSelecionado;

      this.applicationService.post("PontuacaoMinima/SalvarPontuacaoDoAnexo", this.parametros).subscribe((retorno : any) => {
        if(retorno == 1 ){
          this.modal.infoSucess('Inserido com Sucesso!', 'Sucesso');
          if (this.flagCadastroContinuo) {
            this.limparCheck();
            this.parametros = {};
          } else {
            this.limparInputs()
            this.fechar();
          }
        }
      });
  }

  limparVariaveis() {
    this.parametros = new GrupoPPBVM();
    this._ExbirParametrosAlterar = false;
    this._ExbirParametrosIncluir = false;
    this._ExbirParametrosExcluir = false;
    this.idGrupo = null;
  }

  changeParametros(event: any) {
    this.etapaFromGrid = event;
    console.log(this.etapaFromGrid)
    if (this.operation == 'incluir') {
      this._ExbirParametrosIncluir = true;
    } else{
      let obj = {
        idGrupo : parseInt(this.idGrupo),
        idPPB : this.idPPB,
        idEtapa : event.id
      }
      this.applicationService.get("PontuacaoMinima/ListarPontuacaoMinima", obj).subscribe((result : any) => {
        this.listaPpbPontuacaoMinima = result;
        if (this.operation == 'alterar'){
          this._ExbirParametrosAlterar = true;
        }
        else if (this.operation == 'excluir') {
          this._ExbirParametrosExcluir = true;
        }

      });
    }
  }
  excluirPontuacaoMinima(id: number) {
    this.applicationService.delete("PontuacaoMinima", id).subscribe((retorno : any) => {
        if(retorno == 1 ){
          this.modal.infoSucess('Excluido com Sucesso!', 'Sucesso');
          if (this.flagCadastroContinuo) {
            this.limparCheck();
            this.parametros = {};
          } else {
            this.fechar();
          }
        }
    });
  }

  formatDate(data: string) {
    return this.validation.getDateWhithChangeFormat(
      'YYYY-MM-DD',
      new Date(data),
      true
    );
  }
}
