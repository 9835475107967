import {
  Component,
  EventEmitter,
  Injectable,
  OnInit,
  Output,
  Input,
  ViewChild,
} from '@angular/core';
import { MessagesService } from '../../../../../shared/services/messages.service';
import { ValidationService } from '../../../../../shared/services/validation.service';
import { ApplicationService } from '../../../../../shared/services/application.service';
import { ActivatedRoute } from '@angular/router';
import { CondicionantesPPBComponent } from '../condicionantes-ppb.component';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { EnumRestResponse } from '../../../../../shared/enums/EnumRestResponse';
import { ModalService } from '../../../../../shared/services/modal.service';
import { Validators, FormBuilder } from '@angular/forms';
import { CondicionanteVM } from '../../../../../view-model/CondicionanteVM';
import { ValoresPorGrupoVM } from '../../../../../view-model/ValoresPorGrupoVM';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-modal-incluir-condicionantes-ppb',
  templateUrl: './modal-incluir-condicionantes-ppb.component.html',
})
export class ModalIncluirCondicionantesPPBAtuacao implements OnInit {
  listaDadosAtuacao: any;
  ocultarInputAnexo = false;
  parametros: CondicionanteVM = new CondicionanteVM();
  isDesabilitado: boolean = true;
  ocultaCombos: boolean = true;
  servico = 'CondicionantesPPB';
  flagCadastroContinuo: boolean = false;
  operation: string = '';

  _errorDataInicioNaoPreenchida: boolean = false;
  _errorDataFinalMenorDataCorrente: boolean = false;
  _errorDataFinalMaiorInicial: boolean = false;

  _flagDesabilitaTopico: boolean = false;

  _idPPBRota: number;
  _isPPBMultiplo: boolean;
  _isPPBGrupo: boolean;

  _flagTrouxeItensPPBMultiplo: boolean = false;
  _flagTrouxeItensPPBGrupo: boolean = false;

  name = 'Angular 12.1.2';
  htmlContent = '';
  flagHabilitaComponenteTextual: boolean = true;
  listaPPBMultiplo = new Array<ValoresPorGrupoVM>();
  listaPPBGrupo = new Array<any>();
  serviceDropBox = '';

  @Input() dataVigenciaInicialPPB: string;
  @Output() atualizaListagem: EventEmitter<any> = new EventEmitter();
  @ViewChild('appModalIncluirCondicionantesPPB')
  appModalIncluirCondicionantesPPB: any;
  @ViewChild('appModalIncluirCondicionantesPPBBackground')
  appModalIncluirCondicionantesPPBBackground: any;

  @ViewChild('ppbGrupoDrop')
  _ppbGrupoDropList: any;
  idCondicionante: number;

  constructor(
    private modal: ModalService,
    private CondicionantesPPBComponent: CondicionantesPPBComponent,
    private applicationService: ApplicationService,
    private msg: MessagesService,
    private fb: FormBuilder,
    private validation: ValidationService,
    private route: ActivatedRoute
  ) {
    this._idPPBRota = Number(this.route.snapshot.params['idPpb']);
  }

  /***********************************************/
  condicionateForm = this._initializeValidations();
  get topico() {
    return this.condicionateForm.get('topico');
  }
  get descricaocondicionante() {
    return this.condicionateForm.get('condicionante');
  }
  get dataInicial() {
    return this.condicionateForm.get('dataInicial');
  }
  get dataFinal() {
    return this.condicionateForm.get('dataFinal');
  }
  get cadastroContinuo() {
    return this.condicionateForm.get('cadastroContinuo');
  }
  _initializeValidations() {
    return this.fb.group({
      topico: ['', null],
      descricaocondicionante: ['', null],
      dataInicial: ['', [Validators.required]],
      dataFinal: ['', null],
      condicionante: ['', null],
      cadastroContinuo: ['', null],
    });
  }
  /************************************************/

  config: AngularEditorConfig = {
    editable: this.flagHabilitaComponenteTextual,
    height: '15rem',
    minHeight: '5rem',
    placeholder: 'Enter text here...',
    translate: 'no',
    defaultParagraphSeparator: 'p',
    defaultFontName: 'Arial',
    toolbarHiddenButtons: [['bold']],
    customClasses: [
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText',
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1',
      },
    ],
  };

  ngOnInit() {}

  public abrir(idPPB: number, operation: string, idCondicionante?: number) {
    this.parametros.idPPB = idPPB;
    this.flagCadastroContinuo = false;
    this.operation = operation;
    this.idCondicionante = idCondicionante;

    this.carregarDados();
    this.appModalIncluirCondicionantesPPBBackground.nativeElement.style.display = 'block';
    this.appModalIncluirCondicionantesPPB.nativeElement.style.display = 'block';
  }



  private carregarDados() {
    if (this.operation == 'edit') {
      this.buscar(this.idCondicionante);
    } else {
      this.parametros.dataInicioVigenciaString = this.dataVigenciaInicialPPB;
    }

    this.obterInformacoesModal(this.parametros.idPPB).subscribe(() => {
      if (this._isPPBMultiplo) {
        this.getPPBMultiplo(this.parametros.idPPB);
      } else if (this._isPPBGrupo) {
        this.getPPBGrupo(this.parametros.idPPB);
      }

    });
  }

  obterInformacoesModal(idPPB:number){
    return new Observable((obs)=>{
      this.applicationService.get('AbrirModalListagens',idPPB).subscribe((result:any)=>{
        if(result)
        {
          this._isPPBMultiplo = result.isPPBMultiplo;
          this._isPPBGrupo = result.isPorGrupo;
          this.flagCadastroContinuo = false;
          //this.limparVariaveis()

          obs.next()
        }
      })

    })

  }

  getPPBGrupo(idPPB: number) {
    this.applicationService
      .get(this.servico + '/GetPPBGrupos', { idPPB })
      .subscribe((retorno: Array<any>) => {
        if (retorno.length != undefined && retorno.length > 0) {
          this.listaPPBGrupo = retorno;
          this._flagTrouxeItensPPBGrupo = true;
          this.serviceDropBox = 'GrupoCondicionantesPPB';
        } else {
          this._flagTrouxeItensPPBGrupo = false;
        }
      });
  }

  getPPBMultiplo(idPPB: number) {
    this.applicationService
      .get(this.servico + '/GetPPBMultiplos', { idPPB })
      .subscribe((retorno: Array<ValoresPorGrupoVM>) => {
        if (retorno.length != undefined && retorno.length > 0) {
          this.listaPPBMultiplo = retorno;
          this._flagTrouxeItensPPBMultiplo = true;
          this.serviceDropBox = 'MultiploCondicionantesPPB';
        } else {
          this._flagTrouxeItensPPBMultiplo = false;
        }
      });
  }

  fechar() {
    this.flagCadastroContinuo = false;
    this.limparVariaveis();
    this.operation = '';
    this.condicionateForm.get('dataInicial').reset();
    this.condicionateForm.get('cadastroContinuo').reset();
    this.appModalIncluirCondicionantesPPBBackground.nativeElement.style.display =
      'none';
    this.appModalIncluirCondicionantesPPB.nativeElement.style.display = 'none';
  }

  validar() {
    if (!this.parametros.descricaTopico) {
      this.modal.informacao(
        'Campo Obrigatório não Informado: <b>Condicionante</b>',
        'Atenção'
      );
      return false;
    }

    var dataInicioString = this.condicionateForm.get('dataInicial').value;
    var dataFimString = this.condicionateForm.get('dataFinal').value;

    if (!dataInicioString) {
      this._errorDataInicioNaoPreenchida = true;
      this.condicionateForm.get('dataInicial').reset();
      this.condicionateForm.get('dataFinal').reset();

      return false;
    } else {
      this._errorDataInicioNaoPreenchida = false;
    }

    if (dataInicioString && dataFimString) {
      // if(this.validation.validaDataMenorDataVigente(dataFimString)){

      // 	this._errorDataFinalMenorDataCorrente = true;
      // 	this.condicionateForm.get('dataInicial').reset();
      // 	this.condicionateForm.get('dataFinal').reset();

      // 	return false;
      // } else {
      // 	this._errorDataFinalMenorDataCorrente = false;
      // }

      if (
        this.validation.validaDataInicialMaiorDataFinal(
          dataInicioString,
          dataFimString
        ) == true
      ) {
        this._errorDataFinalMaiorInicial = true;
        this.condicionateForm.get('dataInicial').reset();
        this.condicionateForm.get('dataFinal').reset();

        return false;
      } else {
        this._errorDataFinalMaiorInicial = false;
      }
    }

    if (this.parametros.descricaCondicaoString)
      this.parametros.descricaCondicaoString = String(
        this.parametros.descricaCondicaoString
      );

    this.salvar();
  }

  salvar() {

    var idPPB = this.parametros.idPPB;
    if (this._isPPBMultiplo){
      this.parametros.idPPBMultiplo = this.parametros.idPPBMultiplo != undefined ? Number.parseInt(this.parametros.idPPBMultiplo.toString()) : null;
    }
    if (this._isPPBGrupo){
      if (this.parametros.idPPBGrupo == null && this._ppbGrupoDropList.list.length == 1){
        this.parametros.idPPBGrupo = this._ppbGrupoDropList.idSelected
      }

      this.parametros.idPPBGrupo = this.parametros.idPPBGrupo != null ? Number.parseInt(this.parametros.idPPBGrupo.toString()) : null;
    }
    this.applicationService
      .post(this.servico, this.parametros)
      .subscribe((retorno: EnumRestResponse) => {
        if (retorno > 0) {
          this.modal.infoSucess(
            this.msg.OPERACAO_REALIZADA_COM_SUCESSO,
            'Success'
          );

          if (!this.flagCadastroContinuo) {
            this.fechar();
          } else {
            this.limparVariaveis();
            this.parametros.idPPB = idPPB;

            if (this.operation == 'new')
              this.parametros.dataInicioVigenciaString =
                this.dataVigenciaInicialPPB;

            this.carregarDados();
          }

          this.atualizaListagem.emit(idPPB);
        }
      });
  }

  limparVariaveis() {
    this.parametros = new CondicionanteVM();
    this.limparCamposFormulario();
    this._errorDataFinalMaiorInicial = false;
    this._flagDesabilitaTopico = false;
    this._flagTrouxeItensPPBMultiplo = false;
  }

  limparCamposFormulario() {
    this.condicionateForm.get('topico').reset();
    this.condicionateForm.get('descricaocondicionante').reset();
    this.condicionateForm.get('dataFinal').reset();
    this.condicionateForm.get('condicionante').reset();
  }

  transformStringToBinary(input: string) {
    var bytes = [];
    if (input.length > 0) {
      for (var i = 0; i < input.length; i++) {
        var code = input.charCodeAt(i);
        bytes = bytes.concat([code]);
      }
    }
    return bytes;
  }

  buscar(idCondicionante: number) {
    var objeto: CondicionanteVM = new CondicionanteVM();
    objeto.id = idCondicionante;

    this.applicationService
      .get(this.servico, objeto)
      .subscribe((result: CondicionanteVM) => {
        this.parametros = result;

        if (result.statusCondicionante == 2) {
          //SUBSTITUTO
          this._flagDesabilitaTopico = true;
        }
      });
  }
}
