import { Component, Inject, OnInit, Output, Input, ViewChild, ElementRef, EventEmitter } from '@angular/core';
import { ApplicationService } from "../../../../shared/services/application.service";
import { ModalService } from "../../../../shared/services/modal.service";
import { PagedItems } from '../../../../view-model/PagedItems';
import { PPBHistorico } from '../../../../view-model/PPBHistoricoVM';
import { PPBListagensHistoricoEntityVM } from '../../../../view-model/PPBListagensHistoricoEntityVM';
import { RelatorioGeralListagemHistoricoExclusaoVM } from '../../../../view-model/RelatorioGeralListagemHistoricoExclusaoVM';
import jsPDF from 'jspdf'
import autoTable from 'jspdf-autotable'
import { last } from 'rxjs/operators';

@Component({
	selector: 'app-modal-historico-listagens',
	templateUrl: './modal-historico-listagens.component.html'
})

export class ModalHistoricoListagensComponent implements OnInit {

	//----GRID ITENS-----
	@Input() lista: any = {};
	@Input() total: number;
	@Input() size: number;
	@Input() page: number;
	@Input() sorted: string;
	grid: any = { sort: {} };
	tituloGrid : string = null;
  parametros : any = {};
	//--------- Serviços ---------
	servicoHistorico = "HistoricoListagens";
  exibirListagens : boolean = false;
	_idItemCorrente : number;
  _idPpb : number;


	@ViewChild('appModalHistorico') appModalHistorico : any;
	@ViewChild('appModalHistoricoBackground') appModalHistoricoBackground : any;

	constructor(
    private ModalService: ModalService,
		private applicationService : ApplicationService
	) {}

	ngOnInit() {}

  prencheParametrosDeExportacao(){
    this.parametros.exportarListagem = true;
    this.parametros.exportarPdf = true;
		this.parametros.servico = "HistoricoListagens";
		this.parametros.titulo = "Histórico des exclusões das Listagens";
		this.parametros.columns = ["Descrição Ação", "Usuário","Data e Hora"];
		this.parametros.fields = ["descricaoAcao", "nomeUsuario", "dataHoraAcaoString"];
		this.parametros.width = { 0: { columnWidth: 420 }, 1: { columnWidth: 200 },2: { columnWidth: 150 } };
	}

	abrir(idPpb){
    this._idPpb  = idPpb;
		this.appModalHistorico.nativeElement.style.display = 'block';
		this.appModalHistoricoBackground.nativeElement.style.display = 'block';
    this.exibirListagens = true;
	}

	fechar(){
    this._idPpb = null;
    this.exibirListagens = false;
		this.appModalHistoricoBackground.nativeElement.style.display = 'none';
		this.appModalHistorico.nativeElement.style.display = 'none';
	}

  gerarRelatorioGeral()
  {
    this.applicationService.get("HistoricoListagens",this._idPpb).subscribe((result: RelatorioGeralListagemHistoricoExclusaoVM) =>{

      if(result.existeHistorico)
      {

        this.parametros.columns = ["Descrição Ação", "Usuário","Data e Hora"];
        this.parametros.fields = ["descricaoAcao", "nomeUsuario", "dataHoraAcaoString"];
        this.parametros.width = { 0: { columnWidth: 420 }, 1: { columnWidth: 200 },2: { columnWidth: 150 } };

        let doc = new jsPDF('l', 'pt', 'a4');

        //ETAPAS
        if(result.etapas.total != null && result.etapas.total > 0)
        {
          doc.text("Etapas", 40, 125);
          let rowsEtapas = Array<any>();
          for (var i = 0; i < result.etapas.items.length ; i++)
          {
            let r = Array<any>();
            let valor: any; for (var j = 0; j < this.parametros.fields.length; j++) {
              var item = this.parametros.fields[j].split("|");
              valor = (item.length > 0) ?
                        Object.values(result.etapas.items)[i][item[0].trim()] :
                          Object.values(result.etapas.items)[i][this.parametros.fields[j].trim()];
              r[j] = valor;
            }
            rowsEtapas.push(r);
          }
          autoTable(doc, {
            body: rowsEtapas,
            columns: this.parametros.columns,
            startY: 140,
            tableWidth: 'auto',
            styles: {
              font: "helvetica",
              fontStyle: 'normal',
              overflow: 'linebreak',
              valign: 'middle',
            },
            columnStyles: this.parametros.width
          });
        }

        //CONDICIONANTES
        if(result.condicionantes.total != null && result.condicionantes.total > 0){
          let rowsCondicionantes = Array<any>();
          for (var i = 0; i < result.condicionantes.items.length ; i++)
          {
            let r = Array<any>();
            let valor: any; for (var j = 0; j < this.parametros.fields.length; j++) {
              var item = this.parametros.fields[j].split("|");
              valor = (item.length > 0) ?
                        Object.values(result.condicionantes.items)[i][item[0].trim()] :
                          Object.values(result.condicionantes.items)[i][this.parametros.fields[j].trim()];
              r[j] = valor;
            }
            rowsCondicionantes.push(r);
          }
          doc.cellAddPage();
          doc.text("Condicionantes", 40, 30);
          autoTable(doc, {
            body: rowsCondicionantes,
            columns: this.parametros.columns,
            tableWidth: 'auto',
            styles: {
              font: "helvetica",
              fontStyle: 'normal',
              overflow: 'linebreak',
              valign: 'middle',
            },
            columnStyles: this.parametros.width
          });
        }

        //GRUPO
        if(result.grupo.total != null && result.grupo.total > 0){
          let rowsGrupos = Array<any>();
          for (var i = 0; i < result.grupo.items.length ; i++)
          {
            let r = Array<any>();
            let valor: any; for (var j = 0; j < this.parametros.fields.length; j++) {
              var item = this.parametros.fields[j].split("|");
              valor = (item.length > 0) ?
                        Object.values(result.grupo.items)[i][item[0].trim()] :
                          Object.values(result.grupo.items)[i][this.parametros.fields[j].trim()];
              r[j] = valor;
            }
            rowsGrupos.push(r);
          }
          doc.cellAddPage();
          doc.text("Grupo", 40, 30);
          autoTable(doc, {
            body: rowsGrupos,
            columns: this.parametros.columns,
            tableWidth: 'auto',
            styles: {
              font: "helvetica",
              fontStyle: 'normal',
              overflow: 'linebreak',
              valign: 'middle',
            },
            columnStyles: this.parametros.width
          });
        }

        //METAS
        if(result.metas.total != null && result.metas.total > 0){
          let rowsMetas = Array<any>();
          for (var i = 0; i < result.metas.items.length ; i++)
          {
            let r = Array<any>();
            let valor: any; for (var j = 0; j < this.parametros.fields.length; j++) {
              var item = this.parametros.fields[j].split("|");
              valor = (item.length > 0) ?
                        Object.values(result.metas.items)[i][item[0].trim()] :
                          Object.values(result.metas.items)[i][this.parametros.fields[j].trim()];
              r[j] = valor;
            }
            rowsMetas.push(r);
          }
          doc.cellAddPage();
          doc.text("METAS", 40, 30);
          autoTable(doc, {
            body: rowsMetas,
            columns: this.parametros.columns,
            tableWidth: 'auto',
            styles: {
              font: "helvetica",
              fontStyle: 'normal',
              overflow: 'linebreak',
              valign: 'middle',
            },
            columnStyles: this.parametros.width
          });
        }

        //MULTIPLO
        if(result.ppbMultiplo.total != null && result.ppbMultiplo.total > 0){
          let rowsMultiplos = Array<any>();
          for (var i = 0; i < result.ppbMultiplo.items.length ; i++)
          {
            let r = Array<any>();
            let valor: any; for (var j = 0; j < this.parametros.fields.length; j++) {
              var item = this.parametros.fields[j].split("|");
              valor = (item.length > 0) ?
                        Object.values(result.ppbMultiplo.items)[i][item[0].trim()] :
                          Object.values(result.ppbMultiplo.items)[i][this.parametros.fields[j].trim()];
              r[j] = valor;
            }
            rowsMultiplos.push(r);
          }
          doc.cellAddPage();
          doc.text("MULTIPLO", 40, 30);
          autoTable(doc, {
            body: rowsMultiplos,
            columns: this.parametros.columns,
            tableWidth: 'auto',
            styles: {
              font: "helvetica",
              fontStyle: 'normal',
              overflow: 'linebreak',
              valign: 'middle',
            },
            columnStyles: this.parametros.width
          });
        }

        const addFooters = doc => {
          const pageCount = doc.internal.getNumberOfPages();
          doc.setFont('helvetica', 'italic')
          doc.setFontSize(8)
          for (var i = 1; i <= pageCount; i++) {
            doc.setPage(i)
            doc.text('Página ' + String(i) + ' de ' + String(pageCount), //texto
                  doc.internal.pageSize.width / 2, //largura
                  doc.internal.pageSize.height - 10, //altura
                  {align: 'center'});
          }
        }

        addFooters(doc);

        doc.save(`relatorio_historico_de_exclusao_listagens_${new Date().getTime()}.pdf`);
        //doc.output('dataurlnewwindow');
      }
      else
      {
        this.ModalService.infoError("Nenhuma listagem contém histórico de exclusão", "Informação");
      }
    });
  }

}
