import { CondicionantesPPBVM } from './CondicionantesPPBVM';

export class CondicionanteVM extends CondicionantesPPBVM {
    dataInicioVigenciaString : string = null;
    dataFimVigenciaString : string = null;
    descricaoDocumento : string = null;
    descricaCondicaoString : string;
    buscarVinculos: boolean;
    isSomenteVigente: boolean;
    idPPBMultiplo?:number;
    exibirRevogados:boolean = false;
}
