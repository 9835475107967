import { Component, OnInit, Input } from '@angular/core';
import { ApplicationService } from '../../shared/services/application.service';
import { PssService } from '../../shared/services/pss.service';
import { environment } from '../../../environments/environment';

@Component({
	selector: 'app-menu',
	templateUrl: './menu.component.html'
})

export class MenuComponent implements OnInit {

	public Pss : any = [];
	Cadastros = [];
	Externo = [];
	MeusDados = [];
	MinhasSolicitacoes = [];
	MeusProtocolos = [];
	Protocolo = [];
	Diligencias = [];
	Inscricoes = [];
	DadosCadastrais = [];
	Perfil = [];
	UsuarioPapel = [];
	Parametros = [];
	Agendamento = [];

	usuario!: string;
	cpfcnpj!: string;
  menuAnon: string;

	constructor(
		private applicationService: ApplicationService,
		private pssService: PssService,
	) { }

	ngOnInit() {
    this.menuAnon = sessionStorage.getItem("anon");
		if (environment.developmentMode || this.menuAnon == "true") {
			this.montarMenuMock();
		} else {
			this.applicationService.get('Menu').subscribe((result: any) => {
				if (result && result.length > 0)

					this.Pss = result;
				for (var i = 0; i < this.Pss.length; i++) {
					this.Pss[i].nome = this.Pss[i].nome.split("/")[1];
				}
			});

      this.applicationService.get("UsuarioLogado").subscribe((result: any) => {
        this.usuario = result.usuNomeUsuario;
        this.cpfcnpj = result.usuCpfCnpj;
      });
		}


	}

	montarMenuMock() {
		if(this.menuAnon != "true") {
      this.Pss.push({
      			nome: 'PPB',
      			id: 1,
      			funcoesSistema: [
      				{
      					nome: 'Consultar/Cadastrar PPB',
      					url: '/manter-ppb',
      					descricao: 'Consultar/Cadastrar PPB'
      				}
      				,
      				{
      				 	nome: 'Manter Tipo Documento de PPB',
      				 	url: '/manter-documento-ppb',
      				 	descricao: 'Manter Tipo de Documentos do Processo Produtivo Básico'
      				}
      			]
      		});
    }else{
      this.Pss.push({
        nome: 'PPB',
        id: 1,
        funcoesSistema: [
          {
            nome: 'Consultar PPB',
            url: '/manter-ppb',
            descricao: 'Consultar PPB'
          }
          ,
        ]
      });
    }

	}

	recuperarMenu() {
		var self = this;
		this.applicationService.get('Menu').subscribe((result: any) => {
			if (result && result.length > 0)
				var novoMenu = [];
			novoMenu = JSON.parse(result.replace(/\\"/g, '"'));
			for (var i = 0; i < novoMenu.length; i++) {
				novoMenu[i].nome = novoMenu[i].nome.split("/")[1];
			}
			this.Pss = novoMenu;

		});
	}

	trackElement(index: number, element: any) {
		return element ? element.id : null;
	}
}
