import { Component, Injectable, OnInit, ViewChild, Output, EventEmitter, Input } from "@angular/core";

import { ApplicationService } from "../../../../../shared/services/application.service";
import { ActivatedRoute } from "@angular/router";
import { AngularEditorConfig } from "@kolkov/angular-editor";
import { EtapaVM } from "../../../../../view-model/EtapaVM";
import { RequestResponseVM } from "../../../../../view-model/RequestResponseVM";
import { EnviaParametrosSelecaoItemVM } from "../../../../../view-model/EnviaParametrosSelecaoItemVM";
import { StarTemplateContext } from "@ng-bootstrap/ng-bootstrap/rating/rating";
import { PPBGrupoVM } from "../../../../../view-model/PPBGrupoVM";
import { PagedItems } from "../../../../../view-model/PagedItems";
import { CondicionanteVM } from "../../../../../view-model/CondicionanteVM";

@Component({
  selector: 'grid-mover-por-selecao',
  templateUrl: './move-por-selecao-grid.component.html'
})
export class GridMoverSelecao implements OnInit {

  idPpbCorrente : number = 0;
  grid:any = {
    lista:[],
    total:0
  }
  titleCol2 = "Tipo"
  descricaoTitle="Descrição"
  config: AngularEditorConfig = {
		height: '700px',
		minHeight: '100px',
		placeholder: 'Enter text here...',
		showToolbar: false, //flag oculta barra de edição
		translate: 'no',
		defaultParagraphSeparator: 'p',
		defaultFontName: 'Arial',
		toolbarHiddenButtons: [
		  ['bold']
		],
		customClasses: [
		  {
			name: "quote",
			class: "quote",
		  },
		  {
			name: 'redText',
			class: 'redText'
		  },
		  {
			name: "titleText",
			class: "titleText",
			tag: "h1",
		  },
		]
	};
  idItemDestino:any;
  parametros:any ={
    size:10,
    page:1,
  };
  total:number = 0;
  tituloGrid:string="Lista"

  @Input() _exibeColunaPpbMultiplo : boolean = false;
  @Input() viewRef = ""
  @Input() itemSelecionado : EnviaParametrosSelecaoItemVM = new EnviaParametrosSelecaoItemVM();

  @Output() onFinalizar: EventEmitter<any> = new EventEmitter();


  constructor(
    private applicationService: ApplicationService,
    private route: ActivatedRoute
  ) {
    this.idPpbCorrente = Number(this.route.snapshot.params['idPpb']);
  }

  ngOnInit() {
    this.listar()
  }

  listar(){

    var servico = "";
    switch(this.viewRef){
      case "Grupo":
        servico="GrupoPPB/GetPaginado";
        break;
      case "Condicionante":
        servico="CondicionantesPPB/GetPaginado";
        break;
      case "Etapa":
        servico="EtapasPPB/GetPaginado";
        break;
      case "Metas":
        servico="MetasPPB";
        break;
      case "PPB":
        servico="PPBMultiplo/GetPaginado";
        break;
    }

		var objeto : PPBGrupoVM = new PPBGrupoVM();
		objeto.idPPB = this.idPpbCorrente;
		objeto.fromGridPorGrupo = true;
    objeto.page = this.parametros.page || 1;
		objeto.size = this.parametros.size || 10;
		objeto.sort = this.parametros.sort || "NumeroSequencia";
		objeto.reverse = this.parametros.reverse || false;
		objeto.buscarVinculos = true;
		objeto.fromTelaElaboracao = true;

		this.applicationService.get(servico, objeto).subscribe((retorno : PagedItems) => {
			if(
        (retorno.total!= null && retorno.total > 0)
      ){
				this.grid.lista = retorno.items;
				this.grid.total = retorno.total;
        this.verificarDadosgrid();
			}else if(
        (this.viewRef=='Etapa' && retorno.listagemPaginado)
      ){
        this.grid.lista = retorno.listagemPaginado.items;
				this.grid.total = retorno.listagemPaginado.total;
        this.verificarDadosgrid();
      }else {
				this.grid = { sort: {} };
			}
		});
  }

  changeSize($event : any){
    this.parametros.size = $event;
    this.idItemDestino=null;
    this.listar();
  }
  changePage($event : any){
    this.parametros.page = $event;
    this.idItemDestino=null;
		this.listar()
  }

  onChangeRadioSelector(e){
    this.seleciouItemDropDownDestino(e.target.value)
  }

  seleciouItemDropDownDestino(idItemSelecionado){
    this.idItemDestino = Number(idItemSelecionado);
  }

  async buscarDescricaoEtapa(item : EtapaVM, indice : number){
		if(!item.descricaoEtapa){
			this.applicationService.get("DescricaoEtapa", {id : item.id}).subscribe((result : EtapaVM) => {
				this.grid.lista[indice].descricao = result.descricaoEtapa;
        if(this.grid.lista[indice].descricao){
          this.grid.lista[indice].existeDescricao = true
        }else{
          this.grid.lista[indice].existeDescricao = false
        }
			});
		}
	}

  verificarDadosgrid(){

    this.grid.lista = this.grid.lista.filter(x=> x.id != this.itemSelecionado.idItemOrigem);
    //this.grid.total = this.grid.total - 1;

    switch(this.viewRef){
      case "Grupo":
        this.titleCol2="Grupo"
        break;
      case "Condicionante":
        this.titleCol2="Condicionante"
        break;
      case "Etapa":
        this.titleCol2="Etapa"
        break;
      case "Metas":
        this.titleCol2="Metas";
        break;
      case "PPB":
        this.titleCol2="PPB";
        break;
    }
    for (var i = 0; i < this.grid.lista.length; i++) {
      if(this.viewRef=="PPB"){
        this.grid.lista[i].descricaoPPBMultiplo = this.grid.lista[i].descricao;
      }
      this.grid.lista[i].descricao = "";
      this.grid.lista[i].existeDescricao=false;
    }
    for (var i = 0; i < this.grid.lista.length; i++) {
      switch(this.viewRef){
        case "Condicionante":
          this.grid.lista[i].descricao = this.grid.lista[i].descricaCondicaoString;
          if(this.grid.lista[i].descricao){
            this.grid.lista[i].existeDescricao = true
          }else{
            this.grid.lista[i].existeDescricao = false
          }
          break;
        case "Etapa":
          if(this.grid.lista[i].existeDescricaoEtapa){
            this.buscarDescricaoEtapa(this.grid.lista[i],i);
          }
          break;

        case "PPB":
          this.grid.lista[i].descricao = this.grid.lista[i].descricao;
          if(this.grid.lista[i].descricao){
            this.grid.lista[i].existeDescricao = true
          }else{
            this.grid.lista[i].existeDescricao = false
          }
          break;
      }
    }

  }
  obterValorCol3(item){
    if(this.viewRef=="Metas"){
      return item.valorMeta
    }
    return item.descricaTopico
  }
  obterValorCol5(item){
    if(this.viewRef=="Metas"){
      return item.ppb.descricaoTitulo
    }

    return item.descricaoDocumento
  }
  alterarPosicao()
  {
    this.onFinalizar.emit(this.idItemDestino)
  }


}
