import { Component, Injectable, OnInit, ViewChild } from "@angular/core";
import { PagedItems } from "../../../view-model/PagedItems";
import { MessagesService } from "../../../shared/services/messages.service";
import { ActivatedRoute, Router } from '@angular/router';
import { ValidationService } from "../../../shared/services/validation.service";
import { ApplicationService } from "../../../shared/services/application.service";
import { Location } from '@angular/common';


@Component({
	selector: "app-visualizar-cadastros-de-ppb",
	templateUrl: "./visualizar-cadastros-de-ppb.component.html"
})
@Injectable()

export class VisualizarCadastrosDePPBComponent implements OnInit {

	form = this;
	grid: any = { sort: {} };
	parametros: any = {};
	ocultarFiltro: boolean = false;
	ocultarGrid: boolean = true;

	constructor(
        private route: ActivatedRoute,
		private applicationService: ApplicationService,
		private msg: MessagesService,
        private validation: ValidationService,
        private router: Router,
        private _location: Location
	) { }

	ngOnInit() { }

	ocultar() {
		if (this.ocultarFiltro === false) this.ocultarFiltro = true;
		else this.ocultarFiltro = false;
	}

	onChangeSort($event : any) {
		this.grid.sort = $event;
	}

	onChangeSize($event : any) {
		this.grid.size = $event;
	}

	onChangePage($event : any) {
		this.grid.page = $event;
    }

    voltar(){
    }

}