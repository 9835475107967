import { Component, Output, Input, OnInit, EventEmitter, ViewChild } from '@angular/core';
import { ApplicationService } from '../../../../../shared/services/application.service';
import { MessagesService } from '../../../../../shared/services/messages.service';
import { ModalService } from '../../../../../shared/services/modal.service';
import { Router } from '@angular/router';
import { ValidationService } from '../../../../../shared/services/validation.service';
import { EnumStatusPPB } from '../../../../../shared/enums/EnumStatusPPB';

@Component({
	selector: 'app-grid-vinculos-ppb',
	templateUrl: './grid.component.html'
})

export class GridVinculosPPBComponent {

	ocultarTituloGrid = true;
	desabilitarPaginacaoECabecalho = true;

	constructor(
		private applicationService: ApplicationService,
		private modal: ModalService,
		private msg: MessagesService,
		private router: Router,
		private validation: ValidationService
	) {
	}

	@Input() lista: any = {};
	@Input() total: number;
	@Input() size: number;
	@Input() page: number;
	@Input() sorted: string;
	@Input() isUsuarioInterno: boolean = false;
	@Input() exportarListagem: boolean;
	@Input() parametros: any = {};
	@Input() formPai: any;
	@Input() somenteLeitura: boolean;

	@Output() onChangeSort: EventEmitter<any> = new EventEmitter();
	@Output() onChangeSize: EventEmitter<any> = new EventEmitter();
	@Output() onChangePage: EventEmitter<any> = new EventEmitter();
	@Output() onChange: EventEmitter<any> = new EventEmitter();

	@ViewChild('appModalEtapasCondicoesPPB') appModalEtapasCondicoesPPB;
	@ViewChild('appModalFinalizar') appModalFinalizar;
	@ViewChild('appModalVinculos') appModalVinculos;

	tituloGrid = "Resumo Técnico dos Documentos"
	servico = "Validar";
	servicoFinalizar = "FinalizarPPB";

	changeSize($event) {
		this.onChangeSize.emit($event);
	}

	changeSort($event) {
		this.sorted = $event.field;
		this.onChangeSort.emit($event);
		this.changePage(this.page);
	}

	changePage($event) {
		this.page = $event;
		this.onChangePage.emit($event);
	}

	abrirListagens(idPPB){
		this.appModalEtapasCondicoesPPB.abrir(idPPB);
	}

	abrirFinalizar(id){
		this.applicationService.get(this.servicoFinalizar, {id: id}).subscribe(response => {
			if(response != null){
				this.modal.infoSucess("Operação realizada com sucesso","Sucesso");
			}else{
				this.modal.infoError("PPB com dados Inconsistentes","Erro");
				this.router.navigate(["solicitacoes-ppb/" + id + "/editar"]);
			}
		});
	}

	abrirVinculos(idPPB){
		this.appModalVinculos.abrir(idPPB);
	}

	abrirEditar(idPPB : number){
		this.router.navigate(['/solicitacoes-ppb/'+idPPB+'/editar']);
	}

	abrirVisualizar(idPPB : number){
		this.router.navigate(['/solicitacoes-ppb/'+idPPB+'/visualizar']);
	}

	excluir(){

		//this.modal.infoError("Erro ao Salvar", "Error");

		// this.modal.infoSucess("Operação realizada com Sucesso!", "Informação");

		// this.modal.informacao("Verificar acesso ao sistema", "Informação");

		// this.modal.sucesso("Operação realizada com Sucesso!", "Informação");

		// this.modal.error("Falha ao apagar registro", "Informação");

		// this.modal.confirmacao("Deseja Excluir o Registro?").subscribe( (isConfirmado : any) => {
		// 	 if(isConfirmado) {
		// 		console.log("Confirmado");
		// 	 } else {
		// 		console.log("Ñ Confirmado");
		// 	 }
		// });

	}

	formatDate(date: Date){
		return this.validation.getDateWhithChangeFormat('DD/MM/YYYY', date)
	}

	definirStatus(tipo: number): string{
		if(tipo == EnumStatusPPB.EM_ELABORACAO){
			return "Em Elaboração";
		}

		if(tipo == EnumStatusPPB.REVOGADO){
			return "Revogado";
		}

		if(tipo == EnumStatusPPB.VIGENCIA_FUTURA){
			return "Vigencia Futura";
		}

		if(tipo == EnumStatusPPB.VIGENTE){
			return "Vigente";
		}
	}

	definirVinculo(vinculo: number){
		switch(vinculo){
			case 1:
				return "FIXAÇÃO";
			case 2:
				return "ALTERAÇÃO PARA REVOGAÇÃO";
			case 3:
				return "ALTERAÇÃO PARCIAL";
			case 4:
				return "NORMA COMPLEMENTAR"
		}
	}

	validar(idPPB : number){
		this.router.navigate(["solicitacoes-ppb/" + idPPB + "/validacao"]);
		// this.applicationService.get(this.servico, {id: idPPB} ).subscribe( (response: any) => {
        //    if(response._flagExistePendencia){
		// 		this.modal.infoError("PPB com dados Inconsistentes!", "Informação");
		// 		this.router.navigate(["solicitacoes-ppb/" + idPPB + "/validacao"]);
		//    }else{
        //       console.log(response);
		//    }
		// });
	}
}
