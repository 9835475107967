import { DropDownVM } from './../../../../view-model/DropDownVM';
import { PPBVM } from './../../../../view-model/PPBVM';
import { Component, Injectable, OnInit, ViewChild } from '@angular/core';
import { MessagesService } from '../../../../shared/services/messages.service';
import { ModalService } from '../../../../shared/services/modal.service';
import { ValidationService } from '../../../../shared/services/validation.service';
import { ApplicationService } from '../../../../shared/services/application.service';
import { ActivatedRoute } from '@angular/router';
import { ValidacaoMPPBVM } from '../../../../view-model/ValidacaoMPPBVM';
import { RelatorioListagensPPBVM } from '../../../../view-model/RelatorioListagensPPBVM';
import { DocumentosVinculadosPPBVM } from '../../../../view-model/DocumentosVinculadorPPBVM';
import { PPBAnexoVM } from '../../../../view-model/PPBAnexoVM';
import { TipoConsultaRelatorioVM } from '../../../../view-model/TipoConsultaRelatorioVM';
import { ChangeCondicionantePPBService } from '../../../../shared/services/changeCondicionantePPB.service';
import { ChangeEtapaPPBService } from '../../../../shared/services/changeEtapaPPB.service';
import { ChangeGrupoPPBService } from '../../../../shared/services/changeGrupoPPB.service';
import { ChangeMetasPPBService } from '../../../../shared/services/changeMetasPPB.service';
import { ChangePpbMultiploService } from '../../../../shared/services/changePpbMultiplo.service';
import { ChangeListagemProdutosService } from '../../../../shared/services/changeListagemProdutos.service';
import { ServiceTipoListagemVM } from '../../../../view-model/ServiceTipoListagemVM';
import { DropDownsModalListagemVM } from '../../../../view-model/DropDownsModalListagemVM';
import { FiltrosModalListagemVM } from '../../../../view-model/FiltrosModalListagemVM';
import { ObjetoRelatorioModalListagemVM } from '../../../../view-model/ObjetoRelatorioModalListagemVM';
import { CondicionantesEtapasFiltragemModalListagemVM } from '../../../../view-model/CondicionantesEtapasFiltragemModalListagemVM';
import { Observable } from 'rxjs';
import { EnumTipoPublicacao } from '../../../../shared/enums/EnumTipoPublicacao';
import { EnumTipoEtapa } from '../../../../shared/enums/EnumTipoEtapa';
import { ControladorTiposEtapaPPB } from '../../../../shared/services/controladorTiposEtapaPpb.service';



interface IHabTipoEtapa {
  etapa: boolean;
  condicionante: boolean;
  meta: boolean;
  grupo: boolean;
  ppbMultiplo: boolean;
}

@Component({
  selector: 'app-modal-etapas-condicoes-ppb',
  templateUrl: './modal-listagens-ppb.component.html',
})
export class ModalEtapasCondicoesPPBAtuacao implements OnInit {
  flagIndicadoraTipoConsulta: string = 'Exibir Vigentes';
  finalizouConsultasDropDown: boolean = false;
  finalizouConsultasCabecalho: boolean = false;
  idPPB = 1;
  tipoEtapa = 0;
  tipoPublicacao = 0;
  habilitarInputs = false;
  listaDadosAtuacao: any;
  ocultarInputAnexo = false;
  parametros: any = {};
  isDesabilitado: boolean = true;
  ocultaCombos: boolean = true;
  desabilitarInputs: boolean = true;
  objetoValidacao: ValidacaoMPPBVM = new ValidacaoMPPBVM();
  titulo: string = '';
  _isSempontuacao: boolean = false;
  _isPorPontuacao: boolean = false;
  _isPorGrupo: boolean = false;
  _isPPBMultiplo: boolean = false;
  fromTelaElaboracao: boolean = false;
  buscarVinculos = false;
  _resumoTecnico: string = '';
  flagIsSomenteVigente: boolean;
  temArquivo: boolean = false;
  dataInicioPosteriormenteEscolhida: any;
  dataFimPosteriormenteEscolhida: any;

  mostraComponenteRelatorio: boolean = false;


  _dataVigenciaParams : string; //data que sera passada para etapa e condicionates.
  isAlteracaoParaRevogacao:boolean;
  isRetificacao:boolean;

  habAreasFiltros = {
    todos: {
      condicionante: true,
      etapa: true,
      grupo: true,
      meta: true,
      ppbMultiplo: true,
    } as IHabTipoEtapa,
    sPontuacao: {
      condicionante: true,
      etapa: true,
    } as IHabTipoEtapa,
    pontuacao: {
      condicionante: true,
      etapa: true,
      meta: true,
    } as IHabTipoEtapa,
    gPorPontuacao: {
      condicionante: true,
      etapa: true,
      grupo: true,
      meta: true,
    } as IHabTipoEtapa,
    multiploSemPontuacao: {
      condicionante: true,
      etapa: true,
      ppbMultiplo: true,
    } as IHabTipoEtapa,
  };
  habAreasFiltro: IHabTipoEtapa = this.habAreasFiltros.todos;

  // dados cabecalho relatorio
  objetoCabecalho: RelatorioListagensPPBVM = new RelatorioListagensPPBVM();
  objetoRelatorioFiltrado: RelatorioListagensPPBVM =
    new RelatorioListagensPPBVM();
  _tituloPPB: string;
  listaTipoDocumento: any = {};
  isDocPosterior: number;
  arrayDocumentoPosteriores: Array<DocumentosVinculadosPPBVM> =
    new Array<DocumentosVinculadosPPBVM>();
  arrayDocumentosVinculados: Array<DocumentosVinculadosPPBVM> =
    new Array<DocumentosVinculadosPPBVM>();
  arrayDropDownEtapas: Array<DropDownVM> = new Array<DropDownVM>();
  etapaSelecionada: number;
  listaAnexos = new Array<PPBAnexoVM>();
  _dataDocumento: string;
  _dataPublicacao: string;
  _dataVigencia: string;
  statusPPB: number;
  descricaoPrimeiraEtapa: string;
  flagExibeCabecalhoRelatorio: boolean = false;
  servicoDropDowns = 'DropDownsModalListagem';
  servicoDropDownEtapas = 'TipoEtapaPpbModalListagem';

  tpAlteracao: string = '';

  dropDowns: DropDownsModalListagemVM = new DropDownsModalListagemVM();
  objetoFiltro: FiltrosModalListagemVM = new FiltrosModalListagemVM();

  @ViewChild('appRelatorioConsolidado') appRelatorioConsolidado: any;
  @ViewChild('appModalEtapasCondicoesPPB') appModalEtapasCondicoesPPB: any;
  @ViewChild('appModalEtapasCondicoesPPBBackground')
  appModalEtapasCondicoesPPBBackground: any;
  @ViewChild('appModalHistorico') appModalHistorico;
  isUsuarioExterno: boolean;

  constructor(
    private changeCondicionantePPBService: ChangeCondicionantePPBService,
    private changeEtapaPPBService: ChangeEtapaPPBService,
    private changeGrupoPPBService: ChangeGrupoPPBService,
    private changeMetasPPBService: ChangeMetasPPBService,
    private changePpbMultiploService: ChangePpbMultiploService,
    private changeListagemProdutosService: ChangeListagemProdutosService,
    private applicationService: ApplicationService,
    private modal: ModalService,
    private validation: ValidationService,
    private controlTipoEtapaPPB:ControladorTiposEtapaPPB
  ) {}

  ngOnInit() {}

  abrir(objeto: PPBVM) {

    let acessoAnon = sessionStorage.getItem('anon');
    if (acessoAnon) {
      this.isUsuarioExterno = true;
    }else{
      this.isUsuarioExterno = false;
    }
    this._isSempontuacao = false;
    this._isPorPontuacao = false;
    this._isPorGrupo = false;
    this._isPPBMultiplo = false;

    this._resumoTecnico = objeto.descricaResumoTecnico;
    if(objeto.id === undefined){
      //this.idPPB = objeto;
    }
    this.idPPB = objeto.id;
    this.tipoEtapa = objeto.tipoEtapa;
    this.titulo = objeto.descricaoTitulo;
    this.tipoPublicacao = objeto.tipoPublicacao;

    this.flagIndicadoraTipoConsulta = 'Exibir Vigentes';
    this.buscarInformacoesCabecalhoRelatorio();
    this.appModalEtapasCondicoesPPBBackground.nativeElement.style.display =
      'block';
    this.appModalEtapasCondicoesPPB.nativeElement.style.display = 'block';
  }

  fechar() {
    this.flagIsSomenteVigente = false;
    this.finalizouConsultasDropDown = false;
    this._isSempontuacao = false;
    this._isPorPontuacao = false;
    this._isPorGrupo = false;
    this._isPPBMultiplo = false;
    this._resumoTecnico = null;
    this.dropDowns = new DropDownsModalListagemVM();
    this.flagIndicadoraTipoConsulta = null;
    this.objetoFiltro = new FiltrosModalListagemVM();
    this.arrayDropDownEtapas = new Array<DropDownVM>();
    this.appModalEtapasCondicoesPPBBackground.nativeElement.style.display =
      'none';
    this.appModalEtapasCondicoesPPB.nativeElement.style.display = 'none';
    this.ocultarInformacoesRelatorio();
  }

  abrirHistorico() {
    this.appModalHistorico.abrir(this.idPPB);
  }

  buscarInformacoesCabecalhoRelatorio() {

    const obj = new TipoConsultaRelatorioVM();
    obj.buscarGrids = false;
    obj.idPpb = this.idPPB;

    this.applicationService
      .get('RelatorioListagensPPB', obj)
      .subscribe((result: RelatorioListagensPPBVM) => {
        if (result) {
          this.objetoCabecalho = result;

          this._tituloPPB = result.tituloPPB;

          this.listaAnexos = result.informacoesPPB.ppbAnexoVM;

          if (this.objetoCabecalho.informacoesPPB.dataDocumentoString)
            this._dataDocumento =
              this.objetoCabecalho.informacoesPPB.dataDocumentoString;

          if (this.objetoCabecalho.informacoesPPB.dataPublicacaoString)
            this._dataPublicacao =
              this.objetoCabecalho.informacoesPPB.dataPublicacaoString;

          if (this.objetoCabecalho.informacoesPPB.dataInicioVigenciaString) {
            this._dataVigencia =
              this.objetoCabecalho.informacoesPPB.dataInicioVigenciaString;
          }

          if (this.objetoCabecalho.informacoesPPB.documentosPosteriores) {
            this.arrayDocumentoPosteriores =
              this.objetoCabecalho.informacoesPPB.documentosPosteriores;
          } else {
            this.arrayDocumentoPosteriores = null;
          }

          this.isDocPosterior =
            this.objetoCabecalho.informacoesPPB.ppbVM.statusPosterior;

          if (this.objetoCabecalho.informacoesPPB.documentosVinculados)
            this.arrayDocumentosVinculados =
              this.objetoCabecalho.informacoesPPB.documentosVinculados;

          // PUBLICAÇÕES DO TIPO ALTERACAO PARCIAL, TERAO SEUS GRIDS MONTADOS CONFORME AS REFERENCIAS.
          const tipoPublicacao = this.objetoCabecalho.informacoesPPB.ppbVM.tipoPublicacao;
          const tipoEtapa = this.objetoCabecalho.informacoesPPB.ppbVM.tipoEtapa;
          const tiposPublicacaoReferencia = this.objetoCabecalho.informacoesPPB.tipoPublicacoesDasReferencias;
          const tiposEtapaFormulado = this.controlTipoEtapaPPB.definirTiposEtapa(tipoPublicacao,tipoEtapa,tiposPublicacaoReferencia);

          this._isPPBMultiplo = tiposEtapaFormulado.isMultiplo;
          this._isPorGrupo = tiposEtapaFormulado.isPorGrupo;
          this._isPorPontuacao = tiposEtapaFormulado.isPorPontuacao;
          this._isSempontuacao = tiposEtapaFormulado.isSemPontuacao;


          this.flagExibeCabecalhoRelatorio = true;

          this.buscarDropDownTipoEtapas();
        }
      });
  }

  ocultarInformacoesRelatorio() {
    this.flagExibeCabecalhoRelatorio = false;
    this.objetoCabecalho = new RelatorioListagensPPBVM();
    this._tituloPPB = null;
    this.listaTipoDocumento = {};
    this.isDocPosterior = 0;
    this.arrayDocumentoPosteriores = new Array<DocumentosVinculadosPPBVM>();
    this.arrayDocumentosVinculados = new Array<DocumentosVinculadosPPBVM>();
    this.listaAnexos = new Array<PPBAnexoVM>();
    this._dataDocumento = null;
    this._dataPublicacao = null;
    this._dataVigencia = null;
    this.statusPPB = 0;
  }
  exibirGridPontMinima(){
    if(this.objetoCabecalho.informacoesPPB.ppbVM.tipoEtapa == EnumTipoEtapa.POR_GRUPO ||
      this.objetoCabecalho.informacoesPPB.ppbVM.tipoEtapa == EnumTipoEtapa.POR_PONTUACAO){
        return true;
      }
      return false;
  }

  changeTipoConsulta() {
    if (this.flagIsSomenteVigente) {
      this.flagIsSomenteVigente = false;
      this.flagIndicadoraTipoConsulta = 'Exibir Vigentes';
    } else {
      this.flagIsSomenteVigente = true;
      this.flagIndicadoraTipoConsulta = 'Exibir Todos';
    }

    const obj = new ServiceTipoListagemVM();
    obj.idPpb = this.idPPB;
    obj.getFromSessionStorage = false;
    obj.isSomenteVigente = this.flagIsSomenteVigente;

    this.changeCondicionantePPBService.atualizaListagemPPB(obj);
    this.changeEtapaPPBService.atualizaListagemPPB(obj);
    this.changeGrupoPPBService.atualizaListagemPPB(obj);
    this.changeMetasPPBService.atualizaListagemPPB(obj);
    this.changePpbMultiploService.atualizaListagemPPB(obj);
  }

  preencherDropDownDasListagens(
    tipoEtapa?: number,
    consultaFromDropDown?: boolean
  ) {
    const obj: DropDownsModalListagemVM = new DropDownsModalListagemVM();
    obj.idPpb = this.idPPB;
    obj.tipoEtapa = tipoEtapa ? tipoEtapa : null;


    switch (tipoEtapa.toString()) {
      case EnumTipoEtapa.SEM_PONTUACAO.toString():
        this.habAreasFiltro = this.habAreasFiltros.sPontuacao;
        break;
      case EnumTipoEtapa.POR_PONTUACAO.toString():
        this.habAreasFiltro = this.habAreasFiltros.pontuacao;
        break;
      case EnumTipoEtapa.POR_GRUPO.toString():
        this.habAreasFiltro = this.habAreasFiltros.gPorPontuacao;
        break;
      case EnumTipoEtapa.PPB_MULTIPLO.toString():
        this.habAreasFiltro = this.habAreasFiltros.multiploSemPontuacao;
        break;

      case '99':
        this.setFiltroPadrao();
        break;
    }

    this.applicationService
      .get(this.servicoDropDowns + '/GetTipoEtapa', obj)
      .subscribe((result: DropDownsModalListagemVM) => {
        if (result) {
          this.dropDowns = result;
          this.finalizouConsultasDropDown = true;

          let etapaNumber = Number(tipoEtapa);

          if (consultaFromDropDown && etapaNumber != 99) {
            this.alterarGridsPeloTipoDaEtapa(etapaNumber);
          } else if (etapaNumber == 99) {
            for (var i = 0; i < this.arrayDropDownEtapas.length; i++) {
              this.validaTipoEtapaSelecionada(this.arrayDropDownEtapas[i].id);
            }
          }
        }
      });
  }

  limpar() {
    this.objetoFiltro = new FiltrosModalListagemVM();
    let tipoPub = this.objetoCabecalho.informacoesPPB.ppbVM.tipoPublicacao;
    if (
      tipoPub == EnumTipoPublicacao.FIXAÇÃO ||
      tipoPub == EnumTipoPublicacao.FIXAÇÃO_DE_PPB_ESPECÍFICO ||
      tipoPub == EnumTipoPublicacao.NORMA_COMPLEMENTAR
    ) {
      this.preencherDropDownDasListagens(
        this.objetoCabecalho.informacoesPPB.ppbVM.tipoEtapa,
        false
      );
      this.etapaSelecionada =
        this.objetoCabecalho.informacoesPPB.ppbVM.tipoEtapa;
    } else {
      this.preencherDropDownDasListagens(99, false);
      this.etapaSelecionada = this.arrayDropDownEtapas[0].id;
    }

    this.buscarDropDownTipoEtapas();
  }

  buscar() {
    // console.log(" AQUI A CONSULTA .: " + JSON.stringify(this.objetoFiltro));

    if (
      !this.objetoFiltro.dataFim &&
      !this.objetoFiltro.dataInicio &&
      !this.objetoFiltro.descricaoPrincipal &&
      !this.objetoFiltro.idCondicionante &&
      !this.objetoFiltro.idEtapa &&
      !this.objetoFiltro.idGrupo &&
      !this.objetoFiltro.idMeta &&
      !this.objetoFiltro.idPpbMultiplo &&
      !this.objetoFiltro.tipoEtapa
    ) {
      this.modal.informacao(
        'Informe um filtro para efetuar a consulta!',
        'Atenção'
      );
      return false;
    }

    if (this.objetoFiltro.dataInicio || this.objetoFiltro.dataFim) {
      if (!this.objetoFiltro.dataInicio) {
        this.modal.informacao(
          'Vigência incorreta. Informe a data Inicio!',
          'Atenção'
        );
        return false;
      }
      if (this.objetoFiltro.dataFim) {
        if (
          this.validation.validaDataInicialMaiorDataFinal(
            this.objetoFiltro.dataInicio,
            this.objetoFiltro.dataFim
          ) == true
        ) {
          this.modal.informacao(
            'Vigência incorreta. Data Inicio maior que a Data Fim!',
            'Atenção'
          );
          return false;
        }
      }
      //se o usuario escolheu a busca somente por vigente, valida se a data inserida esta dentro da vigencia ...
      if (this.flagIsSomenteVigente) {
        //data inicio maior que a data corrente
        var currentDate = this.formatDate(new Date());
        if (
          this.validation.validaDataInicialMaiorDataFinal(
            this.objetoFiltro.dataInicio,
            currentDate
          )
        ) {
          this.modal.informacao(
            'Vigência incorreta. Ao selecionar a busca por registros somente em vigencia, a data inicio não pode ser MAIOR que a data corrente!',
            'Atenção'
          );
          return false;
        }
        //data fim menor que a data corrente
        if (this.objetoFiltro.dataFim) {
          if (
            this.validation.validaDataMenorDataVigente(
              this.objetoFiltro.dataFim
            )
          ) {
            this.modal.informacao(
              'Vigência incorreta. Ao selecionar a busca por registros somente em vigencia, a data fim não pode ser MENOR que a data corrente!',
              'Atenção'
            );
            return false;
          }
        }
      }
    }

    const obj = new ServiceTipoListagemVM();
    obj.idPpb = this.idPPB;
    obj.getFromSessionStorage = false;

    // chama a listagem dos ppbs multiplos
    if (this._isPPBMultiplo) {
      this.changePpbMultiploService.atualizaListagemPPB(obj);
    }

    // chama a listagem dos grupos
    if (this._isPorGrupo) {
      this.changeGrupoPPBService.atualizaListagemPPB(obj);
    }

    if (this._isPorPontuacao || this._isPorGrupo) {
      this.changeMetasPPBService.atualizaListagemPPB(obj);
    }

    this.changeListagemProdutosService.atualizaListagemProdutos(obj);
    localStorage.removeItem("isLoadingCond")
    this.changeEtapaPPBService.atualizaListagemPPB(obj);
    this.changeCondicionantePPBService.atualizaListagemPPB({...obj,filtro:this.objetoFiltro});
  }

  formatDate(date) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
  }

  gerarRelatorioFiltrado() {
    let parametrosParaFiltragem: ObjetoRelatorioModalListagemVM =
      new ObjetoRelatorioModalListagemVM();
    parametrosParaFiltragem.ppbId = this.idPPB;
    parametrosParaFiltragem.isSempontuacao = this._isSempontuacao;
    parametrosParaFiltragem.isPorPontuacao = this._isPorPontuacao;
    parametrosParaFiltragem.isPorGrupo = this._isPorGrupo;
    parametrosParaFiltragem.isPPBMultiplo = this._isPPBMultiplo;
    parametrosParaFiltragem.filtros = this.objetoFiltro;
    parametrosParaFiltragem.filtros.flagIsSomenteVigente =
      this.flagIsSomenteVigente;
    this.applicationService
      .get('GerarRelatorioModalListagem', parametrosParaFiltragem)
      .subscribe((result: RelatorioListagensPPBVM) => {
        if (result) {
          this.objetoRelatorioFiltrado = result;
          this.objetoRelatorioFiltrado.informacoesPPB =
            this.objetoCabecalho.informacoesPPB;
          this.mostraComponenteRelatorio = true;
        }
      });
  }

  getFiltroHabInputs(tip: number) {
    switch (tip) {
      case EnumTipoEtapa.SEM_PONTUACAO:
        return this.habAreasFiltros.sPontuacao;
      case EnumTipoEtapa.POR_PONTUACAO:
        return this.habAreasFiltros.pontuacao;
      case EnumTipoEtapa.POR_GRUPO:
        return this.habAreasFiltros.gPorPontuacao;
      case EnumTipoEtapa.PPB_MULTIPLO:
        return this.habAreasFiltros.multiploSemPontuacao;
      case 99:
        return this.habAreasFiltros.todos;
    }
  }

  setFiltroPadrao() {
    let habAreasFiltroPadrao: IHabTipoEtapa = {
      condicionante: false,
      etapa: false,
      grupo: false,
      meta: false,
      ppbMultiplo: false,
    };

    /**
       Sem Pontuação - Etapa + Condicionante
        Por Pontuação - Etapa + Condicionante + Meta
        Grupo Por Pontuação -  Etapa + Condicionante + Meta+ Grupo
        Múltiplo sem Pontuação - Etapa + Condicionante +  PPB Múltiplo
     */

    //SEM PONTUACAO
    if (this.arrayDropDownEtapas.filter((x) => x.id == 1).length) {
      habAreasFiltroPadrao.condicionante = true;
      habAreasFiltroPadrao.etapa = true;
    }
    //Por Pontuação
    if (this.arrayDropDownEtapas.filter((x) => x.id == 2).length) {
      habAreasFiltroPadrao.condicionante = true;
      habAreasFiltroPadrao.etapa = true;
      habAreasFiltroPadrao.meta = true;
    }
    //Grupo Por Pontuação
    if (this.arrayDropDownEtapas.filter((x) => x.id == 3).length) {
      habAreasFiltroPadrao.condicionante = true;
      habAreasFiltroPadrao.etapa = true;
      habAreasFiltroPadrao.meta = true;
      habAreasFiltroPadrao.grupo = true;
    }
    // Múltiplo sem Pontuação
    if (this.arrayDropDownEtapas.filter((x) => x.id == 4).length) {
      habAreasFiltroPadrao.condicionante = true;
      habAreasFiltroPadrao.etapa = true;
      habAreasFiltroPadrao.ppbMultiplo = true;
    }

    this.habAreasFiltro = habAreasFiltroPadrao;
  }

  buscarDropDownTipoEtapas() {
    let that = this;

    this.applicationService
      .get(this.servicoDropDownEtapas, this.idPPB)
      .subscribe((result: Array<DropDownVM>) => {
        for (var i = 0; i < result.length; i++) {
          this.validaTipoEtapaSelecionada(result[i].id);
        }

        this.etapaSelecionada = result[0].id;
        this.descricaoPrimeiraEtapa = result[0].descricao;

        this.habAreasFiltro = this.getFiltroHabInputs(this.etapaSelecionada);

        if (
          that.objetoCabecalho.informacoesPPB.ppbVM.tipoPublicacao ==
            EnumTipoPublicacao.FIXAÇÃO ||
          that.objetoCabecalho.informacoesPPB.ppbVM.tipoPublicacao ==
            EnumTipoPublicacao.NORMA_COMPLEMENTAR ||
          that.objetoCabecalho.informacoesPPB.ppbVM.tipoPublicacao ==
            EnumTipoPublicacao.FIXAÇÃO_DE_PPB_ESPECÍFICO
        ) {
          let value = that.objetoCabecalho.informacoesPPB.ppbVM.tipoEtapa;
          if (result.length > 1) {
            let ob = result.filter((q) => q.id == value);
            that.etapaSelecionada = ob[0].id;
            that.descricaoPrimeiraEtapa = ob[0].descricao;

            this.habAreasFiltro = this.getFiltroHabInputs(
              that.etapaSelecionada
            );

            this.preencherDropDownDasListagens(ob[0].id, false);
          } else {
            this.preencherDropDownDasListagens(value, false);
            this.arrayDropDownEtapas = result;
          }
        } else {
          this.preencherDropDownDasListagens(99, false);
          this.arrayDropDownEtapas = result;
          this.setFiltroPadrao();
        }
      });
  }

  alteraDropDownsTipoEtapa() {
    if (this.etapaSelecionada) {
      this.preencherDropDownDasListagens(this.etapaSelecionada, true);
    }
  }

  eTipoComReferencias() {
    return (
      this.arrayDropDownEtapas.length > 1 &&
      this.objetoCabecalho.informacoesPPB.ppbVM.tipoPublicacao != 1 &&
      this.objetoCabecalho.informacoesPPB.ppbVM.tipoPublicacao != 4 &&
      this.objetoCabecalho.informacoesPPB.ppbVM.tipoPublicacao != 5
    );
  }

  alteraDropDownGrupo() {
    const obj: DropDownsModalListagemVM = new DropDownsModalListagemVM();

    if (!(this.objetoFiltro.idGrupo > 0)) {
      this.objetoFiltro.idGrupo = undefined;
    }
    obj.idGrupoEscolhido = this.objetoFiltro.idGrupo;
    //grupo  = - pp multiplo
    if (this.eTipoComReferencias() && this.etapaSelecionada == 99) {
      this.habAreasFiltro.ppbMultiplo = false;
    }
    this.alteraDropDownGrupoOuPpbMultiplo(obj);
  }
  alteraDropDownPpbMultiplo() {
    const obj: DropDownsModalListagemVM = new DropDownsModalListagemVM();

    if (!(this.objetoFiltro.idPpbMultiplo > 0)) {
      this.objetoFiltro.idPpbMultiplo = undefined;
    }

    //pp multiplo  = - meta - grupo
    if (this.eTipoComReferencias() && this.etapaSelecionada == 99) {
      this.habAreasFiltro.meta = false;
      this.habAreasFiltro.grupo = false;
    }

    obj.idMultiploEscolhido = this.objetoFiltro.idPpbMultiplo;

    this.alteraDropDownGrupoOuPpbMultiplo(obj);
  }

  alteraDropDownGrupoOuPpbMultiplo(obj: DropDownsModalListagemVM) {
    this.applicationService
      .get(this.servicoDropDowns + '/GetGrupoOuPpbMultiplo', obj)
      .subscribe((result: DropDownsModalListagemVM) => {
        if (result) {
          this.carregarSeEtapaNula(result)
          this.carregarSeCondicionanteNulo(result);
          if (this.objetoFiltro.idGrupo && this.objetoFiltro.idGrupo > 0) {
            this.dropDowns.metas = result.metas;
          }
        }
      });
  }

  private carregarSeCondicionanteNulo(result: DropDownsModalListagemVM) {
    if (this.objetoFiltro.idCondicionante == null ||
      this.objetoFiltro.idCondicionante == undefined ||
      this.objetoFiltro.idCondicionante == 0)
      {
        this.dropDowns.condicionante = result.condicionante;
        this.habAreasFiltro.condicionante = false;
        setTimeout(() => {
          this.habAreasFiltro.condicionante = true;
        }, 100);
      }
  }

  alteraDropDownMetas() {
    //meta  = - pp multiplo
    //meta = grupo, etapa
    if (
      this.eTipoComReferencias() &&
      this.etapaSelecionada == 99 &&
      this.objetoFiltro.idMeta > 0
    ) {
      this.habAreasFiltro.ppbMultiplo = false;
    }

    if (this.objetoFiltro.idMeta > 0) {
      this.applicationService
        .get(this.servicoDropDowns + '/GetGruposAposSelecionarMeta', {
          id: this.objetoFiltro.idMeta,
        })
        .subscribe((result: Array<DropDownVM>) => {
          if (result) {
            if(this.objetoFiltro.idGrupo == null ||
              this.objetoFiltro.idGrupo == undefined ||
              this.objetoFiltro.idGrupo == 0 )
            {
              this.dropDowns.grupo = result;
              this.objetoFiltro.idGrupo = null;
            }
            if (!this.objetoFiltro.idEtapa) {
              this.applicationService
                .get(this.servicoDropDowns + '/GetEtapaAposSelecionarMeta', {
                  id: this.objetoFiltro.idMeta,
                })
                .subscribe((result: Array<DropDownVM>) => {
                  this.dropDowns.etapas = result;
                });
            }
          }
        });
    }
  }

  validaTipoEtapaSelecionada(tipoEtapa: number) {
    if (tipoEtapa == EnumTipoEtapa.SEM_PONTUACAO) {
      this._isSempontuacao = true;
    } else if (tipoEtapa == EnumTipoEtapa.POR_PONTUACAO) {
      this._isPorPontuacao = true;
    } else if (tipoEtapa == EnumTipoEtapa.POR_GRUPO) {
      this._isPorGrupo = true;
    } else if (tipoEtapa == EnumTipoEtapa.PPB_MULTIPLO) {
      this._isPPBMultiplo = true;
    }
  }

  alterarGridsPeloTipoDaEtapa(tipoEtapa: number) {
    switch (tipoEtapa) {
      case EnumTipoEtapa.SEM_PONTUACAO:
        this._isSempontuacao = true;
        this._isPorPontuacao = false;
        this._isPorGrupo = false;
        this._isPPBMultiplo = false;
        break;
      case EnumTipoEtapa.POR_PONTUACAO:
        this._isSempontuacao = false;
        this._isPorPontuacao = true;
        this._isPorGrupo = false;
        this._isPPBMultiplo = false;
        break;
      case EnumTipoEtapa.POR_GRUPO:
        this._isSempontuacao = false;
        this._isPorPontuacao = false;
        this._isPorGrupo = true;
        this._isPPBMultiplo = false;
        break;
      case EnumTipoEtapa.PPB_MULTIPLO:
        this._isSempontuacao = false;
        this._isPorPontuacao = false;
        this._isPorGrupo = false;
        this._isPPBMultiplo = true;
        break;
    }
  }

  selecionouEtapaOuCondicionate(origem: string) {
    this.applicationService
      .get(
        this.servicoDropDowns +
          '/GetListagensAposSelecionarEtapaOuCondicionante',
        {
          idEtapa: this.objetoFiltro.idEtapa,
          idCondicionante: this.objetoFiltro.idCondicionante,
          tpAlteracao: String(origem),
        }
      )
      .subscribe((result: DropDownsModalListagemVM) => {
        if (this.objetoFiltro.idCondicionante && origem == 'Condicionante') {
          if (!this.checkArraysIguais(this.dropDowns.etapas, result.etapas)) {
            if(this.objetoFiltro.idPpbMultiplo == null ||
              this.objetoFiltro.idPpbMultiplo == undefined ||
              this.objetoFiltro.idPpbMultiplo == 0 )
            {
              this.carregarSeEtapaNula(result);
            }
          }
        }
        if (this.objetoFiltro.idEtapa && origem == 'Etapa') {
          if (
            !this.checkArraysIguais(
              this.dropDowns.condicionante,
              result.condicionante
            )
          ) {
            if(this.objetoFiltro.idCondicionante == null ||
              this.objetoFiltro.idCondicionante == undefined ||
              this.objetoFiltro.idCondicionante == 0 )
            {
              this.dropDowns.condicionante = result.condicionante;
              this.objetoFiltro.idCondicionante = undefined;
            }

          }
        }
        if (this._isPPBMultiplo) {
          if(this.objetoFiltro.idPpbMultiplo == null ||
            this.objetoFiltro.idPpbMultiplo == undefined ||
            this.objetoFiltro.idPpbMultiplo == 0 )
          {
            this.dropDowns.ppbMultiplo = result.ppbMultiplo;
            this.objetoFiltro.idPpbMultiplo = undefined;
          }
        }
        if (this._isPorGrupo || this._isPorPontuacao) {
          if (this._isPorGrupo) {
            if(this.objetoFiltro.idGrupo == null ||
              this.objetoFiltro.idGrupo == undefined ||
              this.objetoFiltro.idGrupo == 0 )
            {
              this.dropDowns.grupo = result.grupo;
              this.objetoFiltro.idGrupo = undefined;
            }
          }
          if(this.objetoFiltro.idMeta == null ||
            this.objetoFiltro.idMeta == undefined ||
            this.objetoFiltro.idMeta == 0 )
          {
            if(this.objetoFiltro.idGrupo == null ||
              this.objetoFiltro.idGrupo == undefined ||
              this.objetoFiltro.idGrupo == 0 )
            {
              this.dropDowns.metas = result.metas;
              this.objetoFiltro.idMeta = undefined;
            }
          }
        }
      });
  }

  private carregarSeEtapaNula(result: DropDownsModalListagemVM) {
    if (this.objetoFiltro.idEtapa == null ||
      this.objetoFiltro.idEtapa == undefined ||
      this.objetoFiltro.idEtapa == 0) {
      this.dropDowns.etapas = result.etapas;
      this.habAreasFiltro.etapa = false;
      setTimeout(() => {
        this.habAreasFiltro.etapa = true;
      }, 100);
      this.objetoFiltro.idEtapa = undefined;
    }
  }

  checkArraysIguais(a1, a2) {
    return JSON.stringify(a1) === JSON.stringify(a2);
  }
}
