<app-grid 
    [(titulo)]="tituloGrid"
    [(page)]="page"
    [(size)]="size"
    [(total)]="total"
    [(parametros)]="parametros"
    (onChangeSize)="changeSize($event)"
    (onChangePage)="changePage($event)">

	<div class="table-responsive no-margin-bottom no-border">
		<table class="table">
			<thead class="table-header-color">
				<tr>
					<th class="th-sortable unset-nowrap text-left">
						<app-ordenacao field="DescricaoStatus" [sorted]="sorted" (onChangeSort)="changeSort($event)">Código</app-ordenacao>
					</th>					
					<th class="th-sortable unset-nowrap text-left">
						<app-ordenacao field="Data" [sorted]="sorted" (onChangeSort)="changeSort($event)">Descrição do Produto</app-ordenacao>
					</th>
				</tr>
			</thead>
			<tbody>				
				<!-- 
                <tr *ngFor="let item of lista"> 
                -->
                <tr>                       
					<td class="text-left">1</td>
                        <td class="text-left">Os pontos totais serão atribuídos a cada etapa de produção realizada, conforme o disposto no Anexo, sendo que a empresa deverá acumular no mínimo 54 (cinquenta e quatro) pontos por anocalendário.
                    </td>
				</tr>
			</tbody>
		</table>
	</div>
</app-grid>