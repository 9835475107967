import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { ApplicationService } from "../../../../shared/services/application.service";
import { PagedItems } from '../../../../view-model/PagedItems';
import { SolicitarCadastroDocumentoPPBVM } from '../../../../view-model/SolicitarCadastroDocumentoPPBVM';

@Component({
	selector: 'app-modal-historico-tipo-documento',
	templateUrl: './historico-tipo-documento.component.html'
})

export class ModalHistoricoTipoDocumentoComponent {

    idTipoDocumento: any;
    listaHistorico: any = new Array<SolicitarCadastroDocumentoPPBVM>();
    servicoCadastroDocumentoPPB = 'TipoDocumento';
    parametros: any = {};

    @Input() total: number;
	@Input() size: number;
	@Input() page: number;
	@Input() sorted: any = {};

    @ViewChild('appModalHistorico') appModalHistorico : any;
	@ViewChild('appModalHistoricoBackground') appModalHistoricoBackground : any;

    constructor(private applicationService: ApplicationService){

    }

    changeSize($event) {
		this.size = $event;
        this.buscar();
	}

	changeSort($event) {
		this.sorted = $event;
		this.buscar()
	}

	changePage($event) {
		this.page = $event;
		this.buscar();
	}

    abrir(idTipoDocumento) {
        this.idTipoDocumento = idTipoDocumento;
        this.buscar();
        this.appModalHistorico.nativeElement.style.display = 'block';
        this.appModalHistoricoBackground.nativeElement.style.display = 'block';  
    }

    fechar() {
        this.idTipoDocumento = null;
        this.listaHistorico = new Array<SolicitarCadastroDocumentoPPBVM>();
        this.total = 0;
        this.appModalHistoricoBackground.nativeElement.style.display = 'none';
        this.appModalHistorico.nativeElement.style.display = 'none';
    }

    buscar(){

        this.parametros.page = this.page || 1;
        this.parametros.size = this.size || 10;
        this.parametros.sort = this.sorted.field || 'DataHoraAcao';
        this.parametros.reverse = this.sorted.reverse;
        this.parametros.id = this.idTipoDocumento;

        this.applicationService.get(this.servicoCadastroDocumentoPPB+'/historicoTipoDocumento', this.parametros)
            .subscribe((result: PagedItems) => {
                if(result.total != 0){
                    this.listaHistorico = result.items;
                    this.total = result.total;
                }else{
                    this.listaHistorico = new Array<SolicitarCadastroDocumentoPPBVM>();
                    this.total = 0;
                }
            });
    }

}